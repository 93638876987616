import { connect } from 'react-redux';

import { DashboardActionsType } from '@modules/dashboard/actions/dashboardActionsTypes';
import { acceptConsentsRequest } from '@modules/profile/actions/profileActions';
import { AppState } from '@modules/index';
import { ProfileActionsType } from "@modules/profile/actions/profileActionsTypes";
import {
  getConsents,
  getProfileError
} from '@modules/profile/selectors';

import { CGUComponent } from './Cgu.component';

const mapStateToProps = (state: AppState) => {
  return {
    consents: getConsents(state),
    error: getProfileError(state),
    isAcceptLoading: state.isLoading[ProfileActionsType.ACCEPT_CONSENTS_REQUEST],
    isGetAccountsLoading: state.isLoading[DashboardActionsType.GET_ACCOUNTS_REQUEST]
  };
};

const mapDispatchToProps = {
  onAcceptCGU: acceptConsentsRequest
};

export const Cgu = connect(mapStateToProps, mapDispatchToProps)(CGUComponent);
