import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

interface StraightLineProps {
  orientation: 'row' | 'column'
  containerStyle?: StyleProp<ViewStyle>
  isActif?: boolean
}

interface StraightLineStyles{
  containerStyle?: StyleProp<ViewStyle>
}

type StyleContext = {theme:ThemeType, orientation: 'row' | 'column', isActif?: boolean}

export const StraightLine:React.FC<StraightLineProps> = ({
  orientation,
  containerStyle,
  isActif
})=>{
  const theme = useTheme();
  const styles = useStyle({ theme, orientation, isActif }, {
    containerStyle
  });

  return (
    <View style={styles.containerStyle}/>
  );
};

const getStyles = (
  context?: StyleContext,
  style?: StraightLineStyles
): StraightLineStyles =>({
  containerStyle: [
    {
      alignSelf: 'center',
      backgroundColor: context?.isActif ? context?.theme.colors.basics.primary.c500 : context?.theme.colors.basics.grey.c200
    },
    context?.orientation === 'row' ? {
      maxHeight: 2,
      minHeight: 2,
      flex: 1 // TBD : add new property to metrics for bordersize
    } : {
      maxWidth: 2,
      minWidth: 2,
      flex: 1
    },
    style?.containerStyle
  ]
});

const useStyle = createUseStyles(getStyles);
