import React, { useMemo } from 'react'
import { Pressable, View } from 'react-native'

import { FontIcon, Text } from '@ere-uilib/atoms'
import { IconEnum } from '@ere-uilib/enums'
import { InputEditAmmont } from '@ere-uilib/molecules/inputs'
import { useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'

import { AVAILABILITY_TYPE, DueDateProps } from './interface'
import { useStyles } from './useStyles'

export const DueDateCard: React.FC<DueDateProps> = ({
  scheduleCode = '',
  amount = 0,
  numberOfShares = 0,
  date = undefined,
  ontriggerDueDateEditPress,
  onLinkUnderFieldEchenacePress,
  dueDateFieldAmount
}) => {
  const styles = useStyles()
  const theme = useTheme()
  const { formatMessage, formatCurrencyNumber, formatNumber, formatDate } = useTranslation()

  const renderAvailabilityData = useMemo(() => {
    switch (scheduleCode) {
      case AVAILABILITY_TYPE.AVAILABLE: {
        return {
          label: formatMessage({ id: 'MaturityArbitrageFeatureAvailableNow' }),
          icon: IconEnum.CLOCK
        }
      }
      case AVAILABILITY_TYPE.UNAVAILABLE: {
        return {
          label: `${formatMessage({
            id: 'MaturityArbitrageFeatureAvailableAtMaturity'
          })} ${formatDate({
            value: date,
            options: {
              dateStyle: 'short'
            }
          })}`,
          icon: IconEnum.CALENDAR_5
        }
      }
      case AVAILABILITY_TYPE.RETIREMENT: {
        return {
          label: formatMessage({ id: 'MaturityArbitrageFeatureAvailableForRetirement' }),
          icon: IconEnum.RETIREMENT
        }
      }
      default: {
        return {
          label: '',
          icon: ''
        }
      }
    }
  }, [scheduleCode, date])

  const deInvestTitle =
    dueDateFieldAmount > 0 && dueDateFieldAmount < amount
      ? formatMessage({ id: 'MaturityArbitrageFeatureDivestTheRest' })
      : formatMessage({ id: 'MaturityArbitrageFeatureDivestAll' })
  return (
    <View style={styles.container}>
      <FontIcon
        color={theme.colors.basics.grey.c900}
        name={renderAvailabilityData?.icon}
        size={theme.metrics.iconSizes.xm}
        style={styles.icon}
      />
      <View style={styles.detailsContainer}>
        <Text
          variant="t3"
          weight="light">
          {renderAvailabilityData?.label}
        </Text>
        <Text
          variant="t3"
          weight="regular">
          {formatCurrencyNumber({ value: amount })}
        </Text>
        <Text
          variant="t3"
          weight="light">
          {`${formatNumber({
            value: numberOfShares,
            options: { maximumFractionDigits: 4 }
          })} ${formatMessage({ id: 'MaturityArbitrageNumberOfShares' })}`}
        </Text>
      </View>
      <View style={styles.amountContainer}>
        <InputEditAmmont
          amount={dueDateFieldAmount}
          isExtended
          onPress={ontriggerDueDateEditPress}
          options={{ minimumFractionDigits: 0 }}
          showSign={false}
        />
        {!!dueDateFieldAmount && (
          <Pressable onPress={onLinkUnderFieldEchenacePress}>
            <Text
              style={styles.textAmount}
              variant="t3"
              weight="regular">
              {deInvestTitle}
            </Text>
          </Pressable>
        )}
      </View>
    </View>
  )
}
