import React from 'react';
import {
  View,
  StyleProp,
  ViewStyle,
  NativeSyntheticEvent,
  Modal as RNModal
} from 'react-native';

import { useTheme, ThemeType, createUseStyles, useScreenSizes } from '@ere-uilib/styles';

interface Props {
  visible?: boolean;
  transparent?: boolean;
  animationType?: 'none' | 'slide' | 'fade';
  onShow?: (event: NativeSyntheticEvent<any>) => void;
  children: React.ReactNode;
  overlayStyle?: StyleProp<ViewStyle>;
}

type ModalStyles = {
  overlayStyle?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType, isMobile: boolean };

export const Modal: React.FC<Props> = ({
  visible = false,
  transparent = true,
  animationType = 'fade',
  onShow,
  children,
  overlayStyle
}) => {

  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useStyles({ theme, isMobile }, { overlayStyle });

  return (
    <RNModal
      animationType={animationType}
      onShow={onShow}
      transparent={transparent}
      visible={visible}>
      <View
        style={styles.overlayStyle}>
        {children}
      </View>
    </RNModal>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: ModalStyles,
): ModalStyles => ({
  overlayStyle: [
    {
      flexDirection: 'column',
      justifyContent: context?.isMobile ? 'flex-end' : 'center',
      alignItems: 'center',
      backgroundColor: context?.theme.colors.background.poppinFilter.c500,
      width: '100%',
      height: '100%'
    },
    style?.overlayStyle
  ]
});

const useStyles = createUseStyles(getStyles);
