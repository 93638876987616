import React from 'react';

import { InformativePopin } from '@ere-uilib/organisms/overlay/InformativePopin';

interface Props {
  isModalVisible: boolean;
  onValidate: () => void;
  title: string;
  description: string;
  buttonTitle: string;
}

export const ConfirmTriggerThresholdPopin: React.FC<Props> = ({
  isModalVisible,
  onValidate,
  title,
  description,
  buttonTitle,
}) => {

  return (
    <InformativePopin
      buttonTitle={buttonTitle}
      description={description}
      iconName="idea"
      isModalVisible={isModalVisible}
      onClose={onValidate}
      onValidate={onValidate}
      title={title}
    />
  );
};
