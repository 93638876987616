import React from 'react';

import { LoaderPlaceholderView } from '../LoaderPlaceholderView';

interface Props {
  diameter?: number
  testID?: string
}

export function CircleLoader({
  diameter,
  testID,
}: Props) {
  return (
    <LoaderPlaceholderView
      style={{ width: diameter, height: diameter }}
      testID={testID} />
  );
}
