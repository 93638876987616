import React, { ReactNode } from 'react';
import { View, StyleProp, ViewStyle, TextStyle, Platform } from 'react-native';

import { Text, useTranslation } from '@components/index';
import { createUseStyles, useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';

interface ProfileBankAccountCardProps {
  children: ReactNode;
  cardTitle: string;
  eligibleDispositifs: string;
  isES?: boolean;
  isEditable?: boolean;
  containerBackground?: string;
}

interface ProfileBankAccountCardStyles {
  containerStyle?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  cardStyle?: StyleProp<ViewStyle>
  firstLinesStyle?: StyleProp<TextStyle>
  informLinkStyle?: StyleProp<TextStyle>
  lastLinesStyle?: StyleProp<TextStyle>
  editButtonStyle?: StyleProp<ViewStyle>
}

type StyleContext = {
  theme: ThemeType;
  containerBackground: string;
  isDesktop: boolean;
}

type Props = ProfileBankAccountCardProps & ProfileBankAccountCardStyles;

export const ProfileBankAccountCard: React.FC<Props> = ({
  cardTitle,
  eligibleDispositifs,
  isES,
  children,
  containerBackground,
  containerStyle,
  contentStyle,
  firstLinesStyle,
  lastLinesStyle,
  editButtonStyle
}) => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useStyle(
    {
      theme, isDesktop,
      containerBackground: containerBackground || theme.colors.basics.white
    },
    { containerStyle, contentStyle, firstLinesStyle, lastLinesStyle, editButtonStyle }
  );
  const { formatMessage } = useTranslation();

  return (
    <View style={styles.containerStyle}>
      <View style={styles.cardStyle}>
        <View style={styles.contentStyle}>
          <Text
            style={styles.firstLinesStyle}
            variant="t2"
            weight="bold"
          >
            {cardTitle}
          </Text>

          <Text
            style={styles.lastLinesStyle}
            variant="t3"
            weight="regular"
          >
            {formatMessage({ id: 'CoordonneesBancairesRecapDispositifsEligibles' }) + " : "}
            <Text
              style={styles.lastLinesStyle}
              variant="t3"
              weight="light"
            >
              {eligibleDispositifs}
            </Text>
          </Text>
        </View>
      </View>
      {children}
    </View>
  );
};

const getStyles = (
  context?: StyleContext,
  style?: ProfileBankAccountCardStyles
): ProfileBankAccountCardStyles => ({
  containerStyle: [
    (context?.isDesktop || context?.isDesktop) ? {
      minWidth: context?.theme.metrics.contentSizes.centeredContentMaxWidth,
    }
      :
      {
        minWidth: "100%",
      }
    ,
    {
      justifyContent: 'flex-start',

      minHeight: context?.theme?.metrics.spacing.xxhuge,
      marginBottom: context?.theme?.metrics.spacing.xl,
      borderRadius: context?.theme.metrics.borderRadius.xs,
      backgroundColor: context?.theme.colors.basics.white,
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowColor:
        Platform.OS === 'android'
          ? context?.theme.colors.basics.black
          : context?.theme.colors.notifications.shadow,
      shadowOpacity: 1,
      shadowRadius: 10,
      elevation: 7,
    },
    style?.containerStyle
  ],

  buttonStyle: [
    {
      borderWidth: 1,
      alignSelf: 'flex-start',
    },
    style?.buttonStyle
  ],
  cardStyle: [
    {

    }, style?.cardStyle
  ],
  contentStyle: [
    {

      paddingTop: context?.theme?.metrics.spacing.m,
      paddingBottom: context?.theme?.metrics.spacing.m,
      marginHorizontal: context?.theme?.metrics.spacing.m
    }, style?.contentStyle
  ],
  firstLinesStyle: [{ textAlign: 'left', marginBottom: context?.theme?.metrics.spacing.m },
  style?.firstLinesStyle],
  informLinkStyle: [{ textAlign: 'left', marginBottom: context?.theme?.metrics.spacing.m, color: context?.theme.colors.notifications.warning.c500 },
  style?.informLinkStyle],
  lastLinesStyle: [{ textAlign: 'left' },
  style?.lastLinesStyle],
  editButtonStyle: [{
    width: context?.theme?.metrics.spacing.xxxl,
    alignSelf: 'stretch',
    paddingTop: context?.theme?.metrics.spacing.l,
    alignItems: 'center'
  },
  style?.editButtonStyle]
});

const useStyle = createUseStyles(getStyles);
