
export const PISTEPS = [
  'PIMonInteressementEtapesDefinitionBesoinsLabel',
  'PIMonInteressementEtapesVerificationValidationLabel',
];

export const PiIncentiveStepsNormal = [
  'PIMonInteressementEtapesDefinitionBesoinsLabel',
  'PIMonInteressementSaisieLabel',
  'PIMonInteressementEtapesVerificationValidationLabel',
];

export const PiIncentiveStepsComplementary = [
  'PIMonInteressementEtapesDefinitionBesoinsLabel',
  'PIMonInteressementCOMPTitre',
  'PIMonInteressementEtapesVerificationValidationLabel',
];

export const PiIncentiveStepsExceptional = [
  'PIMonInteressementEtapesDefinitionBesoinsLabel',
  'PIMonInteressementEXCEPTitre',
  'PIMonInteressementEtapesVerificationValidationLabel',
];

export const PiProfitSharingStepsNormal = [
  'PIMonInteressementEtapesDefinitionBesoinsLabel',
  'PIMaParticipationSaisieLabel',
  'PIMonInteressementEtapesVerificationValidationLabel',
];

export const PiProfitSharingStepsComplementary = [
  'PIMonInteressementEtapesDefinitionBesoinsLabel',
  'PIMaParticipationCOMPTitre',
  'PIMonInteressementEtapesVerificationValidationLabel',
];

export const PiProfitSharingStepsExceptional = [
  'PIMonInteressementEtapesDefinitionBesoinsLabel',
  'PIMaParticipationEXCEPTitre',
  'PIMonInteressementEtapesVerificationValidationLabel',
];

