import { createNavigationContainerRef } from '@react-navigation/native';
import { StackActions } from '@react-navigation/routers';
import React from 'react';
import { AppNavigatorInterface } from './Interfaces/AppNavigatorInterface';

type CustomReplace<RouteName extends keyof AppNavigatorInterface> = (
  screen: RouteName,
  params?: AppNavigatorInterface[RouteName]
) => void

export const navigationRef = createNavigationContainerRef();

const navigate = navigationRef.navigate;

const goBack = navigationRef.goBack;

const isReady = navigationRef.isReady;

const replace: CustomReplace<keyof AppNavigatorInterface> = (
  screen,
  params
) => {
  // replace is a Stack specific action and does not exist on navigation ref
  // we use navigation dispatch and StackActions to perform this action
  navigationRef.current?.dispatch(StackActions.replace(screen, params));
};

export const RootNavigation = {
  navigate,
  goBack,
  isReady,
  replace
};
