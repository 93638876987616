import React, { useCallback, useState } from 'react';
import { View, Text as RNText } from 'react-native';

import { featureFlipping } from '@config/index';
import { FontIcon, Link, SVGLocalLoader, Text } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { useStyles } from './useStyles';
import { locator } from '@constants/locator';

interface Props {
  amount: number;
  createdAt: string;
  nextPaymentDate: string;
  periodicity: string;
  planName: string;
  isES: boolean;
}

export function SubHeader({
  amount,
  createdAt,
  nextPaymentDate,
  periodicity,
  planName,
  isES,
}: Props) {
  const { formatMessage, formatCurrencyNumber } = useTranslation();
  const { subHeaderStyle: styles } = useStyles();
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);

  const handlePressDetails = useCallback(() => {
    setShowDetails(!showDetails);
  }, [showDetails]);

  const Header = useCallback(() => (
    <View style={styles.headerContainer}>
      <View style={styles.header}>
        <View style={styles.leftHeader}>
          <Text
            testID={locator._vvp_details._plan_title}
            weight="bold">
            {formatMessage({ id: 'VV_VVPDetail_titre' })}
            {' '}
            {planName}
          </Text>
        </View>
        <View style={styles.rightHeader}>
          <Text
            testID={locator._vvp_details._payment_amount}
            weight="bold">
            {formatCurrencyNumber({ value: amount })}
          </Text>
          {featureFlipping.vvpTaxDetails &&
            <Link
              onPress={handlePressDetails}
              textStyle={styles.taxationDetailsLink}
            >
              {formatMessage({ id: 'VV_VVP_Detail_lien', defaultMessage: 'Détails' })}
              <FontIcon
                name={showDetails ? IconEnum.CHEVRON_TOP : IconEnum.CHEVRON_BOTTOM}
                size={theme.metrics.iconSizes.xxxs}
                style={{ marginLeft: theme.metrics.spacing.xs }}
              />
            </Link>
          }
        </View>
      </View>
    </View>
  ), [
    amount,
    formatCurrencyNumber,
    formatMessage,
    handlePressDetails,
    showDetails,
    styles,
    theme,
  ]);

  const Details = useCallback(() => (
    <View style={styles.taxationDetails}>
      <View style={styles.taxationDetailsLeft}>
        <View>
          <Text
            variant="t4"
            weight="light"
          >
            {formatMessage({
              id: 'VV_VVP_Detail_ref_label',
            })}
          </Text>
          <Text
            variant="t4"
            weight="light">O-12719783912719783920</Text>
        </View>
      </View>
      <View>
        <View style={styles.taxationDetailsRightRow}>
          <Text
            variant="t4"
            weight="light">{formatMessage({ id: 'VV_VVP_detailMontantNet_description' })}</Text>
          &nbsp;<Text
            variant="t4"
            weight="light">XX.XX.XX</Text>
        </View>
        <View style={styles.taxationDetailsRightRow}>
          <Text
            variant="t4"
            weight="light">{formatMessage({ id: 'NO_LABEL', defaultMessage: 'Frais' })}</Text>
          &nbsp;<Text
            variant="t4"
            weight="light">XX.XX.XX</Text>
        </View>
        <View style={styles.taxationDetailsRightRow}>
          <Text
            variant="t4"
            weight="light">{formatMessage({
              id: 'NO_LABEL',
              defaultMessage: 'Prélèvement sociaux',
            })}</Text>
          &nbsp;<Text
            variant="t4"
            weight="light">XX.XX.XX</Text>
        </View>
      </View>
    </View>
  ), [formatMessage, styles]);

  const renderNextDate = useCallback(() => {
    if (isES) {
      return (
        <>
          {formatMessage({ id: 'VV_VVPDetail_dateProchVersES_label' })}
          <RNText style={styles.valueTexts}>{nextPaymentDate}</RNText>
        </>
      );

    }
    return formatMessage({ id: 'VV_VVPDetail_dateProchVersRC_label' });
  }, [nextPaymentDate, formatMessage, isES, styles]);

  const Content = useCallback(() => (
    <View>
      {featureFlipping.vvpTaxDetails && showDetails && (
        <Details />
      )}
      <View style={styles.contentContainer}>
        <View style={styles.leftContentPart}>
          <View style={styles.dateCreationContainer} >
            <Text
              testID={locator._vvp_details._creation_date}
              variant="t4"
              weight="light"
            >
              {formatMessage({ id: 'VV_VVP_Detail_dateCreation_label' })}
              <RNText style={styles.valueTexts}>{createdAt}</RNText>
            </Text>
          </View>
          <View >
            <Text
              variant="t4"
              weight="light"
            >
              {formatMessage({ id: 'VV_VVP_Detail_periodicite_label' })}
              <RNText style={styles.valueTexts}>{periodicity}</RNText>
            </Text>
          </View>
        </View>
        <View style={styles.rightContentPart}>
          <Text
            style={styles.nextDateText}
            variant="t4"
            weight="light"
          >
            {renderNextDate()}
          </Text>
        </View>
      </View>
    </View>
  ), [
    showDetails,
    Details,
    formatMessage,
    createdAt,
    periodicity,
    renderNextDate,
    styles,
  ]);

  return (
    <View style={styles.container}>
      <View style={styles.imageBackground}>
        <SVGLocalLoader
          name="notchedWheel"
          width={100}
        />
      </View>
      <View style={styles.detailsContainer}>
        <Header />
        <Content />
      </View>
    </View>
  );
}
