import { Context, Styles } from './interface';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => ({

  loaderContainer: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: context?.theme?.metrics.spacing.xs,
    },
    style?.loaderContainer,
  ],
  loader: [
    { flex: 1, marginLeft: context?.theme?.metrics.spacing.xm },
    style?.loader,
  ],
  pageContainer: [
    { 
      margin: context?.theme?.metrics.spacing.xm 
    },
    style?.pageContainer,
  ],
  bottomActionsStyle: [
    {
    },
    !(context?.isMobile || context?.isTablet) && {
      alignItems: 'flex-start',
      marginTop: context?.theme.metrics.spacing.xl,
    },
    style?.bottomActionsStyle,
  ],
  simpleButtonStyle: [{
    
  },
  style?.simpleButtonStyle,
  ],
  notificationContainerStyle: [{
    maxWidth: 550,
    flexDirection: 'column',
  },
  style?.notificationContainerStyle,
  ],
  premiumAmountCard: [
    {
      flex: 1, 
      maxWidth: 550,
      marginVertical: context?.theme.metrics.spacing.xm,
    }
  ],
});
