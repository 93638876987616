import React, { useCallback, useState } from 'react'
import { Linking, Platform, Pressable, Text as RNText, TouchableOpacity, View } from 'react-native'

import { PlanFamillyList } from '@constants/index'
import { RouteNames } from '@constants/navigation'
import { FontIcon, Link, Text, Title } from '@ere-uilib/atoms'
import { CheckBox, RadioButton } from '@ere-uilib/molecules'
import { InputEditAmountWithLinkAndContribution } from '@ere-uilib/organisms'
import { useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'
import { RootNavigation } from '@navigation/RootNavigation'
import { SelectTypeEnum } from '@ere-uilib/enums'
import { useStyles } from './styles'
import { Props } from './types'
import { SavingRepartitionCardSelectTypeEnum } from '@components/ERE360Components'

export const ManagementWrapper: React.FC<Props> = ({
  title,
  isExpandable,
  totalAmount,
  fieldAmount,
  hasIncorrectDrivingData,
  isDisplayingExtraManagement,
  visibleHeader,
  children,
  onSelectSupportMasterChange,
  onEditMasterAmountPress,
  onLinkUnderFieldPress,
  isFieldAmountPercentage,
  selectType,
  disabled,
  isSelected,
  isSelectable,
  isSupportMasterSelectable,
  isSupportMasterSelected,
  isFundsDisabled,
  onSelectChange,
  fieldAmountPercent,
  mainStyle,
  backGroundColorCustom,
  linkUnderfieldLabel,
  cardTitle,
  isExtraManagementEditable,
  visibleInvestedAmount,
  isFieldPercentagePlusAmount,
  isContributionDisplayed,
  contribution,
  isContributionError,
  onResetAmountPress,
  isContributionLast,
  isLinkUnderfieldAlwaysEnabled,
  isLinkUnderField,
  onLinkButtonPress
}: Props) => {
  const theme = useTheme()
  const { formatMessage, formatCurrencyNumber, formatPercentNumber } = useTranslation()

  const [expanded, setExpanded] = useState(!isExpandable)

  const styles = useStyles({
    backGroundColorCustom,
    isFundsDisabled,
    mainStyle,
    disabled
  })

  const toggleExpanded = useCallback(() => {
    if (isExpandable) setExpanded(prev => !prev)
  }, [isExpandable])

  const renderCommonData = useCallback(
    ({ label, value, valueColor }: { label: string; value: string; valueColor?: string }) => {
      return (
        <Title
          style={styles.commonLabelTextStyle}
          variant="t7"
          weight="light">
          {label}
          <RNText
            style={[
              {
                // set before styles because styles should replace color when disabled.
                color: valueColor
              },
              styles.commonValueTextStyle
            ]}>
            {value}
          </RNText>
        </Title>
      )
    },
    [styles]
  )

  const renderSelection = useCallback(() => {
    if (!isSelectable || disabled) {
      return null
    }

    switch (selectType) {
      case
        SelectTypeEnum.CHECKBOX:
        return (
          <CheckBox
            containerStyle={styles.checkBoxStyle}
            onPress={onSelectChange}
            value={(isSelectable && isSelected) || false}
          />
        )
      case
        SelectTypeEnum.RADIO:
        return (
          <RadioButton
            containerStyle={styles.checkBoxStyle}
            onPress={onSelectChange}
            value={(isSelectable && isSelected) || false}
          />
        )
      default:
        return null
    }
  }, [isSelected, selectType, isSelectable, disabled, onSelectChange, styles])

  const renderSelectionMaster = useCallback(() => {
    if (!isSupportMasterSelectable || disabled) {
      return null
    }
    return (
      <RadioButton
        containerStyle={styles.checkBoxStyle}
        onPress={onSelectSupportMasterChange}
        value={(isSupportMasterSelectable && isSupportMasterSelected) || false}
        testId={`checkBox_${cardTitle}`}
      />
    )
  }, [
    isSupportMasterSelected,
    isSupportMasterSelectable,
    disabled,
    onSelectSupportMasterChange,
    styles
  ])

  const renderAmountManagementInvested = useCallback(() => {
    if (!visibleInvestedAmount) {
      return null
    }
    return renderCommonData({
      label: formatMessage({ id: 'repartition_support_actualposition_label' }),
      value: formatCurrencyNumber({ value: totalAmount || 0 })
    })
  }, [totalAmount, formatMessage, formatCurrencyNumber, renderCommonData, visibleInvestedAmount])

  const handleLinkButtonPress = useCallback(() => {
    onLinkButtonPress?.();
  }, [onLinkButtonPress]);

  return (
    <View style={styles.contentStyle}>
      <View style={{ width: '100%' }}>
        {visibleHeader && (
          <TouchableOpacity
            disabled={isFundsDisabled}
            onPress={toggleExpanded}
            style={styles.mainStyle}>
            <View style={styles.headerStyle}>
              <View 
                style={styles.headerLeftPartStyle}
                testID={title}>
                {isSelectable ? (
                  <View style={styles.checkBoxContainer}>
                    {renderSelection()}
                    <Pressable
                      disabled={false}
                      // onPress={isFundsSheetDisabled ? undefined : onCardPress}
                      onPress={() => {}}
                      // style={styles.supportNameContainerStyle}
                    >
                      <Title
                        style={styles.titleTextStyle}
                        variant="t7"
                        weight="bold">
                        {title}
                      </Title>
                    </Pressable>
                  </View>
                ) : (
                  <Title
                    style={styles.titleStyle}
                    variant={'t7'}
                    weight="bold">
                    {title}
                  </Title>
                )}
              </View>
              <View>
                {!!fieldAmountPercent && isFieldAmountPercentage && (
                  <Title
                    style={styles.titleStyle}
                    variant={'t6'}
                    weight="bold">
                    {formatPercentNumber({ value: fieldAmountPercent || 0 })}
                  </Title>
                )}
                {isExpandable && (
                  <FontIcon
                    color={theme.colors.basics.black}
                    name={expanded ? 'chevron-haut' : 'chevron-bas'}
                    size={theme.metrics.iconSizes.xm}
                    style={styles.chevronStyle}
                  />
                )}
              </View>
            </View>
          </TouchableOpacity>
        )}
      </View>

      <View style={styles.bodyContainerStyle}>
        {isDisplayingExtraManagement && (
          <>
            {hasIncorrectDrivingData ? (
              <>
                <View style={styles.managementDetailContainer}>
                  <View style={styles.managementDetailLeftContainer}>
                    <View style={styles.cardTitleContainerStyle}>
                      <Text
                        style={styles.textStyle}
                        variant={'t3'}
                        weight={'light'}>
                        {formatMessage({
                          id: 'VV_repartition_riskProfileUndefined_label'
                        })}
                      </Text>

                      <Link
                        onPress={handleLinkButtonPress}
                        textStyle={styles.linkStyle}
                        textVariant='t3'
                        textWeight='regular'
                      >{formatMessage({
                        id: 'VV_repartition_riskProfileUndefinedLien_label'
                      })
                        }</Link>
                    </View>

                    <Pressable
                      disabled={false}
                      onPress={toggleExpanded}>
                      <View style={styles.checkBoxContainer}>
                        <Text
                          style={styles.subTitle}
                          variant="t4">
                          {formatMessage({
                            id: 'Arbitrage_desinvest_Tous_Les_Supports'
                          })}
                        </Text>
                        <FontIcon
                          color={theme.colors.text.link.c500}
                          name={expanded ? 'chevron-haut' : 'chevron-bas'}
                          size={theme.metrics.iconSizes.xm}
                          style={styles.chevronStyle}
                        />
                      </View>
                    </Pressable>
                  </View>
                </View>
              </>
            ) : (
              <>
                <View style={styles.managementDetailContainer}>
                  <View style={styles.managementDetailLeftContainer}>
                    {(isSelectable || cardTitle) && (
                      <View style={styles.cardTitleCheckBoxContainer}>
                        {renderSelectionMaster()}
                        <View style={styles.cardTitleContainerStyle}>
                          <Title
                            style={styles.cardTitleTextStyle}
                            variant="t7"
                            weight="bold">
                            {cardTitle}
                          </Title>
                        </View>
                      </View>
                    )}
                    {renderAmountManagementInvested()}
                    <Pressable
                      disabled={false}
                      onPress={toggleExpanded}>
                      <View style={styles.checkBoxContainer}>
                        <Text
                          style={styles.subTitle}
                          variant="t4">
                          {formatMessage({
                            id: 'Arbitrage_desinvest_Tous_Les_Supports'
                          })}
                        </Text>
                        <FontIcon
                          color={theme.colors.text.link.c500}
                          name={expanded ? 'chevron-haut' : 'chevron-bas'}
                          size={theme.metrics.iconSizes.xm}
                          style={styles.chevronStyle}
                        />
                      </View>
                    </Pressable>
                  </View>
                  <View style={styles.managementDetailRightContainer}>
                    <InputEditAmountWithLinkAndContribution
                      amount={totalAmount}
                      contribution={contribution}
                      disabled={false}
                      editable={isExtraManagementEditable}
                      fieldAmount={fieldAmount}
                      fieldPercent={fieldAmountPercent}
                      isContributionDisplayed={isContributionDisplayed}
                      isContributionError={isContributionError}
                      isContributionLast={isContributionLast}
                      isFieldAmountPercentage={isFieldAmountPercentage}
                      isLinkUnderField={isLinkUnderField}
                      isLinkUnderfieldAlwaysEnabled={isLinkUnderfieldAlwaysEnabled}
                      isPercentagePlusAmount={isFieldPercentagePlusAmount}
                      isSelected={isSupportMasterSelected}
                      linkUnderfieldLabel={linkUnderfieldLabel}
                      onEditAmountPress={onEditMasterAmountPress}
                      onLinkUnderFieldPress={onLinkUnderFieldPress}
                      onResetAmountPress={onResetAmountPress}
                    />
                  </View>
                </View>
              </>
            )}
          </>
        )}
        {(expanded || !isExpandable) && children}
      </View>
    </View>
  )
}
