import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';

import { FontIcon } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { getParagraphFontStyle, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { Link } from '../Link';
import { Text } from '../Text';
import { TextProps } from '../Text/interfaces';

type Props = TextProps & {
  children: ReactNode;
  numberOfLines: number;
  testID?: string;
}

enum ExpansionStatusEnum {
  READ_MORE,
  READ_LESS
}

export function ReadMoreText({
  children,
  numberOfLines,
  testID,
  variant = 't2',
  weight = 'regular',
  ...remainingProps
}: Props) {
  const { formatMessage } = useTranslation();
  const [expansionStatus, setExpansionStatus] = useState<ExpansionStatusEnum | undefined>();
  const theme = useTheme();

  const lineHeight = useMemo(
    () => getParagraphFontStyle({
      weight,
      variant,
      theme,
    })?.lineHeight,
    [theme, variant, weight]
  );

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    if (
      numberOfLines &&
      lineHeight &&
      expansionStatus !== ExpansionStatusEnum.READ_LESS &&
      ((event.nativeEvent.layout.height / lineHeight) >= numberOfLines)
    ) {
      setExpansionStatus(ExpansionStatusEnum.READ_MORE);
    }
  }, [lineHeight, numberOfLines, expansionStatus]);

  const handlePressReadMore = useCallback(() => {
    if (expansionStatus === ExpansionStatusEnum.READ_MORE) {
      setExpansionStatus(ExpansionStatusEnum.READ_LESS);
    } else {
      setExpansionStatus(ExpansionStatusEnum.READ_MORE);
    }
  }, [expansionStatus]);

  const renderLink = useCallback((linkTestID, text, icon) => {
    return (
      <Link
        onPress={handlePressReadMore}
        testID={linkTestID}
        textStyle={{ textDecorationLine: undefined, marginTop: theme.metrics.spacing.xs }}
      >
        {text}
        <FontIcon
          name={icon}
          size={theme.metrics.iconSizes.xxxs}
          style={{ paddingLeft: theme.metrics.spacing.s, textAlignVertical: 'center' }}
        />
      </Link>
    );
  }, [handlePressReadMore, theme]);

  return (
    <>
      <Text
        numberOfLines={
          expansionStatus === ExpansionStatusEnum.READ_MORE ?
            numberOfLines
            : undefined
        }
        onLayout={handleLayout}
        style={{ lineHeight }}
        testID={testID}
        variant={variant}
        weight={weight}
        {...remainingProps}
      >
        {children}
      </Text>
      {expansionStatus === ExpansionStatusEnum.READ_MORE &&
        renderLink(
          testID + '_readMore',
          formatMessage({ id: 'Fundsheet_Tab1_asset_management_target_expand_label' }),
          IconEnum.CHEVRON_BOTTOM
        )
      }
      {expansionStatus === ExpansionStatusEnum.READ_LESS &&
        renderLink(
          testID + '_readLess',
          formatMessage({ id: 'Fundsheet_Tab1_asset_management_target_reduce_label' }),
          IconEnum.CHEVRON_TOP
        )
      }
    </>
  );
}
