
import React, { useCallback, useEffect, useState } from 'react';

import {
  ConfirmTriggerThresholdPopin
} from '@components/index';
import { useTranslation } from '@ere-uilib/translations';
import { TriggerThresholdModal } from '@pages/Common';

import { ModalData, TriggerThresholdModalConnectedProps } from './interfaces';
import { ArbitrationTriggerThresholdModalConnectedRefType } from './sharedInterfaces';

const defaultData = {
  displayData: {
    title: '',
    amount: undefined,
    valuationDate: '',
    sharesValue: 0,
    triggerPointDate: '',
    thresholdTriggerType: '',
    thresholdTriggerTitle: '',
    course: '',
    dueDateOption: {
      code: undefined,
      date: undefined,
      amount: undefined,
    }
  },
  amount: 0,
  managementId: '',
  supportIsInCode: '',
};

export const ArbitrationTriggerThresholdModalConnectedComponent = React.forwardRef<
  ArbitrationTriggerThresholdModalConnectedRefType, TriggerThresholdModalConnectedProps
>(({
  onSetModalVisibleState,
  onSetArbitrationSupportTriggerThreshold,
  visible,
}, ref) => {
  const [data, setData] = useState<ModalData>(defaultData);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { formatMessage, getMessageRaw } = useTranslation();

  useEffect(() => {
    if (!visible) {
      setData(defaultData);
    }
  }, [visible]);

  // allow method via ref
  React.useImperativeHandle(ref, () => ({
    openWithData: receivedData => {
      onSetModalVisibleState(true);
      setData(receivedData);
    },
  }));

  const onClose = () => {
    onSetModalVisibleState(false);
  };

  const handleValidate = useCallback(amount => {
    setIsModalVisible(true);
    onSetArbitrationSupportTriggerThreshold({
      amount: data.amount,
      managementId: data.managementId,
      supportIsin: data.supportIsInCode,
      selectState: true,
      dueDateOption: data.displayData.dueDateOption
    }, {
      amount,
      date: data.displayData.triggerPointDate,
    });
  }, [data, onSetArbitrationSupportTriggerThreshold]);
  return (
    <>
      <TriggerThresholdModal
        data={data.displayData}
        onClose={onClose}
        onValid={handleValidate}
        visible={visible} />
      <ConfirmTriggerThresholdPopin
        buttonTitle={formatMessage({ id: 'ArbitrageSeuilDeclenchBouton' })}
        description={getMessageRaw({ id: 'ArbitrageSeuilDeclenchDescription' })}
        isModalVisible={isModalVisible}
        onValidate={() => setIsModalVisible(false)}
        title={formatMessage({ id: 'ArbitrageSeuilDeclenchTitre' })}
      />
    </>
  );
});
