import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';

import { CardWithHeaderButton, Paragraph, Text, useTranslation } from '@components/ERE-UILib';
import { ContributionFamilyEnum, ProfitSharingIncentiveTypeEnum } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { ContributionOperationType } from '@modules/dashboard/types';

import { ContributionOperationCardContent } from '../../components';
import { useStyles } from './useStyles';

interface ContributionOperationProps {
  operation: ContributionFamilyEnum;
  data: ContributionOperationType | null | undefined;
}

export const ContributionOperationCard: React.FC<ContributionOperationProps> = ({ operation, data }) => {
  const { formatMessage } = useTranslation();
  const navigation = useNavigation();
  const styles = useStyles();

  const label = useMemo(
    () => ({
      title: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerOperationToDoTitleColumnTable' }),
      cannotBeOptimized: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerWarningAbondementConsumed' }),
      separator: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerAndOrDispositifSeparator' }),
      payment: {
        buttonLabel: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerOperationTypeVolontaryPaymentCallToActionButton' }),
        subTitle: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerOperationTypeVolontaryPaymentTitleColumnTable' }),
      },
      profitSharing: {
        buttonLabel: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerOperationTypeProfitSharingCallToActionButton' }),
        subTitle: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerOperationTypeProfitSharingTitleColumnTable' }),
      },
      incentive: {
        buttonLabel: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerOperationTypeIncentiveBonusCallToActionButton' }),
        subTitle: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerOperationTypeIncentiveBonusTitleColumnTable' }),
      },
    }),
    [formatMessage]
  );

  const { couldBeOptimized, isDisabled } = useMemo(
    () => ({
      couldBeOptimized: !!data?.hasContribution,
      isDisabled: !data?.hasJourneyAvailable
    }),
    [data]
  );

  const handleButtonPress = useCallback((operation: ContributionFamilyEnum) => {
    switch (operation) {
    case ContributionFamilyEnum.PAYMENT:
      navigation.navigate(RouteNames.InstallmentStack, {
        screen: RouteNames.Installments,
      });
      break;
    default:
      navigation.navigate(RouteNames.ProfitSharingIncentive, {
        screen: RouteNames.ProfitSharingIncentiveDescription,
        params: {
          type: operation === ContributionFamilyEnum.INCENTIVE ?
            ProfitSharingIncentiveTypeEnum.incentive
            : ProfitSharingIncentiveTypeEnum.profitsharing
        }
      });
      break;
    }
  }, [navigation])

  const renderPlans = useCallback(
    () => data?.operations?.map(plan => <ContributionOperationCardContent plan={plan} />),
    [data]
  );

  const renderSeparator = useCallback(
    () => (
      <View style={styles.separatorContainer}>
        <Text
          style={styles.separatorText}
          variant="t3"
          weight="light">
          {label.separator}
        </Text>
        <View style={styles.separatorLine} />
      </View>
    ),
    [label, styles]
  );

  return (
    <CardWithHeaderButton
      description={formatMessage({ id: 'EmployerContributionTopupZone2OptimizerWarningZoneIncentiveBonus' })}
      headerButtonLabel={label[operation].buttonLabel}
      isDisabled={isDisabled}
      keyExtractor={(index:number) => `contribution-${operation}-plan-${index}`}
      onHeaderButtonPress={() => handleButtonPress(operation)}
      renderSeparator={renderSeparator}
      subTitle={label[operation].subTitle}
      title={label.title}
      withDescription={isDisabled}>
      {!couldBeOptimized && (
        <Paragraph
          variant="t3"
          weight="light">
          {label.cannotBeOptimized}
        </Paragraph>
      )}
      {couldBeOptimized && renderPlans()}
    </CardWithHeaderButton>
  );
};
