import React from "react";
import { View } from "react-native";

import { LoaderPlaceholderView } from '@ere-uilib/atoms';

import { useStyles } from "./useStyles";
export const TableLoader:React.FC = () => {
  const styles = useStyles();

  return (
    <View style={styles.tableLoader}>
      <LoaderPlaceholderView
        style={[styles.loaderPlaceholderView, styles.loaderPlaceholderViewHalfLength]}
      />
      <LoaderPlaceholderView style={[styles.loaderPlaceholderView]} />
      <LoaderPlaceholderView style={[styles.loaderPlaceholderView]} />
    </View>
  )
}