import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  NavigationHeader,
  useTranslation,
  Text,
  SimpleButton,
  IconEnum,
  useScreenSizes,
} from '@components/index';
import { OperationHistoryTypesEnum, PlanFamillyList } from '@constants/index';
import { RouteNames } from "@constants/navigation";
import { RetirementPlanDocumentType } from '@modules/retirement/types';
import { RootNavigation } from "@navigation/RootNavigation";

import { RetirementRecoverSavingPlanCard } from '../components';
import { RetirementRecoverSavingPageProps } from './interfaces';
import { useStyles } from './styles';
import { useController } from './useController';

export const RetirementRecoverSavingPageComponent: React.FC<RetirementRecoverSavingPageProps> = (
  {
    retirementDecisionForm,
    onSubmit,
    isApiLoading
  }) => {
  const { formatMessage } = useTranslation();
  const styles = useStyles();
  const { isMobile, isTablet } = useScreenSizes();
  const navigation = useNavigation()
  const {
    dictionary,
    sendType,
    handleExitPress,
    shouldDisplayAnnuityCard
  } = useController(retirementDecisionForm);

  const renderHeader = useCallback(
    () => (
      <NavigationHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={dictionary.infoContent}
        helpPointModalTitle={dictionary.infoTitle}
        onClose={handleExitPress}
        title={dictionary.savingPageTitle}
      />
    ),
    [handleExitPress, dictionary]
  );

  const [enablePacteLoading, setEnablePacteLoading] = useState(false);
  const [enableLink, setEnableLink] = useState(false);

  const setIsEsPacteFile = (isPacte: boolean) => {
    setEnablePacteLoading(isPacte);
    setEnableLink(false)
  }
  const setIsLinkPressed = (isLink: boolean) => {
    setEnableLink(isLink)
  }



  const renderButtons = useCallback(() => {
    const buttonTitle = sendType ?
      formatMessage({ id: `${sendType}_Button` }) : "Retour à l'accueil";
    const buttonAction = sendType ?
      () => {
        RootNavigation.replace(RouteNames.RedirectOperationPage, {
          provider: PlanFamillyList.ES,
          operationType: OperationHistoryTypesEnum.REFUND
        });
      } : handleExitPress

    return (
      <View style={[styles.actionButtonContainer, styles.contentContainer]}>
        <SimpleButton
          design='outlined'
          leftIcon={IconEnum.CHEVRON_LEFT}
          onPress={() => navigation.goBack()}
        />
        <SimpleButton
          containerStyle={[
            styles.actionButtonStyle,
            (isMobile || isTablet) && { flex: 1 }
          ]}
          design='solid'
          onPress={buttonAction}
          title={buttonTitle}
        />
      </View>
    )
  }, [
    sendType,
    formatMessage,
    styles,
    isMobile,
    isTablet
  ]);

  const renderInfos = useCallback(() => {
    if (!sendType)
      return;

    return (
      <View style={styles.contentContainer}>
        <Text
          style={styles.bottomText}
          variant='t4'
          weight='regular'
        >
          {formatMessage({ id: `${sendType}_InfoInLine` })}
        </Text>
      </View>
    );
  }, [
    sendType,
    formatMessage,
    styles
  ]);

  const renderContent = useCallback(() => (
    <View style={styles.contentContainer}>
      {
        retirementDecisionForm.map((
          element: RetirementPlanDocumentType,
          i: number
        ) => {
          const isLastItem = i === retirementDecisionForm.length - 1;
          return (
            <View
              key={i}
              style={!isLastItem && styles.retirementRecoverSavingPlanCardContainer}
            >
              <RetirementRecoverSavingPlanCard
                isLoading={(enablePacteLoading && !enableLink) && isApiLoading}
                isLoadingBulletin={(!enablePacteLoading && !enableLink) && isApiLoading}
                onSubmit={onSubmit}
                RetirementPlanDocument={element}
                setIsEsPacteFile={setIsEsPacteFile}
                setIsLinkPressed={setIsLinkPressed}
                shouldDisplayAnnuityCard={shouldDisplayAnnuityCard}
              />
            </View>
          )
        })
      }
    </View>
  ), [
    styles,
    retirementDecisionForm,
    shouldDisplayAnnuityCard,
    onSubmit,
    isApiLoading,
    enablePacteLoading
  ]);

  return (
    <DashboardPageWrapperConnected cardContentStyle={styles.container}>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}>
        <>
          {renderContent()}
          {renderButtons()}
          {renderInfos()}
        </>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
