import React, { useCallback, useMemo, useState } from "react";
import {
  StyleProp, TextStyle,
  TouchableOpacity, View, ViewStyle
} from "react-native";

import { FontIcon, Text, Title } from "@ere-uilib/atoms";
import { useTheme } from "@ere-uilib/styles";
import { useTranslation } from "@ere-uilib/translations";

import { Picker } from "./Picker";
import { ItemState } from "./types";
import { useStyles } from './useStyles';

interface SelectProps {
  items: ItemState[] | undefined;
  selectedItem?: {
    value: string | number;
    label: string;
  };
  onSelectItem: (item: ItemState) => void;
  containerStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  itemsContainerStyle?: StyleProp<ViewStyle>;
  selectedItemStyle?: StyleProp<TextStyle>;
  pickerTextStyle?: StyleProp<TextStyle>;
  pickerTextContainerStyle?: StyleProp<ViewStyle>;
  defaultTitle?: string;
  selectedGreen?: boolean;
  iconSize?: number;
  placeholder?: string;
  pickerTitle?: string;
  disabled?: boolean;
  isAbsolutePositionDisabled?: boolean
  selectTestId?: string
}

export const Select: React.FC<SelectProps> = ({
  items,
  selectedItem,
  onSelectItem,
  defaultTitle,
  selectedGreen,
  containerStyle,
  titleStyle,
  itemsContainerStyle,
  iconSize,
  placeholder,
  selectedItemStyle,
  pickerTitle,
  pickerTextStyle,
  pickerTextContainerStyle,
  disabled = false,
  isAbsolutePositionDisabled,
  selectTestId
}) => {
  const { formatMessage } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const styles = useStyles({ isOpen, disabled });

  const fontIconColor = useMemo(() => {
    if (selectedItem && selectedGreen) {
      return theme.colors.basics.white;
    }
    if (disabled) {
      return theme.colors.basics.grey.c400;
    }

    return theme.colors.basics.black;
  }, [selectedGreen, selectedItem, disabled, theme]);

  const isPlaceholderVisible = !!placeholder && !!selectedItem;

  const contentTextStyle = [
    styles.titleStyle,
    titleStyle,
    !!selectedItem &&
    selectedGreen && { color: theme.colors.basics.white },
    isPlaceholderVisible && { paddingTop: theme.metrics.spacing.xxm }
  ];

  const touchableOpacityStyle = [
    styles.selectedItemStyle,
    !!selectedItem &&
    selectedGreen && {
      backgroundColor: theme.colors.basics.primary.c500,
    },
    selectedItemStyle,
  ];

  const title = useMemo(() => {
    if (selectedItem && selectedItem.label) {
      return selectedItem.label;
    }

    if (defaultTitle) {
      return defaultTitle;
    }

    return formatMessage({ id: "Component_dropdown_default_label" });
  }, [defaultTitle, formatMessage, selectedItem]);

  const handleInputPress = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSelectItem = useCallback((item: ItemState) => {
    onSelectItem(item);
    setIsOpen(false);
  }, [onSelectItem]);

  const handlePickerClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <View
      style={[styles.containerStyle, containerStyle]}>
      <TouchableOpacity
        disabled={disabled}
        onPress={handleInputPress}
        style={[touchableOpacityStyle, styles.touchableOpacityStyle]}
      >
        {isPlaceholderVisible && (<Text
          style={styles.placeholder}
          variant="t3">{placeholder}</Text>
        )}
        <Title
          style={[contentTextStyle, styles.contentTextStyle]}
          variant="t7"
          testID={selectTestId}
        >
          {title}
        </Title>
        <FontIcon
          color={fontIconColor}
          name={isOpen ? "chevron-haut" : "chevron-bas"}
          size={iconSize || theme.metrics.iconSizes.xm}
        />
      </TouchableOpacity>
      <Picker
        isOpen={isOpen}
        items={items}
        itemsContainerStyle={itemsContainerStyle}
        onClose={handlePickerClose}
        onSelectItem={handleSelectItem}
        selectedItem={selectedItem}
        textContainerStyle={pickerTextContainerStyle}
        textStyle={pickerTextStyle}
        title={pickerTitle}
        isAbsolutePositionDisabled={isAbsolutePositionDisabled}
      />
    </View>
  );
};
