import React from 'react';
import { default as LibCalendar } from 'react-calendar';

import './Calendar.css';
import { CalendarProps } from './interfaces';

export function  Calendar({
  value,
  minDate,
  maxDate,
  onChange,
  defaultView,
}: CalendarProps) {

  return (
    <LibCalendar
      defaultView={defaultView}
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      value={value}
    />
  );
}
