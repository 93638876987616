import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from 'react';
import { View } from 'react-native';

import { RouteNames } from "@constants/navigation";
import { useTranslation, FontIcon, IconEnum, Text, Link } from '@ere-uilib/index';
import { useTheme } from '@ere-uilib/styles/theme';

import { ContributionInfoCardProps } from './interfaces';
import { useStyles } from './useStyles';

export const ContributionInfoEligibleCard: React.FC<ContributionInfoCardProps> = ({
  children,
  isDisabled = false,
  renderSeparator,
  keyExtractor,
  contributionPlan,
  estimatedContribution,
  leftText,
  rightText,
  descriptionText,
  linkText,
  isTargeted,
  avialableContributionAmount,
  suggestedContributionPaymentAmount,
  ...props
}) => {
  const styles = useStyles(isDisabled);
  const theme = useTheme();

  const { formatCurrencyNumber } = useTranslation();
  const navigation = useNavigation();

  const handleOpenContributionModal = useCallback(() => {
    navigation.navigate(RouteNames.ContributionModal);
  }, [navigation]);

  const renderContents = useCallback(() => {
    const Contents = React.Children.toArray(children);

    return Contents.map((Content, index, array) => {
      const lastIndex = array.length - 1;
      const isLastIndex = index === lastIndex;
      return (
        <React.Fragment key={keyExtractor?.(index)}>
          <View style={styles.cardContent}>{Content}</View>
          {!isLastIndex && renderSeparator?.()}
        </React.Fragment>
      );
    })

  }, [renderSeparator, keyExtractor, children, styles]);
  const renderCibledContribution = useCallback(() => {
    if (!(isTargeted && suggestedContributionPaymentAmount)) return null;
    return (
      <View style={styles.descriptionStyle}>
        <Text
          style={styles.descriptionTextStyle}
          variant="t3"
          weight="light"
        >
          {descriptionText}
        </Text>
        <Text
          style={styles.descriptionTextStyle}
          weight="regular">
          {formatCurrencyNumber({ value: suggestedContributionPaymentAmount || 0 })}
        </Text>
        {linkText &&
          <Link
            onPress={handleOpenContributionModal}
            textStyle={styles.linkStyle}
            textVariant="t3"
            textWeight='regular'>
            {linkText}
          </Link>
        }
      </View>)
  }, [])

  return (
    <View
      {...props}
      style={[styles.container, props.style]}>
      <View style={styles.cardHeader}>
        <View style={styles.cardHeaderTop}>
          <View>
            <Text
              style={styles.titleStyle}
              variant="t3"
              weight="regular">
              {leftText}
            </Text>
            <View style={styles.leftSectionStyle}>
              <FontIcon
                name={IconEnum.CONTRIBUTION}
                size={theme.metrics.iconSizes.m}
                style={styles.contributionIconLeft}
              />
              <Text
                style={styles.contributionAmountStyles}
                weight="regular">
                {formatCurrencyNumber({
                  value: avialableContributionAmount || 0
                })}
              </Text>
            </View>
          </View>
          <View>
            <Text
              style={styles.titleStyle}
              variant="t3"
              weight="regular">
              {rightText}
            </Text>
            <View style={styles.rightSectionStyle}>
              <FontIcon
                name={IconEnum.CONTRIBUTION}
                size={theme.metrics.iconSizes.m}
                style={styles.contributionIconLeft}
              />
              <Text
                style={styles.contributionAmountStyles}
                weight="regular">
                {formatCurrencyNumber({
                  value: estimatedContribution || 0
                })}
              </Text>
            </View>
          </View>
        </View>
        {renderCibledContribution()}
      </View>
      {renderContents()}
    </View>
  );
}