import React from 'react';

import {
  DashboardAnnounce,
  SimpleButton,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { useTranslation } from '@ere-uilib/translations';

import { InstallmentFailedPageProps } from './interfaces';

export const InstallmentFailedPage: React.FC<InstallmentFailedPageProps> = ({
  route,
  navigation,
  clearInstallmentData,
}) => {
  const { formatMessage } = useTranslation();

  const renderActions = () => {
    return (
      <SimpleButton
        onPress={() => {
          clearInstallmentData();
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.Home,
          });
        }}
        title={formatMessage({ id: 'VV_boutonPaiement_echec' })}
      />
    );
  };
  return (
    <DashboardAnnounce
      actions={renderActions}
      description={formatMessage({ id: 'VV_paiement_echec_label' })}
      sourceName={'installmentError'}
      title={formatMessage({ id: 'VV_paiement_echec_title' })}
    />
  );
};
