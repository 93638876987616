import { connect } from 'react-redux';

import {
  updateContactPreferencesRequest,
  updateContactPreferencesSuccessReset,
  getContactPreferencesRequest
} from '@modules/profile/actions/profileActions';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import { AppState } from '@modules/reducers';
import {
  getContactPreferenceHasNotificationsActivated,
  getContactPreferenceSuccessOptInEmail,
  getContactPreferenceSuccessOptInSms,
  getIsUpdateContactPreferenceSuccess,
  getProfileUserInfos,
} from '@modules/profile/selectors';

import { ContactPreferencesPage as contactPreferences } from './ContactPreferencesPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    isSuccess: getIsUpdateContactPreferenceSuccess(state),
    hasNotificationsActivated: getContactPreferenceHasNotificationsActivated(state),
    emailOptIn: getContactPreferenceSuccessOptInEmail(state),
    smsOptIn: getContactPreferenceSuccessOptInSms(state),
    isLoading: state.isLoading[ProfileActionsType.UPDATE_CONTACT_PREFERENCES_REQUEST],
    phone: getProfileUserInfos(state).phone,
  };
};

const mapDispatchToProps = ({
  onSubmit: updateContactPreferencesRequest,
  onPressButtonSuccessModal: updateContactPreferencesSuccessReset,
  getContactPreferencesRequest: getContactPreferencesRequest,
});

export const ContactPreferencesPage = connect(mapStateToProps, mapDispatchToProps)(contactPreferences);

