import React from 'react';

import { useTranslation } from '@components/ERE-UILib';
import { NotificationIconTypeEnum, Paragraph } from '@ere-uilib/atoms';
import { NotificationHard } from '@ere-uilib/molecules';

interface NotificationReminderCancelableProps {
  deadline: string | null | undefined;
  remainingDays: number | null | undefined;
}

export const NotificationReminderCancelable: React.FC<NotificationReminderCancelableProps> = ({
  deadline,
  remainingDays
}) => {
  const { formatMessage, formatDate } = useTranslation();

  return (
    <NotificationHard
      title={formatMessage({ id: 'DetailOperationCardAnnulableTitre' })}
      type={NotificationIconTypeEnum.INFO}>
      <Paragraph
        variant="t3"
        weight="light">
        {formatMessage({
          id: 'DetailOperationCardAnnulableDescription',
          values: {
            date_fin_affectation: formatDate({
              value: deadline || '',
              options: {
                year: 'numeric',
                month: '2-digit',
                day: 'numeric'
              }
            }),
            nb_jour_restant: remainingDays
          }
        })}
      </Paragraph>
    </NotificationHard>
  );
};
