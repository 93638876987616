import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';

import { BankCopyIbanPage as BankCopyIbanPageComponent } from './BankCopyIbanPage.component';
import { getBankData } from '@modules/bank-details/selectors';

const mapStateToProps = (state: AppState) => ({
  bankData: getBankData(state),
});

const mapDispatchToProps = {

};

export const BankCopyIbanPage = connect(mapStateToProps, mapDispatchToProps)(BankCopyIbanPageComponent);