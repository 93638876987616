import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import { Platform } from 'react-native';

import { IdentificationLandingPageTemplate, useTranslation } from '@components/index';
import { AuthenticationChoice } from '@components/templates/IdentificationLandingPageTemplate/components/AuthenticationChoice';
import { RouteNames } from '@constants/navigation';
import { loginOpenId } from '@modules/auth/services';

import { OidcLogoutCallbackRedirectPageProps } from './interfaces';

export const OidcLogoutCallbackRedirectPage: React.FC<OidcLogoutCallbackRedirectPageProps> = ({
  logoutCallbackSuccess,
  applicationSettings,
  authConfig
}) => {
  const navigation = useNavigation();
  const { formatMessage } = useTranslation();
  const isSelfcareLetterLanding = false;
  const handleLoginOpenId = () => {
    if (Platform.OS === 'web') return loginOpenId(authConfig);
    navigation.navigate(RouteNames.NativeLoginPage);
  };

  const handleActivateAccountPress = useCallback(() => {
    if (isSelfcareLetterLanding) {
      navigation.navigate(RouteNames.SelfcareStack, {
        screen: RouteNames.LetterIdentifierForm
      });
    } else {
      navigation.navigate(RouteNames.SelfcareStack, {
        screen: RouteNames.ActivateAccountModeSelection
      });
    }
  }, [isSelfcareLetterLanding, navigation]);

  const dynamicLabels = useMemo(() => {
    if (isSelfcareLetterLanding) {
      return {
        page: {
          title: formatMessage({ id: 'EerSelfcareStep1CourrierPageTitle' }),
          subtitle: formatMessage({ id: 'EerSelfcareStep1CourrierPageSubtitle' })
        },
        ActiverCompteCard: {
          CardTitle: formatMessage({ id: 'EerSelfcareStep1CourrierCardTitle' }),
          cardDescription: formatMessage({ id: 'EerSelfcareStep1CourrierCardSubtitle' })
        }
      };
    }
    return {
      page: {
        title: formatMessage({ id: 'EerSelfcareLandingPageTitle' }),
        subtitle: formatMessage({ id: 'EerSelfcareLandingPageLabel' })
      },
      ActiverCompteCard: {
        CardTitle: formatMessage({ id: 'EerSelfcareLandingPageActiverCompteTitle' }),
        cardDescription: formatMessage({ id: 'EerSelfcareLandingPageActiverComptelabel' })
      }
    }
  }, [formatMessage]);
  return (
    <IdentificationLandingPageTemplate
      applicationSettings={applicationSettings}
      authConfig={authConfig}
      logoutCallbackSuccess={logoutCallbackSuccess}
      subTitle={dynamicLabels.page.subtitle}
      title={dynamicLabels.page.title}>
      <AuthenticationChoice
        handleActivateAccountPress={handleActivateAccountPress}
        handleLoginOpenId={handleLoginOpenId}
        isSelfcareLetterLanding={isSelfcareLetterLanding}
        OptionEERPremiereConnexion={applicationSettings.OptionEERPremiereConnexion}
      />
    </IdentificationLandingPageTemplate>
  );
};
