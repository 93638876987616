import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';
import { getAuthConfig } from '@modules/settings/selectors';

import { AccountCreationSuccessPage as AccountCreationSuccessPageComponent } from './AccountCreationSuccessPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state),
    authConfig: getAuthConfig(state),
  };
};

export const AccountCreationSuccessPage = connect(
  mapStateToProps,
  null,
)(AccountCreationSuccessPageComponent);
