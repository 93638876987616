import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { FontIcon } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

interface RoundedIconProps {
  iconName: IconEnum;
}

interface RoundedIconStyles {
  containerStyle?: StyleProp<ViewStyle>
}

type StyleContext = {
  theme?: ThemeType;
  iconSize?: number;
  iconColor?: string;
  containerSize?: number;
  containerBackground?: string;
}

type Props = RoundedIconProps & StyleContext & RoundedIconStyles;

export const RoundedIcon:React.FC<Props> = ({
  containerStyle,
  containerSize,
  containerBackground,
  iconSize,
  iconColor,
  iconName
})=>{
  const theme = useTheme();

  const styles = useStyle({ theme, containerSize, containerBackground
  }, { containerStyle });

  return (
    <View style={styles.containerStyle}>
      <FontIcon
        color={iconColor ? iconColor : theme.colors.basics.black}
        name={iconName}
        size={iconSize ? iconSize : theme.metrics.spacing.xm}/>
    </View>
  );
};

const getStyles = (
  context?: StyleContext,
  style?: RoundedIconStyles
): RoundedIconStyles =>({
  containerStyle: [
    {
      height: context?.containerSize ? context?.containerSize : context?.theme?.metrics.spacing.xxxxl,
      width: context?.containerSize ? context?.containerSize : context?.theme?.metrics.spacing.xxxxl,
      backgroundColor: context?.containerBackground ?
        context?.containerBackground : context?.theme?.colors.basics.white,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: context?.theme?.metrics.spacing.xxxhuge
    },
    style?.containerStyle
  ]
});

const useStyle = createUseStyles(getStyles);
