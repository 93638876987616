import React from 'react';
import { View } from 'react-native';

import {
  LogoBnp,
 LogoColorModeEnum,
 LogoOrientationEnum,
} from '@ere-uilib/molecules';
import {
  HelpPoint,
  HelpPointProps,
} from '@ere-uilib/organisms';
import { useScreenSizes } from '@ere-uilib/styles';

import { useStyles } from './useStyles';
import { LogoBnpProps } from '@ere-uilib/molecules/LogoBnp/interfaces';

interface Props {
  helpPointProps?: HelpPointProps
  logoProps?: LogoBnpProps
  onLogoPress?: () => void
}

export const LogoHeader: React.FC<Props> = ({
  helpPointProps,
  logoProps,
  onLogoPress
}) => {
  const styles = useStyles();
  const {isDesktop} = useScreenSizes();
  return isDesktop ? (
    <View style={styles.container}>
    <LogoBnp 
      colorMode={LogoColorModeEnum.dark}
      orientation={LogoOrientationEnum.row}
      onLogoPress={onLogoPress}
      {...{logoProps}}
        />
        {!!helpPointProps && (
          <HelpPoint
            {...helpPointProps}
          />
        )}
    </View>
  ) : <></>;  
};