import { RouteProp, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import {
  ColumnsContainer, OfflinePageLayout,
  ThemeType,
  useScreenSizes, useTheme, useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { SimpleButton } from '@ere-uilib/molecules';
import { ContactPreferencesRequest } from '@modules/profile/actions/profileActions';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { useLogoNavigation } from '@utils/useLogoNavigation';

import { ContactPreferencetemplate } from '../templates';
import { useController } from '../useController';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
  onSubmit: (values: ContactPreferencesRequest) => void;
  isSuccess?: boolean;
  onPressButtonSuccessModal: () => void;
  hasNotificationsActivated: boolean;
  emailOptIn: boolean;
  smsOptIn: boolean;
  getContactPreferencesRequest: () => void;
  isLoading: boolean;
  phone?: string | null;
}

type StylesContext = {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
};

type StylesProps = {
  container?: StyleProp<ViewStyle>
  stickyButtonsViewStyle?: StyleProp<ViewStyle>
  button?: StyleProp<ViewStyle>
};

export function ContactPreferencesPage({
  navigation,
  onSubmit,
  onPressButtonSuccessModal,
  hasNotificationsActivated,
  emailOptIn,
  smsOptIn,
  getContactPreferencesRequest,
  isLoading,
  container,
  stickyButtonsViewStyle,
  button,
}: Props & StylesProps) {
  type ContactPreferencesParam = {
    contact: {
      toDoPostSuccessActionType: string,
      demandId: string
    }
  };
  const route = useRoute<RouteProp<ContactPreferencesParam, 'contact'>>();
  const { logoNavigateTo } = useLogoNavigation();
  const actionType = route?.params?.toDoPostSuccessActionType;
  const demandId = route?.params?.demandId;

  const {
    isSubmitActive,
    handlePressReceiveMessage,
    handlePressNotReceiveMessage,
    handlePressEmail,
    handlePressSMS,
    handleSubmit,
    optInSms,
    optInEmail,
    isNotificationsActivated,
  } = useController({
    onSubmit,
    onPressButtonSuccessModal,
    navigation,
    hasNotificationsActivated,
    emailOptIn,
    smsOptIn,
    getContactPreferencesRequest,
    toDoActionType: actionType,
    demandId: demandId,
  });

  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const styles = useStyles(
    { theme, isMobile, isTablet },
    { container, stickyButtonsViewStyle, button }
  );

  const renderButton = useCallback(() => (
    <View style={styles.button}>
      <SimpleButton
        disabled={!isSubmitActive}
        loading={isLoading}
        onPress={handleSubmit}
        title={formatMessage({ id: 'Profil_Prefcontact_edit_page_Bouton' })}
      />
    </View>
  ), [isSubmitActive, handleSubmit, isLoading]);

  return (
    <OfflinePageLayout
      displayLogo={!(isMobile || isTablet)}
      onLogoPress={logoNavigateTo}
    >
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderStickyMobileBottom={renderButton}
      >
        <View style={styles.container}>
          <ContactPreferencetemplate
            handlePressEmail={handlePressEmail}
            handlePressNotReceiveMessage={handlePressNotReceiveMessage}
            handlePressReceiveMessage={handlePressReceiveMessage}
            handlePressSMS={handlePressSMS}
            hasNotificationsActivated={isNotificationsActivated}
            optInEmail={optInEmail}
            optInSms={optInSms}
          />
          {isDesktop && renderButton()}
        </View>
      </ColumnsContainer>
    </OfflinePageLayout>
  );
}
const getStyles = (
  context?: StylesContext,
  style?: StylesProps
): StylesProps => ({
  container: [
    !(context?.isMobile || context?.isTablet)
      ? {
        paddingTop: 76,
        flex: 1,
        alignSelf: "center"
      }
      : { marginTop: context?.theme.metrics.spacing.xxbig },
    style?.container,
  ],
  stickyButtonsViewStyle: [
    (context?.isMobile || context?.isTablet) && {
      marginTop: context && -context?.theme.metrics.spacing.l,
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingBottom: context?.theme.metrics.spacing.l,
    },
    style?.stickyButtonsViewStyle,
  ],
  button: [
    !(context?.isMobile || context?.isTablet) &&
    {
      alignItems: 'flex-start',
    },
    { marginTop: 60 },
    style?.button,
  ],
});

const useStyles = createUseStyles(getStyles);
