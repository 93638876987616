import React, { useEffect } from 'react';

import {
  Spinner,
  useTranslation,
  OfflineAnnounceLayout360,
} from '@components/index';
import { LOCATION_URL } from '@constants/location';
import { UserState } from '@modules/auth/types';
import { AuthConfigType } from '@modules/settings/types';
import { endOidcSession } from '@modules/auth/services';
interface Props {
  user: UserState
  authConfig: AuthConfigType
}

export const EndOidcSessionPage: React.FC<Props> = ({ authConfig, user }: Props) => {
  const { formatMessage } = useTranslation();

  useEffect(() => {
    if (user.access_token && user.access_token !== '') {
      endOidcSession(
        authConfig.authority,
        user.id_token ?? '',
        `${LOCATION_URL}/oidc/logoutcallback`
      );
    }
  }, [user.access_token]);
  return (
    <OfflineAnnounceLayout360
      description={'start'}
      sourceName="logout"
      title={formatMessage({ id: 'logout_transition_title' })}
    >
      <Spinner />
    </OfflineAnnounceLayout360>
  );
};
