import React from 'react';
import { StyleProp, TextStyle } from 'react-native';

import { SVGLocalLoader, Title, Paragraph } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

interface Props {
  titleStyle?: StyleProp<TextStyle>;
  subtitleStyle?: StyleProp<TextStyle>;
  title: string
}

type ThankYouInformationsStyles = {
  titleStyle?: StyleProp<TextStyle>;
  subtitleStyle?: StyleProp<TextStyle>;
};

type StylesContext = { theme: ThemeType };

export const ThankYouInformations: React.FC<Props> = ({
  subtitleStyle,
  titleStyle,
  title
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, { titleStyle, subtitleStyle });
  const { formatMessage } = useTranslation();
  return (
    <>
      <SVGLocalLoader
        height={261}
        name="thanks"
        width={450} />
      <Title
        style={styles.titleStyle}
        variant="t3"
        weight="bold">{title}</Title>
      <Paragraph
        style={styles.subtitleStyle}
        variant="t2">
        {formatMessage({ id: 'thanks_description' })}
      </Paragraph>
    </>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: ThankYouInformationsStyles,
): ThankYouInformationsStyles => ({
  titleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm
    },
    style?.titleStyle
  ],
  subtitleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
      maxWidth: 450,
      textAlign: 'center'
    },
    style?.subtitleStyle
  ]
});

const useStyles = createUseStyles(getStyles);
