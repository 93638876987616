import React, { useEffect } from 'react';
import { Platform } from 'react-native';

import {
  Spinner,
  useTranslation,
  OfflineAnnounceLayout360,
} from '@components/index';
import { useLogoNavigation } from '@utils/index';
import { RouteNames } from '@constants/navigation';
import { RootNavigation } from '@navigation/RootNavigation';

interface Props {
  isCampaignActive: boolean;
  signoutRedirectCallback: () => void;
  resetCampaignReducer(): void
  logoutCallbackSuccess: () => void
}

export const LogoutPageComponent: React.FC<Props> = ({ 
  isCampaignActive,
  signoutRedirectCallback,
  resetCampaignReducer,
  logoutCallbackSuccess
}: Props) => {
  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();

  useEffect(() => {
    if (isCampaignActive) {
      resetCampaignReducer()
    } 
  }, [isCampaignActive]);

  const manageNativeAppLogout = () => {
    logoutCallbackSuccess()
    RootNavigation.replace(RouteNames.NativeLoginPage)
  }
  useEffect(() => {
    if (Platform.OS === 'web') {
      signoutRedirectCallback();
    } else {
      manageNativeAppLogout()
    } 
  }, []);

  return (
    <OfflineAnnounceLayout360
      description={formatMessage({ id: 'logout_description' })}
      onLogoPress={logoNavigateTo}
      sourceName="logout"
      title={formatMessage({ id: 'logout_transition_title' })}
    >
      <Spinner/>
    </OfflineAnnounceLayout360>
  );
};
