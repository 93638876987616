import { connect } from 'react-redux';

import { otpFlowAskCodeRequest } from '@modules/profile/actions/profileActions';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import { AppState } from '@modules/index';
import { getOtpParameters, getProfileUserInfos } from '@modules/profile/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';

import { OtpSecurisationStartPage as OtpSecurisationStartPageComponent } from './OtpSecurisationStartPage.component';

const mapStateToProps = (state: AppState) => ({
  otpParameters: getOtpParameters(state),
  userInfos: getProfileUserInfos(state),
  isAskCodeLoading: state.isLoading[ProfileActionsType.OTP_FLOW_ASK_CODE_REQUEST],
  appSettings: getApplicationSettings(state)
});

const mapDispatchToProps = {
  onSendMessagePress: otpFlowAskCodeRequest
};

export const OtpSecurisationStartPage = connect(mapStateToProps, mapDispatchToProps)(OtpSecurisationStartPageComponent);
