import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { getOperationDetails } from '@modules/savings/selectors';

import { OperationCancelSuccessPage as OperationCancelSuccessPageComponent } from './OperationCancelSuccessPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    operationDetails: getOperationDetails(state),
  };
};

export const OperationCancelSuccessPage = connect(mapStateToProps, null)(OperationCancelSuccessPageComponent);