import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  OtpChanelTypeEnum
} from '@constants/index';
import { OtpRequestTypeEnum } from '@ere-uilib/enums';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import { AppState } from '@modules/reducers';
import { getDecodedUserAccessToken } from '@modules/auth/selectors';

import { useUrlLink } from './useUrlLink';

export const useSecureSessionState = () => {

  const dispatch = useDispatch();
  const { linkToUrl } = useUrlLink();
  const decodedUserTokenId = useSelector((state: AppState) =>
    getDecodedUserAccessToken(state)
  );
  const OtpSmsIndex: boolean | number | undefined = ((decodedUserTokenId?.mfa === 'Otp_Sms') || decodedUserTokenId?.mfa?.indexOf('Otp_Sms') || undefined);

  const redirectToPersoneo = useCallback((url: string) => {
    if ((OtpSmsIndex !== -1 || OtpSmsIndex) && OtpSmsIndex !== undefined) {
      linkToUrl(url);
    } else {
      dispatch({
        type: ProfileActionsType.OTP_FLOW_START_REQUEST,
        otpParameters: {
          requestType: OtpRequestTypeEnum.SECURE,
          chanelType: OtpChanelTypeEnum.SMS,
          outputActionType: ProfileActionsType.ASK_NAVIGATION_TO_URL,
          outputActionParameters: {
            url: url
          }
        }
      });
    }
  }, [decodedUserTokenId, OtpSmsIndex]);

  return {
    redirectToPersoneo
  };

};
