import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { RouteNames } from '@constants/navigation';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import {
  ArbitrationChoicePage,
  ArbitrationDisinvestmentsSavingPage,
  ArbitrationFailurePage,
  ArbitrationOADRepartitionPage,
  ArbitrationOnboardingPage,
  ArbitrationPlanChoicePage,
  ArbitrationReinvestmentPage,
  ArbitrationSuccessPage,
  ArbitrationSynthesisPageContainer,
  ArbitrationOADRepartitionPageContainer,
  ArbitrationOADRepartitionConfirmationPageContainer
} from '@pages/Arbitration';
import { ConfirmModalPageComponent } from '@pages/Common';

const ArbitrationStack = createStackNavigator();

export const ArbitrationStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();

  // TODO : reactivate data test for page display when it become esier to work on

  return (
    <ArbitrationStack.Navigator
      screenOptions={{
        gestureEnabled: false,
        headerShown: false
      }}>
      <ArbitrationStack.Screen
        component={ArbitrationChoicePage}
        name={RouteNames.ArbitrationChoice}
        options={defaultScreensOptions.ArbitrationChoice}
      />
      <ArbitrationStack.Screen
        component={ArbitrationOnboardingPage}
        name={RouteNames.ArbitrationOnboarding}
        options={defaultScreensOptions.ArbitrationOnboarding}
      />
      <ArbitrationStack.Screen
        component={ArbitrationPlanChoicePage}
        name={RouteNames.ArbitrationPlanChoice}
        options={defaultScreensOptions.ArbitrationPlanChoice}
      />
      <ArbitrationStack.Screen
        component={ArbitrationDisinvestmentsSavingPage}
        name={RouteNames.ArbitrationDisinvestmentsSaving}
        options={defaultScreensOptions.ArbitrationDisinvestmentsSaving}
      />
      <ArbitrationStack.Screen
        component={ArbitrationReinvestmentPage}
        name={RouteNames.ArbitrationReinvestment}
        options={defaultScreensOptions.ArbitrationReinvestment}
      />
      <ArbitrationStack.Screen
        component={ArbitrationSynthesisPageContainer}
        name={RouteNames.ArbitrationSynthesis}
        options={defaultScreensOptions.ArbitrationSynthesis}
      />
      <ArbitrationStack.Screen
        component={ArbitrationFailurePage}
        name={RouteNames.ArbitrationFailure}
        options={defaultScreensOptions.ArbitrationFailure}
      />
      <ArbitrationStack.Screen
        component={ArbitrationSuccessPage}
        name={RouteNames.ArbitrationSuccess}
        options={defaultScreensOptions.ArbitrationSuccess}
      />
      <ArbitrationStack.Screen
        component={ArbitrationOADRepartitionPageContainer}
        name={RouteNames.ArbitrationOADRepartition}
        options={defaultScreensOptions.ArbitrationOADRepartition}
      />
      <ArbitrationStack.Screen
        component={ArbitrationOADRepartitionConfirmationPageContainer}
        name={RouteNames.ArbitrationOADRepartitionConfirmation}
        options={defaultScreensOptions.ArbitrationOADRepartitionConfirmation}
      />

      {/* Modal screen here if needed */}
      <ArbitrationStack.Screen
        component={ConfirmModalPageComponent}
        name={RouteNames.ArbitrationModal}
        options={defaultScreensOptions.ConfirmModal}
      />
    </ArbitrationStack.Navigator>
  );
};
