import React from "react";
import { KeyboardAvoidingView, Platform } from "react-native";

import { EERKAVWrapperProps } from "./interface";
import { useStyles } from "./styles";

export const EERKeyboardAvoidingViewPageWrapper:React.FC<EERKAVWrapperProps> = ({ children, behavior="padding" }) => {
  const  styles = useStyles()
  return (
    <KeyboardAvoidingView
      behavior={behavior}
      enabled={Platform.OS ==="ios"}
      style={styles.container}>
      {children}
    </KeyboardAvoidingView>
  )
}