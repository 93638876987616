import React from 'react';

import { InformativePopin } from '@ere-uilib/organisms/overlay/InformativePopin';
import { useTranslation } from '@ere-uilib/translations';

interface Props {
  isModalVisible: boolean;
  onValidate: () => void;
  testId?: string;
}

export const DispositifChoicePopin: React.FC<Props> = ({
  isModalVisible,
  onValidate,
  testId
}) => {
  const { formatMessage, getMessageRaw } = useTranslation();

  return (
    <InformativePopin
      buttonTitle={formatMessage({ id: 'VV_repartition_entrancepopin_button' })}
      description={getMessageRaw({ id: 'VV_repartition_entrancepopin_label' })}
      iconName="idea"
      isModalVisible={isModalVisible}
      onClose={onValidate}
      onValidate={onValidate}
      title={formatMessage({ id: 'VV_repartition_entrancepopin_title' })}
      testId={testId}
    />
  );
};
