import {
  FontIcon, MobileMenuModalConnected, TabBarAdvancedButton,
  Text
} from "@components/index";
import { RouteNames } from "@constants/navigation";
import {
  createUseStyles, ThemeType, useScreenSizes, useTheme
} from "@ere-uilib/styles";
import {
  BottomTabNavigatorInterface,
  BottomTabNavigatorRouteNames
} from "@navigation/Interfaces/BottomTabInterface";
import { useDefaultScreensOptions } from "@navigation/useDefaultScreensOptions";
import { HomePage } from "@pages/index";
import {
  BottomTabBar,
  createBottomTabNavigator
} from "@react-navigation/bottom-tabs";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "@translations/index";
import React from "react";
import { Platform, StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { HistoryStackNavigator } from "./HistoryStack";
import { ProfilStackNavigator } from "./ProfileStack";
import { SavingsStackNavigator } from "./SavingsStack";
import { locator } from "@constants/locator";



type Styles = {
  container?: StyleProp<ViewStyle>;
  tabBar?: StyleProp<ViewStyle>;
  tabMask?: StyleProp<ViewStyle>;
  xFillLine?: StyleProp<ViewStyle>;
  tabBarItem?: StyleProp<ViewStyle>;
  tabBarIcon?: StyleProp<ViewStyle>;
  tabBarTitle?: StyleProp<TextStyle>;
  TabBarMenu?: StyleProp<ViewStyle>;
};

interface Props {
  route: any;
}

type StylesContext = { theme: ThemeType };

export const BottomTabNavigator: React.FC<Props> = ({ route }) => {
  const defaultScreensOptions = useDefaultScreensOptions();
  const BottomBar = createBottomTabNavigator<BottomTabNavigatorInterface>();
  const barColor = "#FFF";
  const { isMobile, isTablet } = useScreenSizes();
  const theme = useTheme();
  const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
    container: {
      flex: 1,
    },
    tabBar: {
      position: "absolute",
      bottom: 0,
      justifyContent: "space-around",
      height: 75,
      width: "100%",
      backgroundColor: "transparent",
      shadowRadius: 6,
      shadowColor: "#5e5e72",
      shadowOffset: {
        width: 0,
        height: -10,
      },
      shadowOpacity: 0.1,
      elevation: 30,
      zIndex: 1,
    },
    tabMask: {
      borderTopWidth: 0,
      elevation: 0,
      flexDirection: "row",
      width: "100%",
      backgroundColor: "transparent",
    },
    xFillLine: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: 30,
      backgroundColor: context?.theme.colors.basics.white,
    },
    tabBarItem: {
      flex: 1,
      alignItems: "center",
    },
    tabBarIcon: {
      marginTop: context?.theme.metrics.spacing.s,
    },
    tabBarTitle: {
      fontSize: context?.theme.fonts.fontSize.title.t9,
      marginTop: context?.theme.metrics.spacing.xs,
      minWidth: 60,
      textAlign: "center",
      textTransform: "uppercase",
    },
    TabBarMenu: {
      justifyContent: "space-around",
      shadowColor: context?.theme.colors.basics.greyTransparent.c100,
      shadowOffset: { width: 5, height: -9 },
      shadowOpacity: 0.5,
      height: context?.theme.metrics.spacing.xxbig,
      bottom: context?.theme.metrics.spacing.none,
      left: context?.theme.metrics.spacing.none,
      right: context?.theme.metrics.spacing.none,
      backgroundColor: "transparent",
      width: "100%",
      zIndex: 1,
    }
  });

  const useStyles = createUseStyles(getStyles);
  const styles = useStyles({ theme }, {});
  const { formatMessage } = useTranslation();
  const navigation = useNavigation();
  const isMobileMenuVisible =
    route?.params?.screen === BottomTabNavigatorRouteNames.MobileMenuModal;
  const flushNavigationAndGoBack = () => {
    if (isMobileMenuVisible) {
      navigation.setParams({ screen: undefined });
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.Home,
      });
    } else {
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: BottomTabNavigatorRouteNames.MobileMenuModal,
      });
    }
  };
  return (
    <BottomBar.Navigator
      initialRouteName={BottomTabNavigatorRouteNames.Home}
      tabBar={(props: any) =>
        (isMobile || isTablet) &&
        (Platform.OS === "web" ? (
          <View style={styles.TabBarMenu}>
            <View style={styles.xFillLine} />
            <BottomTabBar {...props} />
          </View>
        ) : (
          <View style={styles.tabBar}>
            <View style={styles.xFillLine} />
            <BottomTabBar {...props} />
          </View>
        ))
      }
      tabBarOptions={{
        showLabel: false,
        style: styles.tabMask,
        activeTintColor: theme?.colors.basics.primary.c500,
        inactiveTintColor: theme?.colors.text.primary.c500,
        tabStyle: {
          backgroundColor: barColor,
        },
      }}
    >
      <BottomBar.Screen
        component={HomePage}
        name={BottomTabNavigatorRouteNames.Home}
        options={{
          ...defaultScreensOptions.Home,
          tabBarIcon: ({ focused }) => (
            <View style={styles.tabBarItem}>
              <FontIcon
                accessibilityLabel={locator._tab_bar._home}
                testID={locator._tab_bar._home}
                color={
                  focused
                    ? theme?.colors.basics.primary.c500
                    : theme?.colors.text.primary.c500
                }
                name="accueil"
                size={theme.metrics.iconSizes.mplus}
                style={styles.tabBarIcon}
              />
              <Text
                style={[
                  styles.tabBarTitle,
                  focused
                    ? { color: theme?.colors.basics.primary.c500 }
                    : { color: theme?.colors.text.primary.c500 },
                ]}
              >
                {formatMessage({ id: "MainMenu_Home_label" })}
              </Text>
            </View>
          ),
        }}
      />
      <BottomBar.Screen
        component={SavingsStackNavigator}
        name={RouteNames.SavingsStack}
        options={{
          ...defaultScreensOptions.Savings,
          unmountOnBlur: true,
          tabBarIcon: ({ focused }) => (
            <View style={styles.tabBarItem}>
              <FontIcon
                accessibilityLabel={locator._tab_bar._savings}
                testID={locator._tab_bar._savings}
                color={
                  focused
                    ? theme?.colors.basics.primary.c500
                    : theme?.colors.text.primary.c500
                }
                name="epargne"
                size={theme.metrics.iconSizes.mplus}
                style={styles.tabBarIcon}
              />
              <Text
                style={[
                  styles.tabBarTitle,
                  focused
                    ? { color: theme?.colors.basics.primary.c500 }
                    : { color: theme?.colors.text.primary.c500 },
                ]}
              >
                {formatMessage({ id: "MainMenu_Savings_label" })}
              </Text>
            </View>
          ),
        }}
      />
      <BottomBar.Screen
        component={HomePage}
        name={BottomTabNavigatorRouteNames.Menu}
        options={{
          ...defaultScreensOptions.MobileMenuModal,
          tabBarButton: props => {
            return (
              <TabBarAdvancedButton
                active={!isMobileMenuVisible}
                onPress={() => {
                  flushNavigationAndGoBack();
                }}
              />
            );
          },
        }}
      />
      <BottomBar.Screen
        component={HistoryStackNavigator}
        name={BottomTabNavigatorRouteNames.HistoryStack}
        options={{
          ...defaultScreensOptions.History,
          tabBarIcon: ({ focused }) => (
            <View style={styles.tabBarItem}>
              <FontIcon
                accessibilityLabel={locator._tab_bar._my_activity_life}
                testID={locator._tab_bar._my_activity_life}
                color={
                  focused
                    ? theme?.colors.basics.primary.c500
                    : theme?.colors.text.primary.c500
                }
                name="historique"
                size={theme.metrics.iconSizes.mplus}
                style={styles.tabBarIcon}
              />
              <Text
                style={[
                  styles.tabBarTitle,
                  focused
                    ? { color: theme?.colors.basics.primary.c500 }
                    : { color: theme?.colors.text.primary.c500 },
                ]}
              >
                {formatMessage({ id: "MainMenu_History_label" })}
              </Text>
            </View>
          ),
        }}
      />
      <BottomBar.Screen
        component={ProfilStackNavigator}
        name={RouteNames.ProfileStack}
        options={{
          ...defaultScreensOptions.Profile,
          unmountOnBlur: true,
          tabBarIcon: ({ focused }) => (
            <View style={styles.tabBarItem}>
              <FontIcon
                accessibilityLabel={locator._tab_bar._profile}
                testID={locator._tab_bar._profile}
                color={
                  focused
                    ? theme?.colors.basics.primary.c500
                    : theme?.colors.text.primary.c500
                }
                name="profil"
                size={theme.metrics.iconSizes.mplus}
                style={styles.tabBarIcon}
              />
              <Text
                style={[
                  styles.tabBarTitle,
                  focused
                    ? { color: theme?.colors.basics.primary.c500 }
                    : { color: theme?.colors.text.primary.c500 },
                ]}
              >
                {formatMessage({ id: "MainMenu_Profile_label" })}
              </Text>
            </View>
          ),
        }}
      />
      <BottomBar.Screen
        component={MobileMenuModalConnected}
        name={BottomTabNavigatorRouteNames.MobileMenuModal}
        options={{
          ...defaultScreensOptions.MobileMenuModal,
          tabBarButton: () => <View style={{ width: 0, height: 0 }} />,
        }}
      />
    </BottomBar.Navigator>
  );
};
