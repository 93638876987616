import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { getSelectedCompany } from '@modules/dashboard/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';
import { getOperationDetailsRequest } from '@modules/savings/actions/savingsActions';
import { SavingsActionsType } from '@modules/savings/actions/savingsActionsTypes';
import { getOperationDetails } from '@modules/savings/selectors';

import { OperationDetailsPageComponent } from './OperationDetailsPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    isLoading: state.isLoading[SavingsActionsType.GET_OPERATION_DETAILS_REQUEST],
    error: state.error[SavingsActionsType.GET_OPERATION_DETAILS_REQUEST],
    operationDetails: getOperationDetails(state),
    selectedCompany: getSelectedCompany(state),
    applicationSettings: getApplicationSettings(state),
  };
};
const mapDispatchToProps = {
  onGetOperationDetails: getOperationDetailsRequest,
}

export const OperationDetailsPage = connect(mapStateToProps, mapDispatchToProps)(OperationDetailsPageComponent);