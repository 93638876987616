import React, { useMemo } from 'react';
import { View, StyleProp, TextStyle, ViewStyle } from 'react-native';

import { Title } from '@ere-uilib/atoms';
import { useTranslation } from '@ere-uilib/translations';

import { useStyles } from './useStyles';

interface Props extends Styles {
  navigation?: any;
  title?: string;
  subHeaderInstallmentBottomRowTitle?: string;
  amount?: number;
  subHeaderInstallmentBottomRowAmount?: number;
  showContribution?: boolean;
  addShadow?: boolean;
  hasContributionError?: boolean;
  isPercent?: boolean;
}

interface Styles {
  containerStyle?: StyleProp<ViewStyle>;
  subHeaderInstallmentRowStyle?: StyleProp<ViewStyle>;
  subHeaderInstallmentRowTitleStyle?: StyleProp<TextStyle>;
  subHeaderInstallmentRowAmountStyle?: StyleProp<TextStyle>;
}

export const TotalSubHeader: React.FC<Props> = ({
  title,
  amount,
  isPercent,
}) => {
  const { formatCurrencyNumber } = useTranslation();
  const styles = useStyles();
  const value = useMemo(() => {
    if (amount === undefined) {
      return;
    }

    let formattedValue;

    if (isPercent) {
      formattedValue = `${amount} %`;
    } else {
      formattedValue = formatCurrencyNumber({ value: amount });
    }

    return formattedValue;
  }, [amount, formatCurrencyNumber, isPercent]);

  return (
    <View style={styles.subHeaderInstallmentRowStyle}>
      <Title
        style={styles.subHeaderInstallmentRowTitleStyle}
        variant="t7"
        weight="light"
      >
        {title}
      </Title>
      {value &&
        <Title
          style={styles.subHeaderInstallmentRowAmountStyle}
          variant="t7"
          weight="bold"
        >
          {value}
        </Title>
      }
    </View>
  );
};
