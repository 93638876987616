import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { RouteNames } from '@constants/navigation';
import { StepperHeader } from '@ere-uilib/molecules';
import { HelpPointPositiontype } from '@ere-uilib/molecules/headers/NavigationHeader/interface';
import { StepperItemType } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes';

interface Props {
  displayCloseButton: boolean
  hideStepper?: boolean
  helpPointContentHtml?: string
  helpPointModalTitle?: string
  helpPointPosition?: HelpPointPositiontype
  onClose?: () => void
  title: string
  stepperItems?: StepperItemType[]
  helpPoint: boolean
}

interface StyleProps {
  stepperContainer?: StyleProp<ViewStyle>
  stepperStyle?: StyleProp<ViewStyle>
}

export const ArbitrationHeader: React.FC<Props & StyleProps> = ({
  onClose,
  title,
  displayCloseButton,
  helpPointModalTitle,
  helpPointContentHtml,
  helpPointPosition,
  stepperItems,
  helpPoint,
}) => {
  const navigation = useNavigation();

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();

      return;
    }

    navigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationModal,
      params: {
        clearAction: ArbitrationActionsType.CLEAR_ARBITRATION_DATA,
      },
    });
  }, [onClose, navigation]);

  return (
    <StepperHeader
      displayCloseButton={displayCloseButton}
      helpPoint={helpPoint}
      helpPointContentHtml={helpPointContentHtml}
      helpPointModalTitle={helpPointModalTitle}
      helpPointPosition={helpPointPosition}
      onClose={handleClose}
      stepperItems={stepperItems}
      title={title}
    />
  );
};
