import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { getAuthConfig } from '@modules/settings/selectors';

import { Error as ErrorComponent } from './Error.component';

const mapStateToProps = (state: AppState) => {
  return {
    authConfig: getAuthConfig(state),
  };
};

const mapDispatchToProps = {
};

export const Error = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorComponent);
