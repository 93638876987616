import React, { useCallback } from 'react';
import { View } from 'react-native';
import {
  ModalFrame,
  SimpleButton,
  useTranslation,
  Title,
  NotificationHard,
  NotificationIconTypeEnum,
  NotificationIcon,
  Paragraph,
  HtmlStyledRenderConnectedContainer
} from '@components/index';

import { useCancelOperationModalInfos } from '../hooks';
import { OperationCancelPageProps } from './interface';
import { useStyles } from './style';
import { OperationFamilyEnum } from '@constants/savings';
import { ThresholdTriggerTypesEnum } from '@constants/arbitrations';

export const OperationCancelPage: React.FC<OperationCancelPageProps> = ({
  navigation,
  operationDetails,
  cancelError,
  isCancelLoading,
  onCancelOperation
}) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const styles = useStyles();

  const { cancelModalTitle, renderCancelModalDescription } = useCancelOperationModalInfos({
    operationDetails
  });

  const handleModalClose = useCallback(() => {
    navigation.pop();
  }, [navigation]);

  const handleModalValidation = useCallback(() => {
    onCancelOperation(operationDetails?.identifier || '');
  }, [onCancelOperation, operationDetails?.identifier]);

  const operationFamily = operationDetails?.family;
  const isArbitration = operationFamily === OperationFamilyEnum.ARBITRATION;
  const isRefund = operationFamily === OperationFamilyEnum.REFUND;
  const thresholdType = operationDetails?.details?.conditionalOrder?.thresholdType;

  const renderVCPCancelDetails = useCallback(() => {
    if ((!isArbitration && !isRefund) || thresholdType !== ThresholdTriggerTypesEnum.VCP)
      return null;
    return (
      <>
        <Paragraph
          style={styles.modalDescriptionText}
          autoMargin
          variant="t3"
          weight="light">
          {formatMessage({ id: 'DetailOperationSeuilAnnulationDescription1' })}
        </Paragraph>
        <HtmlStyledRenderConnectedContainer
          html={
            getMessageRaw({
              id: 'DetailOperationSeuilAnnulationDescription2',
              defaultMessage: ''
            })
          }
        />
      </>
    );
  }, [isArbitration, isRefund, thresholdType]);

  const renderActions = () => {
    return (
      <View style={styles.buttonContainerStyle}>
        <SimpleButton
          containerStyle={styles.buttonStyle}
          loading={isCancelLoading}
          onPress={handleModalValidation}
          title={formatMessage({ id: 'PIAnnulationOuiBouton' })}
        />
        <SimpleButton
          containerStyle={[styles.buttonStyle, styles.lastButtonStyle]}
          design={'outlined'}
          disabled={isCancelLoading}
          onPress={handleModalClose}
          title={formatMessage({ id: 'PIAnnulationNonBouton' })}
        />
      </View>
    );
  };
  return (
    <View style={styles.containerStyle}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom">
        <View style={styles.modalContainer}>
          <NotificationIcon type={NotificationIconTypeEnum.WARNING} />
          <Title
            style={styles.titleStyle}
            variant="t6">
            {cancelModalTitle}
          </Title>
          {renderVCPCancelDetails()}
          {renderCancelModalDescription?.()}
          {!!cancelError?.message && cancelError?.message !== '' && (
            <NotificationHard
              text={cancelError?.message}
              type={NotificationIconTypeEnum.WARNING}
            />
          )}
          {renderActions()}
        </View>
      </ModalFrame>
    </View>
  );
};
