import { connect } from 'react-redux';

import { onGetLabel, onPutLanguageRequest } from '@modules/label/actions/labelActions';
import { AppState } from '@modules/index';
import { getLabels, getLanguage } from '@modules/label/selectors';

import { AppLanguageHead as AppLanguageHeadComponent } from './AppLanguageHead.component';

const mapStateToProps = (state: AppState) => {
  return {
    language: getLanguage(state),
    labels: getLabels(state)
  };
};

const mapDispatchToProps = {
  onGetLabels: onGetLabel,
  onPutLanguageRequest
};

export const AppLanguageHead = connect(mapStateToProps, mapDispatchToProps)(AppLanguageHeadComponent);
