import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useMemo(() => StyleSheet.create({
    container: {
      padding: isDesktop ? theme.metrics.spacing.l : theme.metrics.spacing.s
    },
    helpPointNoResponseTitle: {
      marginBottom: theme.metrics.spacing.xs
    },
    buttonsSubmitContainer: {
      flexDirection: isDesktop ? 'row' : 'column'
    },
    notificationText: {
      marginBottom: theme.metrics.spacing.xm
    },
    modalContainer: {
    },
    bottomContainerTitle: {
      marginBottom: theme.metrics.spacing.xm
    },
    bottomContainerSubtitle: {
      marginBottom: theme.metrics.spacing.s
    },
    bottomRadiosButtonsContainer: {
      marginBottom: theme.metrics.spacing.xm
    },
    bottomContainer: {
      marginTop: theme.metrics.spacing.xm
    },
    backButtonContainer: {
      marginBottom: theme.metrics.spacing.l
    },
    mobileFooterContainer: {
      marginHorizontal: theme.metrics.spacing.s
    },
    buttonSubmit: {
      marginTop: theme.metrics.spacing.m,
      marginBottom: theme.metrics.spacing.s,
      width: isDesktop ? 'fit-content' : undefined
    },
    buttonSubmitLater: {
      marginTop: isDesktop ? theme.metrics.spacing.m : 0,
      marginBottom: isDesktop ? theme.metrics.spacing.s : 0,
      marginRight: isDesktop ? theme.metrics.spacing.m : 0,
      width: isDesktop ? 'fit-content' : undefined
    }
  }), [theme, isDesktop])
  return styles
};