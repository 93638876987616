import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useSelector } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { AppState } from '@modules/reducers';
import { getRefundsDecision } from '@modules/refund/selectors';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import {
  RefundsRequirementDefinitonPage,
  RefundRepartitionPage,
  RefundOnboardingPage,
  RefundsPlanChoicePage,
  RefundAskProofPage,
  ConfirmModalPageComponent,
  RefundSuccessPage,
  RefundFailedPage,
} from '@pages/index';
import { RefundsRecapPage } from '@pages/Refunds/AvailableRefund/RefundsRecapPage';
import { UnavailableRefundRC } from '@pages/Refunds/UnavailableRefund/UnavailableRefundRC';

const RefundStack = createStackNavigator();

export const RefundsStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  const refundDecisionData = useSelector((state: AppState) =>
    getRefundsDecision(state)
  );

  const isDataAvailable = !!refundDecisionData?.questions?.length;

  return (
    <RefundStack.Navigator
      initialRouteName={RouteNames.RefundOnboarding}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
      }}
    >
      <RefundStack.Screen
        component={RefundOnboardingPage}
        name={RouteNames.RefundOnboarding}
        options={defaultScreensOptions.RefundOnboarding}
      />
      {isDataAvailable && (
        <>
          <RefundStack.Screen
            component={RefundsRequirementDefinitonPage}
            name={RouteNames.RefundsRequirementDefiniton}
            options={defaultScreensOptions.RefundsRequirementDefiniton}
          />
          <RefundStack.Screen
            component={UnavailableRefundRC}
            name={RouteNames.UnavailableRefundRC}
            options={defaultScreensOptions.UnavailableRefundRC}
          />
          <RefundStack.Screen
            component={RefundRepartitionPage}
            name={RouteNames.RefundRepartition}
            options={defaultScreensOptions.RefundRepartition}
          />
          <RefundStack.Screen
            component={RefundsPlanChoicePage}
            name={RouteNames.RefundPlanChoice}
            options={defaultScreensOptions.RefundsPlanChoice}
          />
          <RefundStack.Screen
            component={RefundAskProofPage}
            name={RouteNames.RefundAskProof}
            options={defaultScreensOptions.RefundAskProof}
          />
          <RefundStack.Screen
            component={RefundsRecapPage}
            name={RouteNames.RefundsRecap}
            options={defaultScreensOptions.RefundsRecap}
          />
          <RefundStack.Screen
            component={RefundSuccessPage}
            name={RouteNames.RefundSuccess}
            options={defaultScreensOptions.RefundSuccess}
          />
          <RefundStack.Screen
            component={RefundFailedPage}
            name={RouteNames.RefundFailed}
            options={defaultScreensOptions.RefundFailed}
          />
        </>
      )}
      {/* Modal screen here if needed */}
      <RefundStack.Screen
        component={ConfirmModalPageComponent}
        name={RouteNames.RefundModal}
        options={defaultScreensOptions.ConfirmModal}
      />
    </RefundStack.Navigator>
  );
};
