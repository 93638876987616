import {createUseStyles, ThemeType, useTheme} from '@ere-uilib/styles';
import React, {useEffect, useRef} from 'react';
import {Animated, StyleProp, View, ViewStyle} from 'react-native';


interface Props {
  progress: number;
  progressBarContainerStyle?: StyleProp<ViewStyle>;
  progressBarStyle?: StyleProp<ViewStyle>;
}

type ProgressBarStyles = {
  progressBarContainerStyle?: StyleProp<ViewStyle>;
  progressBarStyle?: StyleProp<ViewStyle>;
};

type StylesContext = {theme: ThemeType};

export const ProgressBar: React.FC<Props> = props => {
  const animation = useRef(new Animated.Value(0));

  const { progress, progressBarStyle, progressBarContainerStyle } = props;
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    { progressBarContainerStyle, progressBarStyle },
  );

  useEffect(() => {
    Animated.timing(animation.current, {
      toValue: progress,
      duration: 2000,
      useNativeDriver: false
    }).start();
  }, [progress]);

  const width = animation.current.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
    extrapolate: 'clamp'
  });

  return (
    <View style={styles.progressBarContainerStyle}>
      <View style={styles.progressBarStyle}>
        <Animated.View
          style={{
            backgroundColor: theme.colors.basics.primary.c500,
            width
          }}
        />
      </View>
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: ProgressBarStyles,
): ProgressBarStyles => ({
  progressBarContainerStyle: [
    {
      width: '100%',
      marginTop: context?.theme.metrics.spacing.s,
      height: 5
    },
    style?.progressBarContainerStyle
  ],
  progressBarStyle: [
    {
      width: '100%',
      flexDirection: 'row',
      height: 5,
      backgroundColor: context?.theme.colors.basics.grey.c300,
      borderColor: 'transparent',
      borderWidth: 0
    },
    style?.progressBarStyle
  ]
});

const useStyles = createUseStyles(getStyles);
