import React, { useState } from 'react';
import { View } from 'react-native';

import {
  OtpValidationModal,
  OtpHelpModal,
  HelpButton,
  Text,
  useTheme,
  useTranslation,
  DialOncePressable
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { useIsUserCloned } from '@utils/useIsUserCloned';

import { OtpSecurisationStartPageProps } from './interface';
import { getStyles } from './styles';

export const OtpSecurisationStartPage: React.FC<OtpSecurisationStartPageProps> = ({
  otpParameters,
  isAskCodeLoading,
  onSendMessagePress,
  userInfos,
  appSettings
}) => {
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const isUserCloned = useIsUserCloned();
  const styles = useStyles({ theme }, {});
  return (
    <OtpValidationModal
      isActionsDisabled={isUserCloned}
      isLoading={isAskCodeLoading}
      onSendMessagePress={onSendMessagePress}
      otpParameters={otpParameters}
      renderHelp={otpHelpText => {
        return (
          otpParameters?.chanelType && (
            <View style={styles.helpContainerStyle}>
              <DialOncePressable
                integrationKey={appSettings?.HelpDialOnceIntegrationKey || ''}
                targetedFlowId={appSettings?.HelpCredentialsIssuePageDialOnceId || ''}>
                <>
                  <Text
                    style={styles.helpTextStyle}
                    variant={'t2'}>
                    {otpHelpText}
                  </Text>
                  <HelpButton
                    onPress={() => {
                      setHelpModalVisible(true);
                    }}
                    title={formatMessage({
                      id: 'session_security_help_contact_button'
                    })}
                  />
                </>
              </DialOncePressable>
              <OtpHelpModal
                channelType={otpParameters?.chanelType}
                email={otpParameters?.chanelLabelMail || userInfos.mail || undefined}
                integrationKey={appSettings?.HelpDialOnceIntegrationKey || ''}
                phoneNumber={otpParameters?.chanelLabelSms || userInfos.phone || undefined}
                setVisible={setHelpModalVisible}
                targetedFlowId={appSettings?.HelpCredentialsIssuePageDialOnceId || ''}
                visible={helpModalVisible}
              />
            </View>
          )
        );
      }}
      userInfos={userInfos}
    />
  );
};
const useStyles = createUseStyles(getStyles);
