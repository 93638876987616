import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

interface Props {
  isQuestionAnswered?: boolean // to be used to add filet whand question is answered
}

interface StyleProps {
  container?: StyleProp<ViewStyle>
}

interface StyleContext {
  theme: ThemeType
}

export const FiletIcon: React.FC<Props & StyleProps> = ({
  container
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, { container });
  return (
    <View style={styles.container}/>
  );

};

const getStyles = (
  context?: StyleContext,
  style?: StyleProps,
): StyleProps => ({
  container: [
    {
      width: 7,
      height: 7,
      borderRadius: 7 / 2,
      borderWidth: 2,
      borderColor: context?.theme.colors.basics.black,
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.container
  ]
});

const useStyles = createUseStyles(getStyles);
