import React from 'react';
import { StyleProp, TextStyle } from 'react-native';

import { Paragraph, SVGLocalLoader, Title } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

interface Props {
  titleStyle?: StyleProp<TextStyle>;
  subtitleStyle?: StyleProp<TextStyle>;
  name?: string
}

type AccountCreationSuccessStyles = {
  titleStyle?: StyleProp<TextStyle>;
  subtitleStyle?: StyleProp<TextStyle>;
};

type StylesContext = { theme: ThemeType };

export const AccountCreationSuccess: React.FC<Props> = ({
  subtitleStyle,
  titleStyle,
  name
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, { titleStyle, subtitleStyle });
  const { formatMessage } = useTranslation();
  return (
    <>
      <SVGLocalLoader
        height={261}
        name="congrats"
        width={450}
      />
      <Title
        style={styles.titleStyle}
        variant="t3"
        weight="bold"
      >
        {formatMessage({
          id: 'congrats_title',
          values: { variable: name || '' }
        })}
      </Title>
      <Paragraph
        style={styles.subtitleStyle}
        variant="t2"
      >
        {formatMessage({ id: 'congrats_description' })}
      </Paragraph>
    </>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: AccountCreationSuccessStyles,
): AccountCreationSuccessStyles => ({
  titleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
      textAlign: 'center'
    },
    style?.titleStyle
  ],
  subtitleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
      maxWidth: 450,
      textAlign: 'center'
    },
    style?.subtitleStyle
  ]
});

const useStyles = createUseStyles(getStyles);
