import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import { ArbitrationParamsTypes, ArbitrationTypes } from '@constants/arbitrations'
import { RouteNames } from '@constants/navigation'
import {
  NotificationSystemNameEnum,
  NotificationType
} from '@ere-uilib/types/notificationSystemTypes'
import * as arbitrationAction from '@modules/arbitration/actions/arbitrationActions'
import {
  ArbitrationActionsType,
  GetDispositifDetailRequestAction,
  GetOadAllocationsRequestAction,
  GetRealocationDivestmentRequestAction,
  GetRiskProfileRequestAction,
  SelectArbitrationTypeRequest,
  UpdateArbitrationQuestionWithResponseRequest
} from '@modules/arbitration/actions/arbitrationActionsTypes'
import {
  CompartmentType,
  DispositifState,
  FilterPropertiesType,
  PlanCompartmentsType
} from '@modules/common/types'
import { getUsedCompanyId } from '@modules/dashboard/selectors'
import { runManager } from '@modules/moduleManager'
import * as notificationSystemActions from '@modules/notification-system/actions/notificationSystemActions'
import {
  fetchArbitrationQuestions,
  fetchCreateArbitration,
  fetchDispositifDetails,
  fetchOadAllocations,
  fetchOadAllocationsDemandSubmit,
  fetchReallocationDivestment,
  fetchRiskProfileData
} from './services'
import { updateQuestionWhenResponded } from '@modules/utils/requirementDefinition'
import { RootNavigation } from '@navigation/RootNavigation'

import {
  planQuestion,
  planCompartmentsQuestion,
  OADStartQuestion,
  ArbitrationDecisionPropertiesEnum,
  ArbitrationQuestionTypesEnum
} from './constants'
import {
  eligibleAllocation,
  getArbitrationDecision,
  getArbitrationState,
  getFetchCreateArbitrationParameters,
  getPlanCompartments,
  getReallocationType,
  getSelectedDispositif,
  getArbitrationDecisionAllResponses,
  getOadReallocationsSubmitData,
  OadReallocationsParams,
  getActualRepartitionProfileRisk
} from './selectors'
import {
  EligibleAllocation,
  SubmitedDivestedManagement,
  ArbitrationState,
  FetchCreateArbitrationExpectedData,
  RiskProfileData
} from './types'
import {
  updateArbitrationDecisionPlanQuestion,
  updateArbitrationDecisionCompartmentQuestion,
  getDecisionAllResponses
} from './utils'
import { PlanFamillyList } from '@ere-uilib/constants'

function* getArbitrationQuestions(): any {
  const companyId = yield select(getUsedCompanyId)
  const response = yield call(fetchArbitrationQuestions, companyId)

  const arbitrationDecision = response.data
  let shouldRedirect = false
  let arbitrationType = ArbitrationTypes.stock

  yield updateArbitrationQuestionFromSelectedArbitrationType(arbitrationDecision)

  // manage arbitrationType auto answer and redirection

  const eligibilityStatus: EligibleAllocation = yield select(eligibleAllocation)

  if (eligibilityStatus.isEligibleStock && !eligibilityStatus.isEligibleFlow) {
    shouldRedirect = true
    yield put(arbitrationAction.selectArbitrationTypeRequest(arbitrationType))
  }

  if (!eligibilityStatus.isEligibleStock && eligibilityStatus.isEligibleFlow) {
    shouldRedirect = true
    arbitrationType = ArbitrationTypes.flow
    yield put(arbitrationAction.selectArbitrationTypeRequest(arbitrationType))
  }

  if (!eligibilityStatus.isEligibleStock && !eligibilityStatus.isEligibleFlow) {
    shouldRedirect = true
    arbitrationType = ArbitrationTypes.ineligible
  }

  if (shouldRedirect) {
    return yield RootNavigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationOnboarding
    })
  }
}

// Oad Allocations
function* getOadAllocations(action: GetOadAllocationsRequestAction): any {
  const response = yield call(fetchOadAllocations, action.profile, action.horizon)
  const oadAllocation = response.data

  const constructedAllocation = Object.keys(oadAllocation).map(key => ({
    key,
    value: oadAllocation[key]
  }))

  yield put(arbitrationAction.getOadAllocationsSuccess(constructedAllocation))
}
function* updateArbitrationQuestionFromSelectedArbitrationType(
  arbitrationDecision: ArbitrationState['decision']
) {
  const isEligibleOADDispositif =
    arbitrationDecision.dispositifs.filter(
      (dispositif: DispositifState) => dispositif.filterProperties.OadReallocation === true
    ).length > 0

  const selectedReallocationType: ArbitrationState['decision']['reallocationType'] = yield select(
    getReallocationType
  )
  const isStock = selectedReallocationType === ArbitrationTypes.stock

  if (isStock && isEligibleOADDispositif) {
    arbitrationDecision = {
      ...arbitrationDecision,
      questions: [{ ...OADStartQuestion }, { ...planQuestion }, { ...planCompartmentsQuestion }]
    }
  } else {
    arbitrationDecision = {
      ...arbitrationDecision,
      questions: [{ ...planQuestion }, { ...planCompartmentsQuestion }]
    }
  }

  arbitrationDecision = updateArbitrationDecisionPlanQuestion(arbitrationDecision)

  yield put(arbitrationAction.getArbitrationQuestionsSuccess(arbitrationDecision))
}

function* getArbitrationQuestionsSagas() {
  yield takeLatest(
    ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_REQUEST,
    runManager(getArbitrationQuestions, ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_FAILURE)
  )
}
// dispositif details
function* getDispositifDetails(action: GetDispositifDetailRequestAction): any {
  const companyId = yield select(getUsedCompanyId)
  const allResponses: FilterPropertiesType = yield select(getArbitrationDecisionAllResponses)
  const isOAD = allResponses?.OadReallocation === true
  const arbitrationType: ArbitrationTypes = yield select(getReallocationType)
  const selectedReallocationParam = isOAD
    ? ArbitrationParamsTypes.Oad
    : ArbitrationParamsTypes[arbitrationType]
  const response = yield call(
    fetchDispositifDetails,
    companyId,
    action.planId,
    selectedReallocationParam
  )
  yield put(arbitrationAction.getDispositifDetailSuccess(response?.data))
}

// This function is used to answer any of arbitration decision

function* updateArbitrationQuestionWithResponse(
  action: UpdateArbitrationQuestionWithResponseRequest
): any {
  const arbitrationState: ArbitrationState = yield select(getArbitrationState)

  let newDecision: ArbitrationState['decision'] = {
    ...arbitrationState.decision,
    ...updateQuestionWhenResponded(
      arbitrationState,
      action.response,
      action.index,
      action.canNotBeAsked
    )
  }

  const targetedQuestion = newDecision?.questions?.[action.index]

  // manage specific questions
  switch (targetedQuestion.type) {
    case ArbitrationQuestionTypesEnum.OAD_START:
      // update planchoice question to only elligible plans based on OADStart answer
      newDecision = updateArbitrationDecisionPlanQuestion(newDecision)

      const currentAnswers = getDecisionAllResponses(newDecision)

      if (currentAnswers[ArbitrationDecisionPropertiesEnum.OAD_REALLOCATION] === true) {
        // add OAD questions
        newDecision = {
          ...newDecision,
          questions: [
            ...newDecision.questions,
            { ...action.OADDelayQuestion },
            { ...action.OADRiskQuestion }
          ]
        }
      }
      break
    case ArbitrationQuestionTypesEnum.PLAN_CHOICE:
      // update compartmentChoice question to set compartments
      const planId = action.response.respondedPropertyFilter?.value

      yield put(
        arbitrationAction.getDispositifDetailRequest((typeof planId === 'string' && planId) || '')
      )
      yield take([
        ArbitrationActionsType.GET_DISPOSITIF_DETAIL_SUCCESS,
        ArbitrationActionsType.GET_DISPOSITIF_DETAIL_FAILURE
      ])
      const planCompartments = yield select(getPlanCompartments)
      const dispositifs: DispositifState[] = newDecision.dispositifs
      const selectedDispositif = [
        dispositifs.filter((dispo: DispositifState) => dispo.id === planId)[0]
      ]
      // updated newDecision with planCompartments to avoid removing it
      newDecision = {
        ...newDecision,
        selectedDispositif,
        planCompartments
      }
      newDecision = updateArbitrationDecisionCompartmentQuestion(newDecision)

      break
    case ArbitrationQuestionTypesEnum.OAD_RISK:
      const delayQuestion = newDecision.questions.find(
        question => question.type === ArbitrationQuestionTypesEnum.OAD_DELAY
      )
      const delay = delayQuestion?.responded?.respondedValue || ''
      const profil = targetedQuestion.responded?.respondedValue || ''

      yield put(arbitrationAction.getOadAllocationsRequest(profil, delay))

      break
    case ArbitrationQuestionTypesEnum.COMPARTMENT_CHOICE:
      const allPlanCompartments: PlanCompartmentsType = yield select(getPlanCompartments)
      const selectedCompartment = allPlanCompartments.compartments.find(
        (_compartment: any) => _compartment.code === action.response.respondedPropertyFilter?.value
      )

      yield put(arbitrationAction.setSelectedCompartment(selectedCompartment))

      newDecision = {
        ...newDecision,
        planCompartments: allPlanCompartments,
        selectedCompartment
      }

      break
    default:
      break
  }

  yield put(arbitrationAction.updateArbitrationQuestionWithResponseSuccess(newDecision))

  const isUnAnsweredQuestions =
    newDecision.questions.filter(question => !question.responded).length > 0

  if (!isUnAnsweredQuestions) {
    // define selected plan and compartment
    const updatedDecision: ArbitrationState['decision'] = yield select(getArbitrationDecision)
    const updatedPlanCompartments = updatedDecision.planCompartments
    const allAnswers = getDecisionAllResponses(updatedDecision)
    const selectedArbitartionType = yield select(getReallocationType)

    const selectedDispositif = updatedDecision.dispositifs.find(
      dispositif => dispositif.id === allAnswers.planId
    )
    const selectedCompartment = updatedPlanCompartments?.compartments.find(
      compartement => compartement.code === allAnswers.compartmentCode
    )

    const selectedReallocationType = yield select(getReallocationType)
    const isFlow = selectedReallocationType === ArbitrationTypes.flow

    if (selectedDispositif && selectedCompartment) {
      yield put(arbitrationAction.setSelectedDispositif([selectedDispositif]))
      yield put(arbitrationAction.setSelectedCompartment(selectedCompartment))
      if (isFlow) {
        // for instance we manage the first management . later we will have to divest all compartment
        const totalAmount = selectedCompartment?.managements[0].totalAmount
        const managementId = selectedCompartment?.managements[0].id
        yield put(
          arbitrationAction.setDivestedManagamentSource({
            amount: totalAmount,
            managementId: managementId,
            supportIsinCode: undefined
          })
        )
        yield put(
          arbitrationAction.getRealocationDivestmentRequest(
            {
              amount: totalAmount || 0,
              managementId: managementId || ''
            },
            false
          )
        )
        yield take([ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_SUCCESS])
      }
      if (!allAnswers[ArbitrationDecisionPropertiesEnum.OAD_REALLOCATION] === true)
        if (selectedDispositif.planFamily === PlanFamillyList.ES) {
          const routeName =
            selectedArbitartionType === ArbitrationTypes.flow
              ? RouteNames.ArbitrationReinvestment
              : RouteNames.ArbitrationDisinvestmentsSaving

          return RootNavigation.navigate(RouteNames.ArbitrationStack, {
            screen: routeName
          })
        } else {
          if(selectedArbitartionType === ArbitrationTypes.flow) {
            return RootNavigation.navigate(RouteNames.ArbitrationStack, {
              screen: RouteNames.ArbitrationReinvestment
            })
          }
          const isInformativePopinHidden = !!selectedDispositif?.filterProperties?.FlowReallocation
            && !selectedDispositif?.filterProperties?.StockReallocation

          if (isInformativePopinHidden) return
          return yield put(arbitrationAction.setIsVisibleArbitrationDecisionSubmitModal(true))
        }

    }
  }
}

function* setCompartmentAndManageModal(
  selectedDispositif: DispositifState[],
  compartment?: CompartmentType,
  isModalVisible = true
) {
  yield put(arbitrationAction.setSelectedDispositif(selectedDispositif)),
    yield put(arbitrationAction.setSelectedCompartment(compartment)),
    yield put(arbitrationAction.setIsVisibleArbitrationDecisionSubmitModal(isModalVisible))
}

function* getReallocationInvest(action: GetRealocationDivestmentRequestAction): any {
  const companyId = yield select(getUsedCompanyId)
  const selectedPlan = yield select(getSelectedDispositif)
  const selectedArbitartionType = yield select(getReallocationType)
  let divestedManagement: SubmitedDivestedManagement
  if (selectedPlan[0].filterProperties.PartialReallocation) {
    divestedManagement = {
      type: selectedArbitartionType,
      planManagement: {
        fundsList: [
          {
            amount: action.DivestmentPayloadParams.amount,
            executionTriggerValue: action.DivestmentPayloadParams.executionTriggerValue || 0,
            supportIsinCode: action.DivestmentPayloadParams.supportIsinCode || ''
          }
        ],
        id: action.DivestmentPayloadParams.managementId
      }
    }
  } else {
    divestedManagement = {
      type: selectedArbitartionType,
      planManagement: {
        fundsList: [],
        id: action.DivestmentPayloadParams.managementId
      }
    }
  }
  const response = yield call(
    fetchReallocationDivestment,
    companyId,
    selectedPlan[0].id,
    divestedManagement
  )
  yield put(arbitrationAction.getRealocationDivestmentSuccess(response?.data))
  if (response?.data.compartments.length > 0) {
    if (!action.shouldNavigate) return
    return yield RootNavigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationReinvestment
    })
  } else {
    yield put(
      notificationSystemActions.addNotification({
        uid: uuidv4(),
        type: NotificationType.WARNING,
        name: NotificationSystemNameEnum.ARBITRATION_NO_DESTINATION_ERROR
      })
    )
  }
}

function* createArbitration(): any {
  const fetchCreateArbitrationParameters: FetchCreateArbitrationExpectedData | undefined =
    yield select(getFetchCreateArbitrationParameters)

  const response = yield call(fetchCreateArbitration, fetchCreateArbitrationParameters)
  if (response?.data?.isSucceeded) {
    yield RootNavigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationSuccess
    })
  } else {
    yield RootNavigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationFailure
    })
  }

  yield put(arbitrationAction.createArbitrationSuccess(response?.data))
}

function* createOadReallocations(): any {
  const oadRallocations: OadReallocationsParams = yield select(getOadReallocationsSubmitData)

  const response = yield call(fetchOadAllocationsDemandSubmit, oadRallocations)
  if (response?.data?.isSucceeded) {
    yield RootNavigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationSuccess
    })
  } else {
    yield RootNavigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationFailure
    })
  }

  yield put(arbitrationAction.submitOadReallocationsSuccess(response?.data))
}

// this function set arbitration operation type (flow & stock) then prepare plan question
function* selectArbitrationType(action: SelectArbitrationTypeRequest): any {
  yield put(arbitrationAction.setReallocationType(action.arbitrationType))

  const arbitrationDecision: ArbitrationState['decision'] = yield select(getArbitrationDecision)

  yield updateArbitrationQuestionFromSelectedArbitrationType(arbitrationDecision)
  //init selectedDispositif, selectedCompartment and modal status
  yield setCompartmentAndManageModal([], undefined, false)
  yield put(arbitrationAction.selectArbitrationTypeSuccess())
}

function* selectArbitrationTypeSaga() {
  yield takeLatest(
    ArbitrationActionsType.SELECT_ARBITRATION_TYPE_REQUEST,
    runManager(selectArbitrationType, ArbitrationActionsType.SELECT_ARBITRATION_TYPE_FAILURE)
  )
}

function* getDispositifDetailsSagas() {
  yield takeLatest(
    ArbitrationActionsType.GET_DISPOSITIF_DETAIL_REQUEST,
    runManager(getDispositifDetails, ArbitrationActionsType.GET_DISPOSITIF_DETAIL_FAILURE)
  )
}

function* updateArbitrationQuestionWithResponseSagas() {
  yield takeLatest(
    ArbitrationActionsType.UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_REQUEST,
    runManager(
      updateArbitrationQuestionWithResponse,
      ArbitrationActionsType.UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_FAILURE
    )
  )
}

function* getReallocationInvestSagas() {
  yield takeLatest(
    ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_REQUEST,
    runManager(getReallocationInvest, ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_FAILURE)
  )
}
function* createArbitrationSagas() {
  yield takeLatest(
    ArbitrationActionsType.CREATE_ARBITRATION_REQUEST,
    runManager(createArbitration, ArbitrationActionsType.CREATE_ARBITRATION_FAILURE)
  )
}

function* getOadAllocationsSagas() {
  yield takeLatest(
    ArbitrationActionsType.GET_OAD_ALLOCATIONS_REQUEST,
    runManager(getOadAllocations, ArbitrationActionsType.GET_OAD_ALLOCATIONS_FAILURE)
  )
}
// PROFILE RISK

function* getRiskProfile(action: GetRiskProfileRequestAction): any {
  const actualRepartitionRiskProfile = yield select(getActualRepartitionProfileRisk)
  const companyId = yield select(getUsedCompanyId)
  const selectedPlan = yield select(getSelectedDispositif)
  const response: { data: RiskProfileData } = yield call(fetchRiskProfileData, {
    data: action.params,
    planId: selectedPlan[0].id,
    companyId
  })
  yield put(
    arbitrationAction.getRiskProfileSuccess({
      ...response.data,
      actualProfileRisk: actualRepartitionRiskProfile
    })
  )
}
function* getRiskProfileSagas() {
  yield takeLatest(
    ArbitrationActionsType.GET_RISK_PROFILE_REQUEST,
    runManager(getRiskProfile, ArbitrationActionsType.GET_RISK_PROFILE_FAILURE)
  )
}
function* createOadReallocation() {
  yield takeLatest(
    ArbitrationActionsType.SUBMIT_OAD_REALLOCATIONS_REQUEST,
    runManager(createOadReallocations, ArbitrationActionsType.SUBMIT_OAD_REALLOCATIONS_FAILURE)
  )
}
// PROFILE RISK

export function* ArbitrationSagas() {
  yield all([
    fork(getArbitrationQuestionsSagas),
    fork(getDispositifDetailsSagas),
    fork(updateArbitrationQuestionWithResponseSagas),
    fork(getReallocationInvestSagas),
    fork(createArbitrationSagas),
    fork(selectArbitrationTypeSaga),
    fork(getOadAllocationsSagas),
    fork(getRiskProfileSagas),
    fork(createOadReallocation)
  ])
}
