import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles(isMobile: boolean) {
  const contextTheme = useTheme();
  const mobileTextLinkWidth = isMobile ? { width: '60%' } : {}
  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      marginLeft: isMobile ? contextTheme.metrics.spacing.xxs : contextTheme.metrics.spacing.s,
      marginRight: isMobile ? contextTheme.metrics.spacing.xxs : contextTheme.metrics.spacing.s,
      paddingLeft: isMobile ? contextTheme.metrics.spacing.xxs : contextTheme.metrics.spacing.none,
      paddingRight: isMobile ? contextTheme.metrics.spacing.xxs : contextTheme.metrics.spacing.none,
    },
    contentContainer: { marginTop: contextTheme.metrics.spacing.big },
    buttonContainerStyle: {
      flex: 1,
      alignItems: 'center',
      marginLeft: isMobile ? contextTheme.metrics.spacing.xxs : contextTheme.metrics.spacing.s,
      marginRight: isMobile ? contextTheme.metrics.spacing.xxs : contextTheme.metrics.spacing.s,
      paddingLeft: isMobile ? contextTheme.metrics.spacing.xxs : contextTheme.metrics.spacing.none,
      paddingRight: isMobile ? contextTheme.metrics.spacing.xxs : contextTheme.metrics.spacing.none,
    },
    leftButton: {
      justifyContent: 'flex-start',
    },
    rightButtons: {
      position: 'absolute',
      alignSelf: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row',
    },
    iconButton: {
      flexDirection: 'row',
      paddingHorizontal: contextTheme.metrics.spacing.s,
      paddingVertical: contextTheme.metrics.spacing.xs,
      borderRadius: contextTheme.metrics.borderRadius.l,
      backgroundColor: contextTheme.colors.buttons.filter.c500,
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconButtonText: {
      justifyContent: 'flex-end',
      color: contextTheme.colors.basics.white,
      marginLeft: contextTheme.metrics.spacing.s,
    },
    helpPointStyle: {
      marginLeft: contextTheme.metrics.spacing.s,
    },
    linkStyle: {
      alignSelf: 'flex-start',
      marginRight: contextTheme.metrics.spacing.xm,
      ...mobileTextLinkWidth
    },
  }), [contextTheme]);

  return styles;
}
