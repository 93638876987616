import React, { useCallback } from 'react';

import {
  FiletStepperCardWrapper,
  FiletStepperSimpleCard,
  useTheme,
  getPlanTypeColors
} from '@components/index';

import { Props } from './interfaces';
import { ProfitSharingIncentiveDestinationCardType } from './sharedInterface'
import { useStyles } from './useStyles';
import { View } from 'react-native';

export const FiletStepperCardDestination: React.FC<Props> = ({
  cards,
  disableLine
}) => {

  const theme = useTheme();
  const styles = useStyles();

  //   RENDER DESTINATION CARD
  const renderDestination = useCallback((card: ProfitSharingIncentiveDestinationCardType, index: number) => {

    const dispositifColor = theme.colors.basics.primary

    const renderManagements = () => {
      return card.managements.map(management => {
        return (
          <FiletStepperSimpleCard
            amount={management.amount}
            contribution={management.contribution}
            hasContribution={management.hasContribution}
            headerColor={dispositifColor?.c100}
            hideManagementRow={management.hideManagementRow}
            isFreeManagement={management.isFree}
            percentage={management.percentage}
            subCards={management.subCards || undefined}
            title={management.title}
          />
        )
      })
    }

    return (
      <FiletStepperCardWrapper
        borderLeftColor={dispositifColor?.c500}
        disableIcone={index !== 0}
        disableLine={disableLine}
        headerResponseAmount={card.amount}
        headerResponseCustomContainerStyle={[
          styles.bankAccountHeader,
          {
            backgroundColor: dispositifColor?.c500,
          },
        ]}
        headerResponseTitle={card.planName}
        hideHeader={true}
        showHeaderResponseDarkText={false}
        title={card.filetTitle || ''}
      >
        {renderManagements()}
      </FiletStepperCardWrapper>
    );
  }, [
    disableLine,
    styles,
    theme
  ]);

  return (
    <>
      {
        cards?.map((card, index) => (
          <View>
            {renderDestination(card, index)}
          </View>
        ))}
    </>

  );
};