import React, { memo } from 'react';

import { createUseStyles, useTheme } from '@ere-uilib/styles';

import { Text } from '../Text';
import { Props } from './interfaces';
import { getStyles } from './styles';

export const Link: React.FC<Props> = memo(({
  children,
  onPress,
  textStyle,
  textVariant,
  textWeight,
  numberOfLines,
  testID,
}) => {
  const theme = useTheme();
  const styles = useStyles(
    {
      theme,
      variant: textVariant,
      weight: textWeight,
      numberOfLines,
    },
    {
      textStyle,
    },
  );

  return (
    <Text
      accessibilityLabel={testID}
      numberOfLines={numberOfLines}
      onPress={onPress}
      style={styles.textStyle}
      testID={testID}
      variant={textVariant}
      weight={textWeight}
    >
      {children}
    </Text>
  );
});

const useStyles = createUseStyles(getStyles);
