import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import 'react-native-get-random-values';

import { getRiskProfileRequest, setOadManualRepartitionData } from '@modules/arbitration/actions/arbitrationActions';
import {
  getArbitrationOadRepartitionData,
  getArbitrationOadSimulationData
} from '@modules/arbitration/selectors';
import { ArbitrationOadRepartitionData, GetRiskProfileParams } from '@modules/arbitration/types';
import { SupportType } from '@modules/common/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';

import { useRepartitionLegend } from '../hooks/useRepartitionLegend';
import { ArbitrationOADRepartitionPage } from './ArbitrationOADRepartitionPage.component';
import { FormValues } from './hooks/useForm';
import { ActiveClassSupportObjectList } from './interfaces';

interface ActiveClassSupport {
  percentageValue: number;
  data: SupportType;
}

const formatActivClassSupports = (supports: ActiveClassSupport[]) => {
  const activClassSupports = supports.reduce((accumulator, supportData) => {
    const support = supportData.data;
    const uuid = uuidv4();

    accumulator[uuid] = {
      title: support.supportName,
      riskLevel: `${support.riskLevelSRI || support.riskLevelSRRI}/7`,
      actualRepartition: supportData.percentageValue,
      uuid,
      support
    };

    return accumulator;
  }, {} as ActiveClassSupportObjectList);

  return activClassSupports;
};

interface ActiveClassCardContentItem {
  key: string;
  uuid: string;
  title: string;
  contentData: ActiveClassSupportObjectList;
  backgroundColor: string;
  color: string;
  borderLeftColor: string;
  value: number;
}

interface ActiveClassCardContentData {
  [uuid: string]: ActiveClassCardContentItem;
}

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
}

interface SupportObjectList {
  [key: string]: number | undefined;
}

export const ArbitrationOADRepartitionPageContainer = ({ navigation }: Props) => {
  const dispatch = useDispatch();

  const repartitionData = useSelector(getArbitrationOadRepartitionData);
  const simulationData = useSelector(getArbitrationOadSimulationData);

  const activeClassCardContentDataObject = useMemo(
    () =>
      repartitionData.reduce((accumulator, activeClass) => {
        const contentData = formatActivClassSupports(activeClass.supports);
        const uuid = activeClass.key;

        accumulator[uuid] = {
          uuid,
          title: activeClass.title,
          contentData,
          value: activeClass.value,
          backgroundColor: `${activeClass.color}30`,
          color: `${activeClass.color}`,
          borderLeftColor: activeClass.color,
          key: activeClass.key
        };

        return accumulator;
      }, {} as ActiveClassCardContentData),
    [repartitionData]
  );

  const formatRepartionDataSupport = useCallback(
    (currentActiveClassData: ActiveClassCardContentItem, formValues: FormValues) => {
      const { contentData } = currentActiveClassData;
      const formattedSupports = Object.keys(contentData).map(contentUuid => {
        const percentageValue = (formValues[currentActiveClassData.uuid][contentUuid] || 0) / 100;
        const formattedSupport = {
          percentageValue,
          data: currentActiveClassData.contentData[contentUuid].support
        };

        return formattedSupport;
      });

      return formattedSupports;
    },
    []
  );

  const getFormActivClassValue = useCallback((supportObjectList: SupportObjectList) => {
    let activeClassValue = 0;

    Object.keys(supportObjectList).forEach(supportUuid => {
      const supportValue = supportObjectList[supportUuid];
      if (!supportValue) {
        return;
      }

      activeClassValue += supportValue;
    });

    return activeClassValue / 100;
  }, []);

  const handleSubmit = useCallback(
    (formValues: FormValues) => {
      const newRepartitionData = Object.keys(formValues).map(activClassUuid => {
        const currentActiveClassData = activeClassCardContentDataObject[activClassUuid];
        const activeClassValue = getFormActivClassValue(formValues[activClassUuid]);

        const newActiveClassData: ArbitrationOadRepartitionData = {
          value: activeClassValue,
          key: currentActiveClassData.key,
          color: currentActiveClassData.color,
          title: currentActiveClassData.title,
          supports: formatRepartionDataSupport(currentActiveClassData, formValues)
        };

        return newActiveClassData;
      });

      dispatch(setOadManualRepartitionData(newRepartitionData));
    },
    [activeClassCardContentDataObject, dispatch, formatRepartionDataSupport, getFormActivClassValue]
  );
  const handleGetRiskProfile = useCallback(
    (params: GetRiskProfileParams) => {
      dispatch(getRiskProfileRequest(params));
    },
    [dispatch]
  );

  const repartitionLegend = useRepartitionLegend();

  return (
    <ArbitrationOADRepartitionPage
      activeClassCardContentData={activeClassCardContentDataObject}
      fetchRiskProfile={handleGetRiskProfile}
      navigation={navigation}
      onSubmit={handleSubmit}
      repartitionLegend={repartitionLegend}
      simulatedRepartitionData={simulationData}
    />
  );
};
