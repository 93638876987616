import { put, all, fork, call, takeLatest, select, delay, race, take } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import { PlanFamillyList, PiProfitSharingHorizon } from '@constants/index'
import { RouteNames } from '@constants/navigation'
import {
  NotificationSystemNameEnum,
  NotificationType
} from '@ere-uilib/types/notificationSystemTypes'
import { catchApiExceptions } from '@modules/apiAuthorization'
import { getOCDRequest } from '@modules/dashboard/actions/dashboardActions'
import { getUsedCompanyId } from '@modules/dashboard/selectors'
import { initNewsFeedList } from '@modules/history/actions/historyActions'
import { runManager } from '@modules/moduleManager'
import { addNotification } from '@modules/notification-system/actions/notificationSystemActions'
import { InitSavingReducer } from '@modules/savings/actions/savingsActions'
import {
  fetchProfitSharingIncentiveInitData,
  fetchProfitSharingIncentiveSubmitAllocation,
  fetchProfitSharingIncentiveRepartition,
} from './services';
import { getMobileMenuListRequest } from '@modules/settings/actions/settingsActions'
import { RootNavigation } from '@navigation/RootNavigation'

import * as profitSharingIncentiveAction from './actions/profitSharingIncentiveActions'
import {
  GetProfitSharingIncentiveCalculatedContributionRequestAction,
  GetProfitSharingIncentiveInitDataRequestAction,
  GetProfitSharingIncentiveRepartitionRequestAction,
  ProfitSharingIncentiveActionsType,
  SendProfitSharingIncentiveSubmitAllocationRequestAction,
  SetProfitSharingIncentiveRepartitionAmountRequestAction
} from './actions/profitSharingIncentiveActionsTypes'
import {
  getProfitSharingIncentiveInitDataState,
  getProfitSharingIncentiveRepartitionState,
  getProfitSharingIncentiveAssigmentAmounts,
  getSavedDistributions
} from './selectors'
import {
  ProfitSharingIncentiveSubmitAllocationResponseType,
  ProfitSharingIncentiveCalculatedContributionState,
  ProfitSharingIncentiveRepartitionState
} from './types'

import {
  buildProfitSharingIncentiveCalculatedContributionDistributionsParameter,
  buildProfitSharingIncentiveSubmitAllocationParameters
} from './utils'
import { adaptContributions } from './utils/buildContributionUtil'
import { fetchEstimateCalculContributionByPlanData } from '@modules/common/services/contribution'

function* getProfitSharingIncentiveInitData(
  action: GetProfitSharingIncentiveInitDataRequestAction
): any {
  const companyId = yield select(getUsedCompanyId)

  // comment to use mock
  const response = yield call(fetchProfitSharingIncentiveInitData, {
    companyId,
    type: action.profitSharingIncentiveType
  })

  // Uncomment to use mock
  // yield delay(1500);
  // const response = {
  //   data: mockProfitSharingIncentiveInitData
  // }

  yield put(profitSharingIncentiveAction.getProfitSharingIncentiveInitDataSuccess(response?.data))
}
function* getProfitSharingIncentiveInitDataSaga() {
  yield takeLatest(
    ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_REQUEST,
    runManager(
      getProfitSharingIncentiveInitData,
      ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_FAILURE
    )
  )
}

function* getProfitSharingIncentiveRepartition(
  action: GetProfitSharingIncentiveRepartitionRequestAction
): any {
  const companyId = yield select(getUsedCompanyId)
  const initData = yield select(getProfitSharingIncentiveInitDataState)

  // comment to use mock
  const response = yield call(fetchProfitSharingIncentiveRepartition, {
    companyId,
    type: initData.type
  })

  // Uncomment to use mock
  // yield delay(1500);
  // const response = {
  //   data: mockProfitSharingIncentiveRepartitionIncorrectDrivingData
  // }

  yield put(
    profitSharingIncentiveAction.getProfitSharingIncentiveRepartitionSuccess({
      plans: response.data,
      amounts: action.amounts
    })
  )
  RootNavigation.navigate(RouteNames.ProfitSharingIncentive, {
    screen: RouteNames.ProfitSharingIncentiveRepartition
  })
}
function* getProfitSharingIncentiveRepartitionSaga() {
  yield takeLatest(
    ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_REQUEST,
    runManager(
      getProfitSharingIncentiveRepartition,
      ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_FAILURE
    )
  )
}

function* setProfitSharingIncentiveRepartitionAmount(
  action: SetProfitSharingIncentiveRepartitionAmountRequestAction
): any {
  const repartition: ProfitSharingIncentiveRepartitionState = yield select(
    getProfitSharingIncentiveRepartitionState
  )
  const initData = yield select(getProfitSharingIncentiveInitDataState)
  let calculatedContribution: ProfitSharingIncentiveCalculatedContributionState[] | null | undefined
  const { planId, compartmentCode, managementId, fundId, amount } = action.params
  /////////////
  const targetedPlan = repartition.plans.find(plan => plan.planId === planId)

  const isHorizonRetraite = targetedPlan?.horizon === PiProfitSharingHorizon.RETRAITE
  const targetedCompartment = targetedPlan?.compartments.find(
    compartment => compartment.code === compartmentCode
  )
  const targetedManagement = targetedCompartment?.managements.find(management => {
    return management.id === managementId
  })
  const targetedSupport = targetedManagement?.supports?.find(support => {
    return support.supportIsin === fundId
  })
  const isES = targetedPlan?.planFamily === PlanFamillyList.ES
  const isRC = targetedPlan?.planFamily === PlanFamillyList.RC
  const isPlanContributionAvailable =
    (targetedPlan?.advancedProperties?.ContributionLimit || 0) -
    (targetedPlan?.advancedProperties?.ContributionConsumed || 0)
  const isPlanHasContributionConsummed = isPlanContributionAvailable === 0
  const isPlanHasContribution =
    targetedPlan?.advancedProperties?.HasContribution && !isPlanHasContributionConsummed
  const isManagementHasContribution = targetedManagement?.advancedProperties?.HasContribution
  const isSupportHasContribution = targetedSupport?.hasContribution || targetedSupport?.isMaster
  const shouldCalculContributionES =
    isES && isPlanHasContribution && isManagementHasContribution && isSupportHasContribution
  const shouldCalculContributionRC = isRC && isPlanHasContribution
  const savedDistributions = yield select(getSavedDistributions)

  if (shouldCalculContributionES || shouldCalculContributionRC) {
    // get Contribution from already calculated or ask for calcul
    const isContributionCalculated = action.params.contribution
    if (isContributionCalculated) {
      // if no delay loading state is not listened as expected
      yield delay(100)
      calculatedContribution = action.params.contribution
    } else {
      const companyId = yield select(getUsedCompanyId)
      // build distributions parameter
      const distributions = buildProfitSharingIncentiveCalculatedContributionDistributionsParameter(
        {
          repartition,
          planId,
          compartmentCode,
          managementId,
          supportId: fundId,
          amount
        }
      )
      const adaptedDistrib = adaptContributions(
        distributions,
        savedDistributions,
        distributions.planId
      )

      yield put(profitSharingIncentiveAction.setEstimateContributionInput(adaptedDistrib))
      try {
        const response = yield call(fetchEstimateCalculContributionByPlanData, {
          companyId,
          payload: adaptedDistrib,
          operationType: initData?.type
        })

        calculatedContribution = response?.data
      } catch (error: any) {
        yield put(
          addNotification({
            uid: uuidv4(),
            type: NotificationType.WARNING,
            name: NotificationSystemNameEnum.CONTRIBUTION_ERROR
          })
        )
        yield catchApiExceptions(
          ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_FAILURE,
          error,
          action
        )
      }
    }
  }
  /////////////
  if (isHorizonRetraite) {
    yield put(
      profitSharingIncentiveAction.setProfitSharingIncentiveRepartitionAmountModalVisibleState(
        false
      )
    )
    yield delay(100)
    yield put(
      profitSharingIncentiveAction.setProfitSharingIncentiveRepartitionRetirementValidationModalVisibleState(
        true
      )
    )

    const { validated, cancelled } = yield race({
      validated: take(
        ProfitSharingIncentiveActionsType.PARTICIPATION_PROFITSHARING_VALIDATE_RETIREMENT_AMOUNT_MODAL
      ),
      cancelled: take(
        ProfitSharingIncentiveActionsType.PARTICIPATION_PROFITSHARING_CANCEL_RETIREMENT_AMOUNT_MODAL
      )
    })
    if (validated) {
      yield put(
        profitSharingIncentiveAction.setProfitSharingIncentiveRepartitionAmountSuccess({
          calculatedContribution,
          profitSharingIncentiveAmountValidationParams: action.params
        })
      )
    }
    if (cancelled) {
      yield put(
        profitSharingIncentiveAction.setProfitSharingIncentiveRepartitionAmountSuccess({
          calculatedContribution: undefined,
          profitSharingIncentiveAmountValidationParams: undefined
        })
      )
    }
    yield put(
      profitSharingIncentiveAction.setProfitSharingIncentiveRepartitionRetirementValidationModalVisibleState(
        false
      )
    )
  } else {
    yield put(
      profitSharingIncentiveAction.setProfitSharingIncentiveRepartitionAmountSuccess({
        calculatedContribution,
        profitSharingIncentiveAmountValidationParams: action.params
      })
    )
  }
}
function* setProfitSharingIncentiveRepartitionAmountSaga() {
  yield takeLatest(
    ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_REQUEST,
    runManager(
      setProfitSharingIncentiveRepartitionAmount,
      ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_FAILURE
    )
  )
}

function* getCalculatedContributionData(
  action: GetProfitSharingIncentiveCalculatedContributionRequestAction
): any {
  const companyId = yield select(getUsedCompanyId)
  const repartition = yield select(getProfitSharingIncentiveRepartitionState)
  const initData = yield select(getProfitSharingIncentiveInitDataState)
  const savedDistributions = yield select(getSavedDistributions)

  const { planId, compartmentCode, managementId, fundId, amount } = action.params
  const distributions = buildProfitSharingIncentiveCalculatedContributionDistributionsParameter({
    repartition,
    planId,
    compartmentCode,
    managementId,
    supportId: fundId,
    amount
  })

  const adaptedDistrib = adaptContributions(distributions, savedDistributions, distributions.planId)

  yield put(profitSharingIncentiveAction.setEstimateContributionInput(adaptedDistrib))

  const response = yield call(fetchEstimateCalculContributionByPlanData, {
    companyId,
    payload: adaptedDistrib,
    operationType: initData?.type
  })

  yield put(
    profitSharingIncentiveAction.getCalculatedContributionDataSuccess({
      contributionData: response.data,
      params: action.params
    })
  )
}

function* getCalculatedContributionDataSaga() {
  yield takeLatest(
    ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_REQUEST,
    runManager(
      getCalculatedContributionData,
      ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_FAILURE
    )
  )
}

function* sendProfitSharingIncentiveSubmitAllocation(
  action: SendProfitSharingIncentiveSubmitAllocationRequestAction
): any {
  const companyId = yield select(getUsedCompanyId)
  const initData = yield select(getProfitSharingIncentiveInitDataState)
  const repartition = yield select(getProfitSharingIncentiveRepartitionState)
  const assigmentAmounts = yield select(getProfitSharingIncentiveAssigmentAmounts)
  const params = buildProfitSharingIncentiveSubmitAllocationParameters({
    companyId,
    repartition,
    assigmentAmounts,
    initData
  })
  const response: { data: ProfitSharingIncentiveSubmitAllocationResponseType } = yield call(
    fetchProfitSharingIncentiveSubmitAllocation,
    params
  )

  if (response?.data?.isSucceeded) {
    yield put(InitSavingReducer())
    yield put(getOCDRequest())
    yield put(initNewsFeedList())
    yield put(getMobileMenuListRequest(companyId))
    RootNavigation.navigate(RouteNames.ProfitSharingIncentive, {
      screen: RouteNames.ProfitSharingIncentiveSuccess
    })
  } else {
    RootNavigation.navigate(RouteNames.ProfitSharingIncentive, {
      screen: RouteNames.ProfitSharingIncentiveFailedPage
    })
  }

  yield put(profitSharingIncentiveAction.sendProfitSharingIncentiveSubmitAllocationSuccess())
}

function* sendProfitSharingIncentiveSubmitAllocationSaga() {
  yield takeLatest(
    ProfitSharingIncentiveActionsType.SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_REQUEST,
    runManager(
      sendProfitSharingIncentiveSubmitAllocation,
      ProfitSharingIncentiveActionsType.SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_FAILURE
    )
  )
}

export function* profitSharingIncentiveSagas() {
  yield all([
    fork(getProfitSharingIncentiveInitDataSaga),
    fork(getProfitSharingIncentiveRepartitionSaga),
    fork(setProfitSharingIncentiveRepartitionAmountSaga),
    fork(getCalculatedContributionDataSaga),
    fork(sendProfitSharingIncentiveSubmitAllocationSaga)
  ])
}
