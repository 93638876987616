import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, StyleProp, TextStyle, ViewStyle, Platform, Animated } from 'react-native';

import { FontIcon, Title, Text, Modal } from '@ere-uilib/atoms';
import {
  ModalFrame,
  SimpleButton,
  ActivableButtonSet,
  GenericCardWidthThreeParams,
} from '@ere-uilib/molecules';
import { createUseStyles, ThemeType, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { AccountsState, AccountState } from '@ere-uilib/types/index';
import { getPlansNameByCompany } from '@hooks/useGetCompanyPlansName';

import { Props } from './interface';
import { getStyles } from './styles';

export const DashboardHeader: React.FC<Props> = props => {
  const {
    userAccounts,
    selectedCompany,
    onSelectCompany,
    navigation,
    totalAmounAt,
    containerStyle,
    modalContainerStyle,
    buttonTitleStyle,
    buttonSubtitleStyle,
    contentStyle,
    buttonContainerStyle,
    companySelectionButtonStyle,
    scrollValue,
  } = props;

  const theme = useTheme();
  const { formatMessage, formatDate, formatCurrencyNumber } = useTranslation();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, {
    containerStyle,
    modalContainerStyle,
    buttonTitleStyle,
    buttonSubtitleStyle,
    contentStyle,
    companySelectionButtonStyle,
    buttonContainerStyle,
  });

  const [isVisible, setIsVisible] = useState(false);
  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>();
  const isMultipleCompanies = (userAccounts?.companies?.length || 0) > 1;
  const [isContentScroll, setIsContentScroll] = useState(false);
  const [isHeaderModify, setIsHeaderModify] = useState(false);
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const moveAnim = useRef(new Animated.Value(1)).current;

  const onPressCompany = (company: AccountState) => {
    onSelectCompany(company, navigation);
  };

  useEffect(() => {
    setIsContentScroll(scrollValue ? scrollValue >= 0 : false);
    if (isContentScroll) {
      if (!isHeaderModify) {
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 200,
          useNativeDriver: true,
        }).start();
        Animated.timing(moveAnim, {
          toValue: -50,
          duration: 200,
          useNativeDriver: false,
        }).start();
        setIsHeaderModify(true);
      }

    } else if (isHeaderModify) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
      Animated.timing(moveAnim, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,

      }).start();
      setIsHeaderModify(false);
    }
  }, [fadeAnim, isContentScroll, isHeaderModify, moveAnim, scrollValue]);

  const handleHovered = (value: boolean, index: number) => {
    setHovered(value)
    setHoveredIndex(index)
  }

  const onHandleChoiceModal = useCallback(() => {
    return (
      <Modal visible={isVisible}>
        <ModalFrame
          desktopPosition="center"
          maxDesktopWidth={550}
          mobilePosition="bottom"
          onClose={() => setIsVisible(false)}
        >
          <View style={styles.modalContainerStyle}>
            <View style={styles.contentStyle}>
              <Title
                style={styles.selectionTitle}
                variant="t6"
                weight="light"
              >
                {formatMessage({ id: 'MultiEntreprise_Choice_List_title' })}
              </Title>
              {userAccounts?.companies.map((company: AccountState, index: number) => {
                const isSelected = company?.companyId === selectedCompany?.companyId;
                return (
                  <ActivableButtonSet
                    buttonStyle={styles.activableButtonSetStyle}
                    hovered={index === hoveredIndex ? hovered : false}
                    isSelected={isSelected}
                    key={index}
                    onChange={() => {
                      onPressCompany(company);
                    }}
                    setHovered={(hoveredValue) => handleHovered(hoveredValue, index)}
                  >
                    <GenericCardWidthThreeParams
                      hovered={index === hoveredIndex ? hovered : false}
                      isSelected={isSelected}
                      subtitle={getPlansNameByCompany(company)}
                      title={company.companyName}
                      value={formatCurrencyNumber({ value: company.totalAmount })}
                    />
                  </ActivableButtonSet>
                );
              })}
            </View>
          </View>
        </ModalFrame>
      </Modal>
    )
  }, [isVisible, setIsVisible, userAccounts, hovered, hoveredIndex])

  return (
    <>
      <View style={isContentScroll ? [styles.topLevelContainerStyle, styles.containerShadow] : styles.topLevelContainerStyle}>
        <View
          style={styles.containerStyle}
        >
          {Platform.OS === 'web' && !isMobile && (
            <View style={styles.leftContainerStyle} />
          )}
          <>
            <Animated.View style={[styles.centreContainerStyle, { opacity: fadeAnim }]}>
              <Title variant="t6">{formatMessage({ id: 'saving_title' })}</Title>
              <Title
                variant="t8"
                weight="light"
              >
                {totalAmounAt && formatMessage({
                  id: 'saving_sub-title',
                  values: {
                    variable: formatDate({
                      value: totalAmounAt,
                      options: {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      },
                    }),
                  },
                })}
              </Title>
            </Animated.View>
            <View style={styles.rightContainerStyle}>
              <SimpleButton
                containerStyle={styles.companySelectionButtonStyle}
                design={isMultipleCompanies ? 'outlined' : 'solid'}
                disabled={!isMultipleCompanies}
                onPress={() => setIsVisible(true)}
                testId='select_company'
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <View style={styles.buttonContainerStyle}>
                    <Text
                      numberOfLines={1}
                      style={
                        [
                          {
                            lineHeight: theme.metrics.spacing.m,
                            color: theme.colors.basics.grey.c400,
                            marginRight: isMultipleCompanies ?
                              theme.metrics.spacing.s :
                              theme.metrics.spacing.none,
                          },
                          isMultipleCompanies &&
                          {
                            color: theme.colors.buttons.classic.c500,
                          }]
                      }
                    >
                      {selectedCompany?.companyName}
                    </Text>
                  </View>
                  {isMultipleCompanies && (
                    <View style={styles.chevronContainerStyle}>
                      <FontIcon
                        color={isMultipleCompanies ? theme.colors.buttons.classic.c500 :
                          theme.colors.basics.grey.c400}
                        name="chevron-bas"
                        size={theme.metrics.iconSizes.xm}
                      />
                    </View>
                  )}
                </View>
              </SimpleButton>
            </View>
          </>
        </View>
        <Animated.View style={[styles.amountContainerStyle, { marginTop: moveAnim }]}>
          {selectedCompany !== undefined && selectedCompany !== null && <Title
            style={styles.amountStyle}
            variant="t3"
            weight="bold"
          >
            {formatCurrencyNumber({ value: selectedCompany.totalAmount })}
          </Title>}
        </Animated.View>
      </View>
      {onHandleChoiceModal()}

    </>
  );
};

export const useStyles = createUseStyles(getStyles);