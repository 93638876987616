import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = (isDisabled: boolean) => {
  const theme = useTheme();

  const styles = useMemo(() => {
    const headerBackgroundColor = isDisabled ? theme.colors.basics.grey.c100 : theme.colors.background.greenBackground.c100;

    return StyleSheet.create({
      container: {
        overflow: 'hidden',
        borderWidth: 0,
        borderColor: theme.colors.basics.grey.c200,
        borderRadius: theme.metrics.borderRadius.xs
      },
      cardHeader: {
        paddingVertical: theme.metrics.spacing.m,
        paddingHorizontal: theme.metrics.spacing.s,
        backgroundColor: headerBackgroundColor
      },
      cardHeaderTop: {
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      description: {
        paddingTop: theme.metrics.spacing.m,
        textAlign: 'center'
      },
      cardContent: {
        paddingVertical: theme.metrics.spacing.m,
        paddingHorizontal: theme.metrics.spacing.s
      },
      contributionIconLeft: {
          marginRight: theme.metrics.spacing.s,
          color: theme.colors.basics.primary.c500,
        },
        contributionAmountStyles: {
          textAlign: 'left',
          color: theme.colors.basics.primary.c500,
          flex: 1
        },
        titleStyle: {
          color: theme.colors.basics.primary.c500,
        },
        linkStyle: {
          textAlign: 'center',
          paddingTop: theme.metrics.spacing.s
        },
        rightSectionStyle: {
          alignSelf: 'flex-end',
          flexDirection: "row",
          marginTop: theme.metrics.spacing.xxs
        },
        leftSectionStyle: {
          alignSelf: 'flex-start',
          flexDirection: "row",
          marginTop: theme.metrics.spacing.xxs
        },
        descriptionStyle: {
          marginTop: theme.metrics.spacing.xs
        },
        descriptionTextStyle: {
          textAlign: 'center'
        }
    });
  }, [isDisabled, theme]);

  return styles;
}