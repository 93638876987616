import React, { FC, useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { LoaderPlaceholderView } from '@ere-uilib/atoms';
import { createUseStyles, ThemeType, useScreenSizes, useTheme } from '@ere-uilib/styles';

type DashboardContentWrapperCardPlaceHolderProps = {

}

type ContextType = {
  theme: ThemeType
  isWeb: boolean
}
type StyleType = {
  button?: StyleProp<ViewStyle>
  loader?: StyleProp<ViewStyle>
  loaderContainerStyle?: StyleProp<ViewStyle>
  halfLoader?: StyleProp<ViewStyle>
  loaderRow?: StyleProp<ViewStyle>
  mainContainer?: StyleProp<ViewStyle>
  rightLoader?: StyleProp<ViewStyle>
  btnLoader?: StyleProp<ViewStyle>
}

export const DashboardContentWrapperCardPlaceHolder: FC<DashboardContentWrapperCardPlaceHolderProps> = () => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const isWeb = !(isMobile || isTablet);
  const style = useStyles({
    theme,
    isWeb,
  }, {
  });
  const leftCard = useMemo(() => (<View style={style.loaderContainerStyle}>
    <LoaderPlaceholderView
      style={style.loader}
    />
    <LoaderPlaceholderView
      style={style.loader}
    />
    <LoaderPlaceholderView
      style={style.halfLoader}
    />
    {isWeb && <LoaderPlaceholderView
      style={style.btnLoader}
    />}
  </View>), []);

  const tableCard = useMemo(() => (<View style={style.loaderContainerStyle}>
    {
      Array.from({ length: 3 }, (_, k) => {

        return <View style={style.loaderRow}>
          <LoaderPlaceholderView
            key={`LPH_${1}`}
            style={style.rightLoader}
          />
          <LoaderPlaceholderView
            key={`LPH_${2}`}
            style={style.rightLoader}
          />
          <LoaderPlaceholderView
            key={`LPH_${3}`}
            style={style.rightLoader}
          />
        </View>;
      })
    }
  </View>), []);

  return (
    <View style={style.mainContainer}>
      {leftCard}
      {tableCard}
    </View>
  );
};

const getStyles = (
  context?: ContextType,
  style?: StyleType
): StyleType => ({
  button: [
    { paddingVertical: context?.theme.metrics.spacing.xm },
    style?.button,
  ],
  loader: [
    {
      margin: context?.theme?.metrics.spacing.xs,
      marginBottom: context?.theme?.metrics.spacing.s,
    },
    style?.loader,
  ],
  btnLoader: [
    {
      width: '50%',
      margin: context?.theme?.metrics.spacing.xs,
      marginTop: context?.theme?.metrics.spacing.m,
      padding: context?.theme?.metrics.spacing.xm,
    },
    style?.btnLoader,
  ],
  rightLoader: [
    {
      flex: 1,
      margin: context?.theme?.metrics.spacing.xs,
    },
    style?.rightLoader,
  ],
  halfLoader: [
    {
      margin: context?.theme?.metrics.spacing.xs,
      width: '50%',
    },
    style?.halfLoader,
  ],
  loaderRow: [
    {
      flexDirection: 'row',
      marginVertical: context?.theme?.metrics.spacing.xs,
    },
    style?.loaderRow,
  ],
  mainContainer: [
    {
      flexDirection: context?.isWeb ? 'row' : 'column',
    },
    style?.mainContainer,
  ],
  loaderContainerStyle: [
    {
      flex: 1,
      padding: context?.theme.metrics.spacing.s,
      margin: context?.theme.metrics.spacing.s,
    },
    style?.loaderContainerStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
