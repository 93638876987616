import { put, call, delay } from '@redux-saga/core/effects';
import { all, fork, takeLatest } from 'redux-saga/effects';

import * as actions from './actions/campaignActions';
import * as actionsType from './actions/campaignActionsTypes';
import { CampaignActionsType } from './actions/campaignActionsTypes';
import { runManager } from '@modules/moduleManager';
import { CampaignValueState } from './types';
import { fetchUserCampaign, postUserCampaign } from './services';
import { RootNavigation } from '@navigation/RootNavigation';

function* getUserCampaign(
  action: actionsType.GetUserCampaignRequestAction
): any {
  const response = yield call(fetchUserCampaign, action.campaignId);
  if (response?.data) {
    yield put(actions.getUserCampaignSuccess(response.data));
  }
}

function* executeCallback(
  action: actionsType.ExecuteCallbackAction
): any {
  if (action?.callbackActionType) {
    yield put({ type: action?.callbackActionType });
  }
  if (action?.redirectionAfterCallback) {
    RootNavigation.navigate(action?.redirectionAfterCallback)
  }
}

function* submitUserCampaign(
  action: actionsType.SubmitUserCampaignRequestAction
): any {
  const isLaterAnswer =  action.value === CampaignValueState.LATER
  if( isLaterAnswer) {
    yield delay(100);
  }else{
    yield call(postUserCampaign, action.campaignId, action.value);
  }

  yield put(actions.submitUserCampaignSuccess(action.campaignId, isLaterAnswer));

  if (action.toDoPostSuccessActionType) {
    yield put({ type: action.toDoPostSuccessActionType })
  }
}

function* getUserCampaignSaga() {
  yield takeLatest(
    CampaignActionsType.GET_USER_CAMPAIGN_REQUEST,
    runManager(getUserCampaign, CampaignActionsType.GET_USER_CAMPAIGN_FAILURE),
  );
}

function* executeCallbackSaga() {
  yield takeLatest(
    CampaignActionsType.EXECUTE_CALLBACK,
    runManager(executeCallback, CampaignActionsType.EXECUTE_CALLBACK),
  );
}

function* submitUserCampaignSaga() {
  yield takeLatest(
    CampaignActionsType.SUBMIT_USER_CAMPAIGN_REQUEST,
    runManager(submitUserCampaign, CampaignActionsType.SUBMIT_USER_CAMPAIGN_FAILURE)
  )
}

export function* CampaignSagas() {
  yield all([
    fork(getUserCampaignSaga),
    fork(submitUserCampaignSaga),
    fork(executeCallbackSaga),
  ])
}
