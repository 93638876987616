export enum ProfitSharingIncentiveTypeEnum {
  profitsharing = 'PARTICIPATION',
  incentive = 'INTERESSEMENT',
}

export enum ProfitSharingIncentiveTypeBackValueEnum {
  PARTICIPATION = 'profitsharing',
  INTERESSEMENT = 'incentive',
}

export enum ProfitSharingIncentiveFrameEnum {
  complementary = 'COMPLEMENTAIRE',
  exceptional = 'EXCEPTIONNEL',
  normal = 'NORMAL',
}

export enum ProfitSharingIncentivePaymentModeTypeEnum {
  RIB = 'RIB',
  CHEQUE = 'CHEQUE',
  COMPANY = 'PAYEUSE',
  UNKNOWN = 'INCONNU',
}

export enum ProfitSharingIncentiveAllowedAffectationTypeEnum {
  REINVEST = 'SOUSCRIPTION',
  REFUND = 'REGLEMENT',
}

export enum PiProfitSharingHorizon { 
  RETRAITE = "Retraite",
  PROJET = "Projet"
};