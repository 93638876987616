export const LockCodesTypeEnum : { [key:string] : string } = {
  DONNEES_PERSO: 'donneesPerso',
  INITIE: 'initie',
  FONDS_CCB: 'fondsccb',
  STATUS_FONDS: 'statuFonds',
  SANS_FISCALITE: 'sansFiscalite',
  SANS_FISCALITE_RACHAT: 'sansFiscaliteRachat',
  SANS_RACHAT: 'sansRachat',
  AVOIRS_BLOQUES: 'avoirsBloques',
  Operation_Error_Unvailable_Contract: 'Operation_Error_Unvailable_Contract',
};
