import { connect } from 'react-redux';

import {
  setArbitrationSupportTriggerThreshold,
  setArbitrationTriggerThresholdModalVisibleState,
} from '@modules/arbitration/actions/arbitrationActions';
import {
  getArbitrationTriggerThresholdModalVisible,
} from '@modules/arbitration/selectors';
import {
  AppState,
} from '@modules/index';

import {
  ArbitrationTriggerThresholdModalConnectedComponent,
} from './ArbitrationTriggerThresoldModalConnected.component';

const mapStateToProps = (state: AppState) => {
  return {
    visible: getArbitrationTriggerThresholdModalVisible(state),
  };
};

const mapDispatchToProps = {
  onSetArbitrationSupportTriggerThreshold: setArbitrationSupportTriggerThreshold,
  onSetModalVisibleState: setArbitrationTriggerThresholdModalVisibleState,
};

export const ArbitrationTriggerThresholdModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ArbitrationTriggerThresholdModalConnectedComponent);
