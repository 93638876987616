import { AppState } from '@modules/reducers';

import { sortSavingsHistoryData } from './utils';

export const getRepartitions = (state: AppState) => {
  return state.savings.repartitions;
};
export const getAccountRepartitions = (state: AppState) =>
  state.savings.accountRepartitions;
export const getUngroupedRepartitions = (state: AppState) =>
  state.savings.unGroupedRepartitions;
export const getRepartitionsFilters = (state: AppState) =>
  state.savings.repartitionFilters;
export const getOperationsHistory = (state: AppState) =>
  state.savings.operationsHistory;
export const getAvailabilitiesDetail = (state: AppState) =>
  state.savings.availabilitiesDetail;
export const getAvailabilitiesFunds = (state: AppState) =>
  state.savings.availabilitiesFunds;
export const getOperationsHistoryFilterRoules = (state: AppState) =>
  state.savings.operationsHistoryFilterRoules;
export const getSelectedPlan = (state: AppState) => state.savings.selectedPlan;
export const getSelectedTab = (state: AppState) => state.savings.selectedTab;
export const getSavingsHistory = (state: AppState) =>
  sortSavingsHistoryData(state.savings.savingsHistory, state);
export const getSavingsHistoryFilters = (state: AppState) =>
  state.savings.savingsHistoryFilters;
export const getOnGoingVVPHistory = (state: AppState) =>
  state.savings.onGoingVVP;
export const getinstallments = (state: AppState) => state.savings.installments;
export const getIsDetailsVVPPopInOpened = (
  state: AppState
) => state.savings.isDetailsVVPPopInOpened;
export const getIsGoingVVPDeleteModalVisible = (state: AppState) => state.savings.onGoingVVPDeleteModalVisible;
export const getOnGoindVVPById = (state: AppState, id: string) => {
  if (!state.savings.onGoingVVP || state.savings.onGoingVVP.length === 0) {
    return;
  }

  return state.savings.onGoingVVP.find(vvp => vvp.id === id);
};

export const getOperationDetails = (state: AppState) => state.savings.operationDetails;

export const getSavingsError = (state: AppState) => state.savings.error;