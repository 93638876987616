import React, { useCallback, useMemo } from 'react'
import { View } from 'react-native'

import { Text } from '@ere-uilib/atoms'
import { RequirementDefinitionItem, RequirementDefinitionProposalItem } from '@ere-uilib/molecules'
import { CardResponsePlaceHolder } from '@ere-uilib/organisms'
import { useScreenSizes } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'

import { Props } from './interface'
import { RequirementDefinitionCardResponse } from './RequirementDefinitionCardResponse'
import { useStyles } from './useStyles'

export const RequirementDefinition: React.FC<Props> = ({
  requirementDecisionData,
  onBackToQuestion,
  onUpdateQuestion,
  renderEligibilityRecap,
  renderSubmitButton,
  showRefundEligibilityTotalAmount,
  isLoading = false,
  shouldFormatAsPlusMinus,
  arbitrationOadAllocations,
  dictionary,
  onGetUnavailableRefundDispositifTable,
  renderMixDispositifsEligibleForUnavailable,
  selectedUnavailableOption,
  handleDispositifsRcValidated,
  handleRedirectToPersoneo
}) => {
  const { isMobile, isTablet } = useScreenSizes()
  const styles = useStyles()
  const { formatMessage, getMessageRaw } = useTranslation()

  const questions = useMemo(() => requirementDecisionData?.questions, [requirementDecisionData])
  const respondedQuestion = useMemo(
    () => questions?.filter(question => question?.responded),
    [questions]
  )
  const canBeAskRespondedQuestion = useMemo(
    () => respondedQuestion?.filter(question => !question?.canNotBeAsked),
    [respondedQuestion]
  )
  const unRespondedQuestion = useMemo(
    () => questions?.filter(question => !question?.responded),
    [questions]
  )

  const infoHtmlContent = useMemo(
    () => getMessageRaw({ id: `${unRespondedQuestion[0]?.key}_info_content` }),
    [getMessageRaw, unRespondedQuestion]
  )
  const titleHtmlContent = formatMessage({ id: `${unRespondedQuestion[0]?.key}_info_title` })
  const isHelpPointShown = !!unRespondedQuestion[0]?.key
  const questionTitle =
    unRespondedQuestion[0]?.title || formatMessage({ id: unRespondedQuestion[0]?.key })
  const questionDescription = unRespondedQuestion[0]?.description?.trim()

  const renderRespondedQuestions = useCallback(() => {
    return (
      canBeAskRespondedQuestion &&
      canBeAskRespondedQuestion?.length > 0 &&
      canBeAskRespondedQuestion?.map((question, index) => {
        const isLastAskableRespondedQuestion = index === canBeAskRespondedQuestion.length - 1
        const noQuestionAsked = !questionTitle && index === canBeAskRespondedQuestion.length - 1
        const isLineDisabled =
          (unRespondedQuestion.length < 1 && isLastAskableRespondedQuestion) || noQuestionAsked
        const respondedValue = question.responded && question.responded.respondedTitle
        const indexInRespondedQuestion = respondedQuestion.indexOf(question)
        const respondedquestionTitle = question?.respondedTitle
          ? formatMessage({ id: question?.respondedTitle })
          : question?.responded?.title
        const handleEdit = () => onBackToQuestion(indexInRespondedQuestion)

        return (
          <RequirementDefinitionProposalItem
            disableLine={isLineDisabled}
            key={index}
            onEdit={handleEdit}
            input={respondedValue}
            title={respondedquestionTitle}
          />
        )
      })
    )
  }, [
    canBeAskRespondedQuestion,
    unRespondedQuestion,
    respondedQuestion,
    onBackToQuestion,
    questionTitle
  ])

  const renderQuestions = useCallback(() => {
    const doNotAsk =
      !unRespondedQuestion ||
      unRespondedQuestion.length === 0 ||
      unRespondedQuestion[0].canNotBeAsked

    if (doNotAsk) {
      return null
    }
    return (
      <RequirementDefinitionItem
        helpPointContentHtml={infoHtmlContent}
        helpPointModalTitle={titleHtmlContent}
        showHelpPoint={isHelpPointShown}
        title={questionTitle}>
        {!!questionDescription && (
          <Text
            style={styles.questionDescription}
            variant="t3"
            weight="light">
            {questionDescription}
          </Text>
        )}

        {!isLoading &&
          unRespondedQuestion[0]?.responses.map((response, index) => (
            <RequirementDefinitionCardResponse
              arbitrationOadAllocations={arbitrationOadAllocations}
              dictionary={dictionary}
              handleDispositifsRcValidated={handleDispositifsRcValidated}
              handleRedirectToPersoneo={handleRedirectToPersoneo}
              key={response.title + '_' + index}
              onGetUnavailableRefundDispositifTable={onGetUnavailableRefundDispositifTable}
              onUpdateQuestion={onUpdateQuestion}
              questionDescription={questionDescription}
              questions={questions}
              renderMixDispositifsEligibleForUnavailable={
                renderMixDispositifsEligibleForUnavailable
              }
              requirementDecisionData={requirementDecisionData}
              respondedQuestion={respondedQuestion}
              response={response}
              selectedUnavailableOption={selectedUnavailableOption}
              shouldFormatAsPlusMinus={shouldFormatAsPlusMinus}
              showRefundEligibilityTotalAmount={showRefundEligibilityTotalAmount}
              testIdIndex={`${response.title}`}
              unRespondedQuestion={unRespondedQuestion}
            />
          ))}
      </RequirementDefinitionItem>
    )
  }, [
    onUpdateQuestion,
    unRespondedQuestion,
    infoHtmlContent,
    titleHtmlContent,
    isHelpPointShown,
    questionTitle,
    questionDescription,
    questions,
    respondedQuestion,
    styles,
    isLoading,
    requirementDecisionData,
    arbitrationOadAllocations,
    shouldFormatAsPlusMinus,
    showRefundEligibilityTotalAmount,
    onGetUnavailableRefundDispositifTable,
    selectedUnavailableOption,
    renderMixDispositifsEligibleForUnavailable
  ])

  return (
    <View>
      {renderRespondedQuestions()}
      {renderQuestions()}
      {isLoading && <CardResponsePlaceHolder />}
      {(isMobile || isTablet) && unRespondedQuestion.length === 0 && renderEligibilityRecap}

      {!isMobile && !isTablet && <View style={styles.buttonContainer}>{renderSubmitButton}</View>}
    </View>
  )
}
