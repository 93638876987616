import React, { useState } from 'react';
import {
  View,
  StyleProp,
  StyleSheet,
  ViewStyle,
  Image,
  LayoutChangeEvent,
  Platform,
} from 'react-native';

import {
  useTheme,
} from '@ere-uilib/styles';

import { allSVGPaths } from '../images/SVGLocalLoader/allSVGPaths';

interface PageBackgroundGreenProps {
  displayimage?: boolean
  style?: StyleProp<ViewStyle>;
}
interface ContainerSize {
  width: number,
  height: number
}

export const PageBackgroundGreen: React.FC<PageBackgroundGreenProps> = ({
  children,
  displayimage = true,
  style,
}) => {

  const {
    styles,
    handleContainerLayout,
    imageSource,
  } = useController();
  const MyComponent = allSVGPaths.greenBackgroundMobile;
  return (
    <View
      onLayout={handleContainerLayout}
      style={[styles.containerStyle, style]}
    >
      {displayimage && <View style={styles.imagesContainer}>
        {Platform.OS === 'web' ? <Image
          source={imageSource}
          style={styles.imageTop}
        /> : <View style={styles.imageSvg}>
          <MyComponent
            height={'100%'}
            testID={'SVGImage_'}
            width={'100%'}
          />
        </View>
        }
      </View>
      }
      {children}
    </View>
  );
};

const useController = () => {
  const theme = useTheme();
  const [containerSize, setContainerSize] = useState<ContainerSize>({
    width: 500,
    height: 500,
  });

  const handleContainerLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    const {
      width,
      height,
    } = nativeEvent.layout;
    if (containerSize.width !== width || containerSize.height !== height) {
      setContainerSize({
        width,
        height,
      });
    }
  };

  const isBigImage = containerSize.width > theme.metrics.breakpoints.desktop;

  const images = {
    bigBackground: require('@assets/icons/greenBackgroundDesktop.svg').default,
    smallBackground: require('@assets/icons/greenBackgroundMobile.svg').default,
  };

  const imageSource = isBigImage ? images.bigBackground : images.smallBackground;
  const imageHeight = isBigImage ?
    968 / 1920 * containerSize.width
    : 774 / 376 * (containerSize.width > 376 ? containerSize.width : 376);
  const backgroundColor = isBigImage ?
    theme.colors.background.greenBackground.c200
    : theme.colors.background.greenBackground.c300;

  return {
    styles: StyleSheet.create({
      containerStyle: {
        backgroundColor: theme.colors.basics.white,
      },
      imagesContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: backgroundColor,
        overflow: 'hidden',
        ...StyleSheet.absoluteFillObject,
      },
      imageTop: {
        height: imageHeight,
        width: '100%',
      },
      imageSvg: {
        maxWidth: '100%',
        maxHeight: imageHeight,
        height: imageHeight,
        width: '100%',
      },
    }),
    handleContainerLayout,
    imageSource,
  };

};
