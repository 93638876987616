import { connect } from 'react-redux';

import { setAnnuitySimulationDataRequest } from '@modules/annuity/actions/annuityActions';
import { AnnuityActionsType } from '@modules/annuity/actions/annuityActionsTypes';
import { getResultsData } from '@modules/annuity/selectors';
import { getProfileUserInfosRequest } from '@modules/profile/actions/profileActions';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import { getProfileUserInfos } from '@modules/profile/selectors';
import { AppState } from '@modules/reducers';
import { getApplicationSettings } from '@modules/settings/selectors';

import { AnnuitySimulator as AnnuitySimulatorComponent } from './AnnuitySimulator.component';

const mapStateToProps = (state: AppState) => ({
  settings: getApplicationSettings(state),
  profileUserInfos: getProfileUserInfos(state),
  resultsData: getResultsData(state),
  isLoadingProfileUserInfos: state.isLoading[ProfileActionsType.GET_PROFILE_USER_INFOS_REQUEST],
  isLoadingSimulationAnnuityData:
    state.isLoading[AnnuityActionsType.SET_ANNUITY_SIMULATION_DATA_REQUEST]
});

const mapDispatchToProps = {
  onGetProfileUserInfosRequest: getProfileUserInfosRequest,
  onSetSimulationAnnuityData: setAnnuitySimulationDataRequest
};

export const AnnuitySimulator = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnuitySimulatorComponent);
