import React from 'react';
import { View } from 'react-native';

import { Title, Text } from '@ere-uilib/atoms';
import { createUseStyles } from '@ere-uilib/styles';
import { useTheme } from '@ere-uilib/styles/theme';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { getStyles } from './styles';
import { locator } from '@constants/locator';

export const CompartmentCardHeader: React.FC<Props> = ({
  containerStyle,
  backGroundColorCustom,
  dispositifTitle,
  compartmentTitle,
  compartmentAmount,
  children,
}) => {
  const theme = useTheme();
  const { formatCurrencyNumber } = useTranslation();
  const styles = useStyles({ theme, backGroundColorCustom }, { containerStyle });

  return (
    <View style={styles.containerStyle}>
      <View>
        <View style={styles.headerContainerStyle}>
          <View style={styles.compartmentContainerStyle}>
            <Title
              style={styles.compartmentTitleStyle}
              variant="t6">
              {dispositifTitle}
              {compartmentTitle && ` - ${compartmentTitle}`}
            </Title>
            {compartmentAmount && compartmentAmount > 0 && (
              <Text
                testID={locator._arbitration._compartment_amount}
                style={styles.amountTextStyle}
                weight="bold">
                {formatCurrencyNumber({
                  value: compartmentAmount ? compartmentAmount : 0,
                })}
              </Text>
            )}
          </View>
        </View>
      </View>

      {children}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
