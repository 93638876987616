import moment from 'moment';
import Cookies from 'universal-cookie';

import { COOKIES_SHARED_DOMAIN } from '@config/index';

const cookies = new Cookies();

export const setCookie = (name:string, value:string, expires?: Date, domain:string = COOKIES_SHARED_DOMAIN || '')=>{
  const defaultExpires = moment().add(1, 'years').toDate();
  cookies.set(
    name,
    value,
    {
      expires: expires || defaultExpires,
      path: '/',
      domain: domain,
      secure: true
    }
  );
};

export const getCookie = (name:string)=>{
  return cookies.get(name);
};
