import { useMemo } from 'react';
import { Dimensions, StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop, isMobile } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        containerStyle: {
          backgroundColor: theme.colors.basics.white,
          marginBottom: isDesktop ? theme.metrics.spacing.xl : 0,
          width: isDesktop ? '80%' : '100%',
          marginHorizontal: 'auto'
        },
        descriptionStyle: {
          marginTop: theme?.metrics.spacing.s
        },
        radioOptionsContainer: {
          marginTop: theme.metrics.spacing.s
        },
        bottomActionsStyle: {
          paddingHorizontal: isMobile ? theme?.metrics.spacing.s : 0,
          flexDirection: !isMobile ? 'row' : 'column',
          width: '100%',
          marginTop: isDesktop ? theme.metrics.spacing.xl : 0
        },
        simpleButtonStyle: {
          marginLeft: theme.metrics.spacing.m,
          flex: isDesktop ? undefined : 1
        },
        bottomActionsButtonsContainerStyle: {
          width: '100%',
          flexDirection: 'row'
        },
        HtmlFrameStyle: {
          width: '100%',
          height: '50vh',
          borderWidth: 1,
          borderColor: theme?.colors.basics.black
        },
        pdfStyle: {
          flex: 1,
          width: Dimensions.get('window').width,
          height: Dimensions.get('window').height / 3
        },
        webviewContainer: {
          marginTop: theme?.metrics.spacing.xm
        },
        recapPageContainer: {
          backgroundColor: theme.colors.basics.white,
          shadowColor: theme.colors.basics.black,
          shadowOffset: {
            width: 0,
            height: 5
          },
          shadowOpacity: 0.34,
          shadowRadius: 6.27,
          elevation: 10,
          width: '60%',
          height: Dimensions.get('screen').height / 3,
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: theme.metrics.spacing.s,
          marginBottom: theme.metrics.spacing.s
        },
        shareIconStyle: {
          display: 'flex',
          alignSelf: 'flex-start',
          marginLeft: theme.metrics.spacing.xs,
          marginTop: theme.metrics.spacing.xs
        }
      }),
    [theme, isDesktop, isMobile]
  );

  return styles;
}
