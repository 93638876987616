import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';

import { BankSummaryPage as BankSummaryPageComponent } from './BankSummaryPage.component';
import { getBankDataRequest, setSelectedAccountType, setSelectedPlanFamily } from '@modules/bank-details/actions/actionsCreators';
import { BankDetailsActionsType } from '@modules/bank-details/actions/actionsTypes';
import { getBankData, getBankDetailsError } from '@modules/bank-details/selectors';

const mapStateToProps = (state: AppState) => ({
  bankData: getBankData(state),
  isLoadingBankData: state.isLoading[BankDetailsActionsType.GET_BANK_DATA_REQUEST],
  bankDataError: getBankDetailsError(state)
});

const mapDispatchToProps = ({
  onGetBankData: getBankDataRequest,
  onSetSelectedAccountType: setSelectedAccountType,
  onSetSelectedPlanFamily: setSelectedPlanFamily
});

export const BankSummaryPage = connect(mapStateToProps, mapDispatchToProps)(BankSummaryPageComponent);