import React from 'react';
import {
  View
} from 'react-native';

import { SVGLocalLoader, Title } from '@ere-uilib/atoms';
import { SimpleButton } from '@ere-uilib/molecules';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const SavingsPlaceholder: React.FC<Props> = ({
  titleLabelId,
  buttonLabelId,
  buttonAction
}) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const title = formatMessage({ id: titleLabelId });
  const buttonText = formatMessage({ id: 'aboundement_content2_button' });
  const styles = useStyles(
    { theme, isMobile, isTablet },
    {
    },
  );

  return (
    <View style={styles.container}>
      <View
        style={styles.svgContainer}
      >
        <SVGLocalLoader
          maxWidth={450}
          name="accountCreationBackground"
        />
      </View>
      <View style={styles.titleContainer}>
        <Title
          style={styles.titleStyle}
          variant="t6">
          {title}
        </Title>
      </View>
      {buttonAction && buttonLabelId && (
        <SimpleButton
          containerStyle={styles.buttonStyle}
          onPress={buttonAction}
          title={buttonText}
        />
      )}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
