import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { FontIcon, Text } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { Select } from '@ere-uilib/molecules';
import { ItemState } from '@ere-uilib/molecules/inputs/Select/types';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { ControlDataInputTypeEnum } from '@modules/control-data/types';
import { controlDataItemFormatter } from '@pages/ControlData/utils';

import { useController } from '../useController';
import { ControlDataCardContentProps, SelectInputType } from './interface';
import { useStyles } from './styles';
import { locator } from '@constants/locator';

export const ControlDataCardContent: React.FC<ControlDataCardContentProps> = ({
  isRiskProfileDisabled,
  isHorizonDisabled,
  isEditing,
  riskProfilesData,
  itemIndex,
  onRiskProfileChange,
  onHorizonChange,
  onManagementChange,
  ...props
}) => {
  const styles = useStyles();
  const theme = useTheme()
  const { formatMessage } = useTranslation()
  const { pageDictionnary } = useController();
  const [selectedHorizonItem, setSelectedHorizonItem] = useState<ItemState | undefined>(undefined);
  const [selectedRiskProfilItem, setSelectedRiskProfileItem] = useState<ItemState | undefined>(undefined);
  const ageExists = riskProfilesData?.ageRange[itemIndex];
  const managementData = props.management.data;
  const managementId = props.management.managementId;

  const areLabelsInvalid = useMemo(() => riskProfilesData ? riskProfilesData?.labels.length < 2 : false, [riskProfilesData]);
  const ageDiff = useMemo(() => ageExists ? (
    ageExists.ageMax - ageExists.ageMin
  ) : 0, [ageExists]);

  const isHorizonErrorVisible = !managementData?.horizon
    && !selectedHorizonItem
    && riskProfilesData && riskProfilesData?.ageRange.length > 0;

  const isRiskErrorVisible = !managementData?.riskProfile && !selectedRiskProfilItem && riskProfilesData &&
    riskProfilesData.labels.length >= 1;

  const isHorizonNotEmpty = Boolean(managementData && managementData?.horizon && managementData?.horizon > 0);

  const isManagementHiden = useMemo(() => areLabelsInvalid && ageDiff === 0, [areLabelsInvalid, ageDiff])
  const isRiskPlaceholderVisible = Boolean(selectedRiskProfilItem || areLabelsInvalid);
  const isHorizonPlaceholderVisible = Boolean(selectedHorizonItem || ageDiff === 0);

  const renderErrorMessage = useCallback((message: string) => {
    return (
      <View style={styles.errorContainer}>
        <FontIcon
          color={theme.colors.notifications.warning.c500}
          name={IconEnum.WARNING}
          size={theme.metrics.spacing.xm}
        />
        <Text
          style={styles.errorText}
          variant='t3'
          weight='regular'>{message}</Text>
      </View>
    )
  }, [styles, theme]);

  useEffect(() => {
    if (areLabelsInvalid) {
      const newItem: ItemState = {
        label: riskProfilesData?.labels[0] || '',
        value: riskProfilesData?.labels[0] || '',
      };

      setSelectedRiskProfileItem(newItem)
    }
    if (ageDiff === 0 && !selectedHorizonItem) {
      const newItem: ItemState = {
        label: ageExists ? `${ageExists.ageMax}` : '',
        value: ageExists ? `${ageExists.ageMax}` : '',
      };

      setSelectedHorizonItem(newItem)
    }
  }, [areLabelsInvalid, ageDiff, selectedHorizonItem, isHorizonNotEmpty]);

  const onSelectItem = useCallback((item: ItemState, type: ControlDataInputTypeEnum) => {
    if (type === ControlDataInputTypeEnum.riskProfile) {
      if (ageDiff === 0) {
        const newItem: ItemState = {
          label: ageExists ? `${ageExists.ageMax}` : '',
          value: ageExists ? `${ageExists.ageMax}` : '',
        };

        setSelectedRiskProfileItem(item)
        onRiskProfileChange(item)
        onHorizonChange(newItem)
        onManagementChange?.(managementId, true)
      } else {

        setSelectedRiskProfileItem(item)
        onRiskProfileChange(item)
      }
    } else {
      if (areLabelsInvalid) {
        const newItem: ItemState = {
          label: riskProfilesData?.labels[0] || '',
          value: riskProfilesData?.labels[0] || '',
        };

        setSelectedHorizonItem(item)
        onHorizonChange(item)
        onRiskProfileChange(newItem)
        onManagementChange?.(managementId, true)
      } else {

        setSelectedHorizonItem(item)
        onHorizonChange(item)
      }
    }
  }, [
    onRiskProfileChange,
    onHorizonChange,
    onManagementChange,
    setSelectedRiskProfileItem,
    ageDiff,
    areLabelsInvalid,
    managementId
  ]
  );

  const renderSelectInput = useCallback(({
    defaultTitle,
    isDisabled,
    items,
    onSelectedItem,
    selectedItem,
    title,
    placeholder,
    children,
    isGreyTitle,
    testId
  }: SelectInputType) => {
    return (
      <View style={styles.inputContainer}>
        <View>
          <Select
            isAbsolutePositionDisabled={true}
            containerStyle={styles.selectContainer}
            defaultTitle={defaultTitle}
            disabled={isDisabled}
            iconSize={20}
            items={items}
            itemsContainerStyle={styles.selectItemsContainerStyle}
            onSelectItem={onSelectedItem}
            pickerTextStyle={styles.pickerText}
            pickerTitle={title}
            placeholder={placeholder}
            selectedItem={{
              value: selectedItem?.value || '',
              label: selectedItem?.label || ''
            }}
            selectedItemStyle={styles.selectedItem}
            titleStyle={isGreyTitle ? styles.disabledTitleStyle : styles.selectTitle}
            selectTestId={testId}
          />
        </View>
        {children}
      </View>
    );
  }, [styles]);

  const renderManagementTitle = useCallback(() => {
    if ((isEditing && !isManagementHiden) || !isEditing) {
      return (
        <Text
          style={styles.title}
          variant="t3"
          weight="regular"
          testId={`${locator._profile._piloted_data._item_title}_${props.management.compartmentLabelCode}`}>
          {formatMessage({ id: props.management.compartmentLabelCode })}
        </Text>
      )
    }
  }, [
    isEditing,
    props.management,
    isManagementHiden,
    styles
  ]);

  return (
    <React.Fragment>
      {renderManagementTitle()}
      {isEditing && !isManagementHiden && (
        <View style={styles.cardContentInputsWrapper}>
          {renderSelectInput({
            isGreyTitle: !selectedRiskProfilItem,
            defaultTitle: areLabelsInvalid ? riskProfilesData?.labels[0] : pageDictionnary.riskProfile,
            isDisabled: isRiskProfileDisabled || areLabelsInvalid,
            items: controlDataItemFormatter({
              itemType: ControlDataInputTypeEnum.riskProfile,
              riskLabels: riskProfilesData?.labels || []
            }),
            onSelectedItem: (item) => onSelectItem(item, ControlDataInputTypeEnum.riskProfile),
            title: pageDictionnary.riskProfile,
            placeholder: isRiskPlaceholderVisible
              ? pageDictionnary.riskProfile
              : undefined,
            selectedItem: selectedRiskProfilItem,
            children: isRiskErrorVisible
              ? renderErrorMessage(pageDictionnary.riskProfileWarning)
              : null,
            testId: `input0_${props.management.compartmentLabelCode}`
          })}

          {renderSelectInput({
            isGreyTitle: !selectedHorizonItem,
            defaultTitle: ageDiff === 0 && ageExists ?
              `${riskProfilesData?.ageRange[itemIndex]?.ageMax} ${pageDictionnary.drivingDataESAge}`
              : pageDictionnary.horizon,
            isDisabled: !!isHorizonDisabled || ageDiff === 0,
            items: controlDataItemFormatter({
              itemType: ControlDataInputTypeEnum.horizon,
              minAge: riskProfilesData?.ageRange[itemIndex]?.ageMin || 0,
              maxAge: riskProfilesData?.ageRange[itemIndex]?.ageMax || 0
            }),
            onSelectedItem: (item) => onSelectItem(item, ControlDataInputTypeEnum.horizon),
            title: pageDictionnary.horizon,
            placeholder: isHorizonPlaceholderVisible
              ? pageDictionnary.horizon
              : undefined,
            selectedItem: selectedHorizonItem,
            children: isHorizonErrorVisible
              ? renderErrorMessage(pageDictionnary.choiceProfileWarning)
              : null,
            testId: `input_${props.management.compartmentLabelCode}`
          })}
        </View>
      )}
      {!isEditing && (
        <View style={styles.rowItemStyle}>
          <View style={styles.rowItemContentContainer}>
            <Text
              style={styles.itemTitleText}
              variant="t3"
              weight="light">
              {`${pageDictionnary.riskProfile} :`}
            </Text>
            <Text
              style={[styles.itemValueText, !managementData?.riskProfile && styles.emptyDueDateTextStyle]}
              variant="t3"
              weight="regular"
              testId={`profil_${props.management.compartmentLabelCode}`}>
              {managementData?.riskProfile || pageDictionnary.dataNotFilledIn}
            </Text>
          </View>
          <View style={styles.rowItemContentContainer}>
            <Text
              style={styles.itemTitleText}
              variant="t3"
              weight="light">
              {`${pageDictionnary.horizon} :`}
            </Text>
            <Text
              style={[styles.itemValueText, !managementData?.horizon && styles.emptyDueDateTextStyle]}
              variant="t3"
              weight="regular"
              testId={`value_${props.management.compartmentLabelCode}`}>
              {isHorizonNotEmpty
                ? `${managementData?.horizon} ${pageDictionnary.drivingDataESAge}`
                : pageDictionnary.dataNotFilledIn}
            </Text>
          </View>
        </View>
      )}
    </React.Fragment>
  );
};
