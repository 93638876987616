import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';
import { getInvitationRequest } from '@modules/invitation/actions/invitationActions';

import { WelcomePage } from './WelcomePage.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state)
  };
};

const mapDispatchToProps = ({
  onFetchInvitation: getInvitationRequest
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WelcomePage);
