import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import {
  DashboardPageWrapperConnected,
  NavigationHeader,
  Title,
  Text,
  SimpleButton,
  useTheme,
  ToolCardList,
  IconEnum,
  useScreenSizes,
  HtmlStyledRenderConnectedContainer,
  TableLoader
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { RetirementActionsType } from '@modules/retirement/actions/retirementActionsTypes';
import { useUrlLink } from '@utils/index';

import { TabForm } from '../components';
import { useController } from '../components';
import { RetirementDetailsPageProps } from './interfaces';
import { useStyles } from './useStyles';

export const RetirementDetailsPageComponent: React.FC<RetirementDetailsPageProps> = ({
  tools,
  isToolsApiLoading,
  errors,
  onGetTool,
  initialFormValues,
  totalAmount,
  isRetirementApiLoading
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const { retirementDetailsPageDictionnary } = useController()
  const [showTabForm, setShowTabForm] = useState(true);
  const navigation = useNavigation();
  const { isDesktop } = useScreenSizes();

  const carouselSpacingHorizontal = useMemo(
    () => theme.metrics.spacing[isDesktop ? 'xhuge' : 's'],
    [theme, isDesktop]
  );

  useEffect(() => {
    onGetTool();
  }, [onGetTool])

  const handleCloseModal = useCallback(() => {
    return navigation.navigate(RouteNames.RetirementStack, {
      screen: RouteNames.RetirementModal,
      params: {
        clearAction: RetirementActionsType.CLEAR_RETIREMENT_DATA,
      },
    });
  }, [navigation]);

  const handlePressButton = useCallback(() => {
    navigation.navigate(RouteNames.RetirementStack, { screen: RouteNames.RetirementPage })
  }, [navigation]);

  const renderHeader = useCallback(
    () => (
      <NavigationHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={retirementDetailsPageDictionnary.retirementJourneyMyInformationPageInfoContent}
        helpPointModalTitle={retirementDetailsPageDictionnary.retirementJourneyMyInformationPageInfoTitle}
        onClose={handleCloseModal}
        title={retirementDetailsPageDictionnary.retirementJourneyMyInformationPageTitle}
      />
    ),
    [
      retirementDetailsPageDictionnary,
      handleCloseModal,
    ]
  );

  const renderToolsCard = useCallback(() => {
    return (
      <View style={styles.toolCardContainer}>
        <ToolCardList
          error={errors.toolsError}
          isToolsApiLoading={isToolsApiLoading}
          onGetTools={onGetTool}
          spacingAfterLastItem={carouselSpacingHorizontal}
          spacingBeforeFirstItem={carouselSpacingHorizontal}
          toolsData={tools}
          useUrlLink={useUrlLink}
        />
        <SimpleButton
          disabled={isRetirementApiLoading}
          containerStyle={styles.buttonContainer}
          onPress={handlePressButton}
          title={retirementDetailsPageDictionnary.retirementJourneyMyInformationPageSavingButton}
        />
      </View>
    );
  }, [
    carouselSpacingHorizontal,
    errors,
    isToolsApiLoading,
    retirementDetailsPageDictionnary,
    onGetTool,
    tools,
    handlePressButton,
    styles,
    isRetirementApiLoading
  ]);

  const renderCardInformation = useCallback(
    () => (
      <View style={styles.cardInfoContainer}>
        <Title
          variant="t5"
          weight="bold">
          {retirementDetailsPageDictionnary.retirementJourneyMyRetirementCard1Title}
        </Title>

        <Text
          style={styles.cardInfoQuestion}
          variant="t3"
          weight="bold">
          {retirementDetailsPageDictionnary.retirementJourneyMyInformationPageQuestion1}
        </Text>
        <Text
          style={styles.cardInfoAnswer}
          variant="t3"
          weight="light">
          {retirementDetailsPageDictionnary.retirementJourneyMyInformationPageAnswer1}
        </Text>
      </View>
    ),
    [
      retirementDetailsPageDictionnary,
      styles,
    ]
  );
  const renderTable = useCallback(() => {

    if (isRetirementApiLoading) return <TableLoader />

    return (
      <TabForm
        initialFormValues={initialFormValues}
        totalAmount={totalAmount}
      />
    )

  }, [initialFormValues, isToolsApiLoading, totalAmount])
  const renderContent = useCallback(
    () => (
      <View
        style={styles.contentContainer}
      >
        {renderCardInformation()}

        <SimpleButton
          containerStyle={styles.detailsButton}
          design='light'
          onPress={() => setShowTabForm(!showTabForm)}
          rightIcon={showTabForm ? IconEnum.CHEVRON_TOP : IconEnum.CHEVRON_BOTTOM}
          title={retirementDetailsPageDictionnary.detailOperationDetailLabel}
        />

        {
          showTabForm && (
            <View
              style={styles.tabFormContainer}
            >
              {renderTable()}
            </View>
          )
        }

        <View style={styles.formContainer}>
          <HtmlStyledRenderConnectedContainer
            html={retirementDetailsPageDictionnary.retirementJourneyMyInformationPageQuestionnaire}
          />
        </View>

        <Text
          variant="t3"
          weight="bold">
          {retirementDetailsPageDictionnary.eerSelfcareStep3CompteIntrouvableLink}
        </Text>
      </View>
    ),

    [styles, renderCardInformation, showTabForm, retirementDetailsPageDictionnary, renderTable]
  );

  return (
    <DashboardPageWrapperConnected cardContentStyle={styles.dashboardWrapper}>
      <View style={{ flex: 1 }}>
        {renderHeader()}
        {renderContent()}
        {renderToolsCard()}
      </View>
    </DashboardPageWrapperConnected>
  );
};