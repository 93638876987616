import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { ForgetPasswordNavigatorInterface, ForgetPasswordNavigatorRouteNames } from '@navigation/Interfaces/ForgetPasswordInterface';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import {
  ForgetPasswordProcessBegin,
  ForgetPasswordValidationCodePage,
  ForgetPasswordChoosePasswordPage,
  ForgetPasswordCongratsPage
} from '@pages/index';

const Stack = createStackNavigator<ForgetPasswordNavigatorInterface>();

export const ForgetPassword = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  return (
    <Stack.Navigator
      initialRouteName={ForgetPasswordNavigatorRouteNames.ForgetPasswordBegin}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        cardStyle: {
          backgroundColor: 'transparent'
        }
      }}>
      <Stack.Screen
        component={ForgetPasswordProcessBegin}
        name={ForgetPasswordNavigatorRouteNames.ForgetPasswordBegin}
        options={defaultScreensOptions.ForgetPasswordBegin}
      />
      <Stack.Screen
        component={ForgetPasswordValidationCodePage}
        name={ForgetPasswordNavigatorRouteNames.ForgetPasswordValidationCodePage}
        options={defaultScreensOptions.ForgetPasswordValidationCodePage}
      />
      <Stack.Screen
        component={ForgetPasswordChoosePasswordPage}
        name={ForgetPasswordNavigatorRouteNames.ForgetPasswordChoosePasswordPage}
        options={defaultScreensOptions.ForgetPasswordChoosePasswordPage}
      />
      <Stack.Screen
        component={ForgetPasswordCongratsPage}
        name={ForgetPasswordNavigatorRouteNames.ForgetPasswordCongratsPage}
        options={defaultScreensOptions.ForgetPasswordCongratsPage}
      />
    </Stack.Navigator>
  );
};
