import React from 'react';
import { View, StyleProp, ViewStyle, ScrollView, TextStyle } from 'react-native';

import { Paragraph, Title } from '@ere-uilib/atoms';
import { SimpleButton } from '@ere-uilib/molecules';
import { createUseStyles, useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';

interface Props {
  onButtonPress: () => void
  scrollViewStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  subtitleStyle?: StyleProp<TextStyle>;
  descriptionStyle?: StyleProp<TextStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
}

type AccountTermsStyles = {
  scrollViewStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  subtitleStyle?: StyleProp<TextStyle>;
  legalMenu?: StyleProp<ViewStyle>
  descriptionStyle?: StyleProp<TextStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
};

type StylesContext = {theme: ThemeType; isMobile: boolean; isTablet:boolean};

export const AccountTerms: React.FC<Props> = ({ subtitleStyle, titleStyle, onButtonPress }) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, { titleStyle, subtitleStyle });
  // TODO LABELS
  return (
    <>
      {!(isMobile || isTablet) && (
        <Title
          style={styles.titleStyle}
          variant="t3"
        >
          Conditions Générales d’Utilisation
        </Title>
      )}
      <ScrollView style={styles.scrollViewStyle}>
        <Title
          style={styles.subtitleStyle}
          variant="t6"
        >Lorem ipsum dolor sit amet</Title>
        <Paragraph
          style={styles.descriptionStyle}
          variant="t3"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in urna
          dui. Quisque sit amet consectetur justo, sit amet rhoncus magna.
          Maecenas pellentesque viverra maximus. Nullam quis mi risus. Aliquam
          vehicula lorem nec lobortis rutrum. Nunc nisl nulla, varius id
          bibendum eu, suscipit at justo. Vestibulum eleifend mollis erat vel
          iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Nulla in urna dui. Quisque sit amet consectetur justo, sit amet
          rhoncus magna. Maecenas pellentesque viverra maximus. Nullam quis mi
          risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla,
          varius id bibendum eu, suscipit at justo. Vestibulum eleifend mollis
          erat vel iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Nulla in urna dui. Quisque sit amet consectetur justo, sit amet
          rhoncus magna. Maecenas pellentesque viverra maximus. Nullam quis mi
          risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla, v
        </Paragraph>
        <Title
          style={styles.subtitleStyle}
          variant="t6"
        >
            Lorem ipsum dolor sit amet
        </Title>
        <Paragraph
          style={styles.descriptionStyle}
          variant="t3"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in urna
          dui. Quisque sit amet consectetur justo, sit amet rhoncus magna.
          Maecenas pellentesque viverra maximus. Nullam quis mi risus. Aliquam
          vehicula lorem nec lobortis rutrum. Nunc nisl nulla, varius id
          bibendum eu, suscipit at justo. Vestibulum eleifend mollis erat vel
          iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Nulla in urna dui. Quisque sit amet consectetur justo, sit amet
          rhoncus magna. Maecenas pellentesque viverra maximus. Nullam quis mi
          risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla,
          varius id bibendum eu, suscipit at justo. Vestibulum eleifend mollis
          erat vel iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Nulla in urna dui. Quisque sit amet consectetur justo, sit amet
          rhoncus magna. Maecenas pellentesque viverra maximus. Nullam quis mi
          risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla, v
        </Paragraph>
        <Title
          style={styles.subtitleStyle}
          variant="t3"
        >Lorem ipsum dolor sit amet</Title>
        <Paragraph
          style={styles.descriptionStyle}
          variant="t3"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in urna
          dui. Quisque sit amet consectetur justo, sit amet rhoncus magna.
          Maecenas pellentesque viverra maximus. Nullam quis mi risus. Aliquam
          vehicula lorem nec lobortis rutrum. Nunc nisl nulla, varius id
          bibendum eu, suscipit at justo. Vestibulum eleifend mollis erat vel
          iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Nulla in urna dui. Quisque sit amet consectetur justo, sit amet
          rhoncus magna. Maecenas pellentesque viverra maximus. Nullam quis mi
          risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla,
          varius id bibendum eu, suscipit at justo. Vestibulum eleifend mollis
          erat vel iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Nulla in urna dui. Quisque sit amet consectetur justo, sit amet
          rhoncus magna. Maecenas pellentesque viverra maximus. Nullam quis mi
          risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla, v
        </Paragraph>

        <View style={styles.buttonStyle}>
          <SimpleButton
            onPress={onButtonPress}
            title="Accepter et continuer"
          />
        </View>
      </ScrollView>
    </>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: AccountTermsStyles,
): AccountTermsStyles => ({
  titleStyle: [
    {
      lineHeight: 28,
      marginVertical: context?.theme.metrics.spacing.xm
    },
    (context?.isMobile || context?.isTablet) && { marginTop: context?.theme.metrics.spacing.xxbig },
    style?.titleStyle
  ],
  subtitleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
      maxWidth: 450
    },
    style?.subtitleStyle
  ],
  descriptionStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm
    },
    style?.descriptionStyle
  ],
  buttonStyle:
  !(context?.isMobile || context?.isTablet)
    ? { marginTop: context?.theme.metrics.spacing.xm, marginRight: context?.theme.metrics.spacing.xm }
    : { marginTop: context?.theme.metrics.spacing.xm },
  scrollViewStyle: [{ flex: 1, marginTop: context?.theme.metrics.spacing.s }, style?.scrollViewStyle]
});

const useStyles = createUseStyles(getStyles);
