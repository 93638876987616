import React, { useCallback } from 'react';
import { useState } from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import {
  SimpleButton,
  PhoneFieldCheck,
  PhoneFieldCheckOnChangeParamsInterface,
  useTheme,
  ThemeType,
  useScreenSizes,
  EERPageWrapperContainer,
  EERContentWrapper,
  Paragraph,
  EERKeyboardAvoidingViewPageWrapper,
} from '@components/index';
import { NotificationSoft } from '@ere-uilib/molecules';
import { createUseStyles } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { IdentityValidationErrorState } from '@modules/validate-identity/types';
import { locator } from '@constants/locator';

interface Props {
  validateEERPhoneNewIdentifier: (phone: string) => void
  isApiLoading: boolean
  validateIdentityError: IdentityValidationErrorState
}

type ChooseIdentificationPhonePageStyles = {
  contentStyle?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
  buttonsRowStyle?: StyleProp<ViewStyle>
  subTitle?: StyleProp<TextStyle>
};

type StylesContext = {
  theme: ThemeType;
  isDesktop: boolean;
};

export const ChooseIdentificationPhonePage: React.FC<Props> = ({
  validateEERPhoneNewIdentifier,
  isApiLoading,
  validateIdentityError,
}) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useStyles({ theme, isDesktop }, {});

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = useCallback(() => {
    if (!(!isPhoneNumberValid || isApiLoading)) {
      validateEERPhoneNewIdentifier(phoneNumber);
    }
  }, [
    isPhoneNumberValid,
    isApiLoading,
    phoneNumber,
    validateEERPhoneNewIdentifier,
  ]);

  const handlePhoneFieldChange = useCallback(({
    isValid,
    internationalPhoneNumber,
  }: PhoneFieldCheckOnChangeParamsInterface) => {
    setIsPhoneNumberValid(isValid);
    setPhoneNumber(internationalPhoneNumber || '');
  }, [setIsPhoneNumberValid, setPhoneNumber]);

  const renderSubmitButton = useCallback(() => (
    <SimpleButton
      disabled={!isPhoneNumberValid}
      loading={isApiLoading}
      onPress={handleSubmit}
      title={formatMessage({ id: 'EerSaisirMobileEnregistrerBouton' })}
      testId={locator._account_activation._save_phone}
    />
  ), [
    isPhoneNumberValid,
    isApiLoading,
    handleSubmit,
    formatMessage,
  ]);

  return (
    <EERKeyboardAvoidingViewPageWrapper>
      <EERPageWrapperContainer
        activeStep={2}
        displayCloseButton={false}
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'eer_info_content' })}
        helpPointModalTitle={formatMessage({ id: 'eer_info_title' })}
        helpPointPosition="left"
        pageTitle={formatMessage({ id: 'verify-identity_menu' })}
        renderStickyMobileFooterContent={renderSubmitButton}
      >
        <EERContentWrapper
          title={formatMessage({ id: 'EerSaisirMobileTitreLabel' })}
        >
          <View style={styles.contentStyle}>
            <Paragraph
              style={styles.subTitle}
              variant="t2"
            >
              {formatMessage({ id: 'EerSaisirMobileSousTitreLabel' })}
            </Paragraph>
            <PhoneFieldCheck
              onChange={handlePhoneFieldChange}
              onFieldSubmit={handleSubmit}
              placeholder={formatMessage({ id: 'EerSaisirMobileNumeroPlaceholder' })}
              testId={locator._account_activation._phone_number}
            />
            {validateIdentityError.message !== '' && (
              <NotificationSoft
                text={validateIdentityError.message}
                type="warning"
              />
            )}
            {isDesktop && (
              <View style={styles.buttonsRowStyle}>
                <View style={styles.buttonStyle}>
                  {renderSubmitButton()}
                </View>
              </View>
            )}
          </View>
        </EERContentWrapper>
      </EERPageWrapperContainer>
    </EERKeyboardAvoidingViewPageWrapper>
  );
};

const getStyles = (
  context?: StylesContext,
): ChooseIdentificationPhonePageStyles => ({
  contentStyle: [
    {
      flex: 1,
      width: '100%',
      maxWidth: 450,
    },
    !context?.isDesktop && {
      alignSelf: 'center',
    },
  ],
  subTitle: [
    {
      marginVertical: context?.theme.metrics.spacing.xm,
    },
  ],
  buttonsRowStyle: [
    context?.isDesktop ? {
      flexDirection: 'row',
    }
      : {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: context?.theme.metrics.spacing.xm,
      },
  ],
  buttonStyle: [
    context?.isDesktop ? {
      marginTop: context?.theme.metrics.spacing.xm,
      marginRight: context?.theme.metrics.spacing.xm,
    } : {
      marginTop: context?.theme.metrics.spacing.xm,
    },
  ],
});

const useStyles = createUseStyles(getStyles);
