import React from "react";
import { View } from "react-native";

import {
  SimpleButton,
  ColumnsContainer,
  DashboardPageWrapperConnected,
  SVGLocalLoader,
  Text,
  FiletStepper,
} from "@components/index";
import { RouteNames } from "@constants/navigation";
import { IconEnum } from "@ere-uilib/enums";
import { DirectionEnum } from "@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces";
import { useTheme, useScreenSizes, createUseStyles } from "@ere-uilib/styles";
import { useTranslation } from "@ere-uilib/translations";
import { constructStepperItems } from "@pages/Common/utils";

import { RefundsHeader } from "../../components/RefundsHeader";
import { RefundAskProofPageProps } from "./interfaces";
import { getStyles } from "./styles";
import { locator } from "@constants/locator";

export const RefundAskProofPageComponent: React.FC<RefundAskProofPageProps> = ({
  navigation,
}) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles(
    {
      isMobile: isMobile || isTablet,
      theme,
    },
    {}
  );

  const { formatMessage } = useTranslation();

  const stepperItems = constructStepperItems({
    size: 5,
    direction: DirectionEnum.ROW,
    activeStep: 3,
  });

  const RenderHeader = () => (
    <RefundsHeader
      displayCloseButton
      helpPoint={false}
      stepperItems={stepperItems}
      title={formatMessage({ id: "Remboursement_PiecesJustif_title" })}
    />
  );

  const renderBottomActions = () => (
    <View style={styles.bottomActionsStyle}>
      <View style={styles.bottomActionsButtonsContainerStyle}>
        <SimpleButton
          design="outlined"
          leftIcon={IconEnum.CHEVRON_LEFT}
          onPress={navigation.goBack}
        />
        <SimpleButton
          containerStyle={styles.simpleButtonStyle}
          design="solid"
          onPress={() =>
            navigation.navigate(RouteNames.RefundsStack, {
              screen: RouteNames.RefundsRecap,
            })
          }
          size="small"
          title={formatMessage({ id: "Remboursement_PiecesJustif_bouton" })}
          testId={locator._recover_savings._justificatif_continue}
        />
      </View>
    </View>
  );

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={styles.container}
      renderStickyMobileBottom={renderBottomActions}
    >
      <ColumnsContainer
        renderHeader={RenderHeader}
      >
        <View style={{ flex: 1 }}>
          <FiletStepper containerStyle={styles.filetStepperContainerStyle}>
            <View style={styles.contentStyle}>
              <Text variant="t3">
                {formatMessage({ id: "Remboursement_PiecesJustif_subtitle" })}
              </Text>
              <Text
                variant="t3"
                weight="light">
                {formatMessage({ id: "Remboursement_PiecesJustif_content" })}
              </Text>
              <Text
                style={styles.textContentStyle}
                variant="t3"
                weight="light">
                {formatMessage({ id: "Remboursement_PiecesJustif_content1" })}
              </Text>
              <Text
                style={styles.textContentStyle}
                variant="t3"
                weight="light">
                {formatMessage({ id: "Remboursement_PiecesJustif_content2" })}
              </Text>
            </View>
          </FiletStepper>

          <View style={styles.skipStepImgStyle}>
            <SVGLocalLoader name="skipStep" />
          </View>
          {(!isMobile && !isTablet) && renderBottomActions()}
        </View>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
