import React from 'react';
import { View } from 'react-native';

import { Title, ModalFrame, SVGLocalLoader, SimpleButton } from '@components/index';
import { OtpChanelTypeEnum } from '@constants/index';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { OtpValidationSuccessPageProps as Props } from './interfaces';
import { getStyles } from './styles';

export const OtpValidationSuccessPage = ({
  onValidation,
  isOtpFlowCompletedLoading,
  OtpParameters
}: Props) => {
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const styles = useStyles({ theme, isMobile, isTablet, windowHeight }, {});

  return (
    <View style={styles.containerStyle}>
      <ModalFrame
        desktopPosition="center"
        innerContainer={styles.innerContainerStyle}
        mobilePosition="bottom">
        <View style={styles.innerContainerStyle}>
          <SVGLocalLoader
            containerStyle={styles.imageContainerStyle}
            height={280}
            name="profileCongrats"
            width={140}
          />
          <Title
            style={styles.titleStyle}
            variant={'t6'}>
            {formatMessage({ id: 'Profil_email_edit_confirm_title' })}
          </Title>
          <Title
            style={styles.subtitleStyle}
            variant={'t6'}>
            {OtpParameters?.chanelType === OtpChanelTypeEnum.SMS
              ? formatMessage({ id: 'Profil_phone_edit_confirm_label' })
              : formatMessage({ id: 'Profil_email_edit_confirm_label' })}
          </Title>
          <SimpleButton
            containerStyle={styles.simpleButtonStyle}
            loading={isOtpFlowCompletedLoading}
            onPress={onValidation}
            title={formatMessage({ id: 'Profil_email_edit_confirm_button' })}
          />
        </View>
      </ModalFrame>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
