import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { Defs, Rect, Svg, Pattern, Line, } from 'react-native-svg';

import { useTheme } from '@ere-uilib/styles/theme';

import { HatchedBackgroundViewProps } from './interfaces';
import { useStyles } from './styles';

export const HatchedBackgroundView: React.FC<HatchedBackgroundViewProps> = ({
  style,
  backgroundColor,
  foregroundColor,
  patternScale,
  hatchScale,
  children,
  testID,
})=>{
  const theme = useTheme();
  const config = useMemo(()=>({
    backgroundColor: backgroundColor || theme.colors.basics.grey.c200,
    foregroundColor: foregroundColor || theme.colors.basics.grey.c600,
    hatchSize: 3.5 * (hatchScale || 1),
    patternSize: 6,
    patternScale: patternScale || 1,
  }), [
    theme,
    backgroundColor,
    foregroundColor,
    patternScale,
    hatchScale,
  ]);
  const styles = useStyles({ style });
  const renderHatchedBackground = useCallback(()=>{
   
    const patternFinalSize = config.patternSize * config.patternScale

    return(
      <Svg
        height="100%"
        style={styles.background}
        width="100%"
      >
        <Defs>
          <Pattern
            height={patternFinalSize}
            id={'diagonalHatch-' + config.patternSize}
            patternUnits="userSpaceOnUse"
            viewBox={`0 0 ${config.patternSize} ${config.patternSize}`}
            width={patternFinalSize}
            x="0"
            y="0"
          >
            <Line 
              stroke={config.foregroundColor}
              strokeWidth={config.hatchSize}
              x1={0}
              x2={config.patternSize}
              y1={config.patternSize}
              y2={0}
            />
            <Line 
              stroke={config.foregroundColor}
              strokeWidth={config.hatchSize}
              x1={- config.hatchSize / 2}
              x2={config.hatchSize / 2}
              y1={config.hatchSize / 2}
              y2={- config.hatchSize / 2}
            />
            <Line 
              stroke={config.foregroundColor}
              strokeWidth={config.hatchSize}
              x1={config.patternSize + config.hatchSize / 2}
              x2={config.patternSize - config.hatchSize / 2}
              y1={config.patternSize - config.hatchSize / 2}
              y2={config.patternSize + config.hatchSize / 2}
            />
          </Pattern>
        </Defs>
        <Rect
          fill={config.backgroundColor}
          height="100%"
          width="100%"
          x="0"
          y="0"/>
        <Rect
          fill={'url(#diagonalHatch-' + config.patternSize + ')'}
          height="100%"
          width="100%"
          x="0"
          y="0"/>
      </Svg>
    )
  }, [config, styles])
  return (
    <View
      style={styles.style}
      testID={testID}
    >
      {renderHatchedBackground()}
      {children}
    </View>
  );
};
