import React from 'react';

import {
  useTranslation,
  OfflineAnnounceLayout360,
  SimpleButton,
  useScreenSizes,
} from '@components/index';
import { AuthConfigType } from '@modules/settings/types';
import { loginOpenId } from '@modules/auth/services';
import { useLogoNavigation } from '@utils/index';
import { useNavigation } from '@react-navigation/native';
import { RouteNames } from '@constants/navigation';
import { Platform } from 'react-native';

interface Props {
  authConfig: AuthConfigType
}
export const ForgetPasswordCongratsPage: React.FC<Props> = ({
  authConfig,
}) => {
  const { isMobile, isTablet } = useScreenSizes();
  const { formatMessage } = useTranslation();
  const navigation = useNavigation()
  const { logoNavigateTo } = useLogoNavigation();
  const manageRedirection = () => {
    if(Platform.OS === 'web') return loginOpenId(authConfig)
    return  navigation.navigate(RouteNames.NativeLoginPage)
  }
  const renderActions = ()=>{
    return (
      <SimpleButton
        onPress={() => manageRedirection()}
        title={formatMessage({ id: 'login_button' })}
      />
    );
  };
  return (
    <OfflineAnnounceLayout360
      actions={renderActions}
      description={formatMessage({ id: 'Profil_ChangePassword_Confirm_title' })}
      displayLogo={!(isMobile || isTablet)}
      onLogoPress={logoNavigateTo}
      sourceName="forget_password_validated" // deux version différente du titre (figma et ancien repo), à voir avec le métier ce qu'ils veulent implémenter
      title="Merci !"
    />
  );
};

