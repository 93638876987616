import React, { useEffect } from 'react';
import { View } from 'react-native';

import {
  useTheme,
  Modal,
  ModalFrame,
  FundSheetInnerPage
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { FundSheetModalProps } from './interfaces';
import { getStyles } from './styles';

export const FundSheetModalComponent: React.FC<FundSheetModalProps> = ({
  modalIsVisible = false,
  onCloseModal,
  onGetFunds,
  id,
  title,
  funds,
  fundsPerformance,
  fundsPerformanceSharePriceHisto,
  fundsDocumentsList,
  innerNavigations,
  isApiLoadingDocuments,
  isApiLoadingOverview,
  isApiLoadingPerformance,
  navigation
}) => {

  const theme = useTheme();
  const styles = useStyles({ theme }, {});

  const isingCode = id;
  const fundTitle = title;

  useEffect(() => {
    if (isingCode && isingCode.length > 0) {
      onGetFunds(isingCode);
    }
  }, [isingCode]);

  return (
    <Modal visible={modalIsVisible}>
      <ModalFrame
        desktopPosition="fullHeight"
        innerContainer={styles.innerContainerPadding}
        maxDesktopWidth={850}
        mobilePosition="full"
      >
        <View style={styles.containerStyle}>
          <FundSheetInnerPage
            dateLastVL={funds?.dateLastVL || ''}
            fundOverview={funds}
            fundsDocumentsList={fundsDocumentsList}
            fundsPerformance={fundsPerformance}
            fundsPerformanceSharePriceHisto={fundsPerformanceSharePriceHisto}
            fundTitle={fundTitle}
            innerNavigations={innerNavigations}
            isApiLoadingDocuments={isApiLoadingDocuments}
            isApiLoadingOverview={isApiLoadingOverview}
            isApiLoadingPerformance={isApiLoadingPerformance}
            isingCode={isingCode}
            isModal={true}
            launchDate={funds?.dateLancement || ''}
            navigation={navigation}
            onCloseModal={() => { onCloseModal(); }}
          />
        </View>
      </ModalFrame>
    </Modal>
  );
};

const useStyles = createUseStyles(getStyles);
