import { useCallback } from 'react';

import { useTranslation } from '@components/ERE-UILib';
import { OperationDetailsPaymentModeTypeEnum } from '@constants/savings';

import { useData } from './useData';

export const useLabel = () => {
  const { formatMessage } = useTranslation();
  const { isIncentive } = useData();

  const getLabel = useCallback(
    (position?: 'SOURCE' | 'DESTINATION') => ({
      stepTitle: formatMessage({
        id:
          (position === 'SOURCE' && 'PIAffectationdefautDeLabel') ||
          (isIncentive && 'PIValidationChoixPercIvestEtVersLabel') ||
          'PIAffectationdefautVersLabel'
      }),
      notificationInProgress: formatMessage({ id: 'DetailOperationSeuilRbsAntInfo' }),
      [OperationDetailsPaymentModeTypeEnum.CHEQUE]: {
        title: formatMessage({
          id:
            position === 'SOURCE'
              ? 'PIDefinitionBesoinCardRIBManquantTitre'
              : 'DetailOperationPaiementChequeLabel'
        }),
        description: formatMessage({ id: 'PIDefinitionBesoinCardRIBManquantDescription' }),
        prePaymentDetailsTitle: formatMessage({ id: 'DetailOperationRemboursementCheque' }),
        prePaymentDetailsSubtitle: formatMessage({
          id: 'DetailOperationRemboursementVirementDispositionsParticulieres'
        }),
        paymentDetailsTitle: formatMessage({ id: 'Remboursement_virementRecap_masquerCondPost' }),
        paymentDetailsSubtitle: formatMessage({
          id: 'DetailOperationRemboursementVirementDeviseEuro'
        })
      },
      [OperationDetailsPaymentModeTypeEnum.COMPANY]: {
        title: formatMessage({ id: 'DetailOperationPaiementTitle' }),
        description: formatMessage({ id: 'PIDefinitionBesoinCardEntreprisePayeuseDescription' })
      },
      [OperationDetailsPaymentModeTypeEnum.CB]: {
        title: formatMessage({ id: 'PIValidationChoixPercIvestCardCompteBancaireTitre' }),
        description: formatMessage({ id: 'DetailOperationPaiementCBLabel' })
      },
      [OperationDetailsPaymentModeTypeEnum.VIREMENT]: {
        title: formatMessage({ id: 'PIValidationChoixPercIvestCardCompteBancaireTitre' }),
        description:
          (position === 'SOURCE' &&
            formatMessage({ id: 'DetailOperationPaiementVirementLabel' })) ||
          '',
        prePaymentDetailsTitle: formatMessage({ id: 'DetailOperationRemboursementVirement' }),
        prePaymentDetailsSubtitle: formatMessage({
          id: 'DetailOperationRemboursementVirementDispositionsParticulieres'
        }),
        paymentDetailsTitle: formatMessage({
          id: 'PIValidationChoixPercIvestCardCompteBancaireLien'
        }),
        paymentDetailsSubtitle1: formatMessage({
          id:
            position === 'SOURCE'
              ? 'DetailOperationRemboursementCheque'
              : 'DetailOperationRemboursementVirementCheque'
        }),
        paymentDetailsSubtitle2: formatMessage({
          id: 'DetailOperationRemboursementVirementDeviseEuro'
        })
      },
      [OperationDetailsPaymentModeTypeEnum.UNKNOWN]: undefined
    }),
    [formatMessage, isIncentive]
  );

  return { getLabel }
}
