import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';

import { ValidateSignupThankYouPage } from './ValidateSignupThankYouPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state)
  };
};

export default connect(
  mapStateToProps,
  null,
)(ValidateSignupThankYouPage);