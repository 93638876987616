import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getEditPhoneNumberIsEnable,
  getOtpError,
  getProfileError,
  getProfileUserInfos,
} from '@modules/profile/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';

import { ProfileEditPhonePageComponent } from './ProfileEditPhonePage.component';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { useCallback } from 'react';
import { UserInfoTypeEnum } from '@constants/profile';
import {
  profileCheckEditPhoneNumberAuthorizeIsEnableRequest,
  sendProfileUserInfoChangeRequest,
} from '@modules/profile/actions/profileActions';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
}

export const ProfileEditPhonePage: React.FC<Props> = ({ navigation }) => {

  const dispatch = useDispatch();

  const error = useSelector(getProfileError);
  const otpError = useSelector(getOtpError)
  const profileUserInfos = useSelector(getProfileUserInfos);
  const applicationSettings = useSelector(getApplicationSettings);
  const editPhoneNumberIsEnable = useSelector(getEditPhoneNumberIsEnable);

  const onSetProfileUserInfoChange = useCallback((
    userInfosType: UserInfoTypeEnum,
    phoneNumber: string
  ) => {
    return dispatch(sendProfileUserInfoChangeRequest(userInfosType, phoneNumber));
  }, [dispatch]);

  const onCheckEditPhoneNumberIsEnable = useCallback(() => {
    return dispatch(profileCheckEditPhoneNumberAuthorizeIsEnableRequest());
  }, [dispatch]);


  return (
    <ProfileEditPhonePageComponent
      profileUserInfos={profileUserInfos}
      error={error}
      otpError={otpError}
      navigation={navigation}
      applicationSettings={applicationSettings}
      editPhoneNumberIsEnable={editPhoneNumberIsEnable}
      onSetProfileUserInfoChange={onSetProfileUserInfoChange}
      oncheckEmailEdit={onCheckEditPhoneNumberIsEnable}
    />
  )
}
