import React from 'react'
import { DashboardContentWrapperCardComponent, useTranslation } from '@components/index'

import { DashboardContributionCardContent } from './components'
import { DashboardContributionCardProps as Props } from './interface'
import { AbondementLoader } from '../Loaders'

export const DashboardContributionCard: React.FC<Props> = ({
  onGetContributionByCompany,
  contributionByCompany,
  cardTableLoading,
  isError
}) => {
  const { formatMessage } = useTranslation()

  const renderLoader = () => {
    return <AbondementLoader />
  }
  const renderContents = () => {
    if (!contributionByCompany?.planContributions?.length) return <></>

    const description = formatMessage({ id: 'DashboardCardAbondementDoneTitre' })

    return (
      <DashboardContributionCardContent
        buttonText={formatMessage({ id: 'DashboardCardContributionButtonTitle' })}
        contributionData={contributionByCompany}
        description={description}
      />
    )
  }

  if (!cardTableLoading && !contributionByCompany?.planContributions?.length) return null

  return (
    <DashboardContentWrapperCardComponent
      errorRetryAction={onGetContributionByCompany}
      helpPoint={{
        contentHtml: formatMessage({ id: 'DashboardCardAbondementinfoContent' }),
        contentTitle: formatMessage({ id: 'DashboardCardAbondementinfoTitle' }),
        modalTitle: formatMessage({ id: 'DashboardCardContributionTitle' })
      }}
      isError={isError}
      isLoading={cardTableLoading}
      renderLoader={renderLoader}
      testId="DashboardCardAbondement_title"
      title={formatMessage({ id: 'DashboardCardContributionTitle' })}>
      {renderContents()}
    </DashboardContentWrapperCardComponent>
  )
}
