import React from 'react';
import { StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';

import { FontIcon, Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

interface Props {
  onDeletePress?: () => void
  onEditPress?: () => void
  isEditable?: boolean
  data?: DataProps
}

interface DataProps {
  amount: number
  date?: string
}

interface Context {
  isDateFormat: boolean
  isTriggerThresholdData: boolean
  theme: ThemeType
  isEditable?: boolean
}

interface Style {
  container?: StyleProp<ViewStyle>
  titleContainer?: StyleProp<ViewStyle>
  iconButtonRightContainer?: StyleProp<ViewStyle>
  iconButtonLeftContainer?: StyleProp<ViewStyle>
  iconButtons?: StyleProp<TextStyle>
  title?: StyleProp<TextStyle>
}

export const TriggerThresholdComponent: React.FC<Props> = ({
  onDeletePress,
  onEditPress,
  isEditable,
  data,
}) => {
  const theme = useTheme();
  const { formatMessage, formatDate, formatCurrencyNumber } = useTranslation();
  const amount = data?.amount;
  const date = data?.date;
  const formattedAmount = amount ? formatCurrencyNumber({ value: amount }) : undefined;
  const formattedDate = formatDate({
    value: date,
  });
  const isTriggerThresholdData = !!amount && !!date;
  const styles = useStyles({ isDateFormat: !!date, isTriggerThresholdData, theme, isEditable }, {});
  return (
    <View style={styles.container}>
      {isTriggerThresholdData && isEditable && (
        <TouchableOpacity
          disabled={!isEditable}
          onPress={onEditPress}
          style={styles.iconButtonLeftContainer}
        >
          <FontIcon
            color={ theme.colors.buttons.classic.c500}
            name={IconEnum.EDIT }
            size={theme.metrics.iconSizes.xxm}
            style={styles.iconButtons}
          />
        </TouchableOpacity>
      )}
      {!isTriggerThresholdData && (
        <TouchableOpacity
          disabled={!isEditable}
          onPress={onEditPress}
          style={styles.iconButtonLeftContainer}
        >
          <FontIcon
            color={
              !isEditable
                ? theme.colors.buttons.disabled.c500
                : theme.colors.buttons.classic.c500
            }
            name={IconEnum.AJOUTER}
            size={theme.metrics.iconSizes.xxm}
            style={styles.iconButtons}
          />
        </TouchableOpacity>
      )}
      <View style={styles.titleContainer}>
        <Title
          style={styles.title}
          variant="t7"
          weight="light">
          {formatMessage({ id: 'Remboursement_lienModale' })}
          {amount && ` : ${formattedAmount}`}
        </Title>
        {date && (
          <Title
            style={styles.title}
            variant="t8"
            weight="light">{`${formatMessage({ id: 'Remboursement_seuilDeclench_validite' })} ${formattedDate}`}</Title>
        )}
      </View>
      { isTriggerThresholdData && isEditable && (
        <TouchableOpacity
          disabled={!isEditable}
          onPress={onDeletePress}
          style={styles.iconButtonRightContainer}
        >
          <FontIcon
            color={theme.colors.buttons.classic.c500}
            name={IconEnum.EFFACER_VIDE}
            size={theme.metrics.iconSizes.xxm}
            style={styles.iconButtons}
          />
        </TouchableOpacity>
      )}
    </View>
  );};

const getStyles = (
  context?: Context,
): Style => ({
  container: [
    {
      flexDirection: 'row',
      flex: 1,
    },
    !context?.isDateFormat && {
      alignItems: 'center',
    },
  ],
  titleContainer: [
    {},
  ],
  iconButtonRightContainer: [
    {
      marginLeft: context?.theme?.metrics.spacing.xs,
    },
  ],
  iconButtonLeftContainer: [
    {
      marginRight: context?.theme?.metrics.spacing.xs,
    },
  ],
  iconButtons: [
    context?.isDateFormat && {
      lineHeight: 21,
    },
  ],
  title: [
    {},
    !context?.isEditable && !context?.isTriggerThresholdData && {
      color: context?.theme.colors.text.disabled.c500,
    },
  ],
});

const useStyles = createUseStyles(getStyles);
