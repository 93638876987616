import React, { useCallback } from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { Pen } from '@ere-uilib/molecules';

import { ControlDataCardLoader } from '../ControlDataCardLoader';
import { ControlDataCardProps } from './interface';
import { useStyles } from './styles';
import { locator } from '@constants/locator';

export const ControlDataCard: React.FC<ControlDataCardProps> = ({
  headerBorderColor,
  headerBackgroundColor,
  withSeparator = true,
  isEditable = false,
  isError,
  isLoading,
  noHeader = false,
  onEditPress,
  title,
  children,
  onRetry,
  keyExtractor,
  ...props
}) => {
  const styles = useStyles();
  const CardContents = React.Children.toArray(children);

  const renderPen = useCallback(() => {
    if (!isEditable) return;

    return <Pen iconSize={22} onPress={() => onEditPress?.()} testId={locator._profile._piloted_data._pen_edit} />;
  }, [onEditPress, isEditable]);

  const renderControlDataCard = useCallback(() => {
    return (<View
      {...props}
      style={styles.wrapperContainer}>
      {!noHeader && (
        <View
          style={[
            styles.headerContainer,
            !noHeader && {
              backgroundColor: headerBackgroundColor,
              borderColor: headerBorderColor
            }
          ]}>
          <Text
            variant="t2"
            weight="bold">
            {title}
          </Text>
          {renderPen?.()}
        </View>
      )}
      {!isError && !isLoading && CardContents.map((Content, index, array) => {
        const lastIndex = array.length - 1
        const isLastIndex = index === lastIndex;
        const zIndex = lastIndex - index;
        return (
          <View
            key={keyExtractor?.(index)}
            style={[
              { zIndex },
              styles.cardContentContainer,
              withSeparator && !isLastIndex && styles.dashStyle
            ]}>
            {Content}
          </View>
        );
      })}
    </View>);
  }, [
    CardContents,
    headerBackgroundColor,
    headerBorderColor,
    isError,
    isLoading,
    keyExtractor,
    props,
    renderPen,
    styles,
    title,
    withSeparator
  ])

  const renderLoaderContainer = useCallback(() => {

    return (
      <ControlDataCardLoader
        isError={isError}
        isLoading={Boolean(isLoading)}
        retryAction={onRetry} />
    );
  }, [isError, isLoading, onRetry]
  )
  return (isLoading || isError) ? renderLoaderContainer() : renderControlDataCard()
};
