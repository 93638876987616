import { all, call, fork, put } from '@redux-saga/core/effects';
import axios from 'axios';
import { delay, select, takeLatest } from 'redux-saga/effects';

import { mockBlockingPendingTask } from '@__mocks__/mockPendingTasks';
import { API_URL } from '@config/index';
import { RouteNames } from '@constants/navigation';
import { OtpChanelTypeEnum } from '@constants/profile';
import { OtpRequestTypeEnum } from '@ere-uilib/enums';
import { getSubmittedCampaignIds } from '@modules/campaign/selectors';
import * as ProfileActions from '@modules/profile/actions/profileActions';
import { OtpParametersState } from '@modules/profile/types';
import { RootNavigation } from '@navigation/RootNavigation';

import { runManager } from '../moduleManager';
import * as PendingTasksActions from './actions/pendingTasksActions';
import { PendingTask, PendingTasksActionsType, PendingTasksResponse, TaskTypeEnum } from './actions/pendingTasksActionsTypes';

export function fetchPendingTasks(): Promise<PendingTasksResponse> {
  return axios.get(API_URL + '/api/v1/checklist');
}

function* executePendingTask(pendingAction: PendingTask) {
  switch (pendingAction.taskType) {
  case TaskTypeEnum.USER_PREFERENCES:
    RootNavigation.navigate(RouteNames.Contact, { 
      toDoPostSuccessActionType: PendingTasksActionsType.GET_PENDING_TASKS_REQUEST,
      demandId: pendingAction.context.demandId
    });

    break; 
  case TaskTypeEnum.PREFERENCES_OPTIN:
    RootNavigation.navigate(RouteNames.BusinessContact, {
      toDoPostSuccessActionType: PendingTasksActionsType.GET_PENDING_TASKS_REQUEST,
      demandId: pendingAction.context.demandId,
    });
    break; 
  case TaskTypeEnum.CAMPAIGN:
    RootNavigation.navigate(RouteNames.ConsentTransferCredit, {
      toDoPostSuccessActionType: PendingTasksActionsType.GET_PENDING_TASKS_REQUEST,
      campaignId: pendingAction.context.campaignId || '',
      isPostConnexionMode: true,
    });
    break; 
  case TaskTypeEnum.MOBILE:
  case TaskTypeEnum.EMAIL:
    const { demandId } = pendingAction.context || {};
    let chanelType = OtpChanelTypeEnum.MAIL;
    if (pendingAction.taskType === TaskTypeEnum.MOBILE) {
      chanelType = OtpChanelTypeEnum.SMS;
    }
    const otpParameters: OtpParametersState = {
      requestType: OtpRequestTypeEnum.VERIFY,
      outputActionType: PendingTasksActionsType.GET_PENDING_TASKS_REQUEST,
      chanelType,
      demandId
    };
    yield put(ProfileActions.otpFlowStartRequest(otpParameters));
    break; 
  default:
    break;
  }
}

function* getPendingTasks(): any {
  const response = yield call(fetchPendingTasks);
  //  Uncomment for using Mock
  // yield delay(1500);
  // const response = {
  //   data: mockBlockingPendingTask
  // }
  
  if (response?.data?.length === 0) return yield put(ProfileActions.getConsentsRequest());
  yield put(PendingTasksActions.getPendingTasksSuccess(response?.data));

  const submittedCampaignIds: string[] = yield select(getSubmittedCampaignIds)

  const pendingTasks:PendingTask[] = response?.data || [];
  // prevent blocking the app while new action is send from back but not ready in front
  const filteredPendingTasks = pendingTasks.filter(task=>{
    const isKnown =  Object.values(TaskTypeEnum).includes(task.taskType)
    
    const isCampaignSubmitted = task.taskType === TaskTypeEnum.CAMPAIGN 
    && task.context.campaignId 
    && submittedCampaignIds.includes(task.context.campaignId)
    const isValid = isKnown && !isCampaignSubmitted
    return isValid
  });
  const blockingTask = pendingTasks[0].taskType;
  if(blockingTask === TaskTypeEnum.BLOCKING) {
    return yield RootNavigation.navigate(RouteNames.MaintenanceModal);
  }

  if (filteredPendingTasks && filteredPendingTasks.length > 0) {
    yield executePendingTask(filteredPendingTasks[0]);
   
  } else {
    yield put(ProfileActions.getConsentsRequest());
  }
}

function* getPendingTasksSagas(): any {
  yield takeLatest(PendingTasksActionsType.GET_PENDING_TASKS_REQUEST,
    runManager(getPendingTasks, PendingTasksActionsType.GET_PENDING_TASKS_FAILURE)
  );
}

export function* PendingTasksSagas() {
  yield all([
    fork(getPendingTasksSagas)
  ]);
}