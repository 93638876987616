import { createStackNavigator } from '@react-navigation/stack';
import React from 'react'; //

import { OtpSecurisationNavigatorInterface, OtpSecurisationNavigatorRouteNames } from '@navigation/Interfaces/OtpSecurisationInterface';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import {
  OtpSecurisationStartPage,
  OtpSecurisationFormPage
} from '@pages/index';

const Stack = createStackNavigator<OtpSecurisationNavigatorInterface>();

export const OtpSecurisation = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  return (
    <Stack.Navigator
      initialRouteName={OtpSecurisationNavigatorRouteNames.OtpSecurisationStart}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        cardStyle: {
          backgroundColor: 'transparent'
        }
      }}>
      <Stack.Screen
        component={OtpSecurisationStartPage}
        name={OtpSecurisationNavigatorRouteNames.OtpSecurisationStart}
        options={defaultScreensOptions.OtpSecurisationStart}
      />
      <Stack.Screen
        component={OtpSecurisationFormPage}
        name={OtpSecurisationNavigatorRouteNames.OtpSecurisationForm}
        options={defaultScreensOptions.OtpSecurisationForm}
      />
    </Stack.Navigator>
  );
};
