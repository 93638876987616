import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { palette } from '@ere-uilib/styles/theme/themeFolder/colors/palette';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      backgroundColor: theme.colors.basics.white,
    },
    contentContainerStyle: {
      alignSelf: isDesktop ? 'center' : 'auto',
      maxWidth: isDesktop ? theme?.metrics.contentSizes.centeredContentMaxWidth : "100%",
      paddingBottom: theme?.metrics.spacing.big,
    },
    renderContentContainerStyle: {
      marginTop: theme?.metrics.spacing.big
    },
    rowStyle: {
      flexDirection: 'row',
      marginBottom: theme?.metrics.spacing.m
    },
    iconStyle: {
      width: 20,
      height: 20,
      backgroundColor: palette.greenDark_1.c500,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.metrics.borderRadius.s,
      marginRight: theme?.metrics.spacing.s
    },
    mandatLinkStyle: {
      textDecorationLine: "none",

    },
    spacingCardStyle: {
      marginTop: theme?.metrics.spacing.m,
    },
    leftIconStyle: {
      fontSize: theme.metrics.iconSizes.m,
    },
    buttonStyle:
    {
      borderWidth: 1,
      alignSelf: 'flex-start',
    },
    contentEmptyStyle: {

      paddingTop: theme?.metrics.spacing.m,
      paddingBottom: theme?.metrics.spacing.m,
      paddingLeft: theme?.metrics.spacing.m
    },
    chevronStyle: {
      paddingLeft: theme.metrics.spacing.xs
    },
    contentFilledStyle: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'space-between',
      paddingTop: theme?.metrics.spacing.m,
      paddingBottom: theme?.metrics.spacing.m,
      paddingLeft: theme?.metrics.spacing.m,
      width: isDesktop ? undefined : '95%',
    },
    leftCard: {
      flex: 1,
      justifyContent: 'space-between',
      alignContent: 'space-between',

    },
    separator: {
      borderBottomWidth: 1,
      width: isDesktop ? "97%" : "90%",
      borderColor: theme.colors.basics.grey.c200,
      alignSelf: 'center'
    },
    firstLinesStyle: {
      textAlign: 'left',
      marginBottom: theme?.metrics.spacing.m
    },
    informLinkStyle: {
      textAlign: 'left',
      marginBottom: theme?.metrics.spacing.m,
      color: theme.colors.notifications.warning.c500
    },
    underFieldLinkStyle: {
      textAlign: 'left',
    },
    lastLinesStyle: { textAlign: 'left' },
    editButtonStyle: {
      width: theme?.metrics.spacing.xxxl,
      alignSelf: 'stretch',
      alignItems: 'center'
    },
    statusTag: {
      alignSelf: 'flex-start',
    },
    mandateModalContentWrapper: {
      alignItems: 'center'
    },
    mandateTextBlocWrapper: {
      marginTop: theme.metrics.spacing.m
    },
    mandateTextStyle: {
      marginVertical: theme.metrics.spacing.xs,
      textAlign: 'center'
    }
  }), [theme, isDesktop]);

  return styles;
}