import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import {
  Text,
  Title,
  InformativePopin,
  ActivableButtonSet
} from '@components/index';

import { useStyles } from './useStyles';

export interface FilePickerPopinDictionary {
  popinTitle: string;
  popinDescription: string;
  cameraTitle: string;
  cameraSubtitle?: string;
  galleryTitle: string;
  gallerySubtitle?: string;
  explorerTitle: string;
  explorerSubtitle?: string;
}

export interface FilePickerPopinProps {
  isModalVisible: boolean,
  onTakePhoto: () => void;
  onPickImage: () => void;
  onPickDocument: () => void;
  onClose: () => void;
  dictionary: FilePickerPopinDictionary;
}

export const FilePickerPopin: React.FC<FilePickerPopinProps> = ({
  isModalVisible,
  onClose,
  onTakePhoto,
  onPickImage,
  onPickDocument,
  dictionary
}) => {
  const styles = useStyles();
  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>();

  const handleHovered = (value: boolean, index: number) => {
    setHovered(value)
    setHoveredIndex(index)
  }

  const cardChoices = useMemo(() => [
    {
      action: onTakePhoto,
      title: dictionary.cameraTitle,
      subtitle: dictionary.cameraSubtitle
    },
    {
      action: onPickImage,
      title: dictionary.galleryTitle,
      subtitle: dictionary.gallerySubtitle
    },
    {
      action: onPickDocument,
      title: dictionary.explorerTitle,
      subtitle: dictionary.explorerSubtitle
    }
  ], [dictionary, onPickDocument, onPickImage, onTakePhoto])

  const renderCardChoices = useCallback(() =>
    <View style={styles.container}>
      {cardChoices.map(({ action, subtitle, title }, index) => {
        const isHoveredStyle = hovered && index === hoveredIndex ? styles.hoveredTextStyle : {}

        return (<>
          <ActivableButtonSet
            buttonStyle={styles.cardChoiceContainer}
            key={'popin-upload-' + index}
            onChange={action}
            hovered={index === hoveredIndex ? hovered : false}
            setHovered={(hoveredValue) => handleHovered(hoveredValue, index)}
          >
            <Title
              style={[styles.titleStyle, isHoveredStyle]}
              variant="t7">
              {title}
            </Title>
            {!!subtitle && (
              <Text
                variant="t3"
                weight="light"
                style={isHoveredStyle}>
                {subtitle}
              </Text>
            )}
          </ActivableButtonSet>
        </>)
      }
      )}
    </View>,
    [cardChoices, styles, hovered, hoveredIndex]
  );

  return (
    <InformativePopin
      content={renderCardChoices}
      description={dictionary.popinDescription}
      isDescriptionHtml={false}
      isModalVisible={isModalVisible}
      onClose={onClose}
      title={dictionary.popinTitle}
    />
  );
};
