import { connect } from 'react-redux';

import { CampaignActionsType } from '@modules/campaign/actions/campaignActionsTypes';
import {
  executeCallback,
  getUserCampaignRequest,
  submitUserCampaignRequest,
} from '@modules/campaign/actions/campaignActions';
import { AppState } from '@modules/reducers';
import { getCampaignData, getCampaignError, getIsCampaignSubmitted } from '@modules/campaign/selectors';

import {
  ConsentTransferCreditComponent
} from './ConsentTransferCredit.component';

const mapStateToProps = (state: AppState) => ({
  isFormSuccessfullySubmitted: !!getIsCampaignSubmitted(state),
  isErrorSubmit: !!getCampaignError(state)?.message,
  isGetUserCampaignLoading: state.isLoading[CampaignActionsType.GET_USER_CAMPAIGN_REQUEST],
  isSubmitLoading: state.isLoading[CampaignActionsType.SUBMIT_USER_CAMPAIGN_REQUEST],
  campaignData: getCampaignData(state)
});

const mapDispatchToProps = {
  getUserCampaign: getUserCampaignRequest,
  executeCallback: executeCallback,
  submitUserCampaign: submitUserCampaignRequest
};

export const ConsentTransferCreditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsentTransferCreditComponent);
 