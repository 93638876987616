import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { CardResponse, Paragraph, Select, useTranslation } from '@ere-uilib/index';
import { ItemState } from '@ere-uilib/molecules/inputs/Select/types';
import { useFormatPercentNumber } from '@pages/Annuity/useFormatPercentNumber';
import { formatSelectValue } from '@pages/Annuity/utils';

import { Reversion, SelectItem } from '../../AnnuitySimulator/sharedInterfaces';
import { AnnuityBirthDate } from '../AnnuityBirthDate';
import { AnnuityGender } from '../AnnuityGender';
import { useStyles } from './useStyles';

interface Props {
  value: Reversion | undefined;
  ratesList: number[];
  onSubmit: (data: Reversion | undefined) => void;
  validator?: (birthDate: Date | undefined) => boolean;
}

const initialReversion: Reversion = {
  rate: undefined,
  beneficiary: undefined
};

export const AnnuityReversion: React.FC<Props> = ({ value, onSubmit, validator, ratesList, ...props }) => {
  const styles = useStyles();
  const { formatMessage } = useTranslation();

  const [gender, setGender] = useState(value?.beneficiary?.gender);

  const [birthDate, setBirthDate] = useState(
    value?.beneficiary?.birthDate ? new Date(value.beneficiary.birthDate) : undefined
  );

  const formatPercentNumber = useFormatPercentNumber();

  const [rate, setRate] = useState<SelectItem<number> | undefined>(
    value?.rate ? formatSelectValue(value?.rate, formatPercentNumber) : undefined
  );

  const rateOptions = useMemo(
    () => ratesList.map(rateItem => formatSelectValue(rateItem, formatPercentNumber)),
    [formatPercentNumber, ratesList]
  );

  const isDisabled = !(gender && birthDate && rate);

  const handleSelectItem = useCallback((item: ItemState) => {
    setRate({
      value: Number(item.value),
      label: item.label,
    });
  }, []);

  const handleSubmit = useCallback(
    () =>
      onSubmit({
        ...initialReversion,
        rate: rate?.value,
        beneficiary: {
          ...initialReversion.beneficiary,
          gender,
          birthDate
        }
      }),
    [birthDate, gender, onSubmit, rate]
  );

  const renderNoReversion = useCallback(
    () => (
      <CardResponse
        buttonAction={() => onSubmit(initialReversion)}
        buttonTitle={formatMessage({
          id: 'PensionSimulatorStartSimulationContinueButton'
        })}
        containerStyle={styles.cardContainer}
        headerTitle={formatMessage({ id: 'PensionSimulatorCapitalReversionTitleNO' })}>
        <View>
          <Text variant="t3">
            {formatMessage({ id: 'PensionSimulatorCapitalReversionSubTitleNO' })}
          </Text>
        </View>
      </CardResponse>
    ),
    [formatMessage, onSubmit, styles]
  );

  const renderYesReversion = useCallback(
    () => (
      <CardResponse
        buttonAction={handleSubmit}
        buttonTitle={formatMessage({
          id: 'PensionSimulatorStartSimulationContinueButton'
        })}
        containerStyle={styles.cardContainer}
        headerTitle={formatMessage({ id: 'PensionSimulatorCapitalReversionTitleYES' })}
        isButtonDisabled={isDisabled}>
        <View>
          <Text variant="t3">
            {formatMessage({ id: 'PensionSimulatorCapitalReversionSubTitleYES' })}
          </Text>
        </View>
        <AnnuityGender
          contentStyle={styles.rowContainer}
          onChange={setGender}
          value={gender}
        />
        <View>
          <Text variant="t3">
            {formatMessage({ id: 'PensionSimulatorCapitalReversionBirthLegend' })}
          </Text>
        </View>
        <AnnuityBirthDate
          contentStyle={styles.rowContainer}
          onChange={setBirthDate}
          validator={validator}
          value={value?.beneficiary?.birthDate}
        />
        <View>
          <Text variant="t3">
            {formatMessage({ id: 'PensionSimulatorCapitalReversionReversionlegend' })}
          </Text>
        </View>
        <Select
          containerStyle={[styles.rowContainer, styles.rateSelect]}
          defaultTitle={formatMessage({
            id: 'PensionSimulatorCapitalReversionReversionRateChoice'
          })}
          items={rateOptions}
          onSelectItem={handleSelectItem}
          pickerTitle={formatMessage({ id: 'PensionSimulatorCapitalReversionReversionlegend' })}
          placeholder={formatMessage({
            id: 'PensionSimulatorCapitalReversionReversionRateChoice'
          })}
          selectedItem={rate}
          titleStyle={[styles.selectTitle, !rate && styles.selectPlaceholder]}
        />
        <View>
          <Paragraph
            variant="t3"
            weight="light">
            {formatMessage({ id: 'PensionSimulatorCapitalReversionReversionlegendExpl' })}
          </Paragraph>
        </View>
      </CardResponse>
    ),
    [handleSubmit, formatMessage, styles, isDisabled, gender, validator, value?.beneficiary?.birthDate, rateOptions, handleSelectItem, rate]
  );

  return (
    <View style={styles.container}>
      {renderNoReversion()}
      {renderYesReversion()}
    </View>
  );
};
