import { connect } from 'react-redux';

import { ValidateIdentityActionsType } from '@modules/validate-identity/actions/validateIdentityActionsTypes';
import { validateEERPhoneNewIdentifierRequest } from '@modules/validate-identity/actions/validateIdentityActions';
import { AppState } from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';
import { getIdentityValidationModuleError } from '@modules/validate-identity/selectors';

import { ChooseIdentificationPhonePage as ChooseIdentificationPhonePageComponent } from './ChooseIdentificationPhonePage.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state),
    validateIdentityError: getIdentityValidationModuleError(state),
    isApiLoading: state.isLoading[ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_REQUEST],
  };
};

const mapDispatchToProps = ({
  validateEERPhoneNewIdentifier: validateEERPhoneNewIdentifierRequest,
});
export const ChooseIdentificationPhonePage =  connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChooseIdentificationPhonePageComponent);
