import React, { useEffect, useState } from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import {
  CheckBox,
  Modal,
  ModalFrame, SimpleButton, SVGLocalLoader, Text, Title
} from '@components/index';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { locator } from '@constants/locator';

interface ModalConsentValidationStyle {
  modalContainer?: StyleProp<ViewStyle>
  iconContainerStyle?: StyleProp<ViewStyle>
  checkboxContainerStyle?: StyleProp<ViewStyle>
  checkBoxTextStyle?: StyleProp<TextStyle>
  titleStyle?: StyleProp<TextStyle>
  buttonContainerStyle?: StyleProp<ViewStyle>
}

interface ModalConsentValidationProps extends ModalConsentValidationStyle {
  modalIsVisible: boolean
  onModalClose: ()=> void
  onModalValidation: ()=> void
}

interface ModalConsentValidationContext {
  theme: ThemeType
}

export const ModalConsentValidation: React.FC<ModalConsentValidationProps> = ({
  modalIsVisible,
  onModalClose,
  onModalValidation,
  modalContainer,
}) => {

  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const getStyles = (
    context?: ModalConsentValidationContext,
    style?: ModalConsentValidationStyle,
  ): ModalConsentValidationStyle => ({
    modalContainer: [
      {
        flexDirection: 'column',
        alignItems: 'center',
      },
      style?.modalContainer,
    ],
    iconContainerStyle: [
      {
        height: context?.theme.metrics.iconSizes.xxxl,
        width: context?.theme.metrics.iconSizes.xxxl,
      },
      style?.iconContainerStyle,
    ],
    checkboxContainerStyle: [
      {
        marginBottom: context?.theme.metrics.spacing.l,
      },
      style?.checkboxContainerStyle,
    ],
    titleStyle: [
      {
        marginTop: context?.theme.metrics.spacing.xm,
        marginBottom: context?.theme.metrics.spacing.s,
      },
      style?.titleStyle,
    ],
    checkBoxTextStyle: [
      {
        marginLeft: context?.theme.metrics.spacing.s,
      },
      style?.checkBoxTextStyle,
    ],
    buttonContainerStyle: [
      { width: '100%' },
      style?.buttonContainerStyle,
    ],
  });
  
  const useStyles = createUseStyles(getStyles);  
  const styles = useStyles({
    theme,
  }, {
    modalContainer,
  });

  function resetCheckboxesValues() {
    if (modalIsChecked === true) {
      setModalIsChecked(false);
    }
  }

  const [modalIsChecked, setModalIsChecked] = useState<boolean>(false);
  useEffect(() => {
    if (!modalIsVisible) {
      resetCheckboxesValues();
    }
  }, [modalIsVisible]);

  return (
    <Modal visible={modalIsVisible}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom"
        onClose={onModalClose}
      >
        <View style={styles?.modalContainer} >
          <View style={styles.iconContainerStyle}>
            <SVGLocalLoader
              name="consent"
            />
          </View>
          <Title
            style={styles?.titleStyle}
            variant="t6"
          >
            {formatMessage({ id: 'PIDeclarationHonneurTitre' })}
          </Title>
          <CheckBox
            containerStyle={styles?.checkboxContainerStyle}
            onPress={()=> {
              setModalIsChecked(!modalIsChecked);
            }}
            value={modalIsChecked}
            testId={locator._pi._checkbox}
          >
            <Text
              style={styles?.checkBoxTextStyle}
              variant="t3"
              weight="light"
            >
              {formatMessage({ id: 'PIDeclarationHonneurDescription' })}
            </Text>
          </CheckBox>
          <SimpleButton
            containerStyle={styles?.buttonContainerStyle}
            disabled={!modalIsChecked}
            onPress={onModalValidation}
            title={formatMessage({ id: 'PIDeclarationHonneurBouton' })}
            testId={locator._pi._accept_consent}
          />
        </View>
      </ModalFrame>
    </Modal>
  );};