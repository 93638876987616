import { AppRegistry } from 'react-native';

import { name as appName } from './app.json';
import '@ere-uilib/styles/globalUILibStyles.css';
import './styles/globalStyles.css';

import App from './App';

AppRegistry.registerComponent(appName, () => App);
if (process.env.NODE_ENV !== 'test') {
  AppRegistry.runApplication(appName, {
    rootTag: document.getElementById('root')
  });
}
