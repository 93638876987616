import React from 'react';
import { TouchableOpacity, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { FontIcon, Text } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';
import { ParagraphSizeTypes } from '@ere-uilib/styles/theme/themeFolder';

interface Props {
  index: number;
  selected?: boolean;

  buttonInformations: {icon: string, title: string};
  onIconButtonPressed?: (index: any) => void;
  titleVariant?: keyof ParagraphSizeTypes
  iconButtonStyle?: StyleProp<ViewStyle>;
  iconButtonTitleStyle?: StyleProp<ViewStyle>;
  iconColor?: string
}

type DesktopMenuIconButtonStyles = {
  iconButtonStyle?: StyleProp<ViewStyle>;
  iconButtonTitleStyle?: StyleProp<TextStyle | ViewStyle>;
};

type StylesContext = {theme: ThemeType; index: number; selected?: boolean};

export const IconButton: React.FC<Props> = ({
  index,
  selected,
  buttonInformations,
  onIconButtonPressed,
  iconColor,
  titleVariant = 't2',
  iconButtonTitleStyle,
  iconButtonStyle
}) => {
  const theme = useTheme();
  const styles = useStyles(
    { theme, selected, index },
    { iconButtonStyle, iconButtonTitleStyle },
  );

  return (
    <TouchableOpacity
      key={index}
      onPress={() => onIconButtonPressed && onIconButtonPressed(index)}
      style={styles.iconButtonStyle}>
      <FontIcon
        color={iconColor}
        name={buttonInformations.icon}
        size={theme.metrics.iconSizes.m}
      />
      <Text
        style={styles.iconButtonTitleStyle}
        variant={titleVariant}>
        {buttonInformations.title}
      </Text>
    </TouchableOpacity>
  );
};

export const getStyles = (
  context?: StylesContext,
  style?: DesktopMenuIconButtonStyles,
): DesktopMenuIconButtonStyles => ({
  iconButtonStyle: [
    {
      alignItems: 'center',
      justifyContent: 'center'

    },
    context?.selected === context?.index
      ? { backgroundColor: context?.theme.colors.buttons.classic.c500 }
      : null,
    style?.iconButtonStyle
  ],
  iconButtonTitleStyle: [{}, style?.iconButtonTitleStyle]
});

const useStyles = createUseStyles(getStyles);
