import * as qs from 'qs'
import React from 'react';
import { GestureResponderEvent } from 'react-native'

import {
  HtmlStyledRenderer
} from '@components/index';
import { InformativePopin } from '@ere-uilib/organisms/overlay/InformativePopin';
import { useTranslation } from '@ere-uilib/translations';
import { isDownloadLink } from '@modules/utils/ocdMethods';
import { useUrlLink } from '@utils/useUrlLink';

import { Props } from './interface'

export const HtmlStyledRenderConnectedComponent: React.FC<Props> = ({
  html,
  baseFontStyle,
  onGetDocumentDownloadFile,
  isDocumentDonwloading,
}) => {
  const { formatMessage } = useTranslation();
  const { linkToUrl } = useUrlLink();

  const handleLinkPress = (e: GestureResponderEvent, href: string) => {
    if (isDownloadLink(href)) {
      const documentType = (qs.parse(href)['/telechargement?documentType']) as string
      return onGetDocumentDownloadFile(documentType, 'Document')
    }

    return linkToUrl(href);
  }

  return (
    <>
      <InformativePopin
        description={formatMessage({ id: 'DocCenterDownloadMessage' })}
        iconName="idea"
        isLoading={isDocumentDonwloading}
        isModalVisible={isDocumentDonwloading}
      />
      <HtmlStyledRenderer
        baseFontStyle={baseFontStyle}
        html={html}
        onLinkPress={handleLinkPress} />
    </>
  );
}
