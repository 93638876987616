import React from 'react';
import { View } from 'react-native';

import { CheckBox, Text, RoundedIcon } from '@components/index';
import { RoundedContainer, SVGLocalLoader, Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';




import {
  FundSelectProps,
  FundSelectContext,
  FundSelectStyles
} from './interfaces';

import {
  FundSourceEnum
} from '@modules/funds/types';

export const FundSelect: React.FC<FundSelectProps> = ({
  fundName,
  fundIsing,
  isSelected = false,
  isGreenLabel = false,
  hasContribution = false,
  hasAlreadyInvested = false,
  setFundsSelected,
  isDisabled = false,
  containerStyle,
  firstRowStyle,
  source,
}) => {
  const { isMobile } = useScreenSizes();
  const theme = useTheme();
  const style = useStyles(
    { theme, isMobile, isDisabled, hasContribution },
    { containerStyle, firstRowStyle }
  );
  const { formatMessage } = useTranslation();

  return (
    <View style={style.containerStyle}>
      <View style={style.firstRowStyle}>
        <CheckBox
          disabled={isDisabled}
          onPress={() => setFundsSelected(fundIsing)}
          value={isSelected}
        />
        <View style={style.firstColomunStyle}>
        <Text style={style.fundNameStyle}>{fundName}</Text>
        {source === FundSourceEnum.UNKNOWN && <Title
            variant="t7"
            weight="light"
            style={style.commonLabelTextStyle}>
            {formatMessage({ id: 'Fundsheet_Tab3Perf_Performance_nodata_label' })}
          </Title>}
        </View>
          </View>
      <View style={style.fundKeysContainer}>
        <View style={style.fundKeysIcons}>
          <View style={style.greenLabelIcon}>
            {isGreenLabel &&
            <RoundedContainer containerStyle={style.greenLabelContainerIcon} >
              <SVGLocalLoader
                maxHeight={theme.metrics.spacing.l}
                maxWidth={theme.metrics.spacing.l}
                name={'greenLabel'}
              />
            </RoundedContainer>
            }
          </View>
          {hasContribution && (
            <View style={style.contributionIcon}>
              <RoundedIcon
                containerBackground={theme.colors.basics.grey.c100}
                containerSize={25}
                containerStyle={style.contributionContainerIcon}
                iconColor={
                  isDisabled
                    ? theme.colors.basics.grey.c400
                    : theme.colors.buttons.classic.c500
                }
                iconName={IconEnum.CONTRIBUTION}
                iconSize={theme.metrics.spacing.m}
              />
            </View>
          )}
        </View>
        {hasAlreadyInvested && (
          <View style={style.alreadyInvestedContainer}>
            <Text
              style={style.alreadyInvestedText}
              weight="light">
              {formatMessage({ id: 'MyFunds_Screen1_Invested_label' })}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

const getStyles = (
  context?: FundSelectContext,
  style?: FundSelectStyles
): FundSelectStyles => {
  return {
    containerStyle: [
      {
        flexDirection: 'row',
        borderBottomColor: context?.theme.colors.basics.grey.c200,
        borderBottomWidth: 1,
        minHeight: context?.isMobile
          ? context?.theme.metrics.spacing.xxxbig
          : context?.theme.metrics.spacing.xxbig
      },
      style?.containerStyle
    ],
    firstRowStyle: [
      {
        flexDirection: 'row',
        marginTop: context?.theme.metrics.spacing.xxm,
        alignItems: 'flex-start',
        flex: 1
      },
      style?.firstRowStyle
    ],
    firstColomunStyle: [
      {
        flexDirection: 'column',
        flex: 1
      },
      style?.firstColomunStyle
    ],
    fundNameStyle: [
      {
        marginLeft: context?.theme.metrics.spacing.xs,
        marginTop: 2,
        color: context?.isDisabled
          ? context?.theme.colors.basics.grey.c400
          : context?.theme.colors.basics.grey.c900
      },
      style?.fundNameStyle
    ],
    fundKeysContainer: [
      {
        marginTop: context?.theme.metrics.spacing.xm,
        marginBottom: context?.theme.metrics.spacing.xm
      },
      style?.fundKeysContainer
    ],
    fundKeysIcons: [
      {
        flexDirection: 'row',
        justifyContent: 'flex-end'
      },
      style?.fundKeysIcons
    ],
    greenLabelIcon: [
      {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: context?.hasContribution ?  context?.theme.metrics.spacing.xs : 0
      },
      style?.greenLabelIcon
    ],
    greenLabelContainerIcon: [
      {
        alignItems: 'center',
        backgroundColor: context?.theme.colors.basics.white
      },
      style?.greenLabelIcon
    ],
    contributionIcon: [
      {
        flexDirection: 'row',
        minWidth: context?.theme.metrics.spacing.xxm,
        alignItems: 'center'
      },
      style?.contributionIcon
    ],
    contributionContainerIcon: [
      { alignItems: 'center' },
      style?.contributionContainerIcon
    ],
    alreadyInvestedContainer: [
      {
        marginTop: context?.theme.metrics.spacing.xs,
        minHeight: 17,
        flexDirection: 'row',
        alignItems: 'center'
      },
      style?.alreadyInvestedContainer
    ],
    alreadyInvestedText: [
      {
        color: context?.isDisabled
          ? context?.theme.colors.basics.grey.c400
          : context?.theme.colors.basics.grey.c900
      },
      style?.alreadyInvestedText
    ],
    commonLabelTextStyle: [
      {
        marginLeft: context?.theme.metrics.spacing.xs,
        color: context?.theme.colors.basics.grey.c500,
      },
      style?.alreadyInvestedText
    ],
  };
};

const useStyles = createUseStyles(getStyles);
