import { useNavigation } from '@react-navigation/native'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RouteNames } from '@constants/navigation'
import { getEDocumentsSubscriptionRequest } from '@modules/document-space/actions/documentsActions'
import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes'
import { isSubscribedToEDocuments } from '@modules/document-space/selectors'
import { AppState } from '@modules/reducers'

export const useController = () => {
  const navigation = useNavigation()
  const [willUnsubscribe, setWillUnsubscribe] = useState<boolean>(false)

  const dispatch = useDispatch()

  const isSubscriber = useSelector(isSubscribedToEDocuments)

  const isLoading = useSelector(
    (state: AppState) => state.isLoading[DocumentsActionsType.GET_EDOCUMENTS_SUBSCRIPTION_REQUEST]
  )

  const setIsSubscriber = useCallback(
    (isSubscriber: boolean) => dispatch(getEDocumentsSubscriptionRequest(isSubscriber)),
    [dispatch]
  )

  const goToEDocumentsFiles = useCallback(() => {
    navigation.navigate(RouteNames.DocumentSpaceStack, {
      screen: RouteNames.DocumentSpaceEDocumentsFiles
    })
  }, [navigation])

  const handleToggle = useCallback(
    (withConfirmation = true) =>
      () => {
        if (isSubscriber && withConfirmation) return setWillUnsubscribe(prev => !prev)
        setIsSubscriber(!isSubscriber)
      },
    [isSubscriber, setIsSubscriber]
  )

  useEffect(() => {
    setWillUnsubscribe(false)
  }, [isSubscriber])

  return {
    handleToggle,
    goToEDocumentsFiles,
    willUnsubscribe,
    isSubscriber: isSubscriber && !willUnsubscribe,
    isLoading
  }
}
