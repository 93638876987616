import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes';

export enum DocumentSpaceNavigatorRouteNames {
  DocumentSpaceHome = 'DocumentSpaceHome',
  DocumentSpaceEDocumentsFiles = 'DocumentSpaceEDocumentsFiles',
  DocumentSpaceEDocumentsSubscription = 'DocumentSpaceEDocumentsSubscription',
  DocumentSpaceGeneralDocuments = 'DocumentSpaceGeneralDocuments',
  DocumentSpaceGeneralDocumentsFiles = 'DocumentSpaceGeneralDocumentsFiles',
  DocumentSpaceViewer = 'DocumentSpaceViewer'
}

export type DocumentSpaceNavigatorInterface = {
  [DocumentSpaceNavigatorRouteNames.DocumentSpaceHome]: undefined;
  [DocumentSpaceNavigatorRouteNames.DocumentSpaceEDocumentsFiles]: undefined;
  [DocumentSpaceNavigatorRouteNames.DocumentSpaceEDocumentsSubscription]: undefined;
  [DocumentSpaceNavigatorRouteNames.DocumentSpaceGeneralDocuments]: undefined;
  [DocumentSpaceNavigatorRouteNames.DocumentSpaceGeneralDocumentsFiles]: undefined;
  [DocumentSpaceNavigatorRouteNames.DocumentSpaceViewer]: {
    base64PDF: string;
    documentTitle: string;
  };
};
