import { connect } from 'react-redux';

import { getSavingsPlans } from '@modules/dashboard/selectors';
import { AppState } from '@modules/reducers';
import { getRefundDemandStatus, getRefundRepartitionData } from '@modules/refund/available-refund/selectors';
import { ClearCacheAfterOperationDone } from '@modules/savings/actions/savingsActions';

import { RefundSuccessPage as RefundSuccessPageComponent } from './RefundSuccessPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    repartitions: getRefundRepartitionData(state),
    savingsPlans: getSavingsPlans(state),
    refundDemandStatus: getRefundDemandStatus(state),
  };
};

const mapDispatchToProps = ({
  onClearCacheAfterOperationDone: ClearCacheAfterOperationDone,
});

export const RefundSuccessPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundSuccessPageComponent);
