import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    bankAccountContainer: {
      marginLeft: theme.metrics.spacing.s,
      marginTop: theme.metrics.spacing.s,
      marginBottom: theme.metrics.spacing.xm,
    },
    paymentDetailContainer: {
      marginTop: theme.metrics.spacing.s,
    },
    notificationHard: {
      alignSelf: 'stretch'
    }
  }), [theme]);

  return styles;
}
