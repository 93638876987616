import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'

import { FundSheetModal, SavingRepartitionCard, Title } from '@components/index'
import { SupportType } from '@modules/common/types'
import { AppNavigatorInterface } from '@navigation/Interfaces'
import { useController } from './useController'
import { DueDateProps } from './components/DueDateCard/interface'
import { useStyles } from './useStyles'
import { View } from 'react-native'

export interface RenderSupportProps {
  support: SupportType
  navigation: StackNavigationProp<AppNavigatorInterface>
  isSupportSelected?: boolean
  isFieldAmountPercentage?: boolean
  percentage?: number
  supportAmount?: number
  isInputEditable?: boolean
  isSupportSelectable?: boolean
  linkUnderfieldLabel?: string
  isDisabledSupport?: boolean
  shouldDisplayRiskLevel?: boolean
  shouldDisplayAddedValue?: boolean
  IsTriggerThreshold?: boolean
  isPercentageField?: boolean
  onLinkUnderFieldPress?: () => void
  onLinkUnderFieldEchenacePress?: (item: DueDateProps) => void
  onSelectChange?: () => void
  onEditAmountPress?: () => void
  onTriggerThresholdDelete?: () => void
  onTriggerThresholdEditPress?: () => void
  ontriggerDueDateEditPress?: (item: DueDateProps) => void
  checkboxTestId?: string
  supportIndexTestId?: string
}

export const Support: React.FC<RenderSupportProps> = ({
  support,
  navigation,
  onSelectChange = () => {},
  onLinkUnderFieldPress = () => {},
  onEditAmountPress = () => {},
  onTriggerThresholdDelete,
  onTriggerThresholdEditPress,
  ontriggerDueDateEditPress,
  onLinkUnderFieldEchenacePress,
  supportAmount,
  isSupportSelected,
  isSupportSelectable,
  isDisabledSupport,
  isInputEditable,
  isFieldAmountPercentage,
  percentage,
  linkUnderfieldLabel,
  shouldDisplayRiskLevel = true,
  shouldDisplayAddedValue = true,
  IsTriggerThreshold = false,
  isPercentageField = false,
  checkboxTestId,
  supportIndexTestId
}) => {
  const {
    savingRepartitionCardProps,
    fundSheetModalData,
    displayFundSheetModal,
    handleModalClose,
    renderSavingCardChildren,
    renderEcheanceCard,
    showAvailabilities
  } = useController({
    support,
    supportAmount,
    isSupportSelected,
    isSupportSelectable,
    isDisabledSupport,
    isInputEditable,
    isFieldAmountPercentage,
    percentage,
    linkUnderfieldLabel,
    shouldDisplayRiskLevel,
    shouldDisplayAddedValue,
    IsTriggerThreshold,
    isPercentageField,
    onSelectChange,
    onLinkUnderFieldPress,
    onEditAmountPress,
    onTriggerThresholdDelete,
    onTriggerThresholdEditPress,
    ontriggerDueDateEditPress,
    onLinkUnderFieldEchenacePress
  })

  const styles = useStyles(showAvailabilities, savingRepartitionCardProps?.disabled)

  return (
    <>
      <View style={styles.container}>
        <SavingRepartitionCard
          checkboxTestId={checkboxTestId}
          testId={supportIndexTestId}
          isStyleAlreadyHandled
          {...savingRepartitionCardProps}>
          {renderSavingCardChildren()}
        </SavingRepartitionCard>
        {renderEcheanceCard()}
      </View>

      {fundSheetModalData && (
        <FundSheetModal
          id={fundSheetModalData?.id}
          modalIsVisible={displayFundSheetModal}
          navigation={navigation}
          onCloseModal={handleModalClose}
          title={fundSheetModalData?.title}
        />
      )}
    </>
  )
}
