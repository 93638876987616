import { useNavigation } from '@react-navigation/native';
import React, { useState, useCallback, useMemo } from 'react';
import { View } from 'react-native'

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  useTranslation,
  Title,
  Paragraph,
  BackAndConfirmButtons,
  useScreenSizes,
  NotificationHard,
  NotificationIconTypeEnum,
  FieldNotification,
  IconEnum,
  DashedLine,
  Link,
  FontIcon,
  HtmlStyledRenderConnectedContainer
} from '@components/index'
import {
  ProfitSharingIncentivePaymentModeTypeEnum,
  ProfitSharingIncentiveAllowedAffectationTypeEnum
} from '@constants/index'
import { RouteNames } from '@constants/navigation';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { useTheme } from '@ere-uilib/styles';
import { ErrorItemState } from "@modules/error/types";
import { ProfitSharingIncentiveAssigmentAmountsType } from '@modules/profit-sharing-incentive/types'
import { ApplicationSettingsState } from '@modules/settings/types';
import { constructStepperItems } from '@pages/Common/utils';
import { useFormatHrefHtml } from '@utils/index';

import { ContributionNotification, ProfitSharingIncentiveHeader } from '../components';
import { AssigmentSelection, FiscalityPercentModal } from './components'
import { useStyles } from './useStyles'
import { locator } from '@constants/locator';
interface ProfitSharingIncentiveAssigmentPageProps {
  applicationSettings: ApplicationSettingsState
  isSubmitLoading: boolean
  submitError?: ErrorItemState
  paymentMode?: ProfitSharingIncentivePaymentModeTypeEnum
  possibleChoices?: ProfitSharingIncentiveAllowedAffectationTypeEnum[]
  availableAmount: number
  minimumReinvestAmount: number
  hasContribution: boolean
  onSubmitAmountsRequest({
    reinvestAmount,
    refundAmount,
  }: ProfitSharingIncentiveAssigmentAmountsType): void
  onSubmitAllRefundAmounts(params: ProfitSharingIncentiveAssigmentAmountsType): void
}

export const ProfitSharingIncentiveAssigmentPage: React.FC<ProfitSharingIncentiveAssigmentPageProps> = ({
  applicationSettings,
  isSubmitLoading,
  submitError,
  paymentMode,
  possibleChoices,
  availableAmount,
  minimumReinvestAmount,
  hasContribution,
  onSubmitAmountsRequest,
  onSubmitAllRefundAmounts
}) => {

  const navigation = useNavigation()
  const theme = useTheme()
  const { isDesktop } = useScreenSizes()
  const styles = useStyles()
  const {
    formatMessage,
    getMessageRaw,
    formatCurrencyNumber
  } = useTranslation()

  const calculFiscalityAmount = useCallback((fiscalityPercent, refundAmount) => {
    return Math.round(fiscalityPercent * refundAmount) / 100
  }, [])

  const isRefund = useMemo(() => {
    return !!possibleChoices && possibleChoices.includes(ProfitSharingIncentiveAllowedAffectationTypeEnum.REFUND)
  }, [possibleChoices])

  const isReinvest = useMemo(() => {
    return !!possibleChoices && possibleChoices.includes(ProfitSharingIncentiveAllowedAffectationTypeEnum.REINVEST)
  }, [possibleChoices])

  const initialAmounts: ProfitSharingIncentiveAssigmentAmountsType = useMemo(() => {
    const PITauxImpot = applicationSettings.PITauxImpot ? Number(applicationSettings.PITauxImpot) : 0.14
    const PITauxImpotPercentage = Math.round((PITauxImpot * 100) * 100) / 100

    if (isRefund && !isReinvest) {
      return {
        reinvestAmount: 0,
        refundAmount: availableAmount,
        fiscalityAmount: calculFiscalityAmount(PITauxImpotPercentage, availableAmount),
        fiscalityPercent: PITauxImpotPercentage
      }
    }
    return {
      reinvestAmount: availableAmount,
      refundAmount: 0,
      fiscalityAmount: 0,
      fiscalityPercent: PITauxImpotPercentage
    }
  }, [
    availableAmount,
    applicationSettings,
    calculFiscalityAmount,
    isRefund,
    isReinvest
  ])
  const [amounts, setAmounts] = useState<ProfitSharingIncentiveAssigmentAmountsType>(initialAmounts)
  const [isFiscalityPercentModalVisible, setIsFiscalityPercentModalVisible] = useState(false)

  const stepperItemsHeader = constructStepperItems({
    size: 3,
    direction: DirectionEnum.ROW,
    activeStep: 1,
  });

  const handleAssigmentChange = useCallback((assigmentAmounts: {
    reinvestAmount: number
    refundAmount: number
  }) => {
    const fiscalityAmount = calculFiscalityAmount(amounts.fiscalityPercent, assigmentAmounts.refundAmount)
    const newAmounts: ProfitSharingIncentiveAssigmentAmountsType = {
      ...assigmentAmounts,
      fiscalityPercent: amounts.fiscalityPercent,
      fiscalityAmount: fiscalityAmount,
    }
    setAmounts(newAmounts)
  }, [
    setAmounts,
    amounts.fiscalityPercent,
    calculFiscalityAmount
  ]);

  const handleFiscalityPercentChange = useCallback((newFiscalityPercent: number) => {

    const fiscalityAmount = Math.round(newFiscalityPercent * amounts.refundAmount) / 100
    const newAmounts: ProfitSharingIncentiveAssigmentAmountsType = {
      ...amounts,
      fiscalityAmount: fiscalityAmount,
      fiscalityPercent: newFiscalityPercent
    }
    setAmounts(newAmounts)
  }, [setAmounts, amounts]);

  const handleOnBackPress = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const handleNavigationToIban = useCallback(() => navigation.navigate(
    RouteNames.OperationTypeModalPage,
    { type: 'IBAN' }
  ), [navigation])

  const handleOnSubmitPress = useCallback(() => {
    const isAllRefund = amounts.reinvestAmount === 0
    if (isAllRefund) {
      onSubmitAllRefundAmounts(amounts)
      navigation.navigate(RouteNames.ProfitSharingIncentive, {
        screen: RouteNames.ProfitSharingIncentiveSynthesis
      })
    } else {
      onSubmitAmountsRequest(amounts)
    }
  }, [
    onSubmitAmountsRequest,
    onSubmitAllRefundAmounts,
    amounts,
    navigation
  ])

  const renderActions = useCallback(() => {
    const isSubmitDisable = amounts.reinvestAmount < minimumReinvestAmount
    return (
      <BackAndConfirmButtons
        confirmButtonDisabled={isSubmitDisable}
        confirmButtonLabel={formatMessage({ id: 'PIDefinitionBesoinContinuerBouton' })}
        containerStyle={styles.bottomButtons}
        isLoading={isSubmitLoading}
        onBack={handleOnBackPress}
        onValidate={handleOnSubmitPress}
        testId={locator._pi._continue}
      />
    )
  }, [
    styles,
    isSubmitLoading,
    handleOnSubmitPress,
    handleOnBackPress,
    formatMessage,
    minimumReinvestAmount,
    amounts.reinvestAmount
  ])

  const renderHeader = useCallback(() => (
    <ProfitSharingIncentiveHeader
      displayCloseButton
      helpPoint
      helpPointContentHtml={getMessageRaw({
        id: 'PIDefinitionBesoinAideDescription',
      })}
      helpPointModalTitle={formatMessage({
        id: 'PIDefinitionBesoinAideTitre',
      })}
      helpPointPosition="left"
      stepperItems={stepperItemsHeader}
      title={formatMessage({ id: 'PIDefinitionBesoinTitre' })}
    />
  ), [
    formatMessage,
    getMessageRaw,
    stepperItemsHeader
  ]);

  const renderPaymentModeInfo = useCallback(() => {
    let paymentModeDescription: string | undefined = undefined
    let paymentModeLinkText: string | undefined = undefined
    switch (paymentMode) {
      case ProfitSharingIncentivePaymentModeTypeEnum.CHEQUE:
        paymentModeDescription = formatMessage({ id: 'PIDefinitionBesoinCardRIBManquantDescription' })
        paymentModeLinkText = formatMessage({ id: 'PIDefinitionBesoinCardRIBRenseignerRIBLien' })
        break;
      case ProfitSharingIncentivePaymentModeTypeEnum.COMPANY:
        paymentModeDescription = formatMessage({ id: 'PIDefinitionBesoinCardEntreprisePayeuseDescription' })
        break;
      case ProfitSharingIncentivePaymentModeTypeEnum.RIB:
        paymentModeDescription = formatMessage({ id: 'PIDefinitionBesoinCardRIBDisponibleDescription' })
        paymentModeLinkText = formatMessage({ id: 'PIDefinitionBesoinCardRIBDisponibleRIBLien' })
        break;
      default:
        break;
    }

    return (
      <View>
        <Paragraph
          variant='t3'
          weight='light'
        >
          {paymentModeDescription}
        </Paragraph>
        {paymentModeLinkText && (
          <HtmlStyledRenderConnectedContainer
            baseFontStyle={{ fontFamily: theme.fonts.fontFamily.light }}
            html={useFormatHrefHtml(paymentModeLinkText)} />
        )}
      </View>
    )
  }, [
    paymentMode,
    styles,
    formatMessage,
    handleNavigationToIban
  ])

  const renderFiscalityNotification = useCallback(() => {
    if (amounts.refundAmount <= 0) { return null }
    return (
      <NotificationHard
        containerStyle={styles.notifications}
        customIconName={IconEnum.FISCALITE}
        renderBottomContent={<View>
          <DashedLine
            axis={'horizontal'}
            dashColor={theme.colors.basics.grey.c200}
            dashGap={6}
            dashLength={6}
            dashStyle={{ borderRadius: 5 }}
            dashThickness={1}
            style={styles.paymentModeDashLine}
          />
          {renderPaymentModeInfo()}
        </View>}
        type={NotificationIconTypeEnum.INFO}
      >
        <Paragraph
          variant='t3'
          weight='light'
        >
          {formatMessage({ id: 'PIDefinitionBesoinCardImpotDescription' })}
        </Paragraph>
        <View
          style={styles.fiscalityRatecontainer}
        >
          <Paragraph
            style={styles.fiscalityRateLabel}
            variant='t3'
          >
            {formatMessage({ id: 'PIDefinitionBesoinCardImpotMontantLabel', values: { taux: amounts.fiscalityPercent + '%' } })}
            {' '}
            <Link onPress={() => { setIsFiscalityPercentModalVisible(true) }}>
              {formatMessage({ id: 'PIDefinitionBesoinCardImpotModiferLien' })}
            </Link>
          </Paragraph>
          <Paragraph
            variant='t3'
          >
            {formatCurrencyNumber({ value: amounts.fiscalityAmount })}
          </Paragraph>
        </View>
      </NotificationHard>
    )
  }, [
    theme,
    styles,
    amounts,
    formatMessage,
    formatCurrencyNumber,
    renderPaymentModeInfo
  ])

  const renderMinimumNotification = useCallback(() => {
    if (!minimumReinvestAmount || minimumReinvestAmount === 0) { return }
    const valueText = formatCurrencyNumber({ value: minimumReinvestAmount })
    const text = `${formatMessage({ id: 'PIChoixInvestMontantMiniDescription' })} ${valueText}`
    return (
      <FieldNotification
        containerStyle={styles.minimumNotification}
        text={text}
        type='warning'
      />
    )
  }, [
    formatMessage,
    formatCurrencyNumber,
    minimumReinvestAmount,
    styles
  ])

  return (
    <DashboardPageWrapperConnected
      renderStickyMobileBottom={renderActions}
    >
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}
      >
        <Title
          style={styles.title}
          variant='t5s'
          weight='bold'
        >
          {formatMessage({ id: 'PIDefinitionBesoinChoixDescription' })}
        </Title>
        <Paragraph
          style={styles.description}
          variant='t3'
          weight='light'
        >
          {formatMessage({ id: 'PIDefinitionBesoinChoixInvestirPercevoirDescription' })}
        </Paragraph>
        {renderMinimumNotification()}
        <AssigmentSelection
          fullAmount={availableAmount}
          initialReinvestAmount={amounts.reinvestAmount}
          isRefund={isRefund}
          isReinvest={isReinvest}
          minimumReinvestAmount={minimumReinvestAmount}
          onChange={handleAssigmentChange}
          style={styles.assigmentSelectorStyle}
        />
        <ContributionNotification />
        {renderFiscalityNotification()}
        {submitError?.message && (
          <NotificationHard
            containerStyle={styles.notifications}
            text={submitError.message}
            type={NotificationIconTypeEnum.WARNING}
          />
        )}
        {!!hasContribution && <View style={styles.rowStyle}>
          <FontIcon
            color={theme.colors.basics.black}
            name={IconEnum.CONTRIBUTION}
            size={theme.metrics.iconSizes.l}
          />
          <Paragraph
            variant='t3'
            weight='light'
          >
            {formatMessage({ id: 'PIDefinitionBesoinAbondementContenu' })}
          </Paragraph>
        </View>}
        {isDesktop && renderActions()}
        <FiscalityPercentModal
          initAmount={amounts.fiscalityPercent}
          isVisible={isFiscalityPercentModalVisible}
          onClosePress={() => setIsFiscalityPercentModalVisible(false)}
          onSubmit={handleFiscalityPercentChange}
        />
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
