import { RouteProp, useRoute } from '@react-navigation/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer, DashboardPageWrapperConnected, Link, NotificationHard,
  NotificationIconTypeEnum, RetryActionPlaceHolder, SimpleButton, Spinner, Text, useScreenSizes, useTheme, useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';
import { ProfitSharingIncentiveTypeEnum } from '@constants/ProfitSharingIncentive';
import {
  ProfitSharingIncentiveNavigatorInterface,
  ProfitSharingIncentiveNavigatorRouteNames
} from '@navigation/Interfaces';

import { ProfitSharingIncentiveHeader } from '../components';
import { useProfitSharingIncentiveTypeAndFrameValues } from '../utils';
import { PrimeAmountCard } from './components';
import { ProfitSharingIncentiveDescriptionPageProps } from './interface';
import { getStyles } from './styles';
import { locator } from '@constants/locator';

const useStyles = createUseStyles(getStyles);

export const ProfitSharingIncentiveDescriptionPage: React.FC<ProfitSharingIncentiveDescriptionPageProps> = ({
  navigation,
  initData,
  getProfitSharingIncentiveInitData,
  isLoading,
  error,
}) => {
  const profitType= useMemo(()=> initData?.type, [initData]);
  const profitFrame= useMemo(()=> initData?.legalFramework, [initData]);
  const PIDescriptionData = useMemo(()=>initData && {
    startDate: new Date(initData.fiscalYearStartDate),
    endDate: new Date(initData.fiscalYearEndDate),
    deadlineDate: new Date(initData.deadlineDate),
    primeAmount: initData.netShareAmount,
    companyAmount: initData.totalAmount,
    mediumGross: undefined,
    grossAmount: undefined,
    socialLevy: undefined,
    derogatoryAmount: initData.derogatoryAmount || undefined
  }, [initData]);
  const route = useRoute<RouteProp<
  ProfitSharingIncentiveNavigatorInterface,
  ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveDescription
  >>()
  const routeProfitType = route?.params?.type
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const { formatMessage, formatDate } = useTranslation();

  const {
    pageTitle,
  } = useProfitSharingIncentiveTypeAndFrameValues({
    profitFrame,
    profitType: profitType || routeProfitType
  })
  const styles = useStyles(
    {
      theme,
      isMobile, 
      isTablet
    },
    {}
  );

  const handleOnSubmitPress = useCallback(()=>{
    navigation.navigate(RouteNames.ProfitSharingIncentive, {
      screen: RouteNames.ProfitSharingIncentiveOnboarding,
    });
  }, [navigation])

  useEffect(() => {
    getProfitSharingIncentiveInitData(routeProfitType)
  }, [route?.params?.type])

  const renderBottomActions = useCallback(() => {
    if(isLoading || error?.message) {return null}
    return (
      <View style={styles.bottomActionsStyle}>
        <SimpleButton
          containerStyle={styles.simpleButtonStyle}
          onPress={handleOnSubmitPress}
          title={formatMessage({ id: 'PIDescriptionSuivantBouton' })}
          testId={locator._pi._lets_go}
        />
      </View>
    )
  }, [
    handleOnSubmitPress,
    formatMessage,
    styles,
    isLoading,
    error?.message
  ]);
  
  const renderAlert = useCallback(() => {
    const descriptionText = profitType === ProfitSharingIncentiveTypeEnum.incentive ?
      formatMessage({ id: 'PIDescriptionCardSansReponseInteressementDescription' })
      : formatMessage({ id: 'PIDescriptionCardSansReponseParticipationDescription' })
    return (
      <NotificationHard
        containerStyle={styles.notificationContainerStyle}
        type={NotificationIconTypeEnum.WARNING}
      >
        <Text>
          {PIDescriptionData && formatMessage({ 
            id: 'PIDescriptionCardSansReponseTitre',
            values: {
              date_fin: formatDate({ value: PIDescriptionData.deadlineDate })
            },
          })}
          <Text weight="light">
            {descriptionText} 
            {" "}
            <Link
              onPress={() => 
                navigation.navigate(RouteNames.ProfitSharingIncentive, {
                  screen: RouteNames.ProfitSharingIncentiveDefaultAffectations,
                })
              }>
              {formatMessage({ id: 'PIDescriptionCardSansReponseModaliteDefautLien' })}
            </Link>
          </Text>
        </Text>
      </NotificationHard>
    );
  }, [
    styles,
    formatMessage,
    formatDate,
    PIDescriptionData,
    navigation
  ]);

  const renderHeader = useCallback(()=> (
    <ProfitSharingIncentiveHeader
      displayCloseButton
      helpPoint={false}
      title={ formatMessage({ id: pageTitle })}
    />
  ), [formatMessage, pageTitle]);

  const renderError = useCallback(() => {
    return <RetryActionPlaceHolder
      retryAction={() => {
        getProfitSharingIncentiveInitData(routeProfitType)
      }}
    />;
  }, [
    getProfitSharingIncentiveInitData,
    routeProfitType
  ]);

  const renderContent = useCallback(() => {
    if (error?.message) {
      return renderError()
    }else{
      if(!PIDescriptionData) {return null}
      return (
        <View style={styles.pageContainer}>
          <PrimeAmountCard
            companyAmount={PIDescriptionData.companyAmount}
            derogatoryAmount={PIDescriptionData.derogatoryAmount}
            endDate={PIDescriptionData.endDate}
            mediumGross={PIDescriptionData.mediumGross}
            primeAmount={PIDescriptionData.primeAmount}
            startDate={PIDescriptionData.startDate}
            style={styles.premiumAmountCard}
          />
          {renderAlert()}
          {!isMobile && !isTablet && renderBottomActions()}
        </View>
      )
    }
  }, [
    isMobile,
    isTablet,
    renderBottomActions,
    renderAlert,
    renderError,
    error,
    PIDescriptionData,
    styles
  ]);
  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}
        renderStickyMobileBottom={() => renderBottomActions()}
      >
        {isLoading?
          (<Spinner/> )
          : renderContent()
        }
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};

