
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import {
  Paragraph,
  Title,
  SimpleButton,
  LegalMenuFooterConnected,
  useScreenSizes,
  LanguageSelector360,
  EERPageWrapperContainer,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { useLogoNavigation } from '@utils/index';

export interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>
}

type StylesProps = {
  containerStyle?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  footerContentContainer?: StyleProp<ViewStyle>
  fullSpaceContainer?: StyleProp<ViewStyle>
  mobileContentContainerStyle?: StyleProp<ViewStyle>
  container?: StyleProp<ViewStyle>
  buttonContainer?: StyleProp<ViewStyle>
  paragraph?: StyleProp<TextStyle>
  errorTextStyle?: StyleProp<TextStyle>
};
interface StylesContext { theme: ThemeType; isMobile: boolean; isTablet: boolean; }

export const Personeo: React.FC<Props> = ({ navigation }) => {
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile, isTablet }, {});
  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();

  const renderMobileFooterContent = () => (
    <View style={styles.footerContentContainer}>
      <SimpleButton
        onPress={() => navigation.navigate(RouteNames.RelationshipEntringProcess, { screen: RouteNames.ChooseIdentificationMail })}
        title={formatMessage({ id: 'validate-inscription_personeo_button' })}
      />
    </View>
  );
  const renderLegalMenuFooter = () => {
    return <LegalMenuFooterConnected isWhite={isDesktop} />;
  };
  return (
    <EERPageWrapperContainer
      activeStep={1}
      displayCloseButton={false}
      displayLeftContent={false}
      pageTitle={formatMessage({ id: 'verify-identity_menu' })}
      renderStickyMobileFooterContent={renderMobileFooterContent}
    >
      <View style={styles.contentStyle}>
        <View style={styles.fullSpaceContainer}>
          <Title
            variant="t4"
            weight="bold"
          >{formatMessage({ id: 'validate-inscription_title' })}</Title>
          <Paragraph
            style={styles.paragraph}
            variant="t2"
          >
            {formatMessage({ id: 'validate-inscription_personeo_label' })}
          </Paragraph>
          {!(isMobile || isTablet) && <SimpleButton
            containerStyle={styles.buttonContainer}
            isSticky={isMobile || isTablet}
            onPress={() => navigation.navigate(RouteNames.RelationshipEntringProcess, { screen: RouteNames.ChooseIdentificationMail })}
            title={formatMessage({ id: 'validate-inscription_personeo_button' })}
          />
          }
        </View>
      </View>
      <LanguageSelector360 offlineLayoutAbsolutePosition />
    </EERPageWrapperContainer>
  );

};
const getStyles = (
  context?: StylesContext,
  style?: StylesProps,
): StylesProps => ({
  contentStyle: [
    !(context?.isMobile || context?.isTablet) ?
      {
        paddingTop: 130,
        paddingRight: context?.theme.metrics.spacing.xxl,
        maxWidth: '100%',
      }
      : { flex: 1, alignItems: 'center', paddingTop: 85 },
    style?.contentStyle,
  ],
  container: [
    !(context?.isMobile || context?.isTablet) ?
      {
        marginTop: context?.theme.metrics.spacing.l,
        maxWidth: 250,
      }
      : { marginTop: context?.theme.metrics.spacing.l, width: '100%' },
  ],
  paragraph: {
    marginTop: 40,
  },
  errorTextStyle: [
    {
      color: context?.theme.colors.basics.message.error.c500,
      marginBottom: context?.theme.metrics.spacing.l,
    },
  ],
  fullSpaceContainer: [
    {
      flex: 1,
    },
    !(context?.isMobile || context?.isTablet) && {
      paddingLeft: 214,
    },
    context?.isTablet && {
      padding: context.theme.metrics.spacing.huge,
    },
  ],
  footerContentContainer: [
    {
      paddingHorizontal: context?.theme.metrics.spacing.xm,
      flexDirection: 'column',
    },
  ],
  buttonContainer: [
    !(context?.isMobile || context?.isTablet) && {
      alignSelf: 'flex-start',
      marginTop: context?.theme.metrics.spacing.l,
    },
  ],
});

const useStyles = createUseStyles(getStyles);

