import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import {
  Text,
  SimpleButton,
  PlanChoiceCard,
  ColumnsContainer,
  DashboardPageWrapperConnected,
  NotificationSoft,
  DispositifChoicePopin,
} from '@components/index';
import { InstallmentDecisionPropertiesEnum } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { IconEnum } from '@ere-uilib/enums';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { getPlanTypeColors } from '@ere-uilib/utils';
import { DispositifState } from '@modules/common/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { constructStepperItems } from '@pages/Common/utils';

import { InstallmentHeader } from '../InstallmentPage/components/InstallmentHeader';
import { locator } from '@constants/locator';

interface Props {
  dispositifs: DispositifState[];
  navigation: StackNavigationProp<AppNavigatorInterface>;
  onSelectDispositif: (dispositif: DispositifState[]) => void;
}
interface PropsStyles {
  containerViewStyle?: StyleProp<ViewStyle>;
  stepperContainer?: StyleProp<ViewStyle>;
  stepperStyle?: StyleProp<ViewStyle>;
}

const stepperItems = constructStepperItems({
  size: 4,
  direction: DirectionEnum.ROW,
  activeStep: 2,
});

function hasRetirementProperty(dispositif: DispositifState) {
  return dispositif.filterProperties[
    InstallmentDecisionPropertiesEnum.RETIREMENT_ONLY
  ];
}

export const InstallmentPlanChoicePageComponent: React.FC<
  Props & PropsStyles
> = ({ dispositifs, navigation, onSelectDispositif }) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const { formatMessage, getMessageRaw } = useTranslation();
  const pageTitle = formatMessage({ id: 'VV_repartition_page_title' });
  const subtitle = formatMessage({ id: 'VV_needs_choix_final_dispositif' });
  const [isModalVisble, setModalVisible] = useState(false);
  const [selectedDispositifIndex, setSelectedDispositifIndex] =
    useState<number>();

  const showModal = () => setModalVisible(true);
  const closeModal = useCallback(() => setModalVisible(false), []);
  const RenderHeader = () => (
    <InstallmentHeader
      displayCloseButton
      helpPoint
      helpPointContentHtml={getMessageRaw({ id: 'VV_needs_info_content' })}
      helpPointModalTitle={formatMessage({ id: 'VV_needs_info_title' })}
      helpPointPosition="left"
      stepperItems={stepperItems}
      title={pageTitle}
    />
  );
  const handleSelectDispositif = useCallback((index: number) => {
    setSelectedDispositifIndex(index);
    showModal();
  }, []);

  const handleValidateInformativePopin = useCallback(() => {
    selectedDispositifIndex !== undefined &&
      onSelectDispositif([dispositifs[selectedDispositifIndex]]);
    closeModal();
    navigation.navigate(RouteNames.InstallmentStack, {
      screen: RouteNames.InstallmentsRepartition,
    });
  }, [
    selectedDispositifIndex,
    closeModal,
    onSelectDispositif,
    dispositifs,
    navigation,
  ]);
  return (
    <View>
      <DashboardPageWrapperConnected
        cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
      >
        <ColumnsContainer renderHeader={RenderHeader}>
          <Text style={{ marginBottom: theme.metrics.iconSizes.xl }}>
            {subtitle}
          </Text>
          {dispositifs &&
            dispositifs.map((dispositif, i) => {
              const color = theme.colors.basics.primary
              const selectDispositif = () => handleSelectDispositif(i);
              const isDispositifRetirementOnly =
                dispositif?.filterProperties?.RetirementOnly;
              return (
                <PlanChoiceCard
                  amount={dispositif.encours}
                  amountHidden={false}
                  borderLeftColor={color?.c500}
                  containerStyle={
                    i < dispositifs.length && {
                      marginBottom: theme.metrics.iconSizes.xl,
                    }
                  }
                  headerBackgroundColor={color?.c100}
                  key={i}
                  onValidate={selectDispositif}
                  title={dispositif.name}
                >
                  <Text
                    style={{ color: theme.colors.basics.black }}
                    weight="light"
                  >
                    {isDispositifRetirementOnly
                      ? formatMessage({ id: 'VV_needs_retirement_label' })
                      : formatMessage({ id: 'VV_needs_project_label' })}
                  </Text>
                  {dispositif.hasOnGoingRcVvp && (
                    <NotificationSoft
                      text={formatMessage({
                        id: 'VV_VVP_disponible_RCinfo_description',
                        values: { dispositif: dispositif.name },
                      })}
                      title={formatMessage({ id: 'VV_VVP_disponible_RCinfo_titre' })}
                      type="info"
                    />
                  )}
                </PlanChoiceCard>
              );
            })}
          <View style={!(isMobile || isTablet) && { alignItems: 'flex-start' }}>
            <SimpleButton
              design="outlined"
              leftIcon={IconEnum.CHEVRON_LEFT}
              onPress={navigation.goBack}
              title={formatMessage({ id: 'VV_needs_back_button' })}
            />
          </View>
        </ColumnsContainer>
      </DashboardPageWrapperConnected>
      <DispositifChoicePopin
        isModalVisible={isModalVisble}
        onValidate={handleValidateInformativePopin}
        testId={locator._payment._i_understand}
      />
    </View>
  );
};
