import React from 'react';
import { connect } from 'react-redux';

import { getLegalContentRequest } from '@modules/legal/actions/legalActions';
import { AppState } from '@modules/index';
import { LegalActionsType } from '@modules/legal/actions/legalActionsTypes';
import { getUser } from '@modules/auth/selectors';
import { getLegalContent, getLegalError, getMenuFooter } from '@modules/legal/selectors';
import { getConsents } from '@modules/profile/selectors';

import { LegalInfoPage as LegalInfoPageToConnect } from './LegalInfoPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    user: getUser(state),
    footerMenu: getMenuFooter(state),
    legalContent: getLegalContent(state),
    consents: getConsents(state),
    isGetLegalContentLoading: state.isLoading[LegalActionsType.GET_LEGAL_CONTENT_REQUEST],
    error: getLegalError(state)
  };
};
const mapDispatchToProps = ({
  onGetLegalContent: getLegalContentRequest
});
export const LegalInfoPage = connect(mapStateToProps, mapDispatchToProps)(LegalInfoPageToConnect);
