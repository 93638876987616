import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

import { Cell } from './Cell';
import { TableCellInterface } from './sharedInterfaces';
interface RowProps {
  column: TableCellInterface[];
  containerStyle?: StyleProp<ViewStyle>;
  rowsLength: number,
  cellPosition?: number,
  index: number;
  testId?: string;
}

interface RowStyles {
  containerStyle?: StyleProp<ViewStyle>;
}

type StyleContext = {
  theme: ThemeType;
  isLast: boolean
};

export const Row: React.FC<RowProps> = ({
  column,
  containerStyle,
  rowsLength,
  cellPosition,
  index,
  testId
}) => {
  const theme = useTheme();
  const isLast = index + 1 === rowsLength;
  const styles = useStyle(
    {
      theme,
      isLast,
    },
    {
      containerStyle,
    }
  );
  return (
    <View style={styles.containerStyle}>
      {column.map((data: any, i:number) => (
        <Cell
          cellPosition={cellPosition}
          columnsLength={column.length}
          containerStyle={data.containerStyle}
          data={data}
          index={i}
          key={i}
          rowIndex={index}
          rowsLength={rowsLength}
          textStyle={data.textStyle}
          testId={testId}
        />
      ))}
    </View>
  );
};

const getStyles = (
  context?: StyleContext,
  style?: RowStyles
): RowStyles => ({
  containerStyle: [
    {
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    style?.containerStyle,
  ],
});

const useStyle = createUseStyles(getStyles);
