import axios from 'axios';
import React, { useEffect } from 'react';

import { TranslateProvider } from '@components/index';
import { languageValuesVersions } from '@constants/index';
import { getCookie } from '@utils/index';
import { getDeviceType } from '@modules/utils/getDeviceType/deviceType';
interface AppLanguageHeadProps {
    onGetLabels: () => void,
    onPutLanguageRequest: (lg: string) => void
    language: string,
    labels: any
}

export const AppLanguageHead: React.FC<AppLanguageHeadProps> = ({
    children,
    onGetLabels,
    language,
    labels,
    onPutLanguageRequest
}) => {

    useEffect(() => {
        const cookieLanguage = getCookie('language');
        axios.defaults.headers['X-DEVICE-TYPE'] = getDeviceType();

        if (cookieLanguage && cookieLanguage !== '') {
            onPutLanguageRequest(cookieLanguage);
        } else {
            axios.defaults.headers.common['Accept-Language'] = languageValuesVersions[language].fetchHeaderParameter;
            onGetLabels();
        }
    }, []);

    return (
        <>
            <TranslateProvider
                labels={labels}
                language={languageValuesVersions?.[language]?.translateModuleParameter}
            >
                {children}
            </TranslateProvider>
        </>
    );
};

