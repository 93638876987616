
import React from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  OnboardingStepper,
  SimpleButton,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';
import { constructStepperItems } from '@pages/Common/utils';

import { ProfitSharingIncentiveHeader } from '../components';
import { useProfitSharingIncentiveTypeAndFrameValues } from '../utils'
import { ProfitSharingIncentiveOnboardingPageProps } from './interfaces';
import { getStyles } from './styles';
import { locator } from '@constants/locator';

export const ProfitSharingIncentiveOnboardingPageComponent: React.FC<
  ProfitSharingIncentiveOnboardingPageProps
> = ({
  navigation,
  profitType,
  profitFrame
}) => {

    const { formatMessage, getMessageRaw } = useTranslation();
    const theme = useTheme();
    const { isMobile, isTablet, isDesktop } = useScreenSizes();
    const {
      pageTitle,
      steps
    } = useProfitSharingIncentiveTypeAndFrameValues({
      profitFrame,
      profitType
    })
    const style = useStyles(
      {
        theme,
        isMobile: isMobile || isTablet,
      },
      {}
    );

    const stepperItems = constructStepperItems({
      size: 3,
      formatMessage,
      messageIds: steps,
    });

    const handleSubmitPress = () => {
      navigation.navigate(RouteNames.ProfitSharingIncentive, {
        screen: RouteNames.ProfitSharingIncentiveAssigment,
      });
    };

    const renderHeader = () => {
      return (
        <ProfitSharingIncentiveHeader
          displayCloseButton
          helpPoint
          helpPointContentHtml={getMessageRaw({
            id: 'PIMonInteressementEtapesAideDescription',
          })}
          helpPointModalTitle={formatMessage({
            id: 'PIMonInteressementEtapesAideTitre',
          })}
          helpPointPosition="left"
          title={formatMessage({ id: pageTitle })}
        />
      );
    };

    const renderSubmitButton = () => {
      return (
        <View style={style.buttonContainer}>
          <SimpleButton
            onPress={handleSubmitPress}
            title={formatMessage({ id: 'PIMonInteressementEtapesCommencerOperationBouton' })}
            testId={locator._pi._start}
          />
        </View>
      );
    };

    return (
      <DashboardPageWrapperConnected
        cardContentStyle={style.dashboardWrapper}
        renderStickyMobileBottom={renderSubmitButton}
      >
        <ColumnsContainer
          hasRenderRightDesktopColumn={false}
          renderHeader={() => renderHeader()}
        >
          <OnboardingStepper
            stepperItems={stepperItems}
            title={formatMessage({ id: 'PIMonInteressementEtapesEtapesSuivreLabel' })}
          />
          {isDesktop && renderSubmitButton()}
        </ColumnsContainer>
      </DashboardPageWrapperConnected>
    );
  };

const useStyles = createUseStyles(getStyles);
