import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles( displayFiletTitle: boolean ) {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const generateStyles = useMemo(() => StyleSheet.create({
    filetContainer: {
      flex: 1,
      marginRight: theme.metrics.spacing.xm,
    },
    fromContent: {
      marginLeft: displayFiletTitle ? theme.metrics.spacing.xm: theme.metrics.spacing.xs,
      width: '100%',
      marginTop: isDesktop ? theme.metrics.spacing.m : 0,
    },
    filetText: {
      marginBottom: theme.metrics.spacing.xm,
    },
    filetSubContainer: {
      marginTop: isDesktop ?
        theme?.metrics.spacing.l :
        theme?.metrics.spacing.m
    }
  }), [isDesktop, theme]);

  return generateStyles;
}
