import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@translations/index';

import { DateSelector } from '../DateSelector';
import { Props } from './interface';
import { useStyles } from './useStyles';

export const PeriodSelector: React.FC<Props> =  ({
  selectorStartDate,
  selectorEndDate,
  onChangeSelectorStartDate,
  onChangeSelectorEndDate,
  minDate,
  maxDate,
  style,
}: Props) => {
  const styles = useStyles();
  const { formatMessage } = useTranslation();

  const [startDate] = useState(selectorStartDate);
  const [endDate, onChangeEndDate] = useState(selectorEndDate);

  const selectorEndMinDate = moment(selectorStartDate, 'YYYY-MM-DD')
    .add(1, 'days')
    .format('YYYY-MM-DDTHH:MM:SS');
  const formattedSelectorEndMinDate = new Date(selectorEndMinDate);

  useEffect(() => {
    if (endDate < startDate) {
      onChangeEndDate(startDate);
    }
  }, [startDate, endDate]);

  return (
    <View style={[styles.containerStyle, style]}>
      <DateSelector
        maxDate={selectorEndDate}
        minDate={minDate}
        onChange={onChangeSelectorStartDate}
        title={formatMessage({ id: 'Fundsheet_Tab3Perf_From_periode_title' })}
        value={selectorStartDate}
      />
      <DateSelector
        maxDate={maxDate}
        minDate={formattedSelectorEndMinDate}
        onChange={onChangeSelectorEndDate}
        title={formatMessage({ id: 'Fundsheet_Tab3Perf_To_periode_title' })}
        value={selectorEndDate}
      />
    </View>
  );
};
