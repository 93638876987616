import React from 'react';
import { connect } from 'react-redux';

import {
  getProfitSharingIncentiveAssigmentAmounts,
  getProfitSharingIncentiveInitDataState,
} from '@modules/profit-sharing-incentive/selectors';
import { clearProfitSharingIncentiveData } from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActions';
import { AppState } from '@modules/reducers';
import {
  getApplicationSettings,
} from '@modules/settings/selectors';

import {
  ProfitSharingIncentiveSuccessPage
  as ProfitSharingIncentiveSuccessPageComponent
} from './ProfitSharingIncentiveSuccessPage.component'

const mapStateToProps = (state: AppState) => {
  return {
    applicationSettings: getApplicationSettings(state),
    initData: getProfitSharingIncentiveInitDataState(state),
    assigmentAmounts: getProfitSharingIncentiveAssigmentAmounts(state)
  };
};

const mapDispatchToProps = {
  clearProfitSharingIncentiveData: clearProfitSharingIncentiveData
};

export const ProfitSharingIncentiveSuccessPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfitSharingIncentiveSuccessPageComponent);
