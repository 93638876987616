export interface AccountState {
  companyName: string;
  companyId: string;
  dateAmount: string;
  accountNumber: string;
  totalAmount: number;
  totalAddedValue: number;
  isActiveAccount: boolean;
  isBudgetInsightEligible: boolean;
  plans: AllocationState[];
  isES?: boolean;
  isRC?: boolean;
}

export interface AllocationState {
  colors: string[];
  planID: string;
  shortName: string;
  name: string;
  totalAmount: number;
  totalAddedValue: number;
}
export enum AllocationsNamesEnum {
  MOYEN_TERM = 'MoyenTerme',
  RETRAITE = 'Retraite'
}

export interface AccountsState {
  totalAmount: number;
  totalAddedValue: number;
  dateAmount: string | null; // totalAmountAt
  companies: AccountState[];
}

export interface OCDState {
  contenu: string;
  appContenu: string;
  backgroundColor: string;
  position: number;
  image1SmallUrl: string;
  image1LargeUrl: string;
  image2SmallUrl: string;
  image2LargeUrl: string;
}

// eslint-disable-next-line no-shadow
export enum StepperStatus {
  DEFAULT,
  IN_PROGRESS,
  TO_DO,
  DONE,
  WAITING,
  FINISH
}

export interface AccountsErrorState {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
}

export interface DashboardRepartitionPlanType {
  value: number;
  label: string;
  color: string;
}
