import { useMemo } from 'react';
import { StyleSheet } from "react-native";

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

import { StylesContext, HomePageStyles } from './interface';

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    contentContainerStyle: {
      flex: 1,
      backgroundColor: theme.colors.background.default.c500
    },
    toolCardContainer: {
      paddingBottom: theme.metrics.spacing.huge,
      paddingHorizontal: isMobile ?theme.metrics.spacing.s : theme.metrics.spacing.xl,
    },
    cardsContainerStyle: {
      marginBottom: theme.metrics.spacing.huge,
      paddingTop: isMobile ? theme.metrics.spacing.l : theme.metrics.spacing.xxxl,
    },
    cardsContainerWithoutOCD: {
      paddingHorizontal: isMobile ? theme.metrics.spacing.s : theme.metrics.spacing.xl,
    },
    ocdOperationsBloc: {
      flex: isMobile ? 1 : undefined,
      flexDirection: isMobile ? 'row' : undefined
    },
    ocdLoadingBlocStyle: {
      flex: 1
    },
    recentOperationsBloc: {
      flex: 1,
      marginBottom: isMobile ? theme.metrics.spacing.xxm : undefined,
      marginRight: !isMobile ? theme.metrics.spacing.xl : undefined,
    },
    commonBlocStyle: {
      padding: 0,
    },
    informationText: {
      alignSelf: 'flex-end',
      marginRight: theme.metrics.spacing.s,
      marginTop: -theme.metrics.spacing.xm,
      paddingBottom: isMobile ? theme.metrics.spacing.s : theme.metrics.spacing.xm,
      color: theme.colors.basics.grey.c500
    },
    containerROCandRSTC: {
      marginTop: isMobile ? theme.metrics.spacing.xm : theme.metrics.spacing.xl,
      flexDirection: isMobile ? undefined : "row",
    },
    remaningSavingsStyle: {
      flex: 1
    }
  }), [theme, isMobile]);

  return styles;
};