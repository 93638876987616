import { Platform } from 'react-native';
import { hasNotch } from 'react-native-device-info';

import { ModalFrameStyle, StylesContext } from './interface';
export const getStyles = (
  context?: StylesContext,
  style?: ModalFrameStyle,
):ModalFrameStyle => ({
  modalFrame: [
    {
      backgroundColor: context?.theme?.colors.basics.white,
      maxHeight: '90%',
      overflow: 'hidden',
      ...(context?.isMobile ? {
        width: '100%',
        ...(context?.mobilePosition === 'bottom' ? {
          alignSelf: 'flex-end',
          borderTopLeftRadius: context?.theme.metrics.borderRadius.l,
          borderTopRightRadius: context?.theme.metrics.borderRadius.l
        } : {}),
        ...(context?.mobilePosition === 'full' ? {
          maxHeight: hasNotch() ? '95%' : '100%',
          height: '100%'
        } : {})
      } : {
        width: '80%',
        maxWidth: context?.maxDesktopWidth || 450,
        ...(context?.desktopPosition === 'fullHeight' ? {
          borderRadius: context?.theme.metrics.borderRadius.m,
          height: '100%'
        } : {
          borderRadius: context?.theme.metrics.borderRadius.xs
        })
      })
    },
    style?.modalFrame
  ],
  titleContainer: [
    {
      borderBottomWidth: 1,
      borderBottomColor: context?.theme?.colors.basics.grey.c200,
      ...(context?.isMobile && context?.mobilePosition === 'full' ? {
        paddingVertical: context?.theme?.metrics.spacing.m,
        paddingHorizontal: context?.theme?.metrics.spacing.l
      } : {
        paddingVertical: context?.theme?.metrics.spacing.xm,
        paddingHorizontal: context?.theme?.metrics.spacing.xbig
      })
    },
    style?.titleContainer
  ],
  titleText: [
    {
      textAlign: 'center'
    },
    style?.titleText
  ],
  titleCloseContainer: [
    {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      alignItems: 'center',
      justifyContent: 'center',
      ...(context?.isMobile && context?.mobilePosition === 'bottom' ? {
        paddingRight: context?.theme?.metrics.spacing.l
      } : {
        paddingRight: context?.theme?.metrics.spacing.m
      })
    },
    style?.titleCloseContainer
  ],
  closeContainer: [
    {
      position: 'absolute',
      ...(context?.isMobile && context?.mobilePosition === 'bottom' ? {
        top: context?.theme?.metrics.spacing.l,
        right: context?.theme?.metrics.spacing.l
      } : {
        top: context?.theme?.metrics.spacing.m,
        right: context?.theme?.metrics.spacing.m
      })
    },
    style?.closeContainer
  ],
  innerContainer: [
    {
      ...(context?.platformOsIsWeb && {
        flex: 1,
      }),
      ...(context?.isMobile && context?.mobilePosition === 'full' ? {
        paddingVertical: context?.theme?.metrics.spacing.l,
        paddingHorizontal: context?.theme?.metrics.spacing.s
      } : {
        padding: context?.theme?.metrics.spacing.xm
      })
    },
    style?.innerContainer
  ]
});
