import React from 'react';
import { StyleProp, Text, TextStyle } from 'react-native';

import { createUseStyles, useTheme, getTitleFontStyle } from '@ere-uilib/styles';

import { TitleProps, TitleStylesContext } from './interfaces';

export const Title: React.FC<TitleProps> = ({
  style,
  variant,
  autoMargin = false,
  weight = 'regular',
  testId,
  ...otherProps
}) => {
  const theme = useTheme();
  const styles = useStyles({
    theme,
    variant,
    autoMargin,
    weight,
    numberOfLines: otherProps.numberOfLines,
  }, style);

  return (
    <Text
      testID={testId}
      accessibilityLabel={testId}
      style={styles}
      {...otherProps}
    />
  );
};

const getStyles = (
  context: TitleStylesContext,
  style?: StyleProp<TextStyle>,
): StyleProp<TextStyle> => [
  {
    ...getTitleFontStyle({
      weight: context?.weight,
      variant: context.variant,
      autoMargin: context.autoMargin,
      theme: context?.theme,
      numberOfLines: context?.numberOfLines,
    }),
  },
  style,
];

const useStyles = createUseStyles(getStyles);
