import { connect } from 'react-redux';

import { ValidateIdentityActionsType } from '@modules/validate-identity/actions/validateIdentityActionsTypes';
import {
  validateEERPhoneNewIdentifierCodeRequest,
  validateEERPhoneNewIdentifierRequest
} from '@modules/validate-identity/actions/validateIdentityActions';
import {
  AppState,
} from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';
import {
  getIdentityValidationModuleError,
  getUserInputPhone,
} from '@modules/validate-identity/selectors';

import {
  ChooseIdentificationPhoneCodePage as ChooseIdentificationPhoneCodePageComponent,
} from './ChooseIdentificationPhoneCodePage.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state),
    phone: getUserInputPhone(state),
    validateIdentityError: getIdentityValidationModuleError(state),
    isValidateEERPhoneNewIdentifierApiLoading: state.isLoading[
      ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_REQUEST
    ],
    isValidateEERPhoneNewIdentifierCodeApiLoading: state.isLoading[
      ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_REQUEST
    ],
  };
};

const mapDispatchToProps = ({
  validateEERPhoneNewIdentifier: validateEERPhoneNewIdentifierRequest,
  validateEERPhoneNewIdentifierCode: validateEERPhoneNewIdentifierCodeRequest,
});
export const ChooseIdentificationPhoneCodePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChooseIdentificationPhoneCodePageComponent);
