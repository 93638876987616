import { connect } from 'react-redux';

import { otpFlowAskCodeRequest } from '@modules/profile/actions/profileActions';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import { AppState } from '@modules/index';
import { getOtpParameters } from '@modules/profile/selectors';

import { OtpValidationStartPage as OtpValidationStartPageComponent } from './OtpValidationStartPage.component';

const mapStateToProps = (state: AppState) => ({
  otpParameters: getOtpParameters(state),
  isAskCodeLoading: state.isLoading[ProfileActionsType.OTP_FLOW_ASK_CODE_REQUEST]
});

const mapDispatchToProps = {
  onSendMessagePress: otpFlowAskCodeRequest
};

export const OtpValidationStartPage = connect(mapStateToProps, mapDispatchToProps)(OtpValidationStartPageComponent);
