import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { BackAndConfirmButtons } from '@ere-uilib/organisms';

import { useStyles } from './useStyles';

type Props = Omit<React.ComponentPropsWithoutRef<typeof BackAndConfirmButtons>, 'containerStyle'>;

interface Style {
  actionsContainerStyle?: StyleProp<ViewStyle>;
}

export const AnnuityBottomActionsWrapper: React.FC<Props & Style> = ({
  children,
  actionsContainerStyle,
  ...props
}) => {
  const styles = useStyles();
  return (
    <>
      {children}
      <BackAndConfirmButtons
        confirmButtonStyle={styles.confirmButton}
        containerStyle={[styles.buttonContainer, actionsContainerStyle]}
        size="small"
        {...props}
      />
    </>
  );
};
