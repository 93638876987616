import {
  DialOncePressable, FontIcon, HelpButton, LegalMenuFooterConnected, OfflinePageLayout, SupportContent, Text, ThemeType,
  useScreenSizes, useTheme, useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import {RouteNames} from '@constants/navigation';
import {IconEnum} from '@ere-uilib/enums';
import {UserState} from '@modules/auth/types';
import {ApplicationSettingsState} from '@modules/settings/types';
import {AppNavigatorInterface} from '@navigation/Interfaces';
import {StackNavigationProp} from '@react-navigation/stack';
import React from 'react';
import {StyleProp, TextStyle, TouchableOpacity, View, ViewStyle} from 'react-native';


type AccountCreationSucessPageStyles = {
  contentStyle?: StyleProp<ViewStyle>;
  backButtonStyle?: StyleProp<ViewStyle>;
  backButtonTextStyle?: StyleProp<TextStyle>;
  dialonceStyle?: StyleProp<ViewStyle>;
};

interface Props extends AccountCreationSucessPageStyles {
  user: UserState
  navigation: StackNavigationProp<AppNavigatorInterface> // TODO : Type navigations screens props
  applicationSettings: ApplicationSettingsState
}

type StylesContext = { theme: ThemeType; isMobile: boolean };

export const SupportPage: React.FC<Props> = ({
  user,
  navigation,
  contentStyle,
  backButtonStyle,
  backButtonTextStyle,
  applicationSettings,
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const getStyles = (
    context?: StylesContext,
    style?: AccountCreationSucessPageStyles,
  ): AccountCreationSucessPageStyles => ({
    contentStyle: [
      context?.isMobile
        ? {
          flex: 1,
          maxWidth: '100%',
          paddingTop: context?.theme.metrics.spacing.huge,
        }
        : {
          flex: 1,
          paddingHorizontal: context?.theme.metrics.spacing.xxxhuge,
          paddingTop: context?.theme.metrics.spacing.xxl,
        },
      style?.contentStyle,
    ],
    backButtonStyle: [
      context?.isMobile
        ? {
          position: 'absolute',
          top: context?.theme.metrics.spacing.s,
          right: context?.theme.metrics.spacing.s,
        }
        : {
          alignSelf: 'flex-start',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: context?.theme.metrics.spacing.huge,
        },
      style?.backButtonStyle,
    ],
    backButtonTextStyle: [
      {
        marginLeft: context?.theme.metrics.spacing.s,
      },
      style?.backButtonTextStyle,
    ],
    dialonceStyle: [
      {
        alignSelf: 'flex-start',
        maxWidth: 335,
        width: '100%',
        marginTop: context?.theme.metrics.spacing.l,
      },
      style?.dialonceStyle,
    ],
  });
  
  const useStyles = createUseStyles(getStyles);  
  const styles = useStyles({ theme, isMobile }, { contentStyle, backButtonStyle, backButtonTextStyle });
  const canGoBack = navigation?.canGoBack();
  const onClose = () => {
    // TODO : find a way to navigate to default root page when no Back Route ( rare case )
    // TODO : it look like on figma that this page will become a modal
    if (canGoBack) {
      navigation?.goBack();
    } else if (user?.access_token) {
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.Home,
      });
    } else {
      navigation.navigate(RouteNames.OidcCallbackRedirect);
    }
  };
  const renderMobileMenuFooter = () => {
    return (
      <LegalMenuFooterConnected />
    );
  };
  return (
    <OfflinePageLayout
      renderMobileStickyGradientFooterContent={renderMobileMenuFooter}
    >
      <View style={styles.contentStyle}>
        <TouchableOpacity
          onPress={onClose}
          style={styles.backButtonStyle}
        >
          <FontIcon
            color={theme?.colors.text.primary.c500}
            name={isMobile ? IconEnum.CLOSE : IconEnum.CHEVRON_LEFT}
            size={isMobile ? theme.metrics.iconSizes.s : theme.metrics.iconSizes.xs}
          />
          {!isMobile && (
            <Text style={styles.backButtonTextStyle}>{formatMessage({ id: 'support_back_button' })}</Text>
          )}
        </TouchableOpacity>
        <View style={{ flex: 1 }}>
          <SupportContent
            renderButton={() => {
              return (
                <DialOncePressable
                  integrationKey={applicationSettings?.HelpDialOnceIntegrationKey || ''}
                  style={styles.dialonceStyle}
                  targetedFlowId={applicationSettings?.HelpErrorPageDialOnceId || ''}
                >
                  <HelpButton
                    onPress={() => { }}
                    title={formatMessage({ id: 'CTA_dialonce_help' })}
                  />
                </DialOncePressable>
              );
            }}
          />
        </View>
        {!isMobile && !isTablet && <LegalMenuFooterConnected />}
      </View>
    </OfflinePageLayout>
  );
};