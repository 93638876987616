import React, { useCallback, useRef, useState } from 'react';
import {
  FlatList,
  FlatListProps,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleProp,
  TouchableOpacity,
  View,
  ViewabilityConfigCallbackPairs,
  ViewStyle
} from 'react-native';

import { FontIcon, Hoverable } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';

import { useStyles } from './useStyles';

interface CarouselStyleProps {
  containerStyle?: StyleProp<ViewStyle>;
  listStyle?: StyleProp<ViewStyle>;
  listContentContainerStyle?: StyleProp<ViewStyle>;
  arrowStyle?: StyleProp<ViewStyle>;
  bulletsContainerStyle?: StyleProp<ViewStyle>;
  bulletStyle?: StyleProp<ViewStyle>;
}

export interface CarouselProps<ItemT>
  extends Omit<FlatListProps<ItemT>, 'style' | 'contentContainerStyle'>,
    CarouselStyleProps {
  withBullets?: boolean;
  bulletsSize?: number;
  withArrows?: boolean;
  arrowsSize?: 'small' | 'large' | number;
  arrowsIndent?: number;
  spacingBeforeFirstItem?: number;
  spacingAfterLastItem?: number;
  spacingBetweenItems?: number;
}

export const Carousel = <ItemT, >({
  withBullets = true,
  bulletsSize,
  withArrows = true,
  arrowsSize = 'small',
  arrowsIndent,
  spacingBeforeFirstItem,
  spacingAfterLastItem,
  spacingBetweenItems = 15,
  containerStyle,
  listStyle,
  listContentContainerStyle,
  arrowStyle,
  bulletsContainerStyle,
  bulletStyle,
  ...props
}: CarouselProps<ItemT>) => {
  const styles = useStyles({
    arrowsSize,
    arrowsIndent,
    bulletsSize,
    spacingBeforeFirstItem,
    spacingAfterLastItem,
    spacingBetweenItems
  });
  const flatlistRef = useRef<FlatList>(null);

  const [bulletIndex, setBulletIndex] = useState(0);
  const [isNavigationEnabled, setIsNavigationEnabled] = useState(false);

  const viewabilityConfigCallbackPairs = useRef<ViewabilityConfigCallbackPairs>([
    {
      viewabilityConfig: { itemVisiblePercentThreshold: 100 },
      onViewableItemsChanged: ({ viewableItems }) =>
        setIsNavigationEnabled(!!props.data && viewableItems.length < props.data.length)
    }
  ]);

  const handleNavigation = useCallback(
    ({ index, viewPosition = 0.5 }: { index: number; viewPosition?: 0 | 0.5 | 1 }) => {
      flatlistRef?.current?.scrollToIndex?.({
        index,
        viewPosition,
        viewOffset: spacingBetweenItems * 0.5
      });
    },
    [spacingBetweenItems]
  );

  const handleScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (!props.data?.length) return;
      const width =
        (event?.nativeEvent?.contentSize?.width || 0) -
        (event?.nativeEvent?.layoutMeasurement?.width || 0);
      const dx = (event?.nativeEvent?.contentOffset?.x || 0) + spacingBetweenItems * 2;
      const maxIndex = props.data.length - 1;
      let index = (dx / (width || 1)) * maxIndex;
      index = Math.round(index - 0.3);
      index = Math.min(index, maxIndex);
      setBulletIndex(index);
    },
    [props.data, spacingBetweenItems]
  );

  const renderArrow = useCallback(
    (action: 'previous' | 'next') => (
      <TouchableOpacity
        onPress={() => {
          handleNavigation({
            index: bulletIndex + (action === 'previous' ? -1 : 1),
            viewPosition: action === 'previous' ? 1 : 0
          });
        }}
        style={[
          styles.arrow,
          arrowStyle,
          action === 'previous' ? styles.leftArrow : styles.rightArrow
        ]}>
        <FontIcon
          name={IconEnum[action === 'previous' ? 'CHEVRON_LEFT' : 'CHEVRON_RIGHT']}
          style={styles.arrowIcon}
        />
      </TouchableOpacity>
    ),
    [bulletIndex, handleNavigation, styles, arrowStyle]
  );

  const renderBullets = useCallback(
    () =>
      !!props.data?.length && (
        <View style={[styles.bulletsContainer, bulletsContainerStyle]}>
          {props.data.map((item, index, array) => (
            <TouchableOpacity
              key={props.keyExtractor?.(item, index) + 'bullet'}
              onPress={() => {
                handleNavigation({ index });
              }}
              style={[
                styles.bullet,
                index === bulletIndex && styles.bulletActive,
                index === array.length - 1 && styles.bulletLast,
                bulletStyle
              ]}
            />
          ))}
        </View>
      ),
    [bulletIndex, handleNavigation, props, styles, bulletsContainerStyle, bulletStyle]
  );

  return (
    <View style={[styles.container, containerStyle]}>
      <Hoverable>
        {(isHovered: boolean) => (
          <View style={styles.hoverableContent}>
            {isNavigationEnabled && withArrows && isHovered && !!props.data?.length && (
              <>
                {bulletIndex !== 0 && renderArrow('previous')}
                {bulletIndex !== props.data.length - 1 && renderArrow('next')}
              </>
            )}
            <FlatList<ItemT>
              horizontal
              ItemSeparatorComponent={() => <View style={styles.listItemSeparator} />}
              key="carousel"
              ListFooterComponent={() => <View style={styles.listHeaderContainer} />}
              ListHeaderComponent={() => <View style={styles.listFooterContainer} />}
              onScroll={handleScroll}
              ref={flatlistRef}
              scrollEventThrottle={50}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
              {...props}
              contentContainerStyle={[styles.listContentContainer, listContentContainerStyle]}
              style={[styles.list, listStyle]}
            />
          </View>
        )}
      </Hoverable>
      {isNavigationEnabled && withBullets && renderBullets()}
    </View>
  );
};
