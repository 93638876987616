import React from 'react';
import { StyleProp, ViewStyle, TouchableOpacity } from 'react-native';

import { SVGLocalLoader } from '@ere-uilib/atoms';
import { createUseStyles } from '@ere-uilib/styles';

interface Props {
  style?: StyleProp<ViewStyle>;
  width: number;
  height: number;
}


type StylesContext = { height: number; width: number };

export const Tooltip: React.FC<Props> = props => {
  const { width = 20, height = 20, style } = props;
  const styles = useStyles({ height, width }, style);

  return (
    <TouchableOpacity style={styles}>
      <SVGLocalLoader
        height={height}
        name="info"
        width={width} />
    </TouchableOpacity>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: StyleProp<ViewStyle>,
): StyleProp<ViewStyle> => [
    { width: context?.width, height: context?.height },
    style
  ];

const useStyles = createUseStyles(getStyles);
