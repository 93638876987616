import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  return useMemo(() => StyleSheet.create({
    hoveredMenuContainer:
      {
        backgroundColor: theme.colors.buttons.menuItemActive.c500
      },
    hoveredLastMenuContainer:
      {
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
      },
    menuItem:
      {
        paddingLeft: theme.metrics.spacing.xm,
        paddingRight: theme.metrics.spacing.xm,
        justifyContent: 'center',
        height: 50,
      },
    hoveredTitle:
      {
        color: theme.colors.basics.white,
      },
    bottomBorder:
      {
        height: "100%",
        borderColor: theme.colors.basics.grey.c200,
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    hoveredBorder:
      {
        borderBottomWidth: 0,
      },
    wishMenuCloseButton:
      {
        position: "absolute",
        top: 20,
        right: 20,
        cursor: "pointer"
      },
    iconStyle:
      {
        color: theme.colors.basics.black,
        fontSize: theme.metrics.iconSizes.mplus
      },
    wishMenuTitle:
      {
        height: 64,
        justifyContent: 'center',
        paddingLeft: 15,
      },
    wishMenuContainer:
      {
        backgroundColor: theme.colors.basics.white,
        width: 410,
        borderRadius: 5,
      }
  }), [theme]);
};
