import React from 'react';
import { View } from 'react-native';

import {
  Link,
  Text,
  useTranslation,
} from '@components/index';

import { DocumentsContainerProps } from './interfaces';
import { useStyles } from './useStyles';

export const DocumentsContainer: React.FC<DocumentsContainerProps> = ({
  documentsTypesList,
  handleDocumentPress,
  setIsLinkPressed
}) => {

  const styles = useStyles();
  const { formatMessage } = useTranslation();

  const handleOnPress = (documentType: string, label: string) => {
    handleDocumentPress(documentType, label);
    setIsLinkPressed(true);
  }
  
  return (
    <View style={styles.container}>
      <Text
        style={styles.title}
        variant="t4"
      >
        {formatMessage({ id: 'RetirementJourneyRecoverHisSavingTextPension' })}
      </Text>

      <View style={styles.documentTypesListContainer}>
        {
          documentsTypesList.map((document, i) => {
            const label = formatMessage({ id: document.labelKey });
            return (
              <Link
                key={i}
                onPress={() => handleOnPress(document.documentType, label)}
                textStyle={styles.documentTypeItem}
                textVariant="t3"
              >
                {label}
              </Link>
            )
          })
        }
      </View>
    </View>
  )
};
