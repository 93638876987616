import axios from 'axios';
import moment from 'moment';
import { Log, UserManager } from 'oidc-client';
import { AuthConfiguration, authorize, logout, refresh, RefreshResult } from 'react-native-app-auth';

import {
  AuthApiReturnedOpenIdConfigForClientId,
  AuthenticationClaims,
  UserState,
} from '@modules/auth/types';
import {
  AuthConfigType,
  AuthSilentRenewConfigType
} from '@modules/settings/types';

Log.logger = console;

export async function getLoginOpenIdConfigForClientId(
  clientId: string,
  authApiUrl: string
): Promise<AuthApiReturnedOpenIdConfigForClientId> {
  return axios.get(`${authApiUrl}/api/configurations/oidc/` + clientId);
}

export async function signinRedirectUsingToken(
  token: string,
  config: AuthConfigType,
) {
  // build an openId config based on AuthConfig
  // + given clientId
  // + specific scope for this clientId
  // + extraQueryParams : given token
  const customOpenIdConfig = {
    ...config,
    scope: config.scope,
    client_id: config.client_id,
    extraQueryParams: {
      'token': token,
    },
  };
  try {
    const manager = new UserManager(customOpenIdConfig);
    await manager.signinRedirect();
  } catch (error) {
  }
}
export async function signinRedirectAppUsingToken(
  token: string,
  config: AuthConfigType,
) {
  // base config
  const configApp = {
    issuer: config.authority,
    clientId: config.client_id,
    redirectUrl: config.redirect_uri,
    scopes: ['openid', 'profile', 'email', 'companysaving', 'ere360', 'api_epargne_salariale', 'web_epargne_salariale', 'api_ere360_middleware', 'am_funds_api', 'offline_access'],
    additionalParameters: {
      'token': token,
    }
  };
  // use the client to make the auth request and receive the authState
  try {
    // call login api
    const res = await authorize(configApp);
    return res
  } catch (error) {
    console.log(error);
  }
}

/*export async function signinMobileApp(
  config: AuthConfigType,
) {
  // base config
  const configApp = {
    issuer: config.authority,
    clientId: config.client_id,
    redirectUrl: config.redirect_uri,
    scopes: ['openid', 'profile','email', 'companysaving', 'ere360', 'api_epargne_salariale', 'web_epargne_salariale', 'api_ere360_middleware', 'am_funds_api', 'offline_access'],
  };
  // use the client to make the auth request and receive the authState
  try {

    // call login api
    const res = await authorize(configApp);
    return res
  } catch (error) {
    console.log(error);
  }
}*/
/*export async function logoutMobileApp(
  config: AuthConfigType,
  idToken: string,
) {
  // base config
  const configApp = {
    issuer: config.authority,
    clientId: config.client_id,
    redirectUrl: config.redirect_uri,
    scopes: ['openid', 'profile','email', 'companysaving', 'ere360', 'api_epargne_salariale', 'web_epargne_salariale', 'api_ere360_middleware', 'am_funds_api', 'offline_access'],
  };
  // use the client to make the auth request and receive the authState
  try {
  
    await logout(configApp, {
      idToken: idToken,
      postLogoutRedirectUrl: config.post_logout_redirect_uri,
    });
  } catch (error) {
    console.log('logout issue', error);
  }
}*/
export async function loginOpenId(config: AuthConfigType) {
  try {
    const manager = new UserManager(config);
    await manager.signinRedirect();
  } catch (error) {
  }
}
// new user is returned in this function
export async function silentRenewTokenOpenId(silentRenewConfig: AuthSilentRenewConfigType) {
  try {
    const manager = new UserManager(silentRenewConfig);
    return await manager.signinSilent().then(user => {
      return user;
    }).catch(error => {
    });
  }
  catch (error) {
  }
}

export async function silentRenewMobileAppTokenOpenId(silentRenewConfig: AuthConfigType, user: UserState) {
  try {
    const configApp: AuthConfiguration = {
      issuer: silentRenewConfig.authority,
      clientId: silentRenewConfig.client_id,
      redirectUrl: silentRenewConfig.redirect_uri,
      scopes: ['openid', 'profile', 'email', 'companysaving', 'ere360', 'api_epargne_salariale', 'web_epargne_salariale', 'api_ere360_middleware', 'am_funds_api', 'offline_access'],
    };

    const result: RefreshResult = await refresh(configApp, {
      refreshToken: user.refresh_token,
    })
    const res: UserState = {
      ...user,
      access_token: result.accessToken,
      expires_at: Number(moment(result.accessTokenExpirationDate).format('X')),
      id_token: result.idToken,
      profile: result.idToken,
      refresh_token: result.refreshToken || '',
      token_type: result.tokenType
    }
    return res
  }
  catch (error) {
    console.log(error, 'refresh error')
  }
}

export async function logoutOpenId(config: AuthConfigType, token?: string) {
  try {
    const manager = new UserManager(config);
    await manager.signoutRedirect({
      id_token_hint: token,
      post_logout_redirect_uri: config.post_logout_redirect_uri,
    });
  } catch (error) {
  }
}
export const clearStaleState = (config: AuthConfigType) => {
  const manager = new UserManager(config);
  manager.clearStaleState();
};

export async function callback(config: AuthConfigType): Promise<any> {
  const manager = new UserManager({
    response_mode: 'query',
  });

  return manager
    .signinRedirectCallback()
    .then(user => {
      return user;
    })
    .catch((e: Event) => {
    });
}

export async function endOidcSession(authorityUrl: string, id_token: string, post_logout_redirect_uri: string) {
  const newHref = `${authorityUrl}/connect/endsession?id_token_hint=${id_token}&post_logout_redirect_uri=${post_logout_redirect_uri}`;

  return window.location.href = newHref;
}

export async function signInNativeAppProcess(claims: AuthenticationClaims) {

  let formBody: string[] | string = [];
  for (const property in claims) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(claims[property as keyof AuthenticationClaims]!);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return axios.post(`${claims.authority}/connect/token`, formBody, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
}

// not need to fire this callback bacause user is returned using manager.signinSilent()
// this function is called from silent route page when resfresh is requested, if we need to update something after refresh, use this callback
export async function silentCallback(): Promise<any> {

}

export async function fetchUserInfo(authority: string, token: string) {
  return axios.get(`${authority}/connect/userinfo`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    }
  })
}