import React, { useEffect, useRef, useState } from 'react'

import { Animated, Pressable, View } from 'react-native'
import { useStyles } from './useStyles'
import { Text } from '../texts'

interface IToggleProps {
  disabled?: boolean
  label: string
  onToggleStatusChange: (_arg: boolean) => void
}
export const ToggleText = ({ disabled = false, label, onToggleStatusChange }: IToggleProps) => {
  const [isToggleActive, setIsToggleActive] = useState<boolean>(false)
  const toggleAnimation = useRef(new Animated.Value(0)).current
  const AnimatedTouchable = Animated.createAnimatedComponent(Pressable)
  const styles = useStyles(isToggleActive, disabled)

  useEffect(() => {
    disabled && setIsToggleActive(false)
  }, [disabled])

  useEffect(() => {
    Animated.spring(toggleAnimation, {
      toValue: isToggleActive ? 12 : 0,
      useNativeDriver: true
    }).start()
    onToggleStatusChange(isToggleActive)
    disabled && setIsToggleActive(false)
  }, [disabled, isToggleActive, toggleAnimation, setIsToggleActive, onToggleStatusChange])

  return (
    <View style={styles.container}>
      <AnimatedTouchable
        style={styles.toggleContainer}
        onPress={() => !disabled && setIsToggleActive(prev => !prev)}>
        <Animated.View
          style={[styles.circleContainer, { transform: [{ translateX: toggleAnimation }] }]}
        />
      </AnimatedTouchable>
      <Text
        variant="t3"
        weight="light"
        style={styles.toggleText}>
        {label}
      </Text>
    </View>
  )
}
