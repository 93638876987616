import React, { useImperativeHandle, useRef } from 'react';
import { ScrollView, View } from 'react-native';

import { BackgroundGradientTransition } from '@ere-uilib/atoms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { ColumnsContainerProps as Props } from './interfaces';
import { getStyles } from './styles';

export const ColumnsContainer: React.FC<Props> = ({
  renderHeader,
  children,
  renderRightDesktopColumn,
  renderStickyMobileBottom,
  renderTopContent,
  hasRenderRightDesktopColumn = true,
  containerStyle,
  leftColumnStyle,
  rightColumnStyle,
  columnSingleRowStyle,
  columnsRowStyle,
  scrollViewStyle,
  scrollViewContentContainerStyle,
  hasColumnCenter,
  columnsContainerRef,
}) => {
  const ref = useRef() as React.MutableRefObject<ScrollView>;
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles(
    { theme, isMobile, isTablet, windowHeight },
    {
      containerStyle,
      leftColumnStyle,
      rightColumnStyle,
      columnSingleRowStyle,
      columnsRowStyle,
      scrollViewStyle,
      scrollViewContentContainerStyle
    }
  );

  useImperativeHandle(columnsContainerRef, () => ({
    scrollToTop: () => {
      ref?.current?.scrollTo?.({ y: 0 });
    }
  }), [])

  return (
    <View style={styles.containerStyle}>
      {renderHeader && renderHeader()}
      <ScrollView
        contentContainerStyle={styles.scrollViewContentContainerStyle}
        ref={ref}
        showsVerticalScrollIndicator={false}
        style={styles.scrollViewStyle}
      >
        {renderTopContent?.()}
        <View style={hasColumnCenter ? styles.columnSingleRowStyle : styles.columnsRowStyle}>
          <View style={styles.leftColumnStyle}>
            {children}
          </View>
          {!isMobile && !isTablet && hasRenderRightDesktopColumn && (
            <View style={styles.rightColumnStyle} >
              {renderRightDesktopColumn && renderRightDesktopColumn()}
            </View>
          )}
        </View>
      </ScrollView>
      {(isMobile || isTablet) && renderStickyMobileBottom && (
        <BackgroundGradientTransition
          containerStyle={styles.stickyButtonsViewStyle}
        >
          {renderStickyMobileBottom()}
        </BackgroundGradientTransition>
      )}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
