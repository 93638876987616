import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import {
  FontIcon, Text, Title
} from '@ere-uilib/atoms';
import { HelpPoint } from '@ere-uilib/organisms';
import { createUseStyles } from '@ere-uilib/styles';
import { useTheme } from '@ere-uilib/styles/theme';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const ManagementCardHeader: React.FC<Props> = ({
  containerStyle,
  backGroundColorCustom,
  helpPointData,
  opened,
  title,
  details,
  children,
}) => {
  const theme = useTheme();
  const { formatMessage, getMessageRaw } = useTranslation();
  const useStyles = createUseStyles(getStyles);
  const [expanded, setExpanded] = useState(opened);

  const styles = useStyles(
    { theme, backGroundColorCustom, expanded },
    { containerStyle },
  );

  const helpPointRender = () => {
    return helpPointData && (
      <HelpPoint
        contentHtml={getMessageRaw({ id: helpPointData.contentHtml, defaultMessage: ' ' })}
        contentTitle={formatMessage({ id: helpPointData.contentTitle, defaultMessage: ' ' })}
        iconSize={theme.metrics.iconSizes.m}
        modalTitle={formatMessage({ id: helpPointData.modalTitle })}
        style={styles.helpPointStyle}
      />
    );
  };

  return (
    <View style={styles.containerStyle}>
      <TouchableOpacity onPress={() => setExpanded(!expanded)}>
        <View style={styles.headerContainerStyle}>
          <View style={styles.headerTopAlignement}>
            <View style={styles.headerTitleAlignement}>
              {helpPointRender()}
              <Title
                numberOfLines={1}
                variant={'t7'}
                weight='bold'
                testId={title}
              >
                {title}
              </Title>
            </View>
            <View style={styles.iconContainer}>
              <FontIcon
                color={theme.colors.basics.black}
                name={expanded ? 'chevron-haut' : 'chevron-bas'}
                size={theme.metrics.iconSizes.xm}
              />
            </View>
          </View>
          {
            details && details.length > 0 && (
              <View style={styles.DetailsContainerStyles}>
                {
                  details.map((data, i) => {
                    return (
                      <View
                        key={i}
                        style={styles.DetailContainerStyle}
                        testID={data.label}
                        accessibilityLabel={data.label}
                      >
                        <Text
                          style={styles.DetailLabelStyle}
                          variant="t3"
                          weight="light"
                        >
                          {data.label}
                        </Text>
                        {
                          data.value &&
                          <Text
                            style={styles.DetailValueStyle}
                            variant="t2"
                            weight="bold"
                          >
                            {data.value}
                          </Text>
                        }
                      </View>
                    );
                  })
                }
              </View>
            )
          }
        </View>
      </TouchableOpacity>

      {
        expanded && (
          children
        )
      }

    </View>
  );
};