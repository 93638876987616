// @flow
import moment from 'moment';
import { AuthActionType } from '@modules/auth/actions/authActionsTypes';
import { race, delay, select, put, take } from 'redux-saga/effects';
import { catchApiExceptions } from './apiAuthorization';
import * as authActions from './auth/actions/authActions'
import { getIsRefreshSessionRequestAlreadyLoading, getUser } from '@modules/auth/selectors';
import { UserState } from '@modules/auth/types';


declare type SagaType = Generator<any, any, any>

export const runManager = (saga: any, ActionTypeFailure: any, timeout = 50000) =>
  function* (...args: any): SagaType {
    try {
      const userInfo: UserState = yield select(getUser);
      const isAuthenticated = (userInfo?.access_token && userInfo?.access_token !== '') || false;
      const isRefreshRequestAlreadyLoading = yield select(getIsRefreshSessionRequestAlreadyLoading);
      const sessionTimeStamp = userInfo?.expires_at || 0;
      const tokenExpireAt = moment(sessionTimeStamp * 1000).diff(moment().format(), 'minutes')
      if (isAuthenticated && tokenExpireAt < 0) return yield put(authActions.logoutCallbackSuccess());
      if ((isAuthenticated && tokenExpireAt <= 5 && tokenExpireAt >= 1) && args[0]?.type !== AuthActionType.SILENT_RENEW_TOKEN_REQUEST && !isRefreshRequestAlreadyLoading) {
        yield put(authActions.silentRenewTokenRequest())
      }
      if (isRefreshRequestAlreadyLoading && args[0]?.type !== AuthActionType.SILENT_RENEW_TOKEN_REQUEST && isAuthenticated) {
        yield take([AuthActionType.SILENT_RENEW_TOKEN_SUCCESS, AuthActionType.SILENT_RENEW_TOKEN_FAILURE])
      }
      const { hasTimeOuted } = yield race({
        executeApiSaga: yield saga.apply(this, args),
        hasTimeOuted: yield delay(timeout),
      });
      if (hasTimeOuted) {
        //@todo: waiting for use cases from MOA
      }
    }
    catch (error) {
      yield catchApiExceptions(ActionTypeFailure, error, args);
    }
  };
