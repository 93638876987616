import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useEffect } from 'react';

import {
  useTheme,
  useTranslation,
  OfflineAnnounceLayout360,
  Spinner,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { useLogoNavigation } from '@utils/index';

const FConnectSignUp : React.FC = () => {
  const theme: any = useTheme();
  const { formatMessage } = useTranslation();
  const navigation = useNavigation();
  const { logoNavigateTo } = useLogoNavigation();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeConfirm = (urlParams.get('confirm'))?.toString();
    if (!codeConfirm || codeConfirm === 'false' || codeConfirm === 'na') {return  navigation.navigate(RouteNames.Error, {
      redirectionRouteName: RouteNames.RelationshipEntringProcess,
      redirectionRouteParams: {
        screen: RouteNames.ValidateSignup,
      },
      errorTitleLabel: 'validate-identity_FCerror_title',
      errorDescriptionLabel: 'validate-identity_FCerror_label',
      errorButtonTitleLabel: 'validate-identity_FCerror_button',
    });}

    return navigation.navigate(RouteNames.RelationshipEntringProcess, { screen: RouteNames.ValidateSignupThankYou });
  }, []);
  return (
    <OfflineAnnounceLayout360
      description={formatMessage({ id: 'login_fc-backpage_description' })}
      onLogoPress={logoNavigateTo}
      sourceName="waitingProcess"
      title={formatMessage({ id: 'login_fc-backpage_title' })}
    >
      <Spinner color={theme.colors.green} />
    </OfflineAnnounceLayout360>
  );
};

export default FConnectSignUp;
