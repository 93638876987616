import React from 'react';
import { connect } from 'react-redux';

import { getEditorialContentRequest } from '@modules/profile/actions/profileActions';
import { AppState } from '@modules/index';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import { getConsents, getEditorialContent, getProfileError } from '@modules/profile/selectors';

import { EditorialPage as EditorialPageToConnect } from './EditorialPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    editorialContent: getEditorialContent(state),
    consents: getConsents(state),
    isGetEditorialContentLoading: state.isLoading[ProfileActionsType.GET_EDITORIAL_CONTENT_REQUEST],
    error: getProfileError(state),
  };
};
const mapDispatchToProps = ({
  onGetEditorialContent: getEditorialContentRequest,
});
export const EditorialPage = connect(mapStateToProps, mapDispatchToProps)(EditorialPageToConnect);
