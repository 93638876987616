import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle, Platform, Linking } from 'react-native';

import { Link, Text, Title } from "@ere-uilib/atoms";
import { createUseStyles, useTheme, ThemeType } from "@ere-uilib/styles";
import { useTranslation } from "@ere-uilib/translations";
import { ApplicationSettingsState, BankDetailsAdress } from '@ere-uilib/types';

interface Props extends Styles {
  containerStyle?: StyleProp<ViewStyle>
  chequeInfoData: BankDetailsAdress
  appSettings: ApplicationSettingsState
}

type Styles = {
  containerStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  subContainerStyle?: StyleProp<ViewStyle>;
  leftIconStyle?: StyleProp<TextStyle>;
  titleIconStyle?: StyleProp<ViewStyle>;
  commentContainer?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType };

export const ChequeInfosCard: React.FC<Props> = ({
  containerStyle,
  chequeInfoData,
  appSettings,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, { containerStyle });
  const { formatMessage } = useTranslation();

  return (
    <View style={styles.containerStyle}>
      <View style={styles.subContainerStyle}>
        <View style={styles.titleIconStyle}>
          <Title
            style={styles.titleStyle}
            variant="t7">
            {formatMessage({
              id: 'Remboursement_adressePostale_Content',
            })}
          </Title>
          {/* <FontIcon
            name="editer"
            style={styles.leftIconStyle} /> */}
        </View>
        <Text
          variant="t3"
          weight="light">
          {`${formatMessage({
            id: "Remboursement_adressePostale_intro",
          })} ${chequeInfoData?.recipient}`}
        </Text>
        <Text
          variant="t3"
          weight="light">
          {chequeInfoData?.street}
        </Text>

        <Text
          variant="t3"
          weight="light">
          {chequeInfoData?.additionalAddress_1}
        </Text>
        <Text
          variant="t3"
          weight="light">
          {chequeInfoData?.additionalAddress_2}
        </Text>
        <Text
          variant="t3"
          weight="light">
          {chequeInfoData?.additionalAddress_3}
        </Text>
        <Text
          variant="t3"
          weight="light">
          {`${chequeInfoData?.zipcode}`} {` ${chequeInfoData?.city}`}
        </Text>
        <View style={styles.commentContainer}>
          <Text
            variant="t3"
            weight="light">
            {`${formatMessage({
              id: 'Remboursement_adressePostale_Content1',
            })} `}
            <Link
              onPress={() => {
                Platform.OS === 'web' ?
                  window.open(appSettings?.RemboursementSyntheseTarifs, '_blank')
                  : Linking.openURL(appSettings?.RemboursementSyntheseTarifs);
              }}>{formatMessage({
                id: 'Remboursement_tarifvigeur_lien'
              })
              }</Link>
          </Text>
        </View>
      </View>
    </View>
  );
};

const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  containerStyle: [
    {
      flex: 1,
    },
    style?.containerStyle,
  ],
  titleStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xs,
    },
    style?.titleStyle,
  ],
  subContainerStyle: [
    {
    },
    style?.subContainerStyle,
  ],
  leftIconStyle: [
    {
      fontSize: context?.theme.metrics.iconSizes.xxm,
      color: context?.theme.colors.basics.primary.c500,
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingTop: context?.theme.metrics.spacing.xxs,
    },
    style?.leftIconStyle,
  ],
  titleIconStyle: [
    {
      flex: 1,
      flexDirection: 'row',
      marginTop: context?.theme.metrics.spacing.m,
    },
    style?.titleIconStyle,
  ],

  commentContainer: [
    {
      flex: 1,
      flexDirection: 'row',
      marginTop: context?.theme.metrics.spacing.s,
    },
    style?.commentContainer,
  ],
});

const useStyles = createUseStyles(getStyles);
