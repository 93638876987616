import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect, useMemo } from 'react';

import {
  ValidationCodePageTemplate,
  useTranslation,
} from '@components/index';
import { InvitationState } from '@modules/invitation/types';
import { IdentityValidationErrorState } from '@modules/validate-identity/types';
import { useLogoNavigation } from '@utils/index';

interface Props {
  route: any;
  isValidateEEREmailNewIdentifierApiLoading: boolean
  isValidateEEREmailNewIdentifierCodeApiLoading: boolean
  validateIdentityError: IdentityValidationErrorState
  validateEEREmailNewIdentifier: (mail:string) => void
  validateEEREmailNewIdentifierCode: (code: string) => void
  invitation: InvitationState
  mail: string
}

export const ChooseIdentificationEmailCodePage: React.FC<Props> = ({
  route,
  mail,
  validateIdentityError,
  isValidateEEREmailNewIdentifierApiLoading,
  isValidateEEREmailNewIdentifierCodeApiLoading,
  validateEEREmailNewIdentifier,
  validateEEREmailNewIdentifierCode,

}) => {
  const navigation = useNavigation();
  const [resetOTP, setResetOTP] = useState(false);
  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();

  // if screen was already rendered in navigation and we navigate again to it, it was not reseting the OTP
  // so we test the update of the route to ask to reset the OTP
  useEffect(() => {
    setResetOTP(!resetOTP);
  }, [route]);

  const onRetryEventOnPress = () => {
    setResetOTP(!resetOTP);
    validateEEREmailNewIdentifier(mail);
  };

  const onValidateIdentity = (otpCode:string)=> {
    validateEEREmailNewIdentifierCode(otpCode);
    setResetOTP(!resetOTP);
  };
  const canGoBack = navigation.canGoBack();
  const handleGoBack = useMemo(()=> canGoBack ? navigation.goBack : undefined, [canGoBack]);

  return (
    <ValidationCodePageTemplate
      description={`${formatMessage({ id: 'forgot-pwd-newpassword-label' })} ${mail}`}
      EERLeftMenuActiveStep={2}
      errorMessage={validateIdentityError.message}
      infoNotificationText={`${formatMessage({ id: 'validate-identity_info-unfound' })}\n${formatMessage({ id: 'validate-identity_info-instruction' })}`}
      isApiLoading={isValidateEEREmailNewIdentifierCodeApiLoading}
      isEERLeftMenu
      isRetryEventApiLoading={isValidateEEREmailNewIdentifierApiLoading}
      onBackPress={handleGoBack}
      onLogoPress={logoNavigateTo}
      onPress={onValidateIdentity}
      onRetryEventOnPress={onRetryEventOnPress}
      resetOTP = {resetOTP}
      title={formatMessage({ id: 'verify-identity_menu' })}
    />
  );
};

