import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  LoaderPlaceholderView,
  NavigationHeader,
  RetryActionPlaceHolder,
  SimpleButton,
  useScreenSizes,
  useTranslation,
  CircleLoader,
  OperationsHeader,
  Text,
  OperationsHistoryStatusEnum,
} from '@components/index';
import { OperationFamilyEnum } from '@constants/index';
import { RouteNames } from '@constants/navigation';

import { OperationDetailsContent } from './components';
import { useOperationDetailsHeaderData } from './hooks'
import { OperationDetailsProps } from './interface';
import { useStyles } from './style';

export const OperationDetailsPageComponent: React.FC<OperationDetailsProps> = ({
  onGetOperationDetails,
  route,
  operationDetails,
  isLoading,
  error,
}) => {
  const navigation = useNavigation();
  const { isDesktop } = useScreenSizes();
  const {
    formatMessage,
    getMessageRaw,
  } = useTranslation();

  const styles = useStyles();

  const operationId = route.params.id;
  const operationFamily = operationDetails?.family;

  const isArbitration = operationFamily === OperationFamilyEnum.ARBITRATION;

  const isRefund = operationFamily === OperationFamilyEnum.REFUND;

  const isAmountHidden = operationDetails?.brutAmount === 0;

  const errorArbitration = (operationDetails?.source?.plans?.length === 0 ||
    operationDetails?.destination?.plans?.length === 0);

  const errorRefund = (operationDetails?.source?.plans?.length === 0 ||
    operationDetails?.destination?.payment === null);

  const isRetryActionPlaceholderVisible =
    !!error?.message ||
    (operationDetails?.statusCode !== OperationsHistoryStatusEnum.ANNULE &&
      ((isRefund && errorRefund) || (isArbitration && errorArbitration)));

  const {
    operationDetailsHeaderDetailList,
    operationDetailsHeaderAdvantageList
  } = useOperationDetailsHeaderData({
    operationDetails
  });

  const handleCancelOperation = useCallback(() => {
    navigation.navigate(RouteNames.OperationCancel)
  }, [navigation]);

  const renderLoader = useCallback(() => (
    <View style={styles.loaderContainer}>
      <CircleLoader diameter={36} />
      <LoaderPlaceholderView style={styles.loader} />
    </View>
  ), [styles]);

  const renderHeader = useCallback(() => (
    <NavigationHeader
      displayBackButton={true}
      helpPoint
      helpPointContentHtml={getMessageRaw({ id: 'DetailOperationInfoContent' })}
      helpPointModalTitle={formatMessage({ id: 'DetailOperationInfoTitle' })}
      helpPointPosition={'right'}
      onGoBack={() => navigation.goBack()}
      title={formatMessage({ id: 'DetailOperationTitrePage' })}
    />
  ), [formatMessage, getMessageRaw, navigation]);

  const renderOperationHeader = useCallback(() => {
    if (isLoading || !!error?.message || !operationDetails) return
    const {
      brutAmount,
      creationDate,
      label,
      statusCode,
      statusLabel,
    } = operationDetails

    const creationDateAsDate = !!creationDate ? new Date(creationDate) : undefined;

    const originTitleLabelCode = operationDetails?.label ? operationDetails?.label : operationDetails?.type;

    return (
      <OperationsHeader
        advantageList={operationDetailsHeaderAdvantageList}
        amount={brutAmount}
        creationDate={creationDateAsDate}
        details={operationDetailsHeaderDetailList}
        hideAmount={isAmountHidden}
        statusLabel={statusLabel}
        statusType={statusCode}
        title={originTitleLabelCode}
      />
    )
  }, [
    isLoading,
    error?.message,
    operationDetails,
    operationDetailsHeaderAdvantageList,
    operationDetailsHeaderDetailList,
    isAmountHidden
  ]);

  const renderBottomActions = useCallback(() => {
    if (!operationDetails?.cancelable || isLoading) return;

    const description = formatMessage({ id: 'RetirementJourneyOperationDetailsInfoText' });

    return (
      <View style={styles.bottomActions}>
        {!!description &&
          <Text
            style={styles.bottomActionsText}
            variant="t3"
            weight="light">
            {description}
          </Text>
        }
        <SimpleButton
          containerStyle={styles.bottomActionsButtonContainer}
          design="outlined"
          onPress={handleCancelOperation}
          title={formatMessage({ id: 'DetailOperationAnnulerBouton' })}
        />
      </View>
    );
  }, [
    styles,
    formatMessage,
    operationDetails?.cancelable,
    isLoading,
    handleCancelOperation
  ]);

  const renderContent = useCallback(() => {
    if (isRetryActionPlaceholderVisible)
      return <RetryActionPlaceHolder retryAction={() => onGetOperationDetails(operationId)} />;

    return <OperationDetailsContent/>;
  }, [
    isRetryActionPlaceholderVisible,
    operationId,
    onGetOperationDetails
  ]);

  useEffect(() => {
    onGetOperationDetails(operationId);
  }, [onGetOperationDetails, operationId]);

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        hasColumnCenter
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={styles.contentContainer}
        renderHeader={renderHeader}
        renderStickyMobileBottom={renderBottomActions}
        renderTopContent={renderOperationHeader}>
        {isLoading && renderLoader()}
        {!isLoading && renderContent()}
        {isDesktop && renderBottomActions?.()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
