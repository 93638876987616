import { connect } from 'react-redux';

import { logoutCallbackSuccess, signinRedirectCallbackMobileAppRequest } from '@modules/auth/actions/authActions';
import { AppState } from '@modules/reducers';
import {
  getAuthConfig,
  getApplicationSettings,
} from '@modules/settings/selectors';

import { OidcLogoutCallbackRedirectPage as OidcLogoutCallbackRedirectPageComponent } from './OidcLogoutCallbackRedirectPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    authConfig: getAuthConfig(state),
    applicationSettings: getApplicationSettings(state),
  };
};

const mapDispatchToProps = {
  logoutCallbackSuccess: logoutCallbackSuccess,
  loginOpenIdMobileApp: signinRedirectCallbackMobileAppRequest
};

export const OidcLogoutCallbackRedirectPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OidcLogoutCallbackRedirectPageComponent);
