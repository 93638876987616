import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  DispositifChoicePopin,
  EligibilityRecapCard,
  Link,
  NotificationHard,
  NotificationIconTypeEnum,
  RequirementDefinition,
  SimpleButton,
  useTranslation,
  useScreenSizes
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { PlanFamillyList } from '@ere-uilib/constants';
import {
  DirectionEnum
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { DispositifState, OperationDecisionState, ResponseType } from '@modules/common/types';
import { constructStepperItems } from '@pages/Common/utils';
import { getEligibleDispositifs, manageImplicitResponse } from '@utils/eligiblePlansCompute';

import { InstallmentHeader } from '../components/InstallmentHeader';
import { ImplicitResponseType } from '../interfaces';
import { useStyles } from './useStyles';

interface Props {
  setEligibleDispositifs: (elligibleDispositifs: DispositifState[]) => void;
  requirementDecisionData: OperationDecisionState;
  onUpdateQuestion: (
    responsePayload: ResponseType,
    index: number,
    canNotBeAsked: boolean
  ) => void;
  onBackToQuestion: (index: number) => void;
  onSelectDispositif: (dispositif: DispositifState[]) => void;
}

export const RequirementDefinitonComponent: React.FC<Props> = ({
  setEligibleDispositifs,
  requirementDecisionData,
  onUpdateQuestion,
  onBackToQuestion,
  onSelectDispositif,
}) => {
  const refDashboardContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>;
  const refColumnsContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>;
  const { isDesktop } = useScreenSizes()
  const styles = useStyles();
  const navigation = useNavigation();
  const { formatMessage, getMessageRaw, formatCurrencyNumber } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isEditing, setEditing] = useState(false);
  const isMandatoryQuestions = requirementDecisionData?.questions?.some(
    question => question?.isRequired
  );
  const isOptionalQuestions = requirementDecisionData?.questions?.some(
    question => !question?.isRequired
  );
  const isOnlyRequiredQuestionModeDefaultValue = isMandatoryQuestions && isOptionalQuestions;
  const [
    isOnlyRequiredQuestionMode,
    setIsOnlyRequiredQuestionMode,
  ] = useState<boolean>(isOnlyRequiredQuestionModeDefaultValue);
  const filteredQuestions = isOnlyRequiredQuestionMode ? requirementDecisionData?.questions?.filter(
    question => question?.isRequired
  ) : requirementDecisionData?.questions;
  const pageTitle = formatMessage({
    id: 'VV_needs_VoiciEtapes_DefinitionBesoins_label',
  });

  const stepperItems = constructStepperItems({
    size: 4,
    direction: DirectionEnum.ROW,
    activeStep: 1,
  });

  const dictionary = {
    totalAmountTitle: (amount: number) => formatMessage({
      id: 'Arbitrage_ChoixDisponible_contentMontant',
      values: { montant: formatCurrencyNumber({ value: amount }) },
    })
  }

  const toggleModal = (modalVisible: boolean) => {
    modalVisible === true || modalVisible === false
      ? setIsModalVisible(modalVisible)
      : setIsModalVisible(!modalVisible);
  };
  const handleValidateInformativePopin = () => {
    toggleModal(false);
    navigation.navigate(RouteNames.InstallmentStack, {
      screen: RouteNames.InstallmentsRepartition,
    });
  };
  const respondedQuestion = filteredQuestions?.filter(
    question => question?.responded
  );
  const unRespondedQuestion = filteredQuestions?.filter(
    question => !question?.responded
  );

  const isFilterQuestionsButtonVisible = unRespondedQuestion.length === 0 && isOnlyRequiredQuestionMode;

  const alreadyResponded: (ResponseType | undefined)[] = respondedQuestion.map(item => item.responded);

  const setEligibilityAndNavigate = () => {
    const eligibleDispositifsData = getEligibleDispositifs(
      requirementDecisionData.dispositifs,
      alreadyResponded
    )?.eligibleDispositifs;

    const eligibleDispositifs = eligibleDispositifsData?.filter(
      dispositif => dispositif.isEligible === true
    );

    eligibleDispositifs && setEligibleDispositifs(eligibleDispositifs);
    if (eligibleDispositifs.length === 1) {
      onSelectDispositif(eligibleDispositifs);
      toggleModal(true);
    } else if (eligibleDispositifs.length > 1) {
      navigation.navigate(RouteNames.InstallmentStack, {
        screen: RouteNames.InstallmentPlanChoice,
      });
    }
  };

  // check question displaying
  useEffect(() => {
    if (isEditing) {
      return;
    }

    const returnedValue: ImplicitResponseType | undefined =
      manageImplicitResponse(
        requirementDecisionData.dispositifs,
        unRespondedQuestion,
        respondedQuestion,
        filteredQuestions
      );
    if (returnedValue && returnedValue?.response) {
      return onUpdateQuestion(
        returnedValue.response,
        returnedValue.indexOfQuestion,
        returnedValue.canNotBeAsked
      );
    }
  }, [
    isEditing,
    onUpdateQuestion,
    unRespondedQuestion,
    requirementDecisionData,
    filteredQuestions,
    respondedQuestion,
  ]);

  const renderHeader = useCallback(
    () => (
      <InstallmentHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'VV_needs_info_content' })}
        helpPointModalTitle={formatMessage({ id: 'VV_repartition_info_label' })}
        helpPointPosition="left"
        stepperItems={stepperItems}
        title={pageTitle}
      />
    ),
    [pageTitle, stepperItems, getMessageRaw, formatMessage]
  );
  const renderButtonSubmit = () => (
    <View>
      {isFilterQuestionsButtonVisible && (
        <Link
          onPress={() => setIsOnlyRequiredQuestionMode(false)}
          textStyle={styles.linkAddOptionalQuestions}
          textVariant="t3"
        >
          {formatMessage({ id: 'VV_needs_ContinueQuestions_link_label' })}
        </Link>
      )}
      <SimpleButton
        disabled={unRespondedQuestion.length !== 0}
        onPress={setEligibilityAndNavigate}
        testId={locator._payment._allocate_my_payment}
        title={formatMessage({ id: 'VV_needs_nextstep_button' })}
      />
    </View>
  );

  const renderOngoingVVPByDispositifs = useCallback(() => {
    if (unRespondedQuestion.length > 0) {
      return null;
    }
    const elligibleDispositifs = getEligibleDispositifs(
      requirementDecisionData.dispositifs,
      alreadyResponded,
    ).eligibleDispositifs;

    const onGoingRcVvpDispositifs = elligibleDispositifs.filter(dispositif => {
      const isEligible = dispositif.isEligible;
      const hasOnGoingRcVvp = dispositif.hasOnGoingRcVvp
        && dispositif.planFamily === PlanFamillyList.RC;
      return isEligible && hasOnGoingRcVvp;
    });

    return onGoingRcVvpDispositifs.map(dispositif => (
      <NotificationHard
        containerStyle={styles.onGoingVvpDispositifNotification}
        text={formatMessage({
          id: 'VV_VVP_disponible_RCinfo_description',
          values: { dispositif: dispositif.name },
        })}
        title={formatMessage({ id: 'VV_VVP_disponible_RCinfo_titre' })}
        type={NotificationIconTypeEnum.WARNING}
      />
    ));

  }, [
    requirementDecisionData.dispositifs,
    alreadyResponded,
    unRespondedQuestion,
    styles.onGoingVvpDispositifNotification,
    formatMessage,
  ]);

  const elligibleDispositifs = getEligibleDispositifs(
    requirementDecisionData.dispositifs,
    alreadyResponded,
  )?.eligibleDispositifs;

  const renderRecapEligibility = () => {

    const mappedElligibleDispositifs = elligibleDispositifs?.map(item => ({
      name: item.name,
      isEligible: !!item.isEligible,
    }));
    return (
      <>
        <EligibilityRecapCard
          cardDescription={formatMessage({ id: 'VV_needs_eligibility_label' })}
          cardTitle={formatMessage({ id: 'VV_needs_results_title' })}
          elligibleDispositifs={mappedElligibleDispositifs}
        />
        {renderOngoingVVPByDispositifs()}
      </>
    );
  };

  const handleScrollToTop = useCallback(() => {
    if (isDesktop) {
      refDashboardContainer?.current?.scrollToTop();
    } else {
      refColumnsContainer?.current?.scrollToTop();
    }
  }, [
    refColumnsContainer,
    refDashboardContainer,
    isDesktop
  ])

  const handelUpdateQuestion = useCallback((
    responsePayload,
    index,
    canNotBeAsked
  ) => {
    handleScrollToTop()
    onUpdateQuestion(responsePayload, index, canNotBeAsked)
  }, [
    onUpdateQuestion,
    handleScrollToTop
  ])

  const handleBackToQuestion = useCallback(index => {
    setEditing(true);
    onBackToQuestion(index)
  }, [onBackToQuestion]);

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={styles.dashboardPageWrapperConnected}
      dashboardPageWrapperRef={refDashboardContainer}
      renderStickyMobileBottom={renderButtonSubmit}
    >
      <ColumnsContainer
        columnsContainerRef={refColumnsContainer}
        renderHeader={renderHeader}
        renderRightDesktopColumn={renderRecapEligibility}
      >
        <RequirementDefinition
          dictionary={dictionary}
          onBackToQuestion={handleBackToQuestion}
          onUpdateQuestion={handelUpdateQuestion}
          renderEligibilityRecap={renderRecapEligibility()}
          renderSubmitButton={
            unRespondedQuestion.length === 0 && renderButtonSubmit()
          }
          requirementDecisionData={{
            ...requirementDecisionData,
            questions: filteredQuestions,
          }}
        />
      </ColumnsContainer>
      <DispositifChoicePopin
        isModalVisible={isModalVisible}
        onValidate={handleValidateInformativePopin}
        testId={locator._payment._i_understand}
      />
    </DashboardPageWrapperConnected>
  );
};
