import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  NavigationHeader,
  SimpleButton,
  SVGLocalLoader,
  Title,
  Text,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';

import { BudgetInsightErrorState } from './interfaces';
import { getStyles } from './styles';

export const BudgetInsightErrorComponent: React.FC<BudgetInsightErrorState> = ({ 
  errors,
}) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const { isMobile, isTablet } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile }, {});
  const navigation = useNavigation();

  const handleNavigateToDashboard = () => {
    navigation.navigate(RouteNames.BottomTabNavigator, {
      screen: RouteNames.Home
    });
  }
  
  const renderHeader = () => {
    return (
      <NavigationHeader
        displayCloseButton
        helpPoint={true}
        helpPointContentHtml={getMessageRaw({ id: 'AgregationCardConsentementInfoDescription' })}
        helpPointModalTitle={formatMessage({ id: 'AgregationCardConsentementInfoTitre' })}
        helpPointPosition="left"
        onClose={handleNavigateToDashboard}
        title={formatMessage({ id: 'AgregationCardConsentementTitre' })}
      />
    );
  };

  const renderSubmitButton = () => {
    return (
      <SimpleButton
        onPress={handleNavigateToDashboard}
        title={formatMessage({ id: 'error_redirect_button' })}
      />
    );
  };

  return (
    <DashboardPageWrapperConnected 
      cardContentStyle={styles.containerStyle}
      renderStickyMobileBottom={renderSubmitButton}
      >
      <ColumnsContainer
        containerStyle={styles.containerStyle}
        hasColumnCenter
        renderHeader={renderHeader}
        >
        <>
          <View style={styles.innerCardStyle}>
            <View style={styles.imageContainerStyle}>
              <SVGLocalLoader name="budgetinsightRedirect" />
            </View>
            <View
              style={styles.textsContainerStyle}
            >
              <Title
                style={styles.titleStyle}
                variant="t4"
                weight="bold">
                {formatMessage({ id: 'error_title' })}
              </Title>
              <Text>
                {formatMessage({
                  id: errors.budgetInsightError?.innerMessage || 'AgregationCardErreurBug'
                })}
              </Text>
            </View>
            {!(isMobile || isTablet) && renderSubmitButton()}
          </View>
        </>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
