import React from 'react';

import {
  LegalMenuFooterConnected,
} from '@components/ERE360Components';
import {
  useScreenSizes,
  OfflineAnnounceLayout,
  OfflineAnnounceLayoutProps,
} from '@ere-uilib/index';

export const OfflineAnnounceLayout360: React.FC<OfflineAnnounceLayoutProps> = ({
  renderMenuLegalFooter,
  ...otherProps
}) => {
  const { isDesktop } = useScreenSizes();
  const defaultMenuLegalFooter = ()=>(<LegalMenuFooterConnected
    isWhite={isDesktop}
  />);
  return (
    <OfflineAnnounceLayout
      {...otherProps}
      renderMenuLegalFooter={defaultMenuLegalFooter || renderMenuLegalFooter}
    />
  );
};
