import { useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

export const useStyles = () => {
  const theme = useTheme();

  return useMemo(() => StyleSheet.create({
    container:
      {
        flexDirection: 'row',
        flex: 1,
        backgroundColor: theme.colors.basics.white
      },
    sidebarContainer:
      {
        backgroundColor: theme.colors.background.verticalMenu.c500,
        minWidth: 304, //TODO Contextual metrics
        width: 304,
        maxWidth: 304,
        minHeight: '100%',
        height: '100%',
        paddingTop: 15,
        borderRightWidth: 1,
        borderRightColor: theme.colors.borders.fields.c200,
        zIndex: 1,
      },
    menuSeparatorStyle:
      {
        width: '100%',
        height: 1,
        backgroundColor: theme.colors.basics.blackTransparent.c200,
        marginTop: theme.metrics.spacing.xxl,
        marginBottom: theme.metrics.spacing.xxl
      },
    contentStyle:
      {
        flex: 1
      },
    logoBnpStyle:
      {
        marginLeft: 20,
        marginBottom: 35
      },
    desktopMenuIconTabsContainerStyle:
      {
        marginBottom: 40
      },
    wishMenuButton:
      {
        backgroundColor: theme.colors.basics.primary.c500,
        height: 48,
        width: 260,
        left: 20,
        marginBottom: theme.metrics.spacing.xxl,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 38,
      },
    iconStyle:
      {
        marginRight: theme.metrics.spacing.s,
      },
    wishMenuTitle:
      {
        marginRight: theme.metrics.spacing.s,
        color: theme.colors.basics.white,
      },
    helpActionMenuButton:
      {
        color: theme.colors.buttons.classic.c500,
        marginLeft: 20,
        cursor: "pointer",
        textDecorationLine: "underline",
      },
  }), [theme]);
};