import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import {
  Title,
} from '@ere-uilib/atoms';
import {
  LinkBar,
  NavigationHeader,
} from '@ere-uilib/molecules';
import {
  useTheme,
  createUseStyles,
  useScreenSizes,
  ThemeType,
} from '@ere-uilib/styles';
import {
  useTranslation,
} from '@ere-uilib/translations';

interface DataProps {
  isModal?: boolean
  onCloseModal?: () => void
  onGoBackButtonPress?: () => void
  name?: string | null
  innerNavigations: string[]
  selectedNavigation: string
  setSelectedNavigation: (item: string) => void

}

type FundSheetHeaderStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  headerContainer?: StyleProp<ViewStyle>;
  bandContainerStyle?: StyleProp<ViewStyle>;
  titleContainerStyle?: StyleProp<ViewStyle>;
  titleTextStyle?: StyleProp<TextStyle>;
  linkbarStyle?: StyleProp<ViewStyle>;
};

type FundSheetHeaderStylesContext = {
  theme: ThemeType;
  isMobile?: boolean;
  isTablet?: boolean;
};

type Props = DataProps & FundSheetHeaderStyles

export const FundSheetHeader: React.FC<Props> =  ({
  name,
  isModal = false,
  onCloseModal,
  onGoBackButtonPress,
  innerNavigations,
  selectedNavigation,
  setSelectedNavigation,
  headerContainer,
  containerStyle,
}: Props) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const { formatMessage } = useTranslation();
  const styles = useStyles({ theme, isMobile, isTablet }, { containerStyle, headerContainer });

  return (
    <View style={styles.containerStyle}>
      <NavigationHeader
        displayBackButton={!isModal && true}
        displayCloseButton={isModal && true}
        onClose={onCloseModal}
        onGoBack={onGoBackButtonPress}
        title={formatMessage({ id: 'fund-card_menu' })}
      />
      <View style={styles.headerContainer}>
        <View style={styles.bandContainerStyle}>
          <View style={styles.titleContainerStyle}>
            <Title
              style={styles.titleTextStyle}
              testId='fund_card_title'
              variant="t6"
              weight={'bold'}>
              {name?.toUpperCase()}
            </Title>
          </View>
        </View>
        <LinkBar
          backgroundColor={theme.colors.basics.grey.c100}
          items={innerNavigations}
          onSelect={setSelectedNavigation}
          selectedItem={selectedNavigation}
          testId='fundSheet'
        />
      </View>
    </View>
  );
};

const getStyles = (
  context?: FundSheetHeaderStylesContext,
  style?: FundSheetHeaderStyles
): FundSheetHeaderStyles => ({
  containerStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white,
    },
    style?.containerStyle,
  ],
  headerContainer: [
    {
      flexDirection: 'column',
      minHeight: 130,
    },
    style?.headerContainer,
  ],
  bandContainerStyle: [{
    minHeight: 90,
    backgroundColor: context?.theme.colors.basics.grey.c100,
  }, style?.bandContainerStyle],
  titleContainerStyle: [{
    marginTop: context?.theme.metrics.spacing.l,
    marginHorizontal: context?.theme.metrics.spacing.s,
  }, style?.titleContainerStyle],
  titleTextStyle: [{
    textAlign: (context?.isMobile || context?.isTablet) ? 'left' : 'center',
  }, style?.titleTextStyle],
});

const useStyles = createUseStyles(getStyles);
