import React, { useMemo } from 'react';
import { BulletList } from 'react-content-loader/native';
import {
  useWindowDimensions,
  View,
} from 'react-native';

import { Text } from '@ere-uilib/atoms';
import {
  SavingsEvolutionChart,
  SavingsEvolutionChartDataItemProps,
  MultiSwitch,
} from '@ere-uilib/molecules';
import {
  HelpPoint,
  SavingsPlaceholder,
} from '@ere-uilib/organisms';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { SavingsEvolutionCardProps } from './interface';
import { getStyles } from './styles';

export const SavingsEvolutionCard: React.FC<SavingsEvolutionCardProps> = ({
  amount,
  selectedPlan,
  savingsHistory,
  savingsHistoryFilters,
  onApplySavingsHistoryFilters,
  isSavingsEvolutionLoading,
  planName,
  containerStyle,
  dotStyle,
  labelsViewStyle,
}) => {
  const theme = useTheme();
  const { formatMessage, getMessageRaw } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const { width, height } = useWindowDimensions()
  const bulletListHeight = useMemo(() => {
    return height / 4;
  }, [])
  const styles = useStyles(
    { theme, isMobile, isTablet, isDesktop },
    {
      containerStyle,
      dotStyle,
      labelsViewStyle,
    },
  );
  const remappedSavingsHistoryForGraph: SavingsEvolutionChartDataItemProps[] = useMemo(() =>
    savingsHistory?.map(item => ({
      x: item.date,
      y: item.savingAmount,
      totalOperationsAmount: item.operationsAmount,
      operations: item.operations.map(operation => ({
        amount: operation.amount,
        date: operation.dateTime,
      })),
    })), [savingsHistory])
  const handlePeriodChange = (period: number) => {
    onApplySavingsHistoryFilters({
      ...savingsHistoryFilters,
      period,
    });
  };

  const periodList = [
    { value: 1, label: formatMessage({ id: 'Saving_Tab5_filter_1year_label' }) },
    { value: 3, label: formatMessage({ id: 'Saving_Tab5_filter_3years_label' }) },
    { value: 5, label: formatMessage({ id: 'Saving_Tab5_filter_5years_label' }) },
  ];
  const isDataUsable =
    (remappedSavingsHistoryForGraph && remappedSavingsHistoryForGraph.length > 1);
  return (
    <>
      <View style={styles.containerStyle}>
        <View style={styles.headerStyle}>
          <MultiSwitch
            onChange={handlePeriodChange}
            options={periodList}
            value={savingsHistoryFilters?.period}
          />
          <HelpPoint
            contentHtml={getMessageRaw({
              id: `saving_Tab5_info_content_${planName}`,
              defaultMessage: ' ',
            })}
            iconSize={theme.metrics.iconSizes.l}
            modalTitle={formatMessage({ id: 'fund-card_help-info_menu' })}
          />
        </View>
        {isSavingsEvolutionLoading
          ? (
            <View>
              <BulletList
                style={{ flex: 1 }}
                width={'100%'}
                height={bulletListHeight}
              />
            </View>
          ) : isDataUsable ? (
            <>
              <View style={styles.labelsViewStyle}>
                <View style={styles.labelsViewStyle}>
                  <View
                    style={[styles.dotStyle, {
                      backgroundColor: theme.colors.basics.primary.c500,
                    }]}
                  />
                  <Text variant="t4">{formatMessage({ id: 'Saving_Tab5_mysavings_label' })}</Text>
                </View>
                <View style={[styles.labelsViewStyle, { marginLeft: theme.metrics.spacing.s }]}>
                  <View
                    style={styles.dotStyle}
                  />
                  <Text variant="t4">
                    {formatMessage({ id: 'Saving_Tab5_myoperations_label' })}
                  </Text>
                </View>
              </View>
              <Text
                style={{
                  marginTop: theme.metrics.spacing.xm,
                }}
                variant="t4"
                weight="light"
              >
                {formatMessage({
                  id: 'Fundsheet_Tab5Perf_Valuation_title',
                  values: { variable: '€' },
                })}
              </Text>
              <SavingsEvolutionChart
                data={remappedSavingsHistoryForGraph}
                numberOfYearPeriod={savingsHistoryFilters?.period}
              />
            </>
          ) : (
            <SavingsPlaceholder
              titleLabelId={selectedPlan === 0
                ? 'Saving_Tab1_noFundsAll_label'
                : selectedPlan !== 0
                  ? 'Saving_Tab1_noFunds_label'
                  : 'Saving_Tabs_noDataFiltered_label'
              }
            />
          )
        }
      </View>

    </>
  );
};

const useStyles = createUseStyles(getStyles);
