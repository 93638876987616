
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import {
  DashboardPageWrapperConnected,
  useTranslation,
  useTheme,
  ColumnsContainer,
  SimpleButton,
  Title,
  Text,
  TextField,
  useScreenSizes,
  IconEnum,
} from '@components/index';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { constructStepperItems } from '@pages/Common/utils';

import { BankDataHeader } from '../components';
import { Props } from './interfaces';
import { useStyles } from './useStyles';
import { RouteProp, useRoute } from '@react-navigation/native';
import { BankOperationTypesList } from '@constants/savings';

const ibantools = require('ibantools');


type CopyIbanParam = {
  CopyIban: {
    operationType: string
  }
}

export const BankCopyIbanPage: React.FC<Props> = ({
  bankData,
  navigation,
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const styles = useStyles();


  const [isIbanValid, setIsIbanValid] = useState(false);
  const [isBicValid, setIsBicValid] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const stepperItems = constructStepperItems({
    size: 2,
    direction: DirectionEnum.ROW,
    activeStep: 1
  });

  const route = useRoute<RouteProp<CopyIbanParam, 'CopyIban'>>();
  const { operationType } = route.params;

  const ibanBottomPlaceholder = "(ex.FR76 1234 2345 2542 2345 2423 234)";
  const bicBottomPlaceholder = "(ex.ADFS FRPP XXX)";
  const [inputIban, setInputIban] = useState("");
  const [inputBic, setInputBic] = useState("");
  const [operationTypeTitle, setOperationTypeTitle] = useState("");
  const [inputTitulaire, setInputTitulaire] = useState("");

  const [operationTypeDescription, setOperationTypeDescription] = useState("");

  const { isMobile, isTablet } = useScreenSizes();
  useEffect(() => {

    setOperationTypeTitle(operationType === BankOperationTypesList.PRELEVEMENT ? "CoordonneesBancairesSaisieRemboursementTitre" : "CoordonneesBancairesSaisiePrelevementTitre");
    const accountData = operationType === BankOperationTypesList.PRELEVEMENT ? bankData.es?.transferBankAccountDetails : bankData.es?.debitBankAccountDetails;
    setInputIban(accountData?.iban || "");
    setInputBic(accountData?.bic || "");
    setInputTitulaire(accountData?.titulaire || "");
    setOperationTypeDescription(operationType === BankOperationTypesList.PRELEVEMENT ? "CoordonneesBancairesSaisieRemboursementDescription" : "CoordonneesBancairesSaisiePrelevementDescription");
  }, [])

  const renderBottomActions = useCallback(() => (
    <View style={styles.bottomActionsStyle}>
      <View style={styles.bottomActionsButtonsContainerStyle}>
        <SimpleButton
          design="outlined"
          leftIcon={IconEnum.CHEVRON_LEFT}
          onPress={() => navigation.goBack()}
        />
        <SimpleButton
          containerStyle={styles.simpleButtonStyle}
          design="solid"
          disabled={buttonDisabled}
          onPress={() => { }}
          size="small"
          title={formatMessage({ id: 'CoordonneesBancairesSaisieCopieIBANBouton' })}
        />
      </View>

    </View>
  ), [
    styles,
    navigation,
    buttonDisabled
  ]);
  const handleChangeIban = (ibanValue: string) => {
    setInputIban(ibanValue.toUpperCase());
    const checkIban = ibantools.isValidIBAN(ibantools.electronicFormatIBAN(ibanValue));
    if (checkIban) {
      setIsIbanValid(true);
    }
    else {
      setIsIbanValid(false);
    }
  };
  const handleChangeBic = (bicValue: string) => {
    setInputBic(bicValue.toUpperCase());
    const checkBic = ibantools.isValidBIC(ibantools.electronicFormatIBAN(bicValue));
    if (checkBic) {
      setIsBicValid(true);
    }
    else {
      setIsBicValid(false);
    }
  };
  useEffect(() => {
    if (isIbanValid && isBicValid) {
      setButtonDisabled(false);
    }
    else {
      setButtonDisabled(true);
    }
  }, [isIbanValid, isBicValid])
  const RenderHeader = () => {
    return (
      <BankDataHeader
        displayBackButton
        displayCloseButton
        helpPoint
        helpPointContentHtml={""}
        helpPointModalTitle={""}
        helpPointPosition="left"
        stepperItems={stepperItems}
        title={formatMessage({ id: "Remboursement_CoordonnéesBancaires" })}
      />
    );
  };

  return (
    <DashboardPageWrapperConnected
      renderStickyMobileBottom={() => renderBottomActions()}
    >
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}
      >
        <View style={styles.containerStyle}>
          <Title
            variant="t4"
            weight="bold">
            {formatMessage({ id: operationTypeTitle })}
          </Title>
          <Text variant="t3" weight='light'>
            {formatMessage({ id: operationTypeDescription })}
          </Text>
          <View style={styles.fieldContainer}>
            <TextField
              isDisabled
              inputProps={{
                helpText: ibanBottomPlaceholder,
                placeholder: formatMessage({ id: 'CoordonneesBancairesSaisieNomPrenomZoneLabel' }),
                value: inputTitulaire || "",
              }}
              labelOnTop

            />
          </View>
          <View style={styles.fieldContainer}>
            <TextField

              inputProps={{
                helpText: ibanBottomPlaceholder,
                placeholder: formatMessage({ id: 'CoordonneesBancairesSaisieIBANZoneLabel' }),
                value: inputIban || "",
                onChangeText: ibanValue => {
                  handleChangeIban(ibanValue);
                },
                mask: [/\w*/, /\w*/, /\w*/, /\w*/, ' ', /\w*/, /\w*/, /\w*/, /\w*/, ' ', /\w*/, /\w*/, /\w*/, /\w*/, ' ', /\w*/, /\w*/, /\w*/, /\w*/, ' ', /\w*/, /\w*/, /\w*/, /\w*/, ' ', /\w*/, /\w*/, /\w*/, /\w*/, ' ', /\w*/, /\w*/, /\w/, /\w/]
              }}

              labelOnTop

            />
          </View>
          <View style={styles.fieldContainer}>
            <TextField

              inputProps={{
                helpText: bicBottomPlaceholder,
                placeholder: formatMessage({ id: 'CoordonneesBancairesSaisieBICZoneLabel' }),
                value: inputBic || "",
                onChangeText: bicValue => {
                  handleChangeBic(bicValue);
                },
                mask: [/\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/]
              }}
              labelOnTop
            />
          </View>
          {!isMobile && !isTablet && renderBottomActions()}
        </View>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};