
import { connect } from 'react-redux';

import {
  onEditVVPRequest,
  onDeleteVVPRequest,
  installmentInitError,
} from '@modules/installment/actions/installmentActions';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';
import { AppState } from '@modules/reducers';
import {
  getInstallmentError
} from '@modules/installment/selectors';
import {
  setOnGoingVVPDeleteModalVisibleState,
  setIsDetailsVvpPopin,
} from '@modules/savings/actions/savingsActions';
import {
  getIsGoingVVPDeleteModalVisible,
  getIsDetailsVVPPopInOpened,
  getOnGoindVVPById,
} from '@modules/savings/selectors';

import { VVPDetailsPage } from './VVPDetailsPage.component';
import { Props } from './VVPDetailsPage.component';

const mapStateToProps = (state: AppState, props: Props) => {
  const { id } = props.route?.params;
  const onGoingVVP = id ? getOnGoindVVPById(state, id) : undefined;

  return {
    onGoingVVP,
    isEditVVPLoading: state.isLoading[InstallmentActionsType.ON_VVP_EDIT_REQUEST],
    installmentError: getInstallmentError(state).message,
    isDetailsVVPPopInOpened: getIsDetailsVVPPopInOpened(state),
    isDeleteVVPLoading: state.isLoading[InstallmentActionsType.ON_VVP_DELETE_REQUEST],
    isGoingVVPDeleteModalVisible: getIsGoingVVPDeleteModalVisible(state),
  };
};
const mapDispatchToProps = {
  onEditVVP: onEditVVPRequest,
  setIsDetailsVvpPopin: setIsDetailsVvpPopin,
  onDeleteVVP: onDeleteVVPRequest,
  setOnGoingVVPDeleteModalVisibleState: setOnGoingVVPDeleteModalVisibleState,
  installmentInitError: installmentInitError,
};

export const VVPDetailsPageContainer = connect(mapStateToProps, mapDispatchToProps)(VVPDetailsPage);
