
import React, { useMemo } from 'react';

import {
  IconEnum,
  OperationsHeaderDetailsItemType,
  OperationsSubHeaderAdvantageListItemType,
  useTranslation
} from '@components/index';
import { OperationDetails } from '@modules/savings/types';

export const useOperationDetailsHeaderData = ({
  operationDetails,
}: {
  operationDetails: OperationDetails | undefined;
}) => {
  const {
    formatMessage,
    formatCurrencyNumber,
    formatDate
  } = useTranslation();

  const operationDetailsHeaderDetailList = useMemo(() => {
    const output: OperationsHeaderDetailsItemType[] = []
    if (!operationDetails) return output

    const {
      identifier,
      netAmount,
      feeAmount,
      source,
      socialContributionsAmount,
    } = operationDetails

    if (netAmount) {
      output.push({
        title: formatMessage({ id: 'DetailOperationMontantNet' }),
        value: formatCurrencyNumber({ value: netAmount })
      })
    }
    if (socialContributionsAmount) {
      output.push({
        title: formatMessage({ id: 'DetailOperationPrelevementSociaux' }),
        value: formatCurrencyNumber({ value: socialContributionsAmount })
      })
    }
    if (feeAmount) {
      output.push({
        title: formatMessage({ id: 'DetailOperationFrais' }),
        value: formatCurrencyNumber({ value: feeAmount })
      })
    }

    if (source && source.bulletinOption && (source.bulletinOption.fiscalYearStartDate && source.bulletinOption.fiscalYearEndDate)) {
      output.push({
        title: formatMessage({
          id: 'PIDescriptionPourPeriodeLabel',
          values: {
            date_debut: formatDate({ value: source.bulletinOption.fiscalYearStartDate || "" }),
            date_fin: formatDate({ value: source.bulletinOption.fiscalYearEndDate || "" })
          }
        }),
      })
    }

    if (identifier) {
      output.push({
        title: formatMessage({ id: 'DetailOperationReference' }),
        value: identifier
      })
    }

    return output
  }, [operationDetails, formatMessage, formatCurrencyNumber])

  const operationDetailsHeaderAdvantageList = useMemo(() => {
    const output: OperationsSubHeaderAdvantageListItemType[] = []
    if (!operationDetails) return output

    const {
      abundanceBrutAmount,
      abundanceNetAmount,
      deductible,
      deductibleAmount,
    } = operationDetails

    if (abundanceBrutAmount && abundanceBrutAmount > 0) {
      const brutContributionAmountText = formatCurrencyNumber({ value: abundanceBrutAmount })
      const netContributionAmountText = abundanceNetAmount && abundanceNetAmount > 0 ?
        formatMessage({
          id: 'DetailOperationAbondementNet',
          values: {
            montant: formatCurrencyNumber({ value: abundanceNetAmount })
          }
        })
        : undefined
      output.push({
        icon: IconEnum.CONTRIBUTION,
        title: `${formatMessage({ id: 'DetailOperationAbondementLabel' })} ${brutContributionAmountText}`,
        titleDetail: netContributionAmountText,
        description: formatMessage({ id: "DetailOperationAbondementCommLabel" }),
      })
    }
    if (deductible && deductibleAmount) {
      const brutFiscalityAmountText = formatCurrencyNumber({ value: deductibleAmount })
      output.push({
        icon: IconEnum.FISCALITE,
        title: `${formatMessage({ id: "DetailOperationFiscalite" })} ${brutFiscalityAmountText}`,
        description: formatMessage({ id: "DetailOperationMontantDeductible" })
      })
    }
    return output
  }, [operationDetails, formatMessage, formatCurrencyNumber])

  return {
    operationDetailsHeaderDetailList,
    operationDetailsHeaderAdvantageList
  }
}