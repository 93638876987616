import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  FiletStepperCardWrapper,
  FiletStepperSimpleCard,
  useTheme,
} from '@components/index';

import { Props } from './interfaces';
import { ProfitSharingIncentiveDestinationCardType } from './sharedInterface'
import { useStyles } from './useStyles';

export const FiletStepperCards: React.FC<Props> = ({
  cards,
  disableLine,
  conditionalOrder,
  statusCode,
  displayFiletTitle = true
}) => {

  const theme = useTheme();
  const styles = useStyles();

  const renderCard = useCallback((card: ProfitSharingIncentiveDestinationCardType, index: number) => {
    const isMergedExternal = card.isMergedExternal;
    const areManagementsExpanded = !isMergedExternal;
    const dispositifColor = theme.colors.basics.primary
    const renderManagements = () => {
      return card.managements.map((management, index) => {
        return (
          <FiletStepperSimpleCard
            key={`card_stepper_${index}`}
            {...management}
            conditionalOrder={conditionalOrder}
            headerColor={dispositifColor?.c100}
            statusCode={statusCode}
          />
        )
      })
    }

    return (
      <FiletStepperCardWrapper
        borderLeftColor={dispositifColor?.c500}
        disableIcone={index !== 0}
        displayFiletTitle={displayFiletTitle}
        disableLine={disableLine}
        headerResponseAmount={card.amount}
        headerResponseCustomContainerStyle={[
          styles.bankAccountHeader,
          {
            backgroundColor: dispositifColor?.c500,
          },
        ]}
        headerResponseTitle={card.planName}
        hideHeader
        isDefaultExpanded={areManagementsExpanded}
        isExpandable={isMergedExternal}
        showHeaderResponseDarkText={false}
        title={card.filetTitle || ''}
      >
        <View style={styles.bankAccountHeaderSeparator}>
          {renderManagements()}
        </View>
      </FiletStepperCardWrapper>
    );
  }, [
    disableLine,
    styles,
    conditionalOrder,
    statusCode,
    theme
  ]);

  return <>{cards?.map(renderCard)}</>;
};
