import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import {
  ColumnsContainer,
  DashboardPageWrapperConnected, DispositifChoicePopin, HelpPoint, PlanChoiceCard, SimpleButton, Text,
} from "@components/index";
import { RouteNames } from "@constants/navigation";
import { IconEnum } from "@ere-uilib/enums";
import { DirectionEnum } from "@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces";
import { useScreenSizes, useTheme } from "@ere-uilib/styles";
import { useTranslation } from "@ere-uilib/translations";
import { getPlanTypeColors } from "@ere-uilib/utils";
import { DispositifState } from "@modules/common/types";
import { constructStepperItems } from "@pages/Common/utils";

import { RefundsHeader } from "../../components";
import { locator } from "@constants/locator";

interface Props {
  dispositifs: DispositifState[];
  onSelectDispositif: (dispositif: DispositifState[]) => void;
}
export type HelpPointPositiontype = "right" | "left";

interface PropsStyles {
  containerViewStyle?: StyleProp<ViewStyle>;
  stepperContainer?: StyleProp<ViewStyle>;
  stepperStyle?: StyleProp<ViewStyle>;
  helpPointStyle?: StyleProp<ViewStyle>;
}

const stepperItems = constructStepperItems({
  size: 5,
  direction: DirectionEnum.ROW,
  activeStep: 2,
});

export const RefundsPlanChoicePageComponent: React.FC<Props & PropsStyles> = ({
  dispositifs,
  onSelectDispositif,
}) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { isMobile, isTablet } = useScreenSizes();
  const { formatMessage, getMessageRaw } = useTranslation();
  const pageTitle = formatMessage({
    id: "Remboursement_choixFinalDispositif_title",
  });
  const subtitle = formatMessage({
    id: "Remboursement_choixFinalDispositif_content",
  });
  const [isModalVisble, setModalVisible] = useState(false);
  const [selectedDispositifIndex, setSelectedDispositifIndex] =
    useState<number>();

  const showModal = () => setModalVisible(true);
  const closeModal = useCallback(() => setModalVisible(false), []);

  const handleValidateInformativePopin = useCallback(() => {
    selectedDispositifIndex !== undefined &&
      onSelectDispositif([dispositifs[selectedDispositifIndex]]);
    closeModal();
    navigation.navigate(RouteNames.RefundsStack, {
      screen: RouteNames.RefundRepartition,
    });
  }, [
    selectedDispositifIndex,
    closeModal,
    onSelectDispositif,
    dispositifs,
    navigation,
  ]);
  const { formatCurrencyNumber } = useTranslation();

  const RenderHeader = () => (
    <>
      <RefundsHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: "Remboursement_needs_info_content" })}
        helpPointModalTitle={formatMessage({ id: "Remboursement_needs_info_title" })}
        helpPointPosition="left"
        stepperItems={stepperItems}
        title={pageTitle}
      />
    </>
  );
  const handleSelectDispositif = useCallback((index: number) => {
    setSelectedDispositifIndex(index);
    showModal();
  }, []);

  const renderHelpPoint = () => {
    return (
      <HelpPoint
        contentHtml={formatMessage({
          id: "Remboursement_choixFinalDispositif_helpContent",
        })}
        iconSize={theme.metrics.iconSizes.xxm}
        modalTitle={
          formatMessage({
            id: "Remboursement_choixFinalDispositif_helpContent",
          }) || ""
        }
      />
    );
  };

  return (
    <View>
      <DashboardPageWrapperConnected
        cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
      >
        <ColumnsContainer renderHeader={RenderHeader}>
          {
            <Text style={{ marginBottom: theme.metrics.iconSizes.xl }}>
              {subtitle} {renderHelpPoint()}
            </Text>
          }
          {dispositifs &&
            dispositifs.map((dispositif, i) => {
              const color = theme.colors.basics.primary
              const selectDispositif = () => handleSelectDispositif(i);
              return (
                <PlanChoiceCard
                  amount={dispositif.encours}
                  amountHidden={false}
                  borderLeftColor={color?.c500}
                  containerStyle={
                    i < dispositifs.length && {
                      marginBottom: theme.metrics.iconSizes.xl,
                    }
                  }
                  headerBackgroundColor={color?.c100}
                  key={i}
                  onValidate={selectDispositif}
                  title={dispositif.name}
                >
                  <View
                    style={{ flexDirection: "row", alignContent: "center" }}
                  >
                    <Text
                      variant="t3"
                      weight="light">
                      {`${formatMessage({
                        id: "Remboursement_disponibleDispositif_content",
                      })}  `}
                    </Text>
                    <Text
                      style={{ lineHeight: 20 }}
                      variant="t3"
                      weight="regular"
                    >
                      {formatCurrencyNumber({ value: dispositif.encours })}
                    </Text>
                  </View>
                </PlanChoiceCard>
              );
            })}
          <View style={!(isMobile || isTablet) && { alignItems: "flex-start" }}>
            <SimpleButton
              design="outlined"
              leftIcon={IconEnum.CHEVRON_LEFT}
              onPress={navigation.goBack}
              title={formatMessage({
                id: "Remboursement_choixFinalDispositif_Retour",
              })}
            />
          </View>
        </ColumnsContainer>
      </DashboardPageWrapperConnected>
      <DispositifChoicePopin
        isModalVisible={isModalVisble}
        onValidate={handleValidateInformativePopin}
        testId={locator._payment._i_understand}
      />
    </View>
  );
};
