import { Platform } from 'react-native';

import { StylesContext, Styles } from './interface';

export const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  amountContainerStyle: [
    { flexDirection: 'row' },
    !context?.isMobile && {
      alignSelf: 'center'
    },
    {
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingBottom: context?.theme.metrics.spacing.m,
    },
    style?.amountContainerStyle,
  ],
  topLevelContainerStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white,
    }
  ],
  containerShadow: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      shadowColor: 'rgba(96, 97, 112, 0.2)',
      shadowOffset: {
        width: 0,
        height: 6,
      },
      shadowOpacity: 1,
      shadowRadius: 15,
      elevation: 6,
      zIndex: 1,
    }
  ],
  containerStyle: [
    {
      flexDirection: 'row',
    },
    !context?.isMobile
      ? {
        paddingTop: context?.theme.metrics.spacing.m,
        paddingRight: context?.theme.metrics.spacing.m,
      }
      : {
        paddingTop: context?.theme.metrics.spacing.m,
        paddingHorizontal: context?.theme.metrics.spacing.s,
      },

    style?.containerStyle,
  ],
  modalContainerStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      flexDirection: 'row',
      maxHeight: '100%',
    },
    !context?.isMobile
      ? {
        padding: context?.theme.metrics.spacing.m,
      }
      : {
        // paddingHorizontal: context?.theme.metrics.spacing.s,
        paddingVertical: context?.theme.metrics.spacing.m,
      },
    style?.modalContainerStyle,
  ],
  amountStyle: [
    {
      fontSize: 36,
      marginTop: context?.theme.metrics.spacing.xs,
    },
    style?.amountStyle,
  ],
  buttonTitleStyle: [
    {
      textAlign: 'center',
      color: 'white',
    },
    style?.buttonTitleStyle,
  ],
  buttonContainerStyle: [
    {
      maxWidth: '100%',
      justifyContent: 'center',
    },
    style?.buttonContainerStyle,
  ],
  chevronContainerStyle: [
    {
      justifyContent: 'center',
    },
    style?.chevronContainerStyle,
  ],
  leftContainerStyle: [{
    flex: 1,
  },
    style?.leftContainerStyle],
  centreContainerStyle: [{
    flex: 1,
  },
    context?.isMobile ? { flex: 2 } :
      {
        justifyContent: 'center',
        alignItems: 'center',
      },
    style?.centreContainerStyle],
  rightContainerStyle: [{
    flex: Platform.OS === 'web' ? 1 : undefined,
    width: "50%",
    minWidth: 100,
  },
    style?.rightContainerStyle],
  buttonSubtitleStyle: [{
    textAlign: 'center',
    color: 'white',
    fontSize: context?.theme.fonts.fontSize.paragraph.t4,
  },
    style?.buttonSubtitleStyle],
  descriptionStyle: [
    {
      fontSize: 12,
    },
    style?.descriptionStyle,
  ],
  contentStyle: [{
    display: 'flex',
    flex: 1,
  }, style?.contentStyle,
  ],
  selectionTitle: [{
    textAlign: 'center',
    marginBottom: context?.theme.metrics.spacing.xm,
  },
    style?.selectionTitle],
  companySelectionButtonStyle: [
    {
      minHeight: 30,
      maxHeight: 30,
      alignSelf: 'flex-end',
      maxWidth: '100%',
      paddingVertical: context?.theme.metrics.spacing.none,
    },
    style?.companySelectionButtonStyle,
  ],
  activableButtonSetStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm,
    },
    style?.activableButtonSetStyle,
  ],
});