import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { RouteNames } from '@constants/navigation';
import { StepperHeader } from '@ere-uilib/molecules';
import { HelpPointPositiontype } from '@ere-uilib/molecules/headers/NavigationHeader/interface';
import { StepperItemType } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { ProfitSharingIncentiveActionsType } from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActionsTypes';

interface Props {
  displayCloseButton: boolean
  hideStepper?: boolean
  helpPointContentHtml?: string
  helpPointModalTitle?: string
  helpPointPosition?: HelpPointPositiontype
  onClose?: () => void
  isGoBack?: boolean
  title: string
  stepperItems?: StepperItemType[]
  helpPoint: boolean
}

interface StyleProps {
  stepperContainer?: StyleProp<ViewStyle>
  stepperStyle?: StyleProp<ViewStyle>
}

export const ProfitSharingIncentiveHeader: React.FC<Props & StyleProps> = ({
  onClose,
  isGoBack,
  title,
  displayCloseButton,
  helpPointModalTitle,
  helpPointContentHtml,
  helpPointPosition,
  stepperItems,
  helpPoint,
}) => {
  const navigation = useNavigation();

  const handleClose = useCallback(() => {
    if (isGoBack) {
      navigation?.goBack();
      return;
    }
    else {
      if (onClose) {
        onClose();

        return;
      }
    
      navigation.navigate(RouteNames.ProfitSharingIncentive, {
        screen: RouteNames.ProfitSharingIncentiveModal,
        params: {
          clearAction: ProfitSharingIncentiveActionsType.CLEAR_PROFIT_SHARING_INCENTIVE_DATA,
        },
      });
    }
  }, [navigation, onClose, isGoBack]);

  return (
    <StepperHeader
      displayCloseButton={displayCloseButton}
      helpPoint={helpPoint}
      helpPointContentHtml={helpPointContentHtml}
      helpPointModalTitle={helpPointModalTitle}
      helpPointPosition={helpPointPosition}
      onClose={handleClose}
      stepperItems={stepperItems}
      title={title}
    />
  );
};
