import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { RefundTotalSubheader } from '@components/ERE360Components';
import { useScreenSizes } from '@components/index';
import {
  useTranslation,
  useTheme,
  DashboardPageWrapperConnected,
  ColumnsContainer,
  SimpleButton,
  FiletStepper,
  Text,
  CardWithLeftThread,
  HeaderResponse,
  ExpendableGreen,
  ChequeInfosCard,
  SynthesisFundsRow,
  NotificationHard,
  getPlanTypeColors,
  NotificationIconTypeEnum,
  Paragraph,
  BankAccountInfosCard,
  BubbleContainer,
  FundSheetModal,
  FundSheetModalDataType,
  TriggerThresholdComponent,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';
import { styles } from '@ere-uilib/atoms/HtmlRenderer/HtmlRenderer.web';
import { IconEnum } from '@ere-uilib/enums';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';

import { constructStepperItems } from '../../../Common/utils';
import { RefundsHeader } from '../../components';
import { RefundsRecapPageProps } from './interfaces';
import { getStyles } from './styles';
import { locator } from '@constants/locator';

export const RefundsRecapPageComponent: React.FC<RefundsRecapPageProps> = ({
  repartitions,
  bankInformations,
  navigation,
  appSettings,
  onSetRepaymentDemand,
  isLoading,
  repaymentDemandError,
}: RefundsRecapPageProps) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const theme = useTheme();
  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);
  const [fundSheetModalData, setFundSheetModalData] =
    useState<FundSheetModalDataType | null>();
  const { isMobile, isTablet } = useScreenSizes();
  const planColors = repartitions && theme.colors.basics.primary
  const style = useStyles(
    {
      theme,
      isMobile: isMobile || isTablet,
    },
    {}
  );
  useEffect(() => {
    if (repaymentDemandError.message) { return navigation.navigate(RouteNames.RefundsStack, { screen: RouteNames.RefundFailed }); }
  }, [repaymentDemandError]);

  const stepperItems = constructStepperItems({
    size: 5,
    direction: DirectionEnum.ROW,
    activeStep: 4,
  });
  const adress = bankInformations?.fullAddress;
  const isTransfertOperation = bankInformations?.bankAccountDetail &&
    (bankInformations?.bankAccountDetail?.iban && bankInformations?.bankAccountDetail?.iban !== '');
  const bankOperationTypeCardTitle = !isTransfertOperation ? formatMessage({
    id: 'Remboursement_chequeRecap_titreTuile',
  }) : formatMessage({
    id: 'Remboursement_virementRecap_titreTuile',
  });

  const chequeInfoData = {
    street: adress?.street || '',
    additionalAddress_1: adress?.additionalAddress_1 || '',
    additionalAddress_2: adress?.additionalAddress_2 || '',
    additionalAddress_3: adress?.additionalAddress_3 || '',
    zipcode: adress?.zipcode || '',
    city: adress?.city || '',
    country: adress?.country || '',
    recipient: adress?.recipient || '',
  };

  const pageTitle = formatMessage({
    id: 'Remboursement_virementRecap_title',
  });
  const totalSetAmout = repartitions?.formValues?.totalAmount;

  const handleSupportRowTitlePress = ({
    supportIsin,
    supportName,
  }: {
    supportIsin: string
    supportName: string
  }) => {
    setDisplayFundSheetModal(true);
    setFundSheetModalData({
      id: supportIsin,
      title: supportName,
    });
  };
  const renderSubHeader = () => repartitions && repartitions.totalAmount && (
    <RefundTotalSubheader
      refundTotalAmount={totalSetAmout || 0}
      totalAmount={repartitions.totalAmount}
    />
  );
  const RenderHeader = () => (
    <>
      <RefundsHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({
          id: 'Remboursement_virementRecap_helpcontent',
        })}
        helpPointModalTitle={formatMessage({
          id: 'Remboursement_virementRecap_help',
        })}
        helpPointPosition="left"
        stepperItems={stepperItems}
        title={pageTitle}
      />
      {(isMobile || isTablet) && renderSubHeader()}
    </>
  );

  const renderRightDesktopColumn = () => (
    <>
      <BubbleContainer>
        {renderSubHeader()}
      </BubbleContainer>
    </>
  );
  const renderMainContent = () => {
    const planTitle = `${formatMessage({
      id: 'Remboursement_repartition_myplan_label',
    })} ${repartitions?.planName} `;
    // for now we manage only one compartment and one management
    const isMultipleCompartments =
      repartitions?.compartments && repartitions?.compartments.length > 1;
    return (
      <>
        <FiletStepper
          containerStyle={style.filetContainer}
          disableLine={false}>
          <View style={style.fromContent}>
            <Text style={style.filetText}>
              {formatMessage({ id: 'Remboursement_virementRecap_DE' })}
            </Text>
            <CardWithLeftThread
              addShadow={true}
              borderLeftColor={planColors?.c500}
            >
              <HeaderResponse
                amount={repartitions?.formValues?.totalAmount && -repartitions.formValues.totalAmount}
                customContainerStyle={{ backgroundColor: planColors?.c100 }}
                darkText
                title={planTitle}
              />
              {repartitions && !isMultipleCompartments &&
                repartitions?.compartments.map(compartment => {
                  // for now we manage only one compartment and one management
                  const isMultipleManagements =
                    compartment.managements && compartment.managements.length > 1;
                  return (!isMultipleManagements &&
                    compartment.managements?.map(management => {
                      return (
                        management.supports?.map((support, supportIndex) => (
                          <>
                            {!!support.formValues?.amount && (
                              <>
                                <SynthesisFundsRow
                                  amount={-support.formValues?.amount}
                                  key={supportIndex}
                                  onTitlePress={() => handleSupportRowTitlePress({
                                    supportIsin: support.supportIsin,
                                    supportName: support.supportName,
                                  })}
                                  title={support?.supportName}
                                />
                                {(support.formValues?.triggerThreshold) &&
                                  <View style={style.titleContainer}>
                                    <TriggerThresholdComponent
                                      data={
                                        support.formValues?.triggerThreshold
                                      }
                                      isEditable={false}
                                    />
                                  </View>
                                }
                              </>
                            )}
                          </>
                        ))
                      );
                    })
                  );
                })}
            </CardWithLeftThread>
          </View>
        </FiletStepper>
        <FiletStepper
          containerStyle={style.filetContainer}
          disableLine={true}
          straightLine={style.straightLineStyle}
        >
          <View style={style.fromContent}>
            <Text style={style.filetText}>
              {formatMessage({ id: 'Remboursement_virementRecap_VERS' })}
            </Text>
            <CardWithLeftThread addShadow={true}>
              <HeaderResponse
                amount={repartitions?.formValues?.totalAmount}
                customContainerStyle={style.bankAccountHeader}
                darkText
                title={bankOperationTypeCardTitle}
              />
              {!isTransfertOperation ?
                <View style={style.bankAccountContainer}>
                  <ExpendableGreen
                    title={formatMessage({
                      id: 'Remboursement_virementRecap_masquerCondPost',
                    })}
                  >
                    <ChequeInfosCard
                      appSettings={appSettings}
                      chequeInfoData={chequeInfoData} />
                  </ExpendableGreen>
                </View>

                : <View style={style.bankAccountContainer}>
                  <Text variant="t3">
                    {
                      `${formatMessage({ id: 'Remboursement_virementRecap_contenuTuile' })} ${bankInformations?.bankAccountDetail?.titulaire || ''}`
                    }
                  </Text>

                  <ExpendableGreen
                    title={formatMessage({ id: 'operation_source_displayiban_label' })}
                  >
                    <BankAccountInfosCard
                      AccountInfoData={{
                        ...bankInformations?.bankAccountDetail,
                        domiciliation: bankInformations?.bankAccountDetail?.domiciliation || undefined,
                        titulaire: bankInformations?.bankAccountDetail?.titulaire || undefined
                      }}
                      NoDisplayPaymentChoice
                    />
                  </ExpendableGreen>
                </View>}
            </CardWithLeftThread>
          </View>
        </FiletStepper>

        <View>
          <NotificationHard
            title={formatMessage({
              id: 'Remboursement_informationfiscale_title',
            })}
            type={NotificationIconTypeEnum.INFO}
          >
            <Paragraph
              style={style.notificationLabel}
              variant="t3"
              weight="light"
            >
              {formatMessage({
                id: 'Remboursement_informationfiscale_content',
              })}
            </Paragraph>
          </NotificationHard>
        </View>
      </>
    );
  };

  const renderBottomActions = () => (
    <View style={style.bottomActionsStyle}>
      <View style={style.bottomActionsButtonsContainerStyle}>
        <SimpleButton
          containerStyle={style.arrowButtonStyle}
          design="outlined"
          leftIcon={IconEnum.CHEVRON_LEFT}
          onPress={navigation.goBack}
        />
        <SimpleButton
          containerStyle={style.simpleButtonStyle}
          design="solid"
          loading={isLoading}
          onPress={() => onSetRepaymentDemand()}
          size="small"
          title={formatMessage({ id: 'Remboursement_boutonValiderContinuer' })}
          testId={locator._recover_savings._synthese_confirm}
        />
      </View>
    </View>
  );
  return (
    <DashboardPageWrapperConnected
      cardContentStyle={style.dashboardWrapper}
      renderStickyMobileBottom={renderBottomActions}>
      <ColumnsContainer
        renderHeader={RenderHeader}
        renderRightDesktopColumn={renderRightDesktopColumn}
      >
        {renderMainContent()}
        {!isMobile && !isTablet && renderBottomActions()}
      </ColumnsContainer>
      {fundSheetModalData && (
        <FundSheetModal
          id={fundSheetModalData?.id}
          modalIsVisible={displayFundSheetModal}
          navigation={navigation}
          onCloseModal={() => {
            setDisplayFundSheetModal(false);
            setFundSheetModalData(null);
          }}
          title={fundSheetModalData?.title}
        />
      )}
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
