import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@translations/index';

interface DataProps {
  value: number
  maxValue?: number
}

type RiskLevelStyles = {
  container?: StyleProp<ViewStyle>;
  riskContainer?: StyleProp<ViewStyle>;
  textContainer?: StyleProp<ViewStyle>;
  textContent?: StyleProp<ViewStyle>;
  leftTextContentContainer?: StyleProp<TextStyle>;
  rightTextContentContainer?: StyleProp<TextStyle>;
  highRiskText?: StyleProp<TextStyle>;
  lowRiskText?: StyleProp<TextStyle>;
  riskElement?: StyleProp<ViewStyle>;
  riskElementSelected?: StyleProp<ViewStyle>;
  riskElementFirst?: StyleProp<ViewStyle>;
  riskElementLast?: StyleProp<ViewStyle>;
  riskElementTextStyle?: StyleProp<TextStyle>;
  riskElementSelectedTextStyle?: StyleProp<TextStyle>;
};

type RiskLevelStylesContext = {theme: ThemeType; value?: number;};

type Props = DataProps & RiskLevelStyles

export const RiskLevel: React.FC<Props> =  ({
  container,
  value,
  maxValue = 7,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme, value }, { container });

  const { formatMessage } = useTranslation();

  const values: any = [];

  const renderElements = () => {
    for (let i = 1; i <= maxValue; i++) {
      values.push(`${i}`);
    }

    return values.map((item: any, index: number) =>{
      const isFistItem = index === 0;
      const isLastItem = index === maxValue - 1;
      const isSelectedItem = index === value - 1;
      return (
        <View
          key={index + '_' + value}
          style={[
            styles.riskElement,
            isFistItem && styles.riskElementFirst,
            isLastItem && styles.riskElementLast,
            isSelectedItem && styles.riskElementSelected,
          ]}
        >
          <Text
            style={[
              styles.riskElementTextStyle,
              isSelectedItem && styles.riskElementSelectedTextStyle,
            ]}
            variant="t4"
          >
            {item}
          </Text>
        </View>
      );
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.riskContainer}>{renderElements()}</View>
      <View style={styles.textContainer}>
        <View style={styles.leftTextContentContainer}>
          <View style={styles.textContent}>
            <Text
              style={styles.lowRiskText}
              variant="t4"
              weight="light">
              {formatMessage({ id: 'Fundsheet_Tab1low_risk-legend' })}
            </Text>
          </View>
        </View>
        <View style={[styles.rightTextContentContainer]}>
          <View style={[styles.textContent]}>
            <Text
              style={styles.highRiskText}
              variant="t4"
              weight="light">
              {formatMessage({ id: 'Fundsheet_Tab1_high-risk_legend' })}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const getStyles = (
  context?: RiskLevelStylesContext,
  style?: RiskLevelStyles,
): RiskLevelStyles => ({
  container: [
    {
      marginVertical: context?.theme.metrics.spacing.xs,
    },
    style?.container,
  ],
  riskContainer: [
    {
      flex: 1,
      flexDirection: 'row',
      alignContent: 'center',

      borderRadius: context?.theme.metrics.spacing.xm,
      backgroundColor: context?.theme.colors.basics.grey.c100,
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.riskContainer,
  ],
  riskElement: [
    {
      justifyContent: 'center',
      alignContent: 'center',
      flex: 1,
      borderColor: 'white',
      borderLeftWidth: 2,
      borderRightWidth: 2,
      height: 30,
    },
    style?.riskElement,
  ],
  riskElementSelected: [
    {
      backgroundColor: context?.theme.colors.basics.primary.c500,
    },
    style?.riskElementSelected,
  ],
  riskElementFirst: [
    {
      borderLeftWidth: 0,
      paddingLeft: context?.theme.metrics.spacing.s,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    style?.riskElementFirst,
  ],
  riskElementLast: [
    {
      borderRightWidth: 0,
      paddingRight: context?.theme.metrics.spacing.s,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
    },
    style?.riskElementLast,
  ],
  textContainer: [
    {
      flex: 1,
      flexDirection: 'row',
    },
    style?.textContainer,
  ],
  leftTextContentContainer: [
    {
      flex: 1,
    },
    style?.leftTextContentContainer,
  ],
  rightTextContentContainer: [
    {
      flex: 1,
      alignItems:'flex-end'
    },
    style?.rightTextContentContainer,
  ],
  textContent: [{ width: 152 }, style?.textContent],
  lowRiskText: [style?.lowRiskText],
  highRiskText: [
    {
      textAlign: 'right',
    },
    style?.highRiskText,
  ],
  riskElementTextStyle: [
    {
      textAlign: 'center',
      color: context?.theme.colors.basics.black,
    },
    style?.riskElementTextStyle,
  ],
  riskElementSelectedTextStyle: [
    {
      color: context?.theme.colors.basics.white,
    },
    style?.riskElementSelectedTextStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
