import { connect } from 'react-redux';

import { getPendingTasksRequest } from '@modules/pending-tasks/actions/pendingTasksActions';

import { MaintenancePage as MaintenancePageComponent } from './MaintenancePage.component';

const mapDispatchToProps = ({
  onGetMaintenanceData: getPendingTasksRequest
});

export const MaintenancePage = connect(null, mapDispatchToProps)(MaintenancePageComponent);