
import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { FiletStepper } from '@ere-uilib/molecules';
import { HelpPoint } from '@ere-uilib/organisms';
import { ThemeType, useTheme } from '@ere-uilib/styles';

interface Props {
  title?: string
  showHelpPoint?: boolean
  helpPointContentHtml?: string
  helpPointContentTitle?: string
  helpPointModalTitle?: string
  disableLine?: boolean
}

export const RequirementDefinitionItem: React.FC <Props> = ({
  helpPointContentHtml,
  helpPointContentTitle,
  helpPointModalTitle,
  showHelpPoint,
  title,
  disableLine = true,
  children
}) => {
  const theme: ThemeType = useTheme();
  const styles = useStyles()
  return (
    <>
      {!!title &&
          <FiletStepper
            containerStyle={styles.stepperStyle}
            disableLine = {disableLine}
          >
            <Text
              style={styles.titleStyle}
              variant="t3"
              weight="bold"
            >
              {title}
            </Text>
            {showHelpPoint && (
              <HelpPoint
                contentHtml={helpPointContentHtml || ''}
                contentTitle={helpPointContentTitle}
                iconSize={theme.metrics.iconSizes.xxm}
                modalTitle={helpPointModalTitle || ''}
              />
            )}
          </FiletStepper>
      }
      <View style={styles.contentContainer}>
        {children}
      </View>
    </>
  );
};

const useStyles = () => {
  const theme = useTheme()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        stepperStyle:{
            marginBottom: 0
          },
        contentContainer: {
          paddingLeft: theme.metrics.spacing.xxm
        },
        titleStyle: {
          marginLeft: theme?.metrics.spacing.xm,
          marginRight: theme?.metrics.spacing.s
        }
      }),
    [theme]
  )

  return styles
}

