import React from 'react';
import {
  View,
  TouchableOpacity,
  Linking,
  Platform,
} from 'react-native';

import { Text, SVGLocalLoader } from '@ere-uilib/atoms';
import {
  useTheme,
  createUseStyles,
  useScreenSizes,
} from '@ere-uilib/styles';
import { useTranslation } from '@translations/index';

import PlaceholderLoader from '../components/PlaceholderLoader';
import { Props } from './interfaces';
import { getStyles } from './styles';

export const DocumentsTabConnected: React.FC<Props> = ({
  childrenStyle,
  containerStyle,
  fundOverview,
  fundsDocumentsList,
  documentsRow,
  icon,
  isApiLoadingDocuments,
}: Props) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const { formatMessage, formatDate } = useTranslation();
  const styles = useStyles(
    {
      theme,
      isMobile,
      isTablet,
    },
    {
      containerStyle,
      childrenStyle,
      documentsRow,
      icon,
    }
  );

  return (
    <View style={styles.globalContainer}>
      <View style={styles.containerStyle}>
        {isApiLoadingDocuments ? (
          <PlaceholderLoader />
        ) : (
          fundsDocumentsList?.map((item, index) => {
            return (
              <View
                key={item.type + '_' + index}
                style={styles.documentsRow}>
                <View style={styles.titleAndDateView}>
                  {item?.datePublish && item?.datePublish !== '' && (
                    <Text
                      style={styles.dateTextStyle}
                      variant="t4"
                      weight="light"
                    >
                      {formatMessage({
                        id: 'Fundsheet_Tab5Docs_As_of_date_legend',
                        values: {
                          variable: formatDate({
                            value: item.datePublish,
                            options: {
                              year: 'numeric',
                              month: '2-digit',
                              day: 'numeric',
                            },
                          }),
                        },
                      })}
                    </Text>
                  )}
                  <Text variant="t3">
                    {formatMessage({
                      id: `Fundsheet_Document_${item?.type.trim()}_label`,
                    })}
                  </Text>
                  {item?.source && (
                    <Text
                      style={styles.dateTextStyle}
                      variant="t4"
                      weight="light"
                    >
                      {item.source}
                    </Text>
                  )}
                </View>
                <TouchableOpacity
                  onPress={() => {
                    Platform.OS === 'web'
                      ? window.open(item.url, '_blank')
                      : Linking.openURL(item.url);
                  }}
                  style={styles.icon}
                >
                  <SVGLocalLoader name="pdf" />
                </TouchableOpacity>
              </View>
            );
          })
        )}
      </View>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
