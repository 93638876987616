import React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { FontIcon } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

interface DataProps {
  direction: 'gauche' | 'bas' | 'droite' | 'haut';
  onClick: () => void;
  iconColor?: string;
}

type ArrowButtonStyles = {
  buttonContainer?: StyleProp<ViewStyle>;
  childrenStyle?: StyleProp<ViewStyle>;
};

type ArrowButtonStylesContext = { theme: ThemeType  };

type Props = DataProps & ArrowButtonStyles;

export const ArrowButton: React.FC<Props> = ({
  onClick,
  direction,
  iconColor,
  buttonContainer
}: Props) => {
  const theme: any = useTheme();
  const styles = useStyles({ theme }, { buttonContainer });

  return (
    <TouchableOpacity
      onPress={onClick}
      style={styles.buttonContainer}>
      <FontIcon
        color={iconColor ? iconColor : theme.colors.basics.black}
        name={`chevron-${direction}`}
        size={theme.metrics.iconSizes.xm}
      />
    </TouchableOpacity>
  );
};

const getStyles = (
  context?: ArrowButtonStylesContext,
  style?: ArrowButtonStyles
): ArrowButtonStyles => ({
  buttonContainer: [
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: context?.theme.metrics.spacing.xl,
      width: context?.theme.metrics.spacing.xl
    },
    style?.buttonContainer
  ]
});

const useStyles = createUseStyles(getStyles);
