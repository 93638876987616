import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { BankOperationTypesList } from '@constants/savings';
import { Spinner, Text } from '@ere-uilib/atoms';
import { PlanFamillyList } from '@ere-uilib/constants';
import { ActivableButtonSet, ModalFrame } from '@ere-uilib/molecules';
import { useTheme } from '@ere-uilib/styles';
import { getSelectedPlanFamily } from '@modules/bank-details/selectors';
import { RedirectOperationActionsType } from '@modules/redirect-operation/actions/redirectOperationActionsTypes';
import { AppState } from '@modules/reducers';
import { useTranslation } from '@translations/index';

import { BankDetailsCopyIBANModalProps } from './interfaces';
import { useStyles } from './styles';

export const BankDetailsCopyIBANModal: React.FC<BankDetailsCopyIBANModalProps> = ({
  onClose,
  plan,
  operationType
}) => {
  const { selectedPlanFamily, isLoadingOperationModalList } = useSelector(
    (state: AppState) => ({
      selectedPlanFamily: getSelectedPlanFamily(state),
      isLoadingOperationModalList:
        state.isLoading[RedirectOperationActionsType.GET_OPERATION_REDIRECT_REQUEST]
    })
  );

  const [hovered, setHovered] = useState<boolean>(false);

  const { formatMessage } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();
  const navigation = useNavigation();

  const handleChange = useCallback(() => {
    onClose();
    navigation.navigate(RouteNames.BankDetailsStack, {
      screen: RouteNames.BankSetDomiciliation
    });
  }, [navigation, onClose]);

  const isHoveredStyle = hovered ? styles.hoveredTextStyle : {}

  const renderModalContent = () => {
    if (isLoadingOperationModalList) {
      return <Spinner color={theme.colors.basics.primary.c500} />;
    }

    return (
      <View>
        <Text style={styles.planFamily}>
          {formatMessage({
            id:
              selectedPlanFamily === PlanFamillyList.ES
                ? 'CoordonneesBancairesRecapEpargneSalarialeESTitre'
                : 'CoordonneesBancairesRecapRetraiteCollectiveRCTitre'
          })}
        </Text>
        <Text
          style={styles.plan}
          weight="light">
          {plan}
        </Text>
        <Text
          style={styles.description}
          weight="regular">
          {formatMessage({ id: 'CoordonneesBancairesPopinChoixSaisieTitre' })}
        </Text>

        <ActivableButtonSet
          buttonStyle={styles.button}
          onChange={handleChange}
          hovered={hovered}
          setHovered={setHovered}>
          <Text
            style={[styles.buttonText, isHoveredStyle]}
            variant="t3">
            {formatMessage({
              id:
                operationType === BankOperationTypesList.REMBOURSEMENT
                  ? 'CoordonneesBancairesPopinChoixSaisieRemboursementBouton'
                  : 'CoordonneesBancairesPopinChoixSaisiePrelevementBouton'
            })}
          </Text>
        </ActivableButtonSet>
        {/* TODO: feature flip off */}
        {/*
          <ActivableButtonSet
            buttonStyle={styles.button}
            onChange={() => {
              onClose();
              navigation.navigate(RouteNames.BankDetailsStack, {
                screen: RouteNames.BankCopyIban,
                params: {
                  operationType
                }
              });
            }}>
            <Text
              style={styles.buttonText}
              variant="t3">
              {formatMessage({ id: 'CoordonneesBancairesPopinChoixSaisiePrelRembBouton' })}
            </Text>
            <Text
              style={styles.buttonText}
              variant="t4"
              weight="light">
              {formatMessage({ id: 'CoordonneesBancairesPopinChoixSaisiePrelRembSubBouton' })}
            </Text>
          </ActivableButtonSet>
        */}
      </View>
    );
  };

  return (
    <ModalFrame
      mobilePosition="bottom"
      onClose={onClose}>
      {renderModalContent()}
    </ModalFrame>
  );
};
