import React from 'react';
import { View } from 'react-native';

import { useScreenSizes } from '@components/index';
import {
  useTranslation,
  useTheme,
  Title,
  InstallmentsStepper,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';

import { RefundsNotificationHard } from '../RefundsNotificationHard';
import { RefundsStepperProps } from './interfaces';
import { getStyles } from './styles';

export const RefundsStepper: React.FC<RefundsStepperProps> = ({
  children,
  stepperItems,
  renderSubmitButton,
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();

  const style = useStyles(
    {
      theme,
      isMobile: isMobile || isTablet,
    },
    {}
  );

  return (
    <View style={style.containerStyle}>
      <Title
        style={style.title}
        variant="t5">
        {formatMessage({ id: 'Remboursement_SousTitreParcours' })}
      </Title>
      <View style={style.stepperContainer}>
        <InstallmentsStepper
          direction={DirectionEnum.COLUMN}
          stepperItems={stepperItems}
        />
      </View>
      {
        (isMobile || isTablet) &&
            <View style={style.notificationContainer}>
              <RefundsNotificationHard/>
            </View>
      }
      {children}
      {!isMobile && !isTablet && renderSubmitButton}

    </View>
  );
};

const useStyles = createUseStyles(getStyles);
