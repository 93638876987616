import { connect } from "react-redux";

import { AppState } from "@modules/reducers";
import { createRepaymentDemandRequest } from "@modules/refund/available-refund/actions/refundActions";
import { RefundActionsType } from "@modules/refund/available-refund/actions/refundActionsTypes";
import { getRefundRepartitionData, getRefundsError } from "@modules/refund/available-refund/selectors";
import { getBankDetails } from "@modules/refund/selectors";
import { getApplicationSettings } from "@modules/settings/selectors";

import { RefundsRecapPageComponent } from "./RefundsRecapPage.component";

const mapStateToProps = (state: AppState) => ({
  repartitions: getRefundRepartitionData(state),
  bankInformations: getBankDetails(state),
  appSettings: getApplicationSettings(state),
  isLoading: state.isLoading[RefundActionsType.CREATE_REPAYMENT_DEMAND_REQUEST],
  repaymentDemandError: getRefundsError(state)
});
const mapDispatchToProps = ({
  onSetRepaymentDemand: createRepaymentDemandRequest
});
export const RefundsRecapPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundsRecapPageComponent);
