import { connect } from 'react-redux';

import { logoutCallbackRequest } from '@modules/auth/actions/authActions';
import { AppState } from '@modules/reducers';
import { getUser } from '@modules/auth/selectors';
import { getAuthConfig } from '@modules/settings/selectors';

import { EndOidcSessionPage as EndOidcSessionPageComponent } from './EndOidcSessionPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    user: getUser(state),
    authConfig: getAuthConfig(state),
  };
};
const mapDispatchToProps = {
  signoutRedirectCallback: logoutCallbackRequest,
};

export const EndOidcSessionPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EndOidcSessionPageComponent);
