import React, { useCallback, useEffect } from 'react';
import { View, ViewStyle } from 'react-native';
import useDynamicRefs from 'use-dynamic-refs';

import { TextField } from '@ere-uilib/molecules';
import { useScreenSizes, useTheme } from '@ere-uilib/styles';

import { useStyles } from './useStyles';

interface Props {
  hasError?: boolean;
  onChange: (value: string[]) => void;
  code: string[];
  onSubmit: () => void;
  style?: ViewStyle;
  isDisabled?: boolean;
}
interface Ref { current: { focus: () => void }}

export function OtpFields({ hasError, onChange, code, onSubmit, style, isDisabled }: Props) {
  const theme = useTheme();
  const [getRef, setRef] = useDynamicRefs();
  const { isMobile } = useScreenSizes();
  const styles = useStyles();

  const handleDelete = useCallback((e: any, index: number) => {
    if (e.key !== 'Backspace' && e.nativeEvent?.key  !== 'Backspace') {
      return;
    }

    const newCode = code.slice();

    newCode.splice(index, 1, '');

    if (code[index] === '') {
      const REF = getRef(
        `CODE_INPUT${index - 1}`,
      ) as Ref;
      REF && REF.current && REF.current.focus();
    }
    onChange(newCode);
  }, [code, getRef, onChange]);

  const handleChange = useCallback((value: string, index: number): void => {
    if (isNaN(parseInt(value))) {
      return;
    }

    const newCode = code.slice();
    const REF = getRef(
      `CODE_INPUT${index + 1}`,
    ) as Ref;

    newCode.splice(index, 1, value);
    REF && REF.current && REF.current.focus();
    onChange(newCode);
  }, [code, getRef, onChange]);

  useEffect(() => {
    const REF = getRef(
      `CODE_INPUT${0}`,
    ) as Ref;
    REF && REF.current && REF.current.focus();
  }, [getRef]);

  return (
    <View style={[styles.container, style]}>
      {code.map((value, index) => (
        <TextField
          testId={`code_input_${index}`}
          borderOnValue={!isDisabled }
          code
          containerStyle={index === code.length - 1 && { marginRight: 0 }}
          errored={hasError}
          innerRef={setRef(`CODE_INPUT${index}`)}
          inputProps={{
            maxLength: 1,
            value: value,
            onChangeText: (e: any) => handleChange(e, index),
          }}
          inputStyle={{
            width: isMobile ? 45 : 50,
            height: isMobile ? 45 : 50,
            fontSize: theme.fonts.fontSize.paragraph.t1,
          }}
          isDisabled={isDisabled}
          key={index}
          onKeyPress={e => handleDelete(e, index)}
          onSubmit={onSubmit}
        />
      ))}</View>
  );
}
