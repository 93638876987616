import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDocumentBlobRequestByType } from '@modules/document-space/actions/documentsActions';
import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes';
import { AppState } from '@modules/index';
import { getRetirementDecisionForm } from '@modules/retirement/selectors';

import { RetirementRecoverSavingPageProps } from './interfaces';
import { RetirementRecoverSavingPageComponent } from './RetirementRecoverSavingPage.component';

export const RetirementRecoverSavingPage = ({ }: RetirementRecoverSavingPageProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: AppState) => state.isLoading[DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST]);

  const retirementDecisionForm = useSelector(getRetirementDecisionForm);
  const onSubmit = useCallback((docType: string, docTitle: string) => {
    dispatch(getDocumentBlobRequestByType(docType, docTitle))
  }, [])
  return (
    <RetirementRecoverSavingPageComponent
      isApiLoading={isLoading}
      onSubmit={onSubmit}
      retirementDecisionForm={retirementDecisionForm}
    />
  )
};
