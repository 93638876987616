import { RouteProp, useRoute } from '@react-navigation/native';
import React, { useEffect } from 'react';

import {
  OfflineAnnounceLayout360,
  Spinner,
  SimpleButton,
} from '@components/index';
import { SimulatorType } from '@constants/sapiendo';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@translations/index';
import { useLogoNavigation } from '@utils/index';

import { SapiendoRedirectProps as Props } from './interfaces';

export const SapiendoRedirect: React.FC<Props> = ({
  navigation,
  onGetSapiendoRedirectUrlRequest,
}: Props) => {

  const theme = useTheme();

  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();
  type SapiendoSimulatorTypeParam = {
    SapiendoRedirectPage: {
      type: SimulatorType
    }
  };
  const route = useRoute<RouteProp<SapiendoSimulatorTypeParam, 'SapiendoRedirectPage'>>();
  useEffect(() => {
    const RouteSimulatorType = route?.params?.type;
    onGetSapiendoRedirectUrlRequest?.(RouteSimulatorType);
  }, [route?.params?.type]);

  const renderActions = () => (
    <SimpleButton
      containerStyle={{ marginTop: theme?.metrics.spacing.xm }}
      onPress={() => navigation.goBack()}
      title={formatMessage({ id: 'Maretraite_redirect_back_button' })}
    />
  );

  return (
    <OfflineAnnounceLayout360
      actions={renderActions}
      description={formatMessage({ id: 'Maretraite_redirect_label' })}
      onLogoPress={logoNavigateTo}
      sourceName="robotSapiendo"
      title={formatMessage({ id: 'Maretraite_redirect_title' })}
    >
      <Spinner
        color={theme.colors.basics.primary.c500}
      />
    </OfflineAnnounceLayout360>
  );
};
