import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

export const useStyles = () => {

  return useMemo(() => StyleSheet.create({
    modalContainer: {
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      width: "100%",
      height: "100%",
      cursor: "default"
    },
  }), []);
};
