import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';

import {
  ArbitrationFailurePage as ArbitrationFailurePageComponent,
} from './ArbitrationFailurePage.component';

const mapStateToProps = (state: AppState) => {
  return {

  };
};

const mapDispatchToProps = ({

});

export const ArbitrationFailurePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArbitrationFailurePageComponent);
