import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from '@ere-uilib/translations';
import { getRetirementDecision } from '@modules/retirement/selectors';
import {
  category,
  RetirementDecision,
  RetirementDecisionSubmitForm
} from '@modules/retirement/types';

import {
  FormValues,
  RetirementDecisionContentTabFormValues,
  RetirementDecisionTabFormValues
} from '../components/TabForm/interfaces';

export const useController = (isEmtpyForm = false) => {
  const { formatMessage, getMessageRaw, formatCurrencyNumber } = useTranslation();
  const retirementDecision: RetirementDecision[] = useSelector(getRetirementDecision);

  const getTabFormValuesContentByCompartments = useCallback(
    (categories: category[]) => {
      if (!categories) return {};
      const content = categories.reduce((accumulator, currentValue) => {
        const hasAsset = !isEmtpyForm ? currentValue.asset : false;
        const hasPension = !isEmtpyForm ? currentValue.pension : false;
        const totalAmount = currentValue.amount;
        const contentUuid = uuidv4();
        const label = formatMessage({ id: currentValue.labelCode }) || '';

        accumulator[contentUuid] = {
          uuid: contentUuid,
          label,
          value: totalAmount,
          capitalEligible: !!hasAsset,
          isCapitalEditable: isEmtpyForm && currentValue.asset,
          annuityEligible: !!hasPension,
          isAnnuityEditable: isEmtpyForm && currentValue.pension,
          categoryCode: currentValue.code
        };

        return accumulator;
      }, {} as RetirementDecisionContentTabFormValues);

      return content;
    },
    [formatMessage]
  );

  const retirementDecisionTabFormValues = useMemo(() => {
    const newRetirementDecisionTabFormValues: RetirementDecisionTabFormValues =
      retirementDecision.reduce((accumulator, currentValue) => {
        const uuid = uuidv4();
        const content = getTabFormValuesContentByCompartments(currentValue.categories);
        accumulator[uuid] = {
          uuid,
          title: currentValue.shortName,
          planName: currentValue.planName,
          content
        };

        return accumulator;
      }, {} as RetirementDecisionTabFormValues);

    return newRetirementDecisionTabFormValues;
  }, [retirementDecision, getTabFormValuesContentByCompartments]);

  const totalAmount = useMemo(() => {
    let newTotalAmount = 0;

    Object.keys(retirementDecisionTabFormValues).forEach(decision => {
      const content = retirementDecisionTabFormValues[decision].content;

      Object.keys(content).forEach(contentItem => {
        newTotalAmount += content[contentItem].value;
      });
    });

    return newTotalAmount;
  }, [retirementDecisionTabFormValues]);
  //labels
  const retirementFormDictionnary = useMemo(() => {
    return {
      savingPageTitle: formatMessage({ id: 'RetirementJourneyMyRetirementPageTitle' }),
      hisSavingText1: formatMessage({ id: 'RetirementJourneyRecoverHisSavingText1' }),
      hisSavingText2: getMessageRaw({ id: 'RetirementJourneyRecoverHisSavingText2' }),
      continueButton: formatMessage({ id: 'RetirementJourneyRecoverHisSavingContinueButton' }),
      availableNow: formatMessage({ id: 'RetirementJourneySavingCompartmentAvailable' }),
      deblocableText: formatMessage({ id: 'RetirementJourneySavingCompartmentRelease' }),
      infoTitle: formatMessage({ id: 'RetirementJourneyRecoverHisSavingBoardInfoTitle' }),
      infoContent: formatMessage({ id: 'RetirementJourneyRecoverHisSavingBoardInfoContent' })
    };
  }, [formatMessage, getMessageRaw]);

  const retirementDetailsPageDictionnary = useMemo(
    () => ({
      retirementJourneyMyInformationPageTitle: formatMessage({
        id: 'RetirementJourneyMyInformationPageTitle'
      }),
      retirementJourneyMyInformationPageSavingButton: formatMessage({
        id: 'RetirementJourneyMyInformationPageSavingButton'
      }),
      retirementJourneyMyRetirementCard1Title: formatMessage({
        id: 'RetirementJourneyMyRetirementCard1Title'
      }),
      retirementJourneyMyInformationPageQuestion1: formatMessage({
        id: 'RetirementJourneyMyInformationPageQuestion1'
      }),
      retirementJourneyMyInformationPageAnswer1: formatMessage({
        id: 'RetirementJourneyMyInformationPageAnswer1',
        values: { totalAmount: formatCurrencyNumber({ value: totalAmount }) }
      }),
      eerSelfcareStep3CompteIntrouvableLink: formatMessage({
        id: 'EerSelfcareStep3CompteIntrouvableLink'
      }),
      retirementJourneyMyInformationPageQuestionnaire: getMessageRaw({
        id: 'RetirementJourneyMyInformationPageQuestionnaire'
      }),
      detailOperationDetailLabel: formatMessage({ id: 'DetailOperationDetailLabel' }),
      retirementJourneyMyInformationPageInfoContent: formatMessage({
        id: 'RetirementJourneyMyInformationPageInfoContent'
      }),
      retirementJourneyMyInformationPageInfoTitle: formatMessage({
        id: 'RetirementJourneyMyInformationPageInfoTitle'
      })
    }),
    [formatCurrencyNumber, formatMessage, totalAmount]
  );

  const tabDictionary = useMemo(
    () => ({
      mySavings: formatMessage({ id: 'RetirementJourneySavingMysavingsHeader' }),
      grossTotal: formatMessage({ id: 'RetirementJourneySavingGrossTotalHeader' }),
      grossAmount: formatMessage({ id: 'RetirementJourneySavingGrossAmountHeader' }),
      exit: formatMessage({ id: 'RetirementJourneySavingExitHeader' }),
      asset: formatMessage({ id: 'RetirementJourneySavingAssetHeader', defaultMessage: 'CAPITAL' }),
      pension: formatMessage({
        id: 'RetirementJourneySavingPensionHeader',
        defaultMessage: 'RENTE'
      })
    }),
    [formatMessage]
  );
  const retirementOnboardingPageDictionnary = useMemo(() => {
    return {
      retirementPageTitle: formatMessage({ id: 'RetirementJourneyMyRetirementPageTitle' }),
      retirementCard1Title: formatMessage({ id: 'RetirementJourneyMyRetirementCard1Title' }),
      retirementCard1Text: formatMessage({ id: 'RetirementJourneyMyRetirementCard1Text' }),
      retirementCard2Title: formatMessage({ id: 'RetirementJourneyMyRetirementCard2Title' }),
      retirementCard2Text: formatMessage({ id: 'RetirementJourneyMyRetirementCard2Text' }),
      retirementPageDescription: formatMessage({
        id: 'RetirementJourneyMyRetirementPageDescription'
      }),
      RetirementJourneyMyRetirementInfoTitle: formatMessage({
        id: 'RetirementJourneyMyRetirementInfoTitle'
      }),
      RetirementJourneyMyRetirementInfoContent: getMessageRaw({
        id: 'RetirementJourneyMyRetirementInfoContent'
      })
    };
  }, [formatMessage, getMessageRaw]);

  const getNewCompartments = useCallback((formValues?: FormValues) => {
    const plans: RetirementDecisionSubmitForm[] = [];
    if (!formValues) return;
    Object.values(formValues).forEach(plan => {
      Object.values(plan.content).map(category => {
        const newItem: RetirementDecisionSubmitForm = {
          planName: plan.planName!,
          categoryCode: category.categoryCode,
          asset: category.capitalEligible,
          pension: category.annuityEligible
        };
        return plans.push(newItem);
      });
      return plan;
    });
    return plans;
  }, []);

  return {
    getTabFormValuesContentByCompartments,
    getNewCompartments,
    retirementDecisionTabFormValues,
    totalAmount,
    retirementFormDictionnary,
    retirementDetailsPageDictionnary,
    tabDictionary,
    retirementOnboardingPageDictionnary
  };
};
