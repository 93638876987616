import moment from 'moment'

import { EDocument } from '@modules/document-space/types'

export const getYear = (date: string | Date) => moment.utc(date).year()

export const getYears = (documents: EDocument[]) => {
  const years = documents.map(document => getYear(document.creationDate))
  return Array.from(new Set(years)).sort((a, b) => b - a)
}

export const filterDocumentsByYear = (documents: EDocument[], year: number | undefined) => {
  if (!year && year !== 0) return documents

  return documents
    .filter(document => year === getYear(document.creationDate))
    .sort((a, b) => moment.utc(b.creationDate).diff(moment.utc(a.creationDate)))
}
