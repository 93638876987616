import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { clearRefundData } from '@modules/refund/actions/refundActions';

import { RefundFailedPage as RefundFailedPageComponent } from './RefundFailedPage.component';

const mapStateToProps = (state: AppState) => {
  return {
  };
};

const mapDispatchToProps = ({
  clearRefundData: clearRefundData,
});

export const RefundFailedPage = connect(mapStateToProps, mapDispatchToProps)(RefundFailedPageComponent);
