import { RouteProp, useRoute } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { Platform } from 'react-native';

import {
  OfflineAnnounceLayout360,
  Spinner,
  SimpleButton,
  NotificationSoft,
} from '@components/index';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@translations/index';
import { useLogoNavigation } from '@utils/index';

import { RedirectOperationProps as Props } from './interfaces';

type PersoneoParams = {
  Personeo: { operationType: string; provider: string }
}
export const RedirectOperation: React.FC<Props> = ({
  navigation,
  onGetRedirectOpeationUrl,
  redirectError,
  isLoading,
}: Props) => {
  const theme = useTheme();
  const route = useRoute<RouteProp<PersoneoParams, 'Personeo'>>();
  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();

  useEffect(() => {
    const { operationType, provider } = route?.params
    onGetRedirectOpeationUrl(operationType, provider);
  }, []);

  const renderActions = () => (
    <SimpleButton
      containerStyle={{ margin: theme?.metrics.spacing.xm }}

      onPress={() => {
        if (Platform.OS === 'web') {
          window.history.back()
        } else {
          navigation.goBack()
        }
      }}
      title={formatMessage({ id: 'Maretraite_redirect_back_button' })}
    />
  );

  return (
    <OfflineAnnounceLayout360
      actions={renderActions}
      description={formatMessage({
        id: 'Dashboard_Personeo_RedirectPage_label',
      })}
      onLogoPress={logoNavigateTo}
      sourceName="waitingProcess"
      title={formatMessage({ id: 'Dashboard_Personeo_RedirectPage_title' })}
    >
      {isLoading && (
        <Spinner color={theme.colors.basics.primary.c500} />
      )}
      {redirectError?.message !== "" && !isLoading && (
        <NotificationSoft
          text={redirectError?.message}
          type="warning"
        />
      )}
    </OfflineAnnounceLayout360>
  );
};
