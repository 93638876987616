import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getUser } from '@modules/auth/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';

import { SupportPage as SupportPageToConnect } from './SupportPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    user: getUser(state),
    applicationSettings: getApplicationSettings(state)
  };
};

export const SupportPage = connect(mapStateToProps, null)(SupportPageToConnect);
