import React, { useState } from 'react';
import { View, Pressable, Platform, ImageBackground } from 'react-native';

import { FontIcon, Title } from '@ere-uilib/atoms';
import { TabBarAdvancedButton } from '@ere-uilib/molecules/tabs/TabBarAdvancedButton';
import { createUseStyles, useTheme } from '@ere-uilib/styles';

import { BottomTabsProps } from './interface';
import { getStyles } from './styles';

export const BottomTabs: React.FC<BottomTabsProps> = props => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const { tabBarItemStyle, tabMaskStyle, tabBarIconStyle, tabBarTitleStyle } =
    props;
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    { tabMaskStyle, tabBarItemStyle, tabBarIconStyle, tabBarTitleStyle },
  );

  const renderContent = () => {
    return (
      <View style={styles.tabMaskStyle}>
        <Pressable
          onPress={() => setSelectedItem(0)}
          style={styles.tabBarItemStyle}
        >
          <FontIcon
            color={selectedItem === 0
              ? theme.colors.basics.primary.c500
              : theme.colors.text.primary.c500}
            name="accueil"
            size={theme.metrics.iconSizes.mplus}
            style={styles.tabBarIconStyle}
          />
          <Title
            style={[
              styles.tabBarTitleStyle,
              selectedItem === 0
                ? { color: theme.colors.basics.primary.c500 }
                : { color: theme.colors.text.primary.c500 }
            ]}
            variant="t7"
          >
            Accueil
          </Title>
        </Pressable>
        <Pressable
          onPress={() => setSelectedItem(1)}
          style={styles.tabBarItemStyle}
        >
          <FontIcon
            color={selectedItem === 1
              ? theme.colors.basics.primary.c500
              : theme.colors.text.primary.c500}
            name="epargne"
            size={theme.metrics.iconSizes.mplus}
            style={styles.tabBarIconStyle}
          />
          <Title
            style={[
              styles.tabBarTitleStyle,
              {
                color: selectedItem === 1
                  ? theme.colors.basics.primary.c500
                  : theme.colors.text.primary.c500
              }
            ]}
            variant="t7"
          >
            Epargne
          </Title>
        </Pressable>
        <TabBarAdvancedButton
          active={!mobileMenuVisible}
          onPress={() => {
            setMobileMenuVisible(!mobileMenuVisible);
            //getMobileMenuModalRequest(route.state.routes[route.state.index].name, mobileMenuVisible);
          }}
        />
        <Pressable
          onPress={() => setSelectedItem(2)}
          style={styles.tabBarItemStyle}
        >
          <FontIcon
            color={selectedItem === 2
              ? theme.colors.basics.primary.c500
              : theme.colors.text.primary.c500}
            name="historique"
            size={theme.metrics.iconSizes.mplus}
            style={styles.tabBarIconStyle}
          />
          <Title
            style={[
              styles.tabBarTitleStyle,
              {
                color: selectedItem === 2
                  ? theme.colors.basics.primary.c500
                  : theme.colors.text.primary.c500
              }
            ]}
            variant="t7"
          >
            Historique
          </Title>
        </Pressable>
        <Pressable
          onPress={() => setSelectedItem(3)}
          style={styles.tabBarItemStyle}
        >
          <FontIcon
            color={selectedItem === 3
              ? theme.colors.basics.primary.c500
              : theme.colors.basics.black}
            name="profil"
            size={theme.metrics.iconSizes.mplus}
            style={styles.tabBarIconStyle}
          />
          <Title
            style={[
              styles.tabBarTitleStyle,
              {
                color: selectedItem === 3
                  ? theme.colors.basics.primary.c500
                  : theme.colors.text.primary.c500
              }
            ]}
            variant="t7"
          >
            Profil
          </Title>
        </Pressable>
      </View>
    );
  };

  return Platform.OS === 'web' ? (
    <ImageBackground source={{}}>{renderContent()}</ImageBackground>
  ) : (
    <View style={styles.tabBarStyle}>{renderContent()}</View>
  );
};

const useStyles = createUseStyles(getStyles);
