import { DashboardPageWrapperConnected } from '@components/ERE360Components';
import {
  ColumnsContainer,
  DocumentCard,
  DocumentTag,
  IconButton,
  useTheme,
  useTranslation
} from '@ere-uilib/index';
import { DocumentApiFile, DocumentCategory } from "@modules/document-space/types";
import React, { useEffect, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { DocumentSpaceHeader, DocumentFilterModal } from '../components';
import { getFileTags, getSelectedFilesByCategories } from '../utils';
import { GeneralDocumentsFilesProps } from './interfaces';
import { useStyles } from './useStyles';



export const GeneralDocumentsFiles: React.FC<GeneralDocumentsFilesProps> = ({
  categories,
  files,
  selectedCategories,
  onSetDocumentsCategoriesFilter,
  onGetDocumentDownloadFile,
  isDocumentDonwloading
}) => {
  const refDashboardContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>;
  const [selectedFiles, setSelectedFiles] = useState<DocumentApiFile[]>();
  const [displayFilterModal, setDisplayFilterModal] = useState(false);
  const [applyFilters, setApplyFilters] = useState(false);
  const [resetFilter, setResetFilter] = useState<DocumentCategory>();
  const theme = useTheme();
  const { formatMessage, getMessageRaw } = useTranslation();
  const style = useStyles();
  const [selectedDocIndex, setSelectedDocIndex] = useState(-1)

  useEffect(() => {
    if (applyFilters) {
      const matchingSelectedDocuments = getSelectedFilesByCategories(categories, files);
      setSelectedFiles(matchingSelectedDocuments);
    }
    setApplyFilters(false)
  }, [applyFilters]);

  const setCategorieToFilter = (category: DocumentCategory) => {
    let selectedState = category.isSelected;
    if (!selectedState) selectedState = false;
    onSetDocumentsCategoriesFilter(category.id, !selectedState);
  };

  useEffect(() => {
    if (resetFilter) {
      setCategorieToFilter(resetFilter)
    }
    setResetFilter(undefined)
  }, [resetFilter, setResetFilter]);

  useEffect(() => {
    const matchingSelectedDocuments = getSelectedFilesByCategories(categories, files);
    setSelectedFiles(matchingSelectedDocuments);
  }, [categories]);

  //   TO DO SET TAG TRACKER
  const RenderHeader = () => {
    return (
      <DocumentSpaceHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'DocCenterInfoContenu' })}
        helpPointModalTitle={formatMessage({ id: 'DocCenterInfoTitre' })}
        helpPointPosition="left"
        title={formatMessage({ id: 'TermsetFrontActionMenuMesDocuments' })}
      />
    );
  };

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={style.dashboardWrapper}
      dashboardPageWrapperRef={refDashboardContainer}
    >
      <ColumnsContainer
        containerStyle={style.containerStyle}
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}
      >
        <DocumentFilterModal
          categories={categories}
          files={files}
          isVisible={displayFilterModal}
          onClose={() => {
            setDisplayFilterModal(false);
          }}
          setApplyFilters={setApplyFilters}
        />
        <View style={style.innerContainer}>
          <View style={style.filterContainer}>
            <View style={style.tagContainer}>
              <IconButton
                buttonInformations={{ icon: 'filtres', title: `${formatMessage({ id: 'Saving_Tab4_Filter_button' })}` }}
                iconButtonStyle={style.iconButton}
                iconButtonTitleStyle={style.iconButtonText}
                iconColor={theme.colors.basics.primary.c500}
                index={1}
                onIconButtonPressed={() => setDisplayFilterModal(true)}
                titleVariant="t3"
              />
              {selectedCategories?.map((category: DocumentCategory, index: number) => (
                <DocumentTag
                  key={`index_${index}`}
                  setResetFilter={setResetFilter}
                  taggedCategory={category}
                  tagName={formatMessage({ id: category.name })}
                />
              ))}
            </View>
          </View>
          <View style={style.filesContainerStyle}>
            {selectedFiles?.map((selectedFile: DocumentApiFile, index: number) => (
              <TouchableOpacity
                disabled={isDocumentDonwloading}
                onPress={() => {
                  setSelectedDocIndex(index)
                  onGetDocumentDownloadFile(selectedFile.documentType, selectedFile.documentTitle)
                }}
                key={`index_${index}`}
                style={style.cardStyle}>
                <DocumentCard
                  key={`DocumentCard_${index}`}
                  description={selectedFile.documentSubTitle}
                  tags={getFileTags(selectedFile, categories).map(tag => ({ ...tag, name: formatMessage({ id: tag.name }) }))}
                  title={selectedFile.documentTitle}
                  documentLoading={selectedDocIndex === index &&
                    isDocumentDonwloading}
                />
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};