import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import {
  FiletStepperCardWrapper,
  getPlanTypeColors,
  IconEnum,
  SimpleButton,
  Text,
  useTheme,
  useTranslation
} from '@components/index';
import { ArbitrationOadRepartitionData } from '@modules/arbitration/types';
import { CompartmentType, PlanCompartmentsType, SupportType } from '@modules/common/types';
import { useActiveClassLabel } from '@pages/Arbitration/hooks/useActiveClassLabel';

import { useStyles } from '../../ArbitrationOADRepartitionConfirmationPage/styles';

interface RecapOldAndNewRepartitionType {
  oldRepartition: ArbitrationOadRepartitionData[];
  newRepartition: ArbitrationOadRepartitionData[];
  selectedCompartment: CompartmentType | undefined;
  planData: PlanCompartmentsType | undefined;
  onCardTitlePress: (support: SupportType) => void
}

export const RecapOldAndNewRepartition: React.FC<RecapOldAndNewRepartitionType> = ({
  oldRepartition,
  newRepartition,
  selectedCompartment,
  planData,
  onCardTitlePress
}) => {
  const { formatPercentNumber, formatMessage } = useTranslation();
  const theme = useTheme();
  const styles = useStyles();
  const [showOldRepartitionDetails, setShowOldRepartitionDetails] = useState(false);
  const { getActiveClassLabel } = useActiveClassLabel();

  const detailsIcon = showOldRepartitionDetails ? IconEnum.CHEVRON_TOP : IconEnum.CHEVRON_BOTTOM;

  const labels = useMemo(() => {
    return {
      oldRepartition: formatMessage({
        id: 'AbritrageOADPageSyntheseChoixAncienneRepartition'
      }),
      newRepartition: formatMessage({
        id: 'AbritrageOADPageSyntheseChoixNouvelleRepartition'
      }),
      detailsLabel: formatMessage({
        id: 'AbritrageOADPageSyntheseChoixDetailsLien'
      })
    };
  }, [formatMessage]);

  const planAndCompartmentName = useMemo(() => {
    let name = planData?.planName;

    if (selectedCompartment?.label) {
      name = `${name} - ${selectedCompartment?.label}`;
    }

    return name;
  }, [planData, selectedCompartment]);

  const dispositifColor = useMemo(() => {
    return theme.colors.basics.primary.c500
  }, [theme, planData]);

  const handlePress = () => {
    setShowOldRepartitionDetails(!showOldRepartitionDetails);
  };

  const renderRepartitionItemHeader = useCallback(
    oadRepartition => (
      <View style={styles.cardItem}>
        <View style={styles.cardItemHeader}>
          <View style={[styles.cardItemPastils, { backgroundColor: oadRepartition.color }]} />
          <Text
            variant="t3"
            weight="bold">
            {getActiveClassLabel(oadRepartition.title)}
          </Text>
        </View>
        <Text
          variant="t3"
          weight="bold">
          {formatPercentNumber({ value: oadRepartition.value * 100, options: { minimumFractionDigits: 0 } })}
        </Text>
      </View>
    ),
    [formatPercentNumber, styles, getActiveClassLabel]
  );

  const renderSupport = useCallback(

    (support, index) => {
      if (support.percentageValue === 0) {
        return null;
      }

      return (
        <View
          key={index}
          style={styles.supportContainer}>
          <TouchableOpacity
            onPress={() => onCardTitlePress(support.data)}
          >
            <Text
              style={styles.supportTitle}
              variant="t3"
              weight="regular">
              {support.data.supportName}
            </Text>
          </TouchableOpacity>
          <Text
            variant="t3"
            weight="regular">
            {formatPercentNumber({ value: support.percentageValue * 100, options: { minimumFractionDigits: 0 } })}
          </Text>
        </View>
      )
    },
    [formatPercentNumber, styles]
  );

  return (
    <>
      <View>
        <FiletStepperCardWrapper
          borderLeftColor={dispositifColor}
          disableLine={false}
          headerResponseCustomContainerStyle={{
            backgroundColor: dispositifColor
          }}
          headerResponseTitle={showOldRepartitionDetails ? planAndCompartmentName : undefined}
          hideHeader={false}
          showHeaderResponseDarkText={false}
          title={labels.oldRepartition}>
          {oldRepartition?.map((oadRepartition, index) => {
            if (!showOldRepartitionDetails) return;
            return (
              <View key={index}>
                {renderRepartitionItemHeader(oadRepartition)}
                {oadRepartition.supports.map((support, index) => {
                  return renderSupport(support, index);
                })}
              </View>
            );
          })}
        </FiletStepperCardWrapper>
        <SimpleButton
          containerStyle={{
            position: 'absolute',
            right: theme.metrics.spacing.none
          }}
          design="light"
          onPress={handlePress}
          rightIcon={detailsIcon}
          rightIconStyle={styles.detailButtonIcon}
          title={labels.detailsLabel}
        />
      </View>
      <FiletStepperCardWrapper
        borderLeftColor={dispositifColor}
        headerResponseCustomContainerStyle={{
          backgroundColor: dispositifColor
        }}
        headerResponseTitle={planAndCompartmentName}
        hideHeader={false}
        showHeaderResponseDarkText={false}
        title={labels.newRepartition}
      >
        {newRepartition?.map((oadRepartition, index) => {
          return (
            <View key={index}>
              {renderRepartitionItemHeader(oadRepartition)}
              {oadRepartition.supports.map((support, index) => {
                return renderSupport(support, index);
              })}
            </View>
          );
        })}
      </FiletStepperCardWrapper>
    </>
  );
};
