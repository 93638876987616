import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { LoaderPlaceholderView } from '@ere-uilib/atoms';
import { LoaderCard } from '@ere-uilib/molecules/Loaders/LoaderCard';
import { createUseStyles, ThemeType } from "@ere-uilib/styles";
import { useTheme, } from "@ere-uilib/styles/theme";

type Context = {
  theme: ThemeType
}

type Style = {
  container?: StyleProp<ViewStyle>
  headerContainer?: StyleProp<ViewStyle>
  gradient?: StyleProp<ViewStyle>
  loaderTitle?: StyleProp<ViewStyle>
  innerCardContainer?: StyleProp<ViewStyle>
  loaderCard?: StyleProp<ViewStyle>
  loaderLine1?: StyleProp<ViewStyle>
  loaderLine2?: StyleProp<ViewStyle>
}

export const CardResponsePlaceHolder: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles({
    theme,
  }, {});
  return (<View style={styles.container}>
    <View style={styles.headerContainer}>
      <LoaderPlaceholderView style={styles.loaderTitle} />
    </View>
    <View style={styles.innerCardContainer}>
      <LoaderCard
        loaderContainerStyle={styles.loaderCard}
      >
        <LoaderPlaceholderView style={styles.loaderLine1} />
        <LoaderPlaceholderView style={styles.loaderLine2} />
      </LoaderCard>
    </View>
  </View>)
}

const getStyles = (
  context: Context,
  style?: Style,
): Style => ({
  container: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      shadowColor: context?.theme.colors.basics.black,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.15,
      shadowRadius: 10,
      elevation: 7,
      marginTop: context?.theme?.metrics.spacing.xm,
    },
    style?.container
  ],
  headerContainer: [
    {
      backgroundColor: context?.theme?.colors.basics.primary.c500,
      borderTopRightRadius: context?.theme?.metrics.borderRadius.xs,
      borderTopLeftRadius: context?.theme?.metrics.borderRadius.xs,
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 45,
      paddingHorizontal: context?.theme?.metrics.spacing.s,
      paddingVertical: context?.theme?.metrics.spacing.s,
      overflow: 'hidden',
    },
    style?.headerContainer
  ],
  gradient: [
    {
      ...StyleSheet.absoluteFillObject,
      zIndex: -1,
    },
    style?.gradient
  ],
  loaderTitle: [{ width: '20%' }, style?.loaderTitle],
  innerCardContainer: [
    { paddingVertical: context?.theme.metrics.spacing.s },
    style?.innerCardContainer
  ],
  loaderCard: [{
    justifyContent: 'space-between',
    height: 50,
  },
  style?.loaderCard],
  loaderLine1: [{ width: '50%' }, style?.loaderLine1],
  loaderLine2: [{ width: '100%' }, style?.loaderLine1],

})

const useStyles = createUseStyles(getStyles);