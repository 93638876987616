import React from 'react'
import { View } from 'react-native'

import { CardWithLeftThread, Text } from '@ere-uilib/atoms'
import { FiletStepper, HeaderResponse } from '@ere-uilib/molecules'

import { Props } from './interfaces'
import { useStyles } from './useStyles'
import { theme } from '@ere-uilib/theme'

export const FiletStepperCardAccounts: React.FC<Props> = ({
  stepTitle,
  title,
  amount,
  description,
  containerStyle,
  headerResponseCustomContainerStyle,
  children,
  disableLine = true,
  status,
  hideAmount
}) => {
  const styles = useStyles()

  return (
    <View>
      <FiletStepper
        containerStyle={styles.filetContainer}
        contentContainer={containerStyle}
        disableLine={disableLine}
        filetContainerStyle={styles.filetSubContainer}>
        <View style={styles.fromContent}>
          <Text style={styles.filetText}>{stepTitle}</Text>
          <CardWithLeftThread
            addShadow
            containerStyle={styles.cardContainer}>
            <HeaderResponse
              amount={amount}
              customContainerStyle={[
                {
                  backgroundColor: theme.colors.white,
                  borderTopLeftRadius: 0
                },
                headerResponseCustomContainerStyle
              ]}
              darkText={true}
              hideAmount={hideAmount}
              shouldFormatAsPlusMinus={false}
              title={title}
            />
            <View style={styles.detailsContainer}>
              <View style={styles.textContainer}>
                <Text
                  style={styles.descriptionStyle}
                  variant="t3">
                  {description}
                </Text>
                {status && (
                  <Text
                    style={styles.statusStyle}
                    variant="t3"
                    weight="light">
                    {status}
                  </Text>
                )}
              </View>
              {children}
            </View>
          </CardWithLeftThread>
        </View>
      </FiletStepper>
    </View>
  )
}
