import React from 'react';

import {
  DashboardAnnounce,
  InstallmentsStepper, Link, SimpleButton, Text, Title
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { innerNavigations } from '@constants/savings';
import {
  DirectionEnum, StepperItemType, StepperSizeEnum
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { StepperStatus } from '@ere-uilib/types';
import { clearArbitrationData } from '@modules/arbitration/actions/arbitrationActions';

import { ArbitrationSuccessPageProps } from './interfaces';
import { getStyles } from './styles';
import { locator } from '@constants/locator';

export const ArbitrationSuccessPage: React.FC<ArbitrationSuccessPageProps> = ({
  onClearCacheAfterOperationDone,
  selectedDispositif,
  arbitrationAnswerData,
}) => {

  const { formatMessage, formatMessageWithJSXInsertion } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const useStyles = createUseStyles(getStyles);
  const styles = useStyles(
    { theme },
    {}
  );

  const renderActions = () => {
    return (
      <SimpleButton
        onPress={() => {
          onClearCacheAfterOperationDone({
            stack: RouteNames.BottomTabNavigator,
            screen: RouteNames.Home,
            actionType: clearArbitrationData(),
          });
        }}
        title={formatMessage({ id: 'Arbitrage_PageConfirmation_BoutonRetourAccueil' })}
        testId={locator._arbitration._back_home}
      />
    );
  };

  const stepperItems: StepperItemType[] = [
    {
      status: StepperStatus.DONE,
      title: formatMessage({
        id: arbitrationAnswerData?.operationEnded ?
          'Arbitrage_demandeAcceptee' : 'Arbitrage_demandeEnCours',
      }),
      children: (
        <Text
          style={styles.stepSubTextStyle}
          variant="t3"
          weight="light">
          {formatMessageWithJSXInsertion({
            id: arbitrationAnswerData?.operationEnded ?
              'Arbitrage_ecranfinal_contentAccepteeEtape1' :
              'Arbitrage_ecranfinal_contentEnCoursEtape1'
          }) + ' '}
          <Link
            onPress={() => {
              onClearCacheAfterOperationDone({
                selectedNavigationTab: innerNavigations[3],
                planId: selectedDispositif && selectedDispositif[0].id,
                stack: RouteNames.BottomTabNavigator,
                screen: RouteNames.SavingsStack,
                actionType: clearArbitrationData(),
              });
            }}
            textVariant="t3"
            textWeight="regular"
          >
            {formatMessage({
              id: 'VV_paiement_confirmation_vvl_step1_linklabel',
            })}
          </Link>
        </Text>
      ),
    },
    {
      status: StepperStatus.WAITING,
      title: formatMessage({
        id: 'Arbitrage_ecranfinal_contentAccepteeEtape2',
      }),
    },
    {
      status: StepperStatus.WAITING,
      title: formatMessage({
        id: 'Arbitrage_ecranfinal_contentAccepteeEtape3',
      }),
      children: (
        <>
          <Title
            style={styles.textStepStyle}
            variant={isMobile ? 't7' : 't6'}
            weight="light"
          >
            {
              formatMessage({
                id: 'ArbitrageEcranFinalPriseEnCompteDescription',
              })
            }
          </Title>
        </>
      ),
    },
    {
      status: StepperStatus.FINISH,
      title: formatMessage({
        id: 'Arbitrage_ecranfinal_contentAccepteeEtape5',
      }),
    },
  ];

  return (
    <DashboardAnnounce
      actions={renderActions}
      sourceName={'savingsJar'}
      title={formatMessage({
        id: 'ArbitrageEcranFinalBravoTitre',
        values: {
          dispositif: selectedDispositif?.[0]?.name ?? '',
        },
      })}
    >
      <InstallmentsStepper
        containerStyle={styles.containerStepper}
        direction={DirectionEnum.COLUMN}
        stepperItems={stepperItems}
        stepperSize={
          isMobile ? StepperSizeEnum.SMALL : StepperSizeEnum.BIG
        }
      />
    </DashboardAnnounce>
  );
};