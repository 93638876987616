import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import {
    SplashScreenContent,
} from '@components/index';

import { OneTrustScript } from '../OneTrustScript';
import { AppHeadContainerProps } from './interface';

const INACTIVITY_TIMEOUT = 1800 * 1000; // 1/2 hour of inactivity 1800

export const AppHeadContainerComponent: React.FC<AppHeadContainerProps> = ({
    children,
    clearErrors,
    onLogoutCallbackRequest,
    onGetApplicationSettingsRequest,
    appSettings,
    user,
}) => {
    axios.defaults.headers.common.Pragma = "no-cache";
    axios.defaults.headers.common["Cache-Control"] = "no-cache";
    axios.defaults.headers.common.Authorization = 'Bearer ' + user?.access_token;

    // manage end session with idle delay
    // end user session after 30min of inactivity
    const onCheckSessionExpiration = () => {
        const sessionTimeStamp = user?.expires_at || 0;
        if (
            (!sessionTimeStamp || (
                sessionTimeStamp && moment(sessionTimeStamp * 1000).format() < moment().format()
            ))
        ) {
            return onLogoutCallbackRequest();
        }
    };
    const handleOnIdle = () => {
        return onLogoutCallbackRequest();
    };

    useEffect(() => {
        // Here we want to get app settings
        const isAuthenticated = user?.access_token && user?.access_token !== '' || false;
        isAuthenticated && onCheckSessionExpiration();
        onGetApplicationSettingsRequest();
        // Here we want to flush errors before reloading/closing tabs
        clearErrors();
    }, []);

    useIdleTimer({
        timeout: appSettings?.SessionExpirationTime ?
            Number(appSettings?.SessionExpirationTime) * 60 * 1000
            : INACTIVITY_TIMEOUT,
        onIdle: handleOnIdle,
        debounce: 500,
    });

    const isMinimumSettingsToStart = !!appSettings?.authority;
    // we nedd minimum
    if (isMinimumSettingsToStart) {
        return (
            <>
                <OneTrustScript>
                    {children}
                </OneTrustScript>
            </>
        );
    } else {
        return (
            <SplashScreenContent />
        );
    }
};
