import React from 'react';
import { useState } from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { createUseStyles } from '@components/ERE-UILib/styles';
import {
  SimpleButton,
  PasswordCheck,
  NotificationHard,
  useTheme,
  ThemeType,
  useScreenSizes,
  NotificationIconTypeEnum,
  EERPageWrapperContainer,
  EERContentWrapper,
  EERKeyboardAvoidingViewPageWrapper,
} from '@components/index';
import { useTranslation } from '@ere-uilib/translations';
import { ApplicationSettingsState } from '@modules/settings/types';
import { locator } from '@constants/locator';

interface Props {
  title: string;
  error: string;
  isProgressHeaderDisplayed?: boolean;
  isApiLoading: boolean;
  onPress: () => void;
  onLogoPress?: () => void;
  setPassword?: React.Dispatch<React.SetStateAction<string>>;
  isLeftMenu: boolean;
  appSettings: ApplicationSettingsState;
  displayLogo?: boolean;
  contentStyle?: StyleProp<ViewStyle>;
  buttonsRowStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
}

type ChoosePasswordPageStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  footerContentContainer?: StyleProp<ViewStyle>;
  errorViewStyle?: StyleProp<ViewStyle>;
  buttonsRowStyle?: StyleProp<ViewStyle>;
  buttonsMobileRowStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
};

type StylesContext = {
  theme: ThemeType;
  isMobile: boolean;
  isLeftMenu: boolean;
  isTablet: boolean;
};

export const DefinePassword: React.FC<Props> = ({
  error,
  isApiLoading,
  onPress,
  title,
  isProgressHeaderDisplayed,
  setPassword,
  isLeftMenu,
  appSettings,
  contentStyle,
}: Props) => {
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const styles = useStyles(
    { theme, isMobile, isTablet, isLeftMenu },
    { contentStyle }
  );
  const [disabled, setDisable] = useState(true);
  const { formatMessage, getMessageRaw } = useTranslation();

  const submitButtonProps = {
    disabled: isApiLoading || disabled,
    loading: isApiLoading,
    onPress: onPress,
    title: formatMessage({ id: 'choose-pwd_input2_label' }),
  };

  const renderMobileFooterContent = () => (
    <View style={styles.footerContentContainer}>
      {error !== '' && (
        <NotificationHard
          containerStyle={styles.errorViewStyle}
          text={error}
          type={NotificationIconTypeEnum.WARNING}
        />
      )}
      <View style={styles.buttonsMobileRowStyle}>
        <SimpleButton
          disabled={disabled}
          loading={isApiLoading}
          onPress={onPress}
          title={formatMessage({
            id: 'define-password_button',
          })}
        />
      </View>
    </View>
  );
  return (
    <EERKeyboardAvoidingViewPageWrapper>
      <EERPageWrapperContainer
        activeStep={1}
        displayCloseButton={false}
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'eer_info_content' })}
        helpPointModalTitle={formatMessage({ id: 'eer_info_title' })}
        helpPointPosition="left"
        pageTitle={formatMessage({ id: 'verify-identity_menu' })}
        renderStickyMobileFooterContent={renderMobileFooterContent}
      >
        <EERContentWrapper
          title={formatMessage({ id: title })}
        >
          <View style={styles.contentStyle}>
            <PasswordCheck
              appSettings={appSettings}
              error={error}
              placeholderConfirmNewPassword={formatMessage({
                id: 'define-password_input2_label',
              })}
              placeholderNewPassword={formatMessage({
                id: 'choose-pwd_input1_label',
              })}
              setDisable={setDisable}
              setPassword={setPassword}
              submitButtonProps={submitButtonProps}
              testIdNewPassword={locator._forgot_password._new_password}
              testIdConfirmNewPassword={locator._forgot_password._confirm_new_password}
            />
            {isDesktop && (
              <View style={styles.buttonsRowStyle}>
                {error !== '' && (
                  <NotificationHard
                    containerStyle={styles.errorViewStyle}
                    text={error}
                    type={NotificationIconTypeEnum.WARNING}
                  />
                )}
                <View>
                  <SimpleButton
                    disabled={disabled}
                    loading={isApiLoading}
                    onPress={onPress}
                    title={formatMessage({
                      id: 'define-password_button',
                    })}
                    testId={locator._forgot_password._confirm_password_button}
                  />
                </View>
              </View>
            )}
          </View>
        </EERContentWrapper>
      </EERPageWrapperContainer>
    </EERKeyboardAvoidingViewPageWrapper>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: ChoosePasswordPageStyles
): ChoosePasswordPageStyles => ({
  containerStyle: [

    !(context?.isMobile || context?.isTablet)
      ? { flex: 1, flexDirection: 'row' }
      : { flex: 1, flexDirection: 'column' },
  ],
  contentStyle: [
    !context?.isLeftMenu
      ? !(context?.isMobile || context?.isTablet)
        ? {
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          flex: 1,
        }
        : { marginTop: context?.theme.metrics.spacing.xm }
      : !(context?.isMobile || context?.isTablet)
        ? {
          flex: 2.1,
          marginRight: context?.theme.metrics.spacing.xm,
          marginTop: context?.theme.metrics.spacing.xm,
        }
        : {
          marginTop: context?.theme.metrics.spacing.xm,
          alignSelf: 'center',
          width: '100%',
          maxWidth: 450,
        },
    style?.contentStyle,
  ],
  footerContentContainer: [
    {
      paddingHorizontal: context?.theme.metrics.spacing.xm,
    },
  ],
  errorViewStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm,
    },
    style?.errorViewStyle,
  ],
  buttonsRowStyle: [
    {
      flexDirection: 'column',
      width: 300,
      justifyContent: 'flex-start',
      marginTop: context?.theme.metrics.spacing.xm,
      marginBottom: context?.theme.metrics.spacing.xxxbig,
    },
    style?.buttonsRowStyle,
  ],
  buttonsMobileRowStyle: [
    {
      flexDirection: 'column',
    },
    style?.buttonsMobileRowStyle,
  ],
  titleStyle: [
    {
      lineHeight: context?.theme.metrics.spacing.l,
      marginBottom: context?.theme.metrics.spacing.xxl,
    },
    style?.titleStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
