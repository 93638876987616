import { CardWithLeftThread, Text } from '@ere-uilib/atoms';
import { FiletStepper, HeaderResponse } from '@ere-uilib/molecules';
import { useTranslation } from '@ere-uilib/translations';
import React from 'react';
import { View } from 'react-native';
import { Props } from './interfaces';
import { useStyles } from './useStyles';



export const FiletStepperCardOrigin: React.FC<Props> = ({
  title,
  amount,
  description,
  containerStyle,
  headerResponseCustomContainerStyle,
  children
}) => {
  const styles = useStyles();

  const {
    formatMessage,
  } = useTranslation()
  //   RENDER ORIGIN CARD
  return (<View >
    <FiletStepper
      containerStyle={styles.filetContainer}
      contentContainer={containerStyle}
      disableLine={false}
      filetContainerStyle={styles.filetSubContainer}>
      <View style={styles.fromContent}>
        <Text style={styles.filetText}>
          {formatMessage({ id: 'PIAffectationdefautDeLabel' })}
        </Text>
        <CardWithLeftThread
          addShadow
          containerStyle={styles.cardContainer}
        >
          <HeaderResponse
            amount={amount}
            customContainerStyle={
              [
                {
                  backgroundColor: "#FFF",
                  borderTopLeftRadius: 0
                },
                headerResponseCustomContainerStyle,
              ]
            }
            darkText={true}
            shouldFormatAsPlusMinus={false}
            title={title}
          />
          {description !== "" &&
            <Text weight="light" style={styles.textContainer}>
              {description}
            </Text>
          }
          {children}
        </CardWithLeftThread>
      </View>
    </FiletStepper>
  </View>
  );
};