import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import {
  DashboardPageWrapperConnected,
  Text,
  useTranslation,
  NavigationHeader,
  ColumnsContainer,
  Title,
  Switch,
  SimpleButton
} from '@components/index';

import { ConnexionBiometriquePageProps } from './interfaces';
import { useStyles } from './useStyles';
import { useNavigation } from '@react-navigation/native';

export const ConnexionBiometriquePageComponent: React.FC<ConnexionBiometriquePageProps> = ({
  isBiometricLockedFromProfileMenu,
  setIsBiometricLocked
}) => {
  const { formatMessage } = useTranslation();
  const styles = useStyles();
  const navigation = useNavigation()
  const [confirmDisableConnexionBiometricState, setConfirmDisableConnexionBiometricState] = useState<boolean>(false);
  const [lockBiometric, setIsLockBiometric] = useState<boolean>(false);

  useEffect(() => {
    setIsLockBiometric(isBiometricLockedFromProfileMenu)
  }, [isBiometricLockedFromProfileMenu])

  const connexionBiometricMessage = useCallback(() => {
    if (!lockBiometric) return formatMessage({ id: 'LoginNatifBiometryJourneyProfilToggleActif' })
    return formatMessage({ id: 'LoginNatifBiometryJourneyProfilToggleDesactive' });
  }, [lockBiometric])

  const handleToggle = useCallback(() => {
    setIsLockBiometric(!lockBiometric)
    if (!lockBiometric) return setConfirmDisableConnexionBiometricState(true);
    return setIsBiometricLocked(false)
  }, [lockBiometric, setConfirmDisableConnexionBiometricState, setIsLockBiometric]);

  const handlePressConfirmButton = useCallback(() => {
    setConfirmDisableConnexionBiometricState(false);
    setIsBiometricLocked(true)
  }, [setConfirmDisableConnexionBiometricState, lockBiometric, setIsBiometricLocked]);

  const handleGoBack = useCallback(() => (
    navigation.goBack()
  ), [navigation]
  );

  const renderConfirmContent = useCallback(() => {
    return (
      <>
        <Text
          style={styles.askConfirmText}
          variant="t3"
          weight="regular">
          {formatMessage({
            id: 'LoginNatifBiometryJourneyProfilConfirmationToggleDesactiveLabel1'
          })}
        </Text>
        <Text
          variant="t3"
          weight="light">
          {formatMessage({
            id: 'LoginNatifBiometryJourneyProfilConfirmationToggleDesactiveLabel2'
          })}
        </Text>
      </>
    );
  }, []);

  const renderConfirmButton = useCallback(() => {
    if (!confirmDisableConnexionBiometricState) return
    return (
      <SimpleButton
        containerStyle={styles.actionsButtonStyle}
        onPress={handlePressConfirmButton}
        title={formatMessage({
          id: 'LoginNatifBiometryJourneyProfilConfirmationToggleDesactiveButton'
        })}
      />
    );
  }, [confirmDisableConnexionBiometricState, handlePressConfirmButton]);

  const renderHeader = useCallback(() => {
    return (
      <NavigationHeader
        displayBackButton={true}
        onGoBack={handleGoBack}
        title={formatMessage({ id: 'LoginNatifBiometryJourneyProfilEditPageTitle' })}
      />
    );
  }, [handleGoBack]);

  const renderToggle = useCallback(() => (
    <Switch
      isOn={!lockBiometric}
      onToggle={handleToggle}
    />
  ), [lockBiometric, handleToggle])

  const renderBiometricCaseMessage = useCallback(() => {
    if (confirmDisableConnexionBiometricState) return renderConfirmContent()
    return (
      <Text
        variant="t3"
        weight="light">
        {connexionBiometricMessage()}
      </Text>
    )
  }, [confirmDisableConnexionBiometricState, connexionBiometricMessage, renderConfirmContent])

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        containerStyle={styles.containerStyle}
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}
        renderStickyMobileBottom={renderConfirmButton}
      >
        <View>
          <View style={styles.headContentContainer}>
            <Title variant="t6">
              {formatMessage({ id: 'LoginNatifBiometryJourneyProfilToggle' })}
            </Title>
            {renderToggle()}
          </View>
          {renderBiometricCaseMessage()}
        </View>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
