import React from 'react';

import {
  IdentificationLandingPageTemplate,
} from '@components/index';

import { SelfcareLetterLandingPageProps } from './interfaces';

export const SelfcareLetterLandingPage: React.FC<SelfcareLetterLandingPageProps> = ({
  logoutCallbackSuccess,
  applicationSettings,
  authConfig,
}) => {
  return (
    <IdentificationLandingPageTemplate
      applicationSettings={applicationSettings}
      authConfig={authConfig}
      isSelfcareLetterLanding={true}
      logoutCallbackSuccess={logoutCallbackSuccess}
    />
  );
};
