import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { RouteNames } from "@constants/navigation";
import { useDefaultScreensOptions } from "@navigation/useDefaultScreensOptions";
import {
  ConfirmModalPageComponent, ProfitSharingIncentiveAssigmentPage, ProfitSharingIncentiveDefaultAffectationsPage, ProfitSharingIncentiveDescription,
  ProfitSharingIncentiveOnboardingPage, ProfitSharingIncentiveRepartitionPage,
  ProfitSharingIncentiveSynthesisPage,
  ProfitSharingIncentiveSuccessPage
} from "@pages/index";
import { ProfitSharingIncentiveFailed } from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveFailedPage";

const ProfitSharingIncentiveStack = createStackNavigator();

export const ProfitSharingIncentiveNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();

  return (
    <ProfitSharingIncentiveStack.Navigator
      initialRouteName={RouteNames.ProfitSharingIncentiveDescription}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
      }}
    >
      <ProfitSharingIncentiveStack.Screen
        component={ProfitSharingIncentiveDescription}
        name={RouteNames.ProfitSharingIncentiveDescription}
        options={defaultScreensOptions.ProfitSharingIncentiveDescription}
      />
      <ProfitSharingIncentiveStack.Screen
        component={ProfitSharingIncentiveAssigmentPage}
        name={RouteNames.ProfitSharingIncentiveAssigment}
        options={defaultScreensOptions.ProfitSharingIncentiveAssigment}
      />
      <ProfitSharingIncentiveStack.Screen
        component={ProfitSharingIncentiveOnboardingPage}
        name={RouteNames.ProfitSharingIncentiveOnboarding}
        options={defaultScreensOptions.ProfitSharingIncentiveOnboarding}
      />
      <ProfitSharingIncentiveStack.Screen
        component={ProfitSharingIncentiveRepartitionPage}
        name={RouteNames.ProfitSharingIncentiveRepartition}
        options={defaultScreensOptions.ProfitSharingIncentiveRepartition}
      />
      <ProfitSharingIncentiveStack.Screen
        component={ProfitSharingIncentiveSynthesisPage}
        name={RouteNames.ProfitSharingIncentiveSynthesis}
        options={defaultScreensOptions.ProfitSharingIncentiveSynthesis}
      />
      <ProfitSharingIncentiveStack.Screen
        component={ProfitSharingIncentiveSuccessPage}
        name={RouteNames.ProfitSharingIncentiveSuccess}
        options={defaultScreensOptions.ProfitSharingIncentiveSuccess}
      />
      <ProfitSharingIncentiveStack.Screen
        component={ProfitSharingIncentiveDefaultAffectationsPage}
        name={RouteNames.ProfitSharingIncentiveDefaultAffectations}
        options={defaultScreensOptions.ProfitSharingIncentiveDefaultAffectations}
      />
      <ProfitSharingIncentiveStack.Screen
        component={ConfirmModalPageComponent}
        name={RouteNames.ProfitSharingIncentiveModal}
        options={defaultScreensOptions.ConfirmModal}
      />
      <ProfitSharingIncentiveStack.Screen
        component={ProfitSharingIncentiveFailed}
        name={RouteNames.ProfitSharingIncentiveFailedPage}
        options={defaultScreensOptions.ProfitSharingIncentiveFailedPage}
      />
    </ProfitSharingIncentiveStack.Navigator>
  );
};
