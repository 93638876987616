import { Title } from '@ere-uilib/atoms';
import { DocumentThemeCard } from '@ere-uilib/organisms';
import { useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import React from 'react'
import { View } from 'react-native';
import { useStyles } from './useStyles';
import { DocumentCategory } from '@modules/document-space/types';
type DocumentFilterContentProps = {
  availableCategories: DocumentCategory[],
  setCategoryToFilter: (category: DocumentCategory) => void,
  renderBottomActions: () => JSX.Element,
}
export const DocumentFilterContent: React.FC<DocumentFilterContentProps> = ({
  availableCategories,
  setCategoryToFilter,
  renderBottomActions
}) => {
  const { formatMessage } = useTranslation();
  const style = useStyles();
  const { isMobile, isTablet } = useScreenSizes();

  return (<View style={style.innerCardStyle}>
    <Title
      style={style.titleStyle}
      variant="t6"
      weight="bold">
      {formatMessage({ id: 'DocCenterThemeSelection' })}
    </Title>
    <View style={style.categoriesCardStyle}>
      {availableCategories?.map((category, index) => (
        <DocumentThemeCard
          isActive={category.isSelected}
          key={category.id + index}
          onPress={() => setCategoryToFilter({ ...category, isSelected: !category.isSelected })}
          style={style.cardStyle}
          title={formatMessage({ id: category.name })} />
      ))}
    </View>
    {(!isMobile && !isTablet) && (renderBottomActions())}
  </View>);
}