import { Context, Styles } from './interfaces';

export const getStyles = (
  context: Context,
  style: Styles
): Styles => ({
  containerStyle: [
    {
      overflow: 'hidden',
      borderRadius: context?.theme.metrics.borderRadius.xs,
      borderLeftWidth: context?.theme.metrics.spacing.xs,
    },
    context?.borderLeftColor ? {
      borderLeftColor: context?.borderLeftColor,
    } : {
      borderLeftColor: context?.theme.colors.basics.grey.c400,
    },
    context?.backgroundColor ? {
      backgroundColor: context?.backgroundColor,
    } : {
      backgroundColor: context?.theme.colors.basics.white,
    },
    context?.addShadow && {
      shadowColor: context?.theme.colors.basics.black,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.15,
      shadowRadius: 10,
      elevation: 7,
    },
    style?.containerStyle,
  ],
});
