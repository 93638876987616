import { connect } from 'react-redux';

import { AuthActionType } from '@modules/auth/actions/authActionsTypes';
import { AppState } from '@modules/index';
import {
  authInitError,
  logoutCallbackSuccess,
  promptBiometricUserModalAskSuccess,
  saveLogin,
  signInNativeAppRequest,
  signInNativeAppUsingBiometricRequest
} from '@modules/auth/actions/authActions';
import {
  getBiometricNativeAuthenticationError,
  getBiometricUserAcceptionStatus,
  getIsPromptBiometricData,
  getLoginMail, getNativeAuthenticationError
} from '@modules/auth/selectors';
import { getApplicationSettings, getAuthConfig } from '@modules/settings/selectors';

import { NativeLoginComponent } from './NativeLoginPage.component';
import { getIsBiometricLockedFromProfileMenu } from '@modules/profile/selectors';

const mapStateToProps = (state: AppState) => {
  return {
    loginEmail: getLoginMail(state),
    authConfig: getAuthConfig(state),
    applicationSettings: getApplicationSettings(state),
    error: getNativeAuthenticationError(state),
    isLoading: state.isLoading[AuthActionType.SIGNIN_NATIVE_APP_REQUEST],
    biometricCredentials: getBiometricUserAcceptionStatus(state),
    isPromptBiometricModalAskVisible: getIsPromptBiometricData(state).shouldPromptBiometricModalAskVisible,
    biometryType: getIsPromptBiometricData(state).BiometryType,
    isBiometricAuthLoading: state.isLoading[AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_REQUEST],
    biometricAuthError: getBiometricNativeAuthenticationError(state),
    isBiometricLockedFromProfileMenu: getIsBiometricLockedFromProfileMenu(state)
  };
};

const mapDispatchToProps = {
  signIn: signInNativeAppRequest,
  saveLogin,
  logoutCallbackSuccess,
  signInUsingBiometric: signInNativeAppUsingBiometricRequest,
  setPromptBiometricModalAskVisibleStatus: promptBiometricUserModalAskSuccess,
  resetAuthErrors: authInitError
};

export const NativeLoginPage = connect(mapStateToProps, mapDispatchToProps)(NativeLoginComponent);