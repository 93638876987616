import React, { useCallback } from 'react';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';

import { HtmlStyledRenderConnectedContainer, LegalMenuFooterComponent, ModalWithHtmlContent } from '@components/index';
import { LegalContentState, MenuFooterState } from '@ere-uilib/types/index';
import {
  GetLegalContentRequestAction,
} from '@modules/legal/actions/legalActionsTypes';
import { getAppVersion } from "@utils/versionManager";

export interface DataProps {
  onGetLegalContent: (code: string) => GetLegalContentRequestAction;
  isWhite?: boolean;
  content?: LegalContentState;
  links: MenuFooterState[];
}

export interface LegalMenuFooterStyles {
  legalMenu?: StyleProp<ViewStyle>;
  listContainerStyle?: StyleProp<ViewStyle>;
  listItemContainerStyle?: StyleProp<ViewStyle>;
  listItemTextStyle?: StyleProp<TextStyle>;
  listItemTextSeparatorStyle?: StyleProp<TextStyle>;
}

type LegalMenuFooterProps = DataProps & LegalMenuFooterStyles;

export const LegalMenuFooterConnectedComponent: React.FC<LegalMenuFooterProps> = ({
  onGetLegalContent,
  links,
  isWhite,
  listContainerStyle,
  listItemContainerStyle,
  listItemTextStyle,
  listItemTextSeparatorStyle,
  content,
}: LegalMenuFooterProps) => {
  const appVersion = getAppVersion();

  const renderHtml = useCallback(() => {
    if (!content) return null;

    return (
      <HtmlStyledRenderConnectedContainer
        html={content.content}
      />
    )
  }, [content]);

  return (
    <LegalMenuFooterComponent
      appVersion={appVersion}
      isWhite={isWhite}
      LegalContentsModal={props => (
        <ModalWithHtmlContent
          renderHtmlContent={renderHtml}
          {...props} />
      )}
      links={links}
      listContainerStyle={listContainerStyle}
      listItemContainerStyle={listItemContainerStyle}
      listItemTextSeparatorStyle={listItemTextSeparatorStyle}
      listItemTextStyle={listItemTextStyle}
      onGetLegalContent={(item: string) => onGetLegalContent(item)}
    />
  );
};
