import React from 'react';

import {
  useScreenSizes,
  useTheme,
  DashboardPageWrapperConnected,
  WebView
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { BudgetInsightConnectState } from './interfaces';
import { getStyles } from './styles';

export const BudgetInsightConnectComponent: React.FC<BudgetInsightConnectState> = ({
  budgetInsightUrl
}) => {
  const { isMobile } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile }, {});

  return (
    <DashboardPageWrapperConnected cardContentStyle={styles.containerStyle}>
      <WebView
        scrollEnabled
        sourceUri={{ uri: budgetInsightUrl }}
        style={styles.WebViewStyle}
      />
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
