import React from 'react';
import {
  View,
  TouchableOpacity
} from 'react-native';

import { Text, FontIcon } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

import { HelpButtonProps } from './interface';
import { getStyles } from './styles';

export const HelpButton: React.FC<HelpButtonProps> = ({
  onPress,
  textStyle,
  containerStyle,
  innerContainerStyle,
  leftContainerStyle,
  leftIconStyle,
  rightContainerStyle,
  triangleStyle,
  title
}) => {
  const theme:ThemeType = useTheme();

  const styles = useStyles(
    {
      theme
    },
    {
      containerStyle,
      innerContainerStyle,
      leftContainerStyle,
      leftIconStyle,
      rightContainerStyle,
      triangleStyle,
      textStyle
    }
  );

  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.containerStyle}
    >
      <View style={styles.leftContainerStyle}>
        <FontIcon
          name="aide"
          style={styles.leftIconStyle}
        />
      </View>
      <View style={styles.rightContainerStyle}>
        <View style={styles.triangleViewStyle}>
          <View style={styles.triangleStyle}/>
        </View>
        <View style={styles.innerContainerStyle}>
          <Text
            style={styles.textStyle}
          >
            {title}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const useStyles = createUseStyles(getStyles);
