import React from "react";
import { View } from "react-native";

import { CardStepperArrowProps } from "./sharedInterfaces";
import { useStyles } from "./styles";

export const CardStepperArrow: React.FC<CardStepperArrowProps> = ({ length, offset, color }) => {
  const styles = useStyles({ length, offset, color });

  return <View
    style={[styles.stepArrow]}
  >
    <View style={styles.stepArrowHead}/>
    <View style={styles.stepArrowShaft}/>
  </View>
}
