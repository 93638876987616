import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  NativeScrollEvent,
  ScrollView,
  TouchableOpacity,
  View,
  NativeSyntheticEvent,
  LayoutChangeEvent,
} from 'react-native';

import { FontIcon, Hoverable } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';

import { Chart } from './Charts';
import { PieChartSectionProps } from './interfaces';
import { PieChartSectionLegend } from './PieChartSectionLegend';
import { useStyles } from './useStyles';

export const ArbitrationPieChartSection: React.FC<PieChartSectionProps> = ({
  chartLegend,
  chartData,
}) => {
  const [displayLeftButton, setDisplayLeftButton] = useState(false);
  const [displayRightButton, setDisplayRightButton] = useState(true);
  const [scrollViewWith, setScrollViewWidth] = useState<number>();
  const [scrollViewHeight, setScrollViewHeight] = useState<number>();
  const [chartSectionWith, setChartSectionWidth] = useState<number>();
  const pieChartScrollViewRef = useRef() as any;

  const styles = useStyles({
    displayLeftButton,
    displayRightButton,
    scrollViewHeight,
  });

  const isScrollable = useMemo(() => {
    if (!chartSectionWith || !scrollViewWith) {
      return false;
    }

    const newIsScrollable = scrollViewWith < chartSectionWith;

    return newIsScrollable;
  }, [scrollViewWith, chartSectionWith]);

  const handleScrollViewLayout = useCallback((event: LayoutChangeEvent) => {
    const width = event.nativeEvent.layout.width;
    const height = event.nativeEvent.layout.height;

    setScrollViewWidth(width);
    setScrollViewHeight(height);

    pieChartScrollViewRef.current?.scrollToEnd();
  }, []);

  const handleChartSectionLayout = useCallback((event: LayoutChangeEvent) => {
    const width = event.nativeEvent.layout.width;

    setChartSectionWidth(width);
  }, []);

  const handleSlideLeftPress = useCallback(() => {
    pieChartScrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
  }, []);

  const handleSlideRightPress = useCallback(() => {
    pieChartScrollViewRef.current?.scrollToEnd();
  }, []);

  const handleSliderScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (!chartSectionWith || !scrollViewWith) {
      return;
    }

    const maxScrollableX = chartSectionWith - scrollViewWith;

    if (event.nativeEvent.contentOffset.x === 0) {
      setDisplayLeftButton(false);
      setDisplayRightButton(true);
    } else {
      setDisplayLeftButton(true);
    }

    if (event.nativeEvent.contentOffset.x === maxScrollableX) {
      setDisplayRightButton(false);
      setDisplayLeftButton(true);
    } else {
      setDisplayRightButton(true);
    }
  }, [scrollViewWith, chartSectionWith]);

  const renderButton = useCallback((side: 'left' | 'right') => {
    const iconName = side === 'left' ? IconEnum.CHEVRON_LEFT : IconEnum.CHEVRON_RIGHT;
    const handlePress = side === 'left' ? handleSlideLeftPress : handleSlideRightPress;

    return (
      <View>
        <TouchableOpacity
          onPress={handlePress}
          style={styles.sliderButton}
        >
          <FontIcon
            name={iconName}
          />
        </TouchableOpacity>
      </View>
    )
  }, [handleSlideLeftPress, handleSlideRightPress, styles]);

  const renderButtons = useCallback((isHoverable?: boolean) => 
    isHoverable && (
      <View style={styles.sliderButtonsContainer}>
        <View style={styles.sliderButtons}>
          {displayLeftButton && (
            renderButton('left')
          )}
          {displayRightButton && (
            renderButton('right')
          )}
        </View>
      </View>
    )
  , [
    displayLeftButton,
    displayRightButton,
    renderButton,
    styles,
  ]);

  const renderPieChartSection = useCallback(
    () => (
      <Hoverable>
        {
          (isHoverable: boolean) =>
            <View>
              <ScrollView
                horizontal
                onLayout={handleScrollViewLayout}
                onScroll={handleSliderScroll}
                ref={pieChartScrollViewRef}
                showsHorizontalScrollIndicator={false}
                style={styles.pieChartScrollView}
              >
                <Chart
                  chartData={chartData}
                  onLayout={handleChartSectionLayout}
                />
              </ScrollView>
              {isScrollable && renderButtons(isHoverable)}
            </View>
        }
      </Hoverable>
    ),
    [
      chartData,
      pieChartScrollViewRef,
      handleScrollViewLayout,
      styles,
      handleSliderScroll,
      renderButtons,
      handleChartSectionLayout,
      isScrollable,
    ]
  );

  return (
    <>
      {renderPieChartSection()}
      <PieChartSectionLegend repartitionLegend={chartLegend} />
    </>
  );
};
