import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import {
  DashboardPageWrapperConnected,
  useTranslation,
  useTheme,
  NavigationHeader,
  ProfileInformationsEditCard,
  ColumnsContainer,
  RectangleLoader
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';
import { ProfileInformationsContent } from '@ere-uilib/molecules/cards/ProfileInformationsContent';

import { RouteNames } from '@constants/navigation';
import { locator } from '@constants/locator';
import { getDocumentBlobRequestByType } from '@modules/document-space/actions/documentsActions';
import { generateAddressContents } from '@pages/Dashboard/ProfilePage/EditProfileInformationsPage/mapping';
import { CardChildInfo } from '@pages/Dashboard/ProfilePage/EditProfileInformationsPage/types';
import { RootNavigation } from '@navigation/RootNavigation';
import { DocumentTypeEnum, PlanFamillyList } from '@constants/common';
import { Props } from './interfaces';
import { getStyles } from './styles';

export const EditProfileInformationsPage: React.FC<Props> = ({
  navigation,
  profileUserInformations,
  profilAddresses,
  descriptionText,
  descriptionTextContainer,
  onGetProfileUserInfosRequest,
  onGetProfilAddressRequest,
  isLoading,
}) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const theme = useTheme();
  const styles = useStyles({ theme }, { descriptionText, descriptionTextContainer });

  useEffect(() => {
    onGetProfileUserInfosRequest();
    onGetProfilAddressRequest();
  }, []);

  const dispatch = useDispatch();
  const changeRCAddressHandle = useCallback(() => {
    dispatch(
      getDocumentBlobRequestByType(
        'Coordonnees_modif_formulaire_RC',
        'Modification des coordonnées personnelles'
      )
    );
  }, [dispatch]);

  const handleESAddressChange = useCallback(() => {
    RootNavigation.replace(RouteNames.RedirectOperationPage, {
      provider: PlanFamillyList.ES,
      operationType: DocumentTypeEnum.POST_ADDRESS
    });
  }, []);

  const cardListInfos = [
    {
      informationTitle: formatMessage({ id: 'Profil_display_myemail_title' }),
      editTestId: locator._profile._personal_details._email_edit,
      onEditPress: () =>
        navigation.navigate(RouteNames.BottomTabNavigator, {
          screen: RouteNames.ProfileStack,
          params: {
            screen: RouteNames.ProfileEditEmail
          }
        }),
      childInfos: [
        {
          valueTestId: locator._profile._personal_details._email_value,
          informationDescription: formatMessage({ id: 'Profil_display_myemail_label' }),
          informationSubDescription: null,
          userInformation: profileUserInformations?.mail,
          isEditable: false,
          isFinished: true
        }
      ]
    },
    {
      informationTitle: formatMessage({ id: 'Profil_display_myphone_title' }),
      editTestId: locator._profile._personal_details._phone_edit,
      onEditPress: () =>
        navigation.navigate(RouteNames.BottomTabNavigator, {
          screen: RouteNames.ProfileStack,
          params: {
            screen: RouteNames.ProfileEditPhone
          }
        }),
      childInfos: [
        {
          valueTestId: locator._profile._personal_details._phone_value,
          informationDescription: formatMessage({ id: 'Profil_display_myphone_label' }),
          informationSubDescription: null,
          userInformation: profileUserInformations?.phone,
          isEditable: false,
          isFinished: true
        }
      ]
    },
    {
      informationTitle: formatMessage({ id: 'PersonalDetailsPagePostalAdressTitle' }),
      isEditable: false,
      childInfos: generateAddressContents(
        profilAddresses.addresses,
        formatMessage,
        changeRCAddressHandle,
        handleESAddressChange
      )
    }
  ];

  const RenderHeader = () => {
    return (
      <NavigationHeader
        displayBackButton={true}
        helpPoint={true}
        helpPointContentHtml={getMessageRaw({ id: 'Profil_email_edit_info_content' })}
        helpPointModalTitle={formatMessage({ id: 'Profil_email_edit_info_title' })}
        helpPointPosition={'right'}
        onGoBack={() =>
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.ProfileStack,
            params: {
              screen: RouteNames.Profile
            }
          })
        }
        title={formatMessage({ id: 'Profil_display_title' })}
      />
    );
  };

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        containerStyle={styles.containerStyle}
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}>
        {isLoading && (
          new Array(4).fill(undefined).map((_item, index) => (
            <View style={styles.loaderContainer}>
              <RectangleLoader
                key={`card-${index}`}
                height={150}
              />
            </View>
          ))
        )}
        {!isLoading && (
          <View
            accessibilityLabel={locator._profile._personal_details._title}
            style={styles.contentContainerStyle}>
            {cardListInfos.map((item, index, array) => {
                const isNotLastItem = index !== array.length - 1
                return item.childInfos.length > 0 && (
                  <ProfileInformationsEditCard
                    key={`profil_card_${index}`}
                    informationTitle={item.informationTitle}
                    editTestId={item.editTestId}
                    onEditPress={item.onEditPress}
                    isEditable={item.isEditable}
                    containerStyle={[isNotLastItem && styles.cardItemParentContainer]}
                  >
                    {item.childInfos.map((childItem: CardChildInfo, index: number) => {
                      return (
                        <>
                          {index > 0 && <View style={styles.separator} />}
                          <ProfileInformationsContent
                            key={`profil_content_${index}`}
                            informationDescription={childItem.informationDescription}
                            informatonSubDescription={childItem.informationSubDescription}
                            editStatusLabel={childItem.statusLabel}
                            editStatusType={childItem.statusType}
                            userInformation={(childItem.userInformation || '').trim()}
                            isEditable={childItem.isEditable}
                            valueTestId={childItem.valueTestId}
                            editTestId={childItem.editTestId}
                            statusTestId={childItem.statusTestId}
                            onEditPress={() =>
                              childItem.onEditPress ? childItem.onEditPress() : () => { }
                            }
                            isFinished={childItem.isFinished}
                          />
                        </>
                      );
                    })}
                  </ProfileInformationsEditCard>
                );
              })}
          </View>
        )}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
