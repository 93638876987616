import { useIsFocused } from '@react-navigation/native';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

import {
  CheckBox, Modal,
  ModalFrame, Paragraph, SimpleButton,
  Title
} from '@components/index';
import { SquareIcon } from '@ere-uilib/atoms';
import { PaymentModeList, PlanFamillyList } from '@ere-uilib/constants';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { getStyles } from './styles';

interface Props {
  onClose: () => void;
  onValid: () => void;
  modalVisible: boolean;
  paymentMethod?: PaymentModeList;
  planFamily?: PlanFamillyList;
  testIdAccept?: string;
}

export const InstallmentRepartitionConsentModal: React.FC<Props> = ({
  onClose,
  onValid,
  modalVisible,
  paymentMethod,
  planFamily,
  testIdAccept
}) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const styles = useStyles({ theme }, {});

  const [consents, setConsents] = useState<
    { checked: boolean; message: string }[] | undefined
  >(undefined);
  const isFocused = useIsFocused();

  useEffect(() => {
    const result = getCorrectArray();
    setConsents(result);
  }, []);

  useEffect(() => {
    const result = getCorrectArray();
    setConsents(result);
  }, [paymentMethod, planFamily]);

  useEffect(() => {
    if (isFocused) {
      resetCheckboxesValues();
    }
  }, [isFocused]);

  /* VV ES par CB */
  const EsCbArray = [
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_ES_documents_label' }),
    },
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_ES_ceiling_label' }),
    },
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_ES_cbpayment_label' }),
    },
  ];

  /* VV ES par prélèvement : */
  const EsDirectDebitArray = [
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_ES_documents_label' }),
    },
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_ES_ceiling_label' }),
    },
  ];

  /* VV RC par CB : */
  const RcCbArray = [
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_RC_documents_label' }),
    },
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_RC_UCfunds_label' }),
    },
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_RC_cbpayment_label' }),
    },
  ];

  /* VV RC par prélèvement : */
  const RcDirectDebitArray = [
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_RC_documents_label' }),
    },
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_RC_UCfunds_label' }),
    },
    {
      checked: false,
      message: formatMessage({ id: 'VV_acceptation_RC_bankaccount_label' }),
    },
  ];

  function getCorrectArray() {
    if (paymentMethod && planFamily) {
      if (paymentMethod === PaymentModeList.cb) {
        if (planFamily === PlanFamillyList.ES) {
          return EsCbArray;
        }
        if (planFamily === PlanFamillyList.RC) {
          return RcCbArray;
        }
      }

      if (paymentMethod === PaymentModeList.debit) {
        if (planFamily === PlanFamillyList.ES) {
          return EsDirectDebitArray;
        }
        if (planFamily === PlanFamillyList.RC) {
          return RcDirectDebitArray;
        }
      }
    }
  }

  function resetCheckboxesValues() {
    consents?.forEach(function (obj) {
      if (obj.checked === true) {
        obj.checked = false;
      }
    });
  }

  return (
    <Modal
      visible={modalVisible}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom"
        onClose={() => {
          resetCheckboxesValues();
          onClose();
        }}
      >
        <ScrollView
          contentContainerStyle={styles.scrollViewContentContainerStyle}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          <SquareIcon
            backgroundColor={theme.colors.basics.primary.c200}
            iconColor={theme.colors.basics.primary.c500}
            iconName={IconEnum.SHAKING_HAND}
            iconSize={theme.metrics.iconSizes.xl}
            style={{ container: styles.iconContainerStyle }}
          />
          <Title
            variant="t6"
            weight="bold">
            {formatMessage({ id: 'VV_acceptation_title' })}
          </Title>
          {consents &&
            consents.length > 0 &&
            consents.map((item, index) => (
              <View style={styles.checkboxRowStyle}>
                <CheckBox
                  children={
                    <Paragraph
                      style={styles.checkboxLabel}
                      variant="t3"
                      weight="light"
                    >
                      {item.message}
                    </Paragraph>
                  }
                  containerStyle={{ marginBottom: theme.metrics.spacing.xs }}
                  onPress={() => {
                    const newConsents = cloneDeep(consents);
                    newConsents[index].checked = !consents[index].checked;
                    setConsents(newConsents);
                  }}
                  value={consents[index].checked}
                  testId={`${index}`}
                />
              </View>
            ))}
          <SimpleButton
            containerStyle={styles.acceptButtonStyle}
            disabled={!consents?.every(item => item.checked === true)}
            onPress={onValid}
            title="Accepter"
            testId={testIdAccept}
          />
        </ScrollView>
      </ModalFrame>
    </Modal>
  );
};

const useStyles = createUseStyles(getStyles);
