import {
  ProfitSharingIncentiveRepartitionState,
  ProfitSharingIncentiveSubmitAllocationParamsType,
  ProfitSharingIncentiveAssigmentAmountsType,
  ProfitSharingIncentiveInitData
} from '../types'

type TargetPlanManagementsItemType = ProfitSharingIncentiveSubmitAllocationParamsType["params"]["targetPlanManagements"][number]
type FundsListItemType = TargetPlanManagementsItemType["fundsList"][number]
export const buildProfitSharingIncentiveSubmitAllocationParameters = ({
  companyId,
  initData,
  repartition,
  assigmentAmounts
}:{
  companyId: string
  initData: ProfitSharingIncentiveInitData
  repartition: ProfitSharingIncentiveRepartitionState
  assigmentAmounts : ProfitSharingIncentiveAssigmentAmountsType
}): ProfitSharingIncentiveSubmitAllocationParamsType =>{
  const targetPlanManagements:TargetPlanManagementsItemType[] = []
  repartition?.plans.forEach(plan=>{
    if(plan.formValues?.amount && plan.formValues?.amountPercent) {
      plan?.compartments.forEach(compartment=>{
        compartment?.managements.forEach(management=>{
          if(management.formValues?.amount && management.formValues?.amountPercent) {
            const fundsList:FundsListItemType[] = []
            management.supports.forEach(support=>{
              if(support.formValues?.amount && support.formValues.amountPercent) {
                fundsList.push({
                  rate: support.formValues.amountPercent,
                  supportIsinCode: support.supportIsin,
                  executionTriggerValue: 0 // back ask for it bu there is no trigger threshold on profit-sharing-incentive
                })
              }
            })
            const isFundsAmounts = fundsList.length > 0
            targetPlanManagements.push({
              id: management.id,
              amount: isFundsAmounts ? 0 : management.formValues.amount,
              rate: isFundsAmounts ? 0 : management.formValues.amountPercent,
              fundsList
            })
            
          }
        })
      })
    }
  })
  const parameters:ProfitSharingIncentiveSubmitAllocationParamsType = {
    companyId,
    params: {
      type: initData.type,
      repaymentAmount: assigmentAmounts.refundAmount,
      targetPlanManagements
    }
  }

  return parameters;
};
