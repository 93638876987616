import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { cancelOperationRequest } from '@modules/savings/actions/savingsActions';
import { SavingsActionsType } from '@modules/savings/actions/savingsActionsTypes';
import { getOperationDetails } from '@modules/savings/selectors';

import { OperationCancelPage as OperationCancelPageComponent } from './OperationCancelPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    operationDetails: getOperationDetails(state),
    isCancelLoading: state.isLoading[SavingsActionsType.CANCEL_OPERATION_REQUEST],
    cancelError: state.error[SavingsActionsType.CANCEL_OPERATION_REQUEST],
  };
};
const mapDispatchToProps = {
  onCancelOperation: cancelOperationRequest,
}

export const OperationCancelPage = connect(mapStateToProps, mapDispatchToProps)(OperationCancelPageComponent);