import { connect } from 'react-redux'

import {
  arbitrationBackToQuestionRequest,
  getOadAllocationsRequest,
  setIsVisibleArbitrationDecisionSubmitModal,
  updateArbitrationQuestionWithResponseRequest,
  setOadRepartitionData,
  setOadSimulationData,
  clearArbitrationData
} from '@modules/arbitration/actions/arbitrationActions'
import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes'
import {
  getArbitrationDecision,
  getIsVisibleSubmitModal,
  getOadAllocations,
  getReallocationType,
  getSelectedDispositif
} from '@modules/arbitration/selectors'
import { AppState } from '@modules/reducers'

import { ArbitrationPlanChoice as ArbitrationPlanChoiceComponent } from './ArbitrationPlanChoice.component'
import { getAtInternetClientIdSession } from '@modules/settings/selectors'

const mapStateToProps = (state: AppState) => {
  const isLoading =
    state.isLoading[ArbitrationActionsType.GET_DISPOSITIF_DETAIL_REQUEST] ||
    state.isLoading[ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_REQUEST] ||
    state.isLoading[ArbitrationActionsType.GET_OAD_ALLOCATIONS_REQUEST]

  return {
    requirementDecisionData: getArbitrationDecision(state),
    isVisible: getIsVisibleSubmitModal(state),
    selectedDispositif: getSelectedDispositif(state),
    isLoading,
    reallocationType: getReallocationType(state),
    arbitrationOadAllocations: getOadAllocations(state),
    atInternetClientIdSession: getAtInternetClientIdSession(state)
  }
}

const mapDispatchToProps = {
  onBackToQuestion: arbitrationBackToQuestionRequest,
  onUpdateQuestion: updateArbitrationQuestionWithResponseRequest,
  setIsVisibleArbitrationDecisionSubmitModal: setIsVisibleArbitrationDecisionSubmitModal,
  onGetOadAllocations: getOadAllocationsRequest,
  setRepartionOad: setOadRepartitionData,
  setSimulationOad: setOadSimulationData,
  clearArbitrationData: clearArbitrationData
}

export const ArbitrationPlanChoicePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArbitrationPlanChoiceComponent)