import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

import { TableCellInterface, TableCellTypeEnum } from './sharedInterfaces';

interface CellProps {
  data: TableCellInterface;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  columnsLength: number;
  cellPosition?: number | undefined;
  index: number;
  rowsLength: number;
  rowIndex: number;
  testId?: string;
}

interface CellStyles {
  containerStyle?: StyleProp<ViewStyle>;
  innerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

type StyleContext = {
  theme: ThemeType;
  isLastColumn: boolean;
  isLastRow: boolean;
  isTopColumn: boolean;
  type: TableCellInterface['type'];
};

export const Cell: React.FC<CellProps> = ({
  data,
  containerStyle,
  textStyle,
  columnsLength,
  cellPosition,
  index,
  rowsLength,
  rowIndex,
  testId
}) => {
  const theme = useTheme();
  const isLastColumn = index + 1 === columnsLength;
  const isLastRow = rowIndex + 1 === rowsLength;
  const isTopColumn = cellPosition === 0;

  const styles = useStyle(
    {
      theme,
      isLastColumn,
      isLastRow,
      isTopColumn,
      type: data.type,
    },
    {
      containerStyle,
      textStyle,
    }
  );

  return (
    <View 
    style={styles.containerStyle}>
      <View style={styles.innerStyle} testID={`${testId}_row:${rowIndex}_cell:${index}`}>
        {data.text && !data?.children && (
          <Text
            style={styles.textStyle}
            variant="t3"
            weight={[TableCellTypeEnum.ENTRIES].includes(data.type) ? 'light' : 'regular'}>
            {data.text}
          </Text>
        )}
        {data?.children && data.children}
      </View>
    </View>
  );
};

const getStyles = (context?: StyleContext, style?: CellStyles): CellStyles => {
  let containerTypeStyle: StyleProp<ViewStyle> = {};
  let innerTypeStyle: StyleProp<ViewStyle> = {};
  let textTypeStyle: StyleProp<TextStyle> = {};
  switch (context?.type) {
  case TableCellTypeEnum.EMPTY:
    containerTypeStyle = {
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: 'transparent',
    };
    break;
  case TableCellTypeEnum.HEADER:
    containerTypeStyle = {
      backgroundColor: context?.theme.colors.basics.primary.c500,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: context?.theme.colors.basics.white,
    };
    textTypeStyle = {
      color: context?.theme.colors.basics.white,
      textAlign: 'center',
    };
    break;
  case TableCellTypeEnum.ENTRIES:
    containerTypeStyle = {
      backgroundColor: context?.theme.colors.basics.grey.c100,
      minHeight: 50,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: context?.theme.colors.basics.grey.c200,
    };
    innerTypeStyle = {
      alignSelf: 'flex-start',
    };
    textTypeStyle = {
      alignSelf: 'flex-start',
      textAlign: 'left',
    };
    break;
  case TableCellTypeEnum.DASHED:
    containerTypeStyle = {
      justifyContent: 'center',
      alignItems: 'flex-start',
      backgroundColor: context?.theme.colors.basics.white,
      borderColor: context?.theme.colors.basics.grey.c200,
      borderStyle: 'dashed',
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      marginBottom: 0,
      ...(context?.isLastRow && {
        borderBottomWidth: 0,
      }),
    };

    textTypeStyle = {
      textAlign: 'left',
    };
    break;
  default:
    containerTypeStyle = {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: context?.theme.colors.basics.white,
      borderColor: context?.theme.colors.basics.grey.c200,
    };
    break;
  }
  return {
    containerStyle: [
      {
        flex: 1,
        alignSelf: 'stretch',
        borderWidth: 1,
        marginRight: -1,
        marginBottom: -1,
        ...(context?.isLastColumn && {
          marginRight: 0,
        }),
        ...(context?.isLastRow && {
          marginBottom: 0,
        }),
      },
      containerTypeStyle,
      style?.containerStyle,
    ],
    innerStyle: [
      {
        ...(!context?.isTopColumn && {
          paddingHorizontal: context?.theme.metrics.spacing.s,
          paddingVertical: context?.theme.metrics.spacing.m,
        }),
        ...(context?.isTopColumn && {
          paddingHorizontal: context?.theme.metrics.spacing.xs,
          paddingVertical: context?.theme.metrics.spacing.s,
        })
      },
      innerTypeStyle,
      style?.innerStyle,
    ],
    textStyle: [
      {
        textAlign: 'center',
      },
      textTypeStyle,
      style?.textStyle,
    ],
  };
};

const useStyle = createUseStyles(getStyles);
