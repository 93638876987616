import { languageList } from '@constants/languages';
import { getCookie } from '@utils/cookiesManager';
import { fallbackLanguage } from '@components/ERE-UILib';
import { getLocaleLanguage } from '@components/ERE-UILib/translations/messages';

export const getDefaultLanguage = (): string => {
  // by default we use the first language of language list
  let output = languageList[0].value;
  // check if we have language from cookie
  const cookieLanguage = getCookie('language');
  const isLanguageFromCookie = cookieLanguage && cookieLanguage !== '';
  if (isLanguageFromCookie) {
    // check if cookie language is in languages list
    const isLanguageValid = languageList.find(item => item.value === cookieLanguage);
    if (isLanguageValid) {
      output = cookieLanguage;
    }
  }

  // if no language in cookies check browser language
  if (!isLanguageFromCookie) {
    const locale = getLocaleLanguage();
    output = locale.language ?? fallbackLanguage;
  }

  return getValidLanguage(output);
};

const getValidLanguage = (language: string): string => {
  // by default we use the first language of language list
  let output = languageList[0].value;
  // check if language is in languages list
  const isLanguageValid = languageList.find(item => item.value === language);
  if (isLanguageValid) {
    output = language;
  }
  return output;
};
