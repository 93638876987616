import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform, View } from 'react-native';

import { DialOncePressable } from '@components/index';
import { RouteNames } from '@constants/navigation';
import {
  SimpleButton,
  Title,
  Text,
  Link,
  useTranslation,
} from '@ere-uilib/index';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles'
import { getStyles } from '../../styles';

type Props = {
  isSelfcareLetterLanding: boolean;
  applicationSettings: any;
  handleLoginOpenId: () => void;
};
export const AuthenticationBottomContainer: React.FC<Props> = ({
  isSelfcareLetterLanding,
  applicationSettings,
  handleLoginOpenId
}) => {
  const navigation = useNavigation();
  const { formatMessage } = useTranslation();
  const { isDesktop, isMobile, isTablet, windowWidth } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile, isTablet, isDesktop, windowWidth }, {});

  return (
    <View style={styles.selfcareBottomContainer}>
      {isSelfcareLetterLanding && (
        <View>
          <Title
            style={styles.smallLoginTitle}
            variant={isMobile ? 't5' : 't4'}
            weight="bold">
            {formatMessage({ id: 'EerSelfcareStep1CourrierDejaActifLabel' })}
          </Title>
          <View style={styles.smallLoginButtonContainer}>
            <SimpleButton
              design="outlined"
              isHoverableButton
              onPress={handleLoginOpenId}
              title={formatMessage({ id: 'EerSelfcareStep1CourrierDejaActifLink' })}
            />
          </View>
        </View>
      )}
      <DialOncePressable
        integrationKey={applicationSettings.HelpDialOnceIntegrationKey || ''}
        targetedFlowId={applicationSettings.HelpLoginPageDialOnceId || ''}>
        <Text variant={isMobile ? 't3' : 't2'}>
          <Link
            onPress={() => { }}
            textStyle={[styles.helpLinkStyle, !isDesktop && styles.textWhiteStyle]}>
            {formatMessage({ id: 'verify-identity_help' })}
          </Link>
        </Text>
      </DialOncePressable>
      {['ios', 'android'].includes(Platform.OS) && __DEV__ && (
        <Text variant={isMobile ? 't3' : 't2'}>
          <Link
            onPress={() => navigation.navigate(RouteNames.Storybook)}
            textStyle={[!isDesktop && styles.textWhiteStyle]}>
            {'DEV access Storybook'}
          </Link>
        </Text>
      )}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
