import React, { useEffect } from 'react';
import { View } from 'react-native';

import {
  useTheme,
  DashboardPageWrapperConnected,
  FundSheetInnerPage,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { FundSheetProps } from './interface';
import { getStyles } from './styles';

export const FundSheet: React.FC<FundSheetProps> = ({
  funds,
  fundsRepartitionSupport,
  fundsPerformance,
  fundsPerformanceSharePriceHisto,
  fundsDocumentsList,
  onGetFunds,
  isApiLoadingOverview,
  isApiLoadingPerformance,
  isApiLoadingDocuments,
  innerNavigations,
  route,
  navigation,
}) => {
  const { title, id }: any = route.params;

  const isingCode = id;
  const fundTitle = title;

  useEffect(() => {
    if (!!isingCode) {
      onGetFunds(isingCode);
    }
  }, [onGetFunds, isingCode]);

  const theme = useTheme();

  const styles = useStyles({ theme }, {});

  return (
    <DashboardPageWrapperConnected>
      <View style={styles.containerStyle}>
        <FundSheetInnerPage
          dateLastVL={funds?.dateLastVL || ''}
          fundOverview={funds}
          fundsDocumentsList={fundsDocumentsList}
          fundsPerformance={fundsPerformance}
          fundsPerformanceSharePriceHisto={fundsPerformanceSharePriceHisto}
          fundsRepartitionSupport={fundsRepartitionSupport}
          fundTitle={fundTitle}
          innerNavigations={innerNavigations}
          isApiLoadingDocuments={isApiLoadingDocuments}
          isApiLoadingOverview={isApiLoadingOverview}
          isApiLoadingPerformance={isApiLoadingPerformance}
          isingCode={isingCode}
          launchDate={funds?.dateLancement || ''}
          navigation={navigation}
        />
      </View>
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
