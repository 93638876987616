import React from 'react';
import { Text as RNText, StyleProp, TextStyle } from 'react-native';

import { createUseStyles, useTheme, getParagraphFontStyle } from '@ere-uilib/styles';

import { TextProps, TextStylesContext } from './interfaces';

export const Text: React.FC<TextProps> = ({
  style,
  variant = 't2',
  weight = 'regular',
  onLayout,
  testId,
  ...otherProps
}) => {
  const theme = useTheme();
  const textStyle = useStyles(
    {
      theme,
      weight,
      variant,
      numberOfLines: otherProps.numberOfLines,
    },
    style
  );

  return (
    <RNText
      onLayout={onLayout}
      style={textStyle}
      testID={testId}
      accessibilityLabel={testId}
      {...otherProps}
    />
  );
};

const getStyles = (
  context: TextStylesContext,
  style?: StyleProp<TextStyle>,
): StyleProp<TextStyle> => [
  {
    ...getParagraphFontStyle({
      weight: context?.weight,
      variant: context.variant,
      theme: context?.theme,
      numberOfLines: context?.numberOfLines,
    }),
  },
  style,
];

const useStyles = createUseStyles(getStyles);
