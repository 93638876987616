import React from "react";
import { OperationTypeModal } from "@components/index";
import { Props } from "./interfaces";
import { useController } from "./useController";

export const OperationTypeModalConnected: React.FC<Props> = ({
  onClose,
  operationType,
  operationModalList,
  isLoadingOperationModalList,
  informationRow,
  separator,
  textContainer,
  iconContainer,
}) => {
  const {
    isModalValidContent,
    onHandleButtonPress,
    validationButtonData
  } = useController({ operationModalList, operationType });


  return (
    <OperationTypeModal
      iconContainer={iconContainer}
      informationRow={informationRow}
      isLoadingOperationModalList={isLoadingOperationModalList}
      onClose={onClose}
      onModalButtonPress={onHandleButtonPress}
      separator={separator}
      textContainer={textContainer}
      data={validationButtonData}
      isModalValidContent={isModalValidContent}
    />
  );
};
