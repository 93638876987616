import { InputMode } from "@modules/bank-details/constants";
import { BankDataType } from "@modules/bank-details/types";

export const mockBankData: BankDataType =
{
  "es": {
    "planList": [
      "PEE",
      "PERECO"
    ],
    "debitBankAccountDetails": {
      "domiciliation": null,
      "titulaire": null,
      "rum": "456",
      "ics": null,
      "iban": "FR*********************0PRE",
      "bic": "SLMPFRP1",
      "type": "PRELEVEMENT",
      "iso": "FR76",
      "status": "DEMANDE_INITIEE",
      "statusLabel": "CoordonneesBancairesDEMANDE_INITIEE",
      "dateSignatureMandat": null,
      "inputMode": InputMode.DATA_AND_UPLOAD,
      "allowedModification": true,
      "tsaAddress": null,
      "bankAccountIsSet": true,
      "fileMaxSize": 2000000,
      "totalFileMaxSize": 25000000
    },
    "transferBankAccountDetails": {
      "domiciliation": null,
      "titulaire": null,
      "rum": "123",
      "ics": null,
      "iban": "FR*********************0VER",
      "bic": "RGFIFRPP",
      "type": "VIREMENT",
      "iso": "FR76",
      "status": "DEMANDE_EN_COURS",
      "statusLabel": "CoordonneesBancairesDEMANDE_EN_COURS",
      "dateSignatureMandat": null,
      "inputMode": InputMode.DATA_AND_UPLOAD,
      "allowedModification": true,
      "tsaAddress": null,
      "bankAccountIsSet": true,
      "fileMaxSize": 2000000,
      "totalFileMaxSize": 25000000
    },
    "signatureAllowed": false,
    "allowedCountries": [
      "COUNTRY_FRA",
      "COUNTRY_BEL",
      "COUNTRY_ESP",
      "COUNTRY_ITA"
    ],
    "fileMaxSize": 2000000,
    "totalFileMaxSize": 25000000,
  },
  "rc": {
    "planList": [
      "PEE",
      "PERECO"
    ],
    "debitBankAccountDetails": {
      "domiciliation": null,
      "titulaire": null,
      "rum": "456",
      "ics": null,
      "iban": "FR*********************0PRE",
      "bic": "SLMPFRP1",
      "type": "PRELEVEMENT",
      "iso": "FR76",
      "status": "DEMANDE_INITIEE",
      "statusLabel": "CoordonneesBancairesDEMANDE_INITIEE",
      "dateSignatureMandat": null,
      "inputMode": InputMode.DATA_AND_UPLOAD,
      "allowedModification": true,
      "tsaAddress": null,
      "bankAccountIsSet": false,
      "fileMaxSize": 2000000,
      "totalFileMaxSize": 25000000
    },
    "signatureAllowed": false,
    "fileMaxSize": 2000000,
    "totalFileMaxSize": 25000000,
  },
}

export const mockBankWithLadRad: BankDataType =
{
  "es": {
    "planList": [
      "PEE",
      "PERECO"
    ],
    "debitBankAccountDetails": {
      "domiciliation": null,
      "titulaire": null,
      "rum": null,
      "ics": null,
      "iban": "FR*********************6660",
      "bic": "BNPAFRPPXXX",
      "type": "PRELEVEMENT",
      "iso": "FR76",
      "status": "VALIDE",
      "statusLabel": "CoordonneesBancairesVALIDE",
      "dateSignatureMandat": null,
      "inputMode": InputMode.DATA_AND_UPLOAD,
      "allowedModification": true,
      "tsaAddress": {
        "receiver": "Alain Dupont",
        "city": 'Paris',
        "country": "France",
        "precision": "10 avenue Tolbiac",
        "zipCode": "75013"
      },
      "bankAccountIsSet": true,
      "fileMaxSize": 2000000,
      "totalFileMaxSize": 25000000
    },
    "transferBankAccountDetails": {
      "domiciliation": null,
      "titulaire": null,
      "rum": null,
      "ics": null,
      "iban": "FR*********************6660",
      "bic": "BNPAFRPPXXX",
      "type": "VIREMENT",
      "iso": "FR76",
      "status": "VALIDE",
      "statusLabel": "CoordonneesBancairesVALIDE",
      "dateSignatureMandat": null,
      "inputMode": InputMode.SERVICE_RH,
      "allowedModification": true,
      "tsaAddress": null,
      "bankAccountIsSet": true,
      "fileMaxSize": 2000000,
      "totalFileMaxSize": 25000000
    },
    "allowedCountries": [
      "COUNTRY_FR",
      "COUNTRY_MC",
      "COUNTRY_YT",
      "COUNTRY_GP",
      "COUNTRY_GF",
      "COUNTRY_MQ",
      "COUNTRY_RE",
      "COUNTRY_NC",
      "COUNTRY_PF",
      "COUNTRY_PM",
      "COUNTRY_TF",
      "COUNTRY_WF",
    ],
    "signatureAllowed": true,
    "fileMaxSize": 2000000,
    "totalFileMaxSize": 25000000,
  },
  "rc": null
}

export const mockBankWithoutLadRad: BankDataType =
{
  "es": {
    "planList": [
      "PEE",
      "PERECO"
    ],
    "debitBankAccountDetails": {
      "domiciliation": null,
      "titulaire": null,
      "rum": null,
      "ics": null,
      "iban": "FR*********************6699",
      "bic": "BNPAFRPPXXX",
      "type": "PRELEVEMENT",
      "iso": "FR76",
      "status": "VALIDE",
      "statusLabel": "CoordonneesBancairesVALIDE",
      "dateSignatureMandat": null,
      "inputMode": InputMode.TSA,
      "allowedModification": true,
      "tsaAddress": {
        "receiver": "Alain Dupont",
        "city": 'Paris',
        "country": "France",
        "precision": "10 avenue Tolbiac",
        "zipCode": "75013"
      },
      "bankAccountIsSet": true,
      "fileMaxSize": 2000000,
      "totalFileMaxSize": 25000000
    },
    "transferBankAccountDetails": {
      "domiciliation": null,
      "titulaire": null,
      "rum": null,
      "ics": null,
      "iban": "FR*********************6660",
      "bic": "BNPAFRPPXXX",
      "type": "VIREMENT",
      "iso": "FR76",
      "status": "VALIDE",
      "statusLabel": "CoordonneesBancairesVALIDE",
      "dateSignatureMandat": null,
      "inputMode": InputMode.TSA,
      "allowedModification": true,
      "tsaAddress": null,
      "bankAccountIsSet": true,
      "fileMaxSize": 2000000,
      "totalFileMaxSize": 25000000
    },
    "allowedCountries": [
      "COUNTRY_FR",
      "COUNTRY_MC",
      "COUNTRY_YT",
      "COUNTRY_GP",
      "COUNTRY_GF",
      "COUNTRY_MQ",
      "COUNTRY_RE",
      "COUNTRY_NC",
      "COUNTRY_PF",
      "COUNTRY_PM",
      "COUNTRY_TF",
      "COUNTRY_WF",
    ],
    "signatureAllowed": false,
    "fileMaxSize": 2000000,
    "totalFileMaxSize": 25000000,
  },
  "rc": null
}