import React from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { useScreenSizes } from '@ere-uilib/styles';

import { ChartSectionLegend } from './interfaces';
import { useStyles } from './useStyles';

export const PieChartSectionLegend: React.FC<ChartSectionLegend> = ({ repartitionLegend }) => {
  const { isMobile } = useScreenSizes();
  const styles = useStyles({});

  if (!repartitionLegend) {
    return null;
  }

  return (
    <View style={styles.legendContainer}>
      {repartitionLegend &&
        repartitionLegend.map((repartition, index: number) => (
          <View
            key={index}
            style={styles.legendTitleRow}>
            <View style={[styles.legendPointStyle, { backgroundColor: repartition.color }]} />
            <Text
              variant={isMobile ? `t4` : `t3`}
              weight="light">
              {repartition.title}
            </Text>
          </View>
        ))}
    </View>
  );
};
