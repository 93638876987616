import {useTranslation} from '@ere-uilib/translations';

export const useLabels = () => {
  const { formatMessage } = useTranslation();

  return {
    helpContent: formatMessage({ id: 'Arbitrage_reinvest_info_content' }),
    helpTitle: formatMessage({ id: 'Arbitrage_reinvest_info_title' }),
    pageTitle: formatMessage({ id: 'PIChoixInvestInteressementTitre' }),
    reinvestTotal: formatMessage({ id: 'PIChoixInvestMontantRepartirLabel' }),
    deinvestTotal: formatMessage({ id: 'PIChoixInvestMontantRepartirLabel' }),
    investProjectTitle: formatMessage({ id: 'PIChoixInvestInvestProjetTitre' }),
    investProjectDescription: formatMessage({ id: 'PIChoixInvestInvestProjetDescription' }),
    investRetraiteTitle: formatMessage({ id: 'PIChoixInvestInvestRetraiteTitre' }),
    investRetraiteDescription: formatMessage({ id: 'PIChoixInvestInvestRetraiteDescription' }),
    reinvestTitle: formatMessage({ id: 'PIChoixInvestInvestProjetTitre' }),
    reinvestText: formatMessage({ id: 'PIChoixInvestInvestProjetDescription' }),
    compareBtn: formatMessage({
      id: 'MyFunds_Screen1Compare_Header_Title',
    }),
    reinvestRemaining: formatMessage({ id: 'Arbitrage_modaleDesinvest_ToutReinvest' }),
    reinvestAll: formatMessage({ id: 'Arbitrage_reinvest_lienreinvest' }),
    nextBtn: formatMessage({ id: 'PIChoixInvestContinuerBouton' }),
  };
};
