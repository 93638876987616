import React, { ReactNode } from 'react';
import { StyleProp, View } from 'react-native';
import RenderHtml, {
  PropsFromParent,
} from 'react-native-render-html';

import {
  ModalFrame,
  Modal,
  SVGLocalLoader,
  SimpleButton,
  Title,
  NotificationHard,
  NotificationIconTypeEnum,
  Text
} from '@components/index';

import { InformativePopinProps } from './interfaces';
import { useStyles } from './style';

export const InformativePopin: React.FC<InformativePopinProps> = ({
  isModalVisible,
  isLoading,
  onValidate,
  onClose,
  title = '',
  description,
  buttonTitle = '',
  iconName,
  error,
  onCancel,
  cancelButtonTitle,
  iconWidth = 60,
  iconHeight = 60,
  iconStyle,
  isDescriptionHtml = true,
  content,
  testId
}) => {
  const styles = useStyles();
  return (
    <Modal visible={isModalVisible}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom"
        onClose={!isLoading ? onClose : undefined}>
        <View style={styles.container}>
          {iconName && <View style={[styles.iconStyle, iconStyle]}>
            <SVGLocalLoader
              height={iconHeight}
              name={iconName}
              width={iconWidth}
            />
          </View>}
          <Title
            style={styles.titleStyles}
            variant="t6">
            {title}
          </Title>
          <View style={styles.contentStyles}>
            {!!description && (isDescriptionHtml ? <RenderHtml
              tagsStyles={{
                p: styles.htmlText,
              }}
              source={{
                html: description
              }}
            /> : <Text
              variant='t3'
              weight='light' >{description}</Text>)}
            {content && content()}
          </View>
          {!!error && (
            <NotificationHard
              text={error}
              type={NotificationIconTypeEnum.WARNING}
            />
          )}
          {onCancel && !!cancelButtonTitle && (

            <SimpleButton
              containerStyle={styles.buttonStyle}
              design='outlined'
              loading={isLoading}
              onPress={onCancel}
              title={cancelButtonTitle} />
          )}
          {onValidate && !!buttonTitle && <SimpleButton
            containerStyle={styles.buttonStyle}
            loading={isLoading}
            onPress={onValidate}
            title={buttonTitle}
            testId={testId}
          />}
        </View>
      </ModalFrame>
    </Modal>
  );
};
