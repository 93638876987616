import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { Paragraph, Link, Title, SquareIcon } from '@ere-uilib/atoms';
import { IconEnum, OtpChanelTypeEnum, OtpRequestTypeEnum } from '@ere-uilib/enums';
import { ModalFrame, SimpleButton } from '@ere-uilib/molecules';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { ProfileUserInfosState, OtpParametersState } from '@ere-uilib/types';

interface Props {
  otpParameters: OtpParametersState | null;
  userInfos?: ProfileUserInfosState;
  isActionsDisabled: boolean;
  onSendMessagePress: (chanelType?: OtpChanelTypeEnum) => void | null;
  isLoading?: boolean;
  renderHelp?: (otpHelpLabel: string) => React.ReactNode;
}

import { getStyles } from './styles';

export const OtpValidationModal: React.FC<Props> = ({
  otpParameters,
  isActionsDisabled,
  onSendMessagePress,
  userInfos,
  isLoading,
  renderHelp
}) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { formatMessage } = useTranslation();

  const otpIsSecure = otpParameters?.requestType === OtpRequestTypeEnum.SECURE;
  const otpChanel = otpParameters?.chanelType || '';
  const otpValue =
    otpChanel === OtpChanelTypeEnum.MAIL || otpChanel === OtpChanelTypeEnum.MAIL_DOCAPOSTE
      ? otpParameters?.chanelLabelMail
      : otpParameters?.chanelLabelSms;
  let otpLabel,
    otpButtonLabel,
    otpHelpLabel = '';

  switch (otpChanel) {
    case OtpChanelTypeEnum.MAIL:
    case OtpChanelTypeEnum.MAIL_DOCAPOSTE:
      if (otpIsSecure) {
        otpLabel = formatMessage({ id: 'sessionsecurity_step1_email_label' });
      } else {
        otpLabel = formatMessage({
          id: 'Profil_email_edit_otp_label'
        });
      }
      break;
    case OtpChanelTypeEnum.SMS_DOCAPOSTE:
    case OtpChanelTypeEnum.SMS:
      if (otpIsSecure) {
        otpLabel = formatMessage({ id: 'sessionsecurity_step1_mobile_label' });
      } else {
        otpLabel = formatMessage({
          id: 'Profil_phone_edit_otp_label'
        });
      }
      break;

    default:
      if (otpIsSecure) {
        otpLabel = formatMessage({ id: 'sessionsecurity_step1_all_label1' });
      } else {
        otpLabel = formatMessage({
          id: 'sessionsecurity_step2_mobile_otp_label'
        });
      }
      break;
  }

  switch (otpChanel) {
    case OtpChanelTypeEnum.MAIL:
    case OtpChanelTypeEnum.MAIL_DOCAPOSTE:
      otpButtonLabel = formatMessage({
        id: 'Profil_email_edit_otp_send_button'
      });
      otpHelpLabel = formatMessage({ id: 'sessionsecurity_help_email_aide' });
      break;
    case OtpChanelTypeEnum.SMS:
    case OtpChanelTypeEnum.SMS_DOCAPOSTE:
      otpButtonLabel = formatMessage({
        id: 'Profil_phone_edit_otp_send_button'
      });
      otpHelpLabel = formatMessage({ id: 'sessionsecurity_help_mobile_aide' });
      break;
    default:
      otpButtonLabel = formatMessage({
        id: 'Profil_email_edit_otp_send_button'
      });
      otpHelpLabel = formatMessage({ id: 'sessionsecurity_help_all_title' });
      break;
  }

  const { isMobile } = useScreenSizes();
  const styles = useStyles({ theme, isMobile }, {});
  return (
    <View style={styles.container}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom"
        onClose={() => navigation.goBack()}>
        <View
          style={{
            alignItems: 'center',
            padding: theme.metrics.spacing.s
          }}>
          <View style={styles.innerModal}>
            {otpIsSecure ? (
              <SquareIcon
                backgroundColor={theme.colors.notifications.warning.c100}
                iconColor={theme.colors.notifications.warning.c500}
                iconName={IconEnum.PASSWORD}
                iconSize={theme.metrics.iconSizes.m}
                style={{ container: styles?.iconContainer }}
              />
            ) : (
              <SquareIcon
                backgroundColor={theme.colors.basics.primary.c200}
                iconColor={theme.colors.basics.primary.c500}
                iconName={IconEnum.CHECK}
                iconSize={theme.metrics.iconSizes.m}
                style={{ container: styles?.iconContainer }}
              />
            )}

            <Title
              style={styles.titleContainer}
              variant="t6">
              {otpIsSecure
                ? formatMessage({ id: 'sessionsecurity_title' })
                : formatMessage({ id: 'validate-identity_title' })}
            </Title>
          </View>

          <View style={styles.textContainer}>
            <Paragraph
              style={{ textAlign: 'center' }}
              variant="t3"
              weight="light">
              {otpLabel}
              {otpChanel && (
                <Paragraph
                  variant="t3"
                  weight="regular">
                  {otpValue}
                </Paragraph>
              )}
            </Paragraph>
            {!otpChanel && (
              <Paragraph
                style={{ marginTop: theme.metrics.spacing.s }}
                variant="t3"
                weight="light">
                {formatMessage({ id: 'sessionsecurity_step1_all_label2' })}
              </Paragraph>
            )}
          </View>
          {otpChanel ? (
            <SimpleButton
              testId="otpReceiveByMessage"
              containerStyle={styles.buttonContainer}
              disabled={isActionsDisabled}
              loading={isLoading}
              onPress={() => {
                onSendMessagePress();
              }}
              title={otpButtonLabel}
            />
          ) : (
            <>
              <SimpleButton
                testId="otpReceiveByMessage"
                containerStyle={styles.buttonContainer}
                disabled={isActionsDisabled}
                loading={isLoading}
                onPress={() => {
                  onSendMessagePress(OtpChanelTypeEnum.SMS);
                }}
                title={
                  formatMessage({
                    id: 'sessionsecurity_step1_all_phonebutton'
                  }) +
                  ' ' +
                  userInfos?.phone
                }
              />
              <SimpleButton
                testId="otpReceiveByEmail"
                containerStyle={styles.buttonContainer}
                disabled={isActionsDisabled}
                loading={isLoading}
                onPress={() => {
                  onSendMessagePress(OtpChanelTypeEnum.MAIL);
                }}
                title={
                  formatMessage({
                    id: 'sessionsecurity_step1_all_emailbutton'
                  }) +
                  ' ' +
                  userInfos?.mail
                }
              />
            </>
          )}
          {renderHelp ? (
            <View style={styles.customHelpContainer}>{renderHelp(otpHelpLabel)}</View>
          ) : (
            <View style={styles.informationRow}>
              <Link numberOfLines={3}>{otpHelpLabel}</Link>
            </View>
          )}
        </View>
      </ModalFrame>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
