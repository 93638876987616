import { ColumnsContainerContext, ColumnsContainerStyle } from './interfaces'

export const getStyles = (
  context?: ColumnsContainerContext,
  style?: ColumnsContainerStyle
): ColumnsContainerStyle => ({
  containerStyle: [
    /* apply display flex 1 to all plateform 'web' included,
    to have more flexibility and avoid display issue in web versions */
    // (context?.isMobile || context?.isTablet) &&
    {
      flex: 1
    },
    style?.containerStyle
  ],
  scrollViewStyle: [
    (context?.isMobile || context?.isTablet) && {
      flex: 1
    },
    style?.scrollViewStyle
  ],
  scrollViewContentContainerStyle: style?.scrollViewContentContainerStyle,
  stickyButtonsViewStyle: [
    (context?.isMobile || context?.isTablet) && {
      marginTop: context && -context?.theme.metrics.spacing.l,
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingBottom: context?.theme.metrics.spacing.l
    },
    style?.stickyButtonsViewStyle
  ],
  columnsRowStyle: [
    {
      flexDirection: context?.isMobile || context?.isTablet ? 'column' : 'row'
    },
    style?.columnsRowStyle
  ],
  columnSingleRowStyle: [
    {
      flexDirection: 'column'
    },
    style?.columnSingleRowStyle
  ],
  leftColumnStyle: [
    {
      flex: 1,
      paddingVertical: context?.theme.metrics.spacing.xxxxl,
      paddingLeft: context?.theme.metrics.spacing.xl,
      paddingRight: context?.theme.metrics.spacing.xm
    },
    (context?.isMobile || context?.isTablet) && {
      paddingVertical: context?.theme.metrics.spacing.l,
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.s
    },
    style?.leftColumnStyle
  ],
  rightColumnStyle: [
    {
      width: 420,
      paddingVertical: context?.theme.metrics.spacing.xxxxl,
      paddingLeft: context?.theme.metrics.spacing.xxm,
      paddingRight: context?.theme.metrics.spacing.xm
    },
    style?.rightColumnStyle
  ]
})
