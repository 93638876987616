import { Platform } from 'react-native';
import deviceInfoModule from 'react-native-device-info';

import { REACT_ANDROID_VERSION_NAME, REACT_IOS_APP_VERSION, REACT_IOS_BUNDLE_VERSION } from "@config/index";
import { APP_STORE_LINKING_URL, APP_STORE_URL, PLAY_STORE_URL } from '@constants/versionManager';
import { REACT_APP_PACKAGE_IDENTIFIER_ } from '../../config';

export const getPlayStoreUrl = () => {
  const packageId = REACT_APP_PACKAGE_IDENTIFIER_;
  const playStoreUrl = `${PLAY_STORE_URL}?id=${packageId}`;
  return { storeUrl: playStoreUrl, deepLink: playStoreUrl };
}

export const getAppStoreUrl = (appId?: string) => {
  const packageId = REACT_APP_PACKAGE_IDENTIFIER_;
  const dateNow = new Date().getTime();
  const appStoreUrl = `${APP_STORE_URL}?bundleId=${packageId}&date=${dateNow}`;
  const appStoreLinkingUrl = `${APP_STORE_LINKING_URL}${appId ?? ''}`;
  return { storeUrl: appStoreUrl, deepLink: appStoreLinkingUrl };
}

export const getPlayStoreLatestVersion = async () => {
  try {
    const { storeUrl } = getPlayStoreUrl();
    const fetchOptions = {
      headers: { 'sec-fetch-site': 'same-origin' },
    }
    const resultSet = await fetch(storeUrl, fetchOptions);
    const text = await resultSet.text();
    const match = text.match(/Current Version.+?>([\d.-]+)<\/span>/);
    if (match) {
      const version = match[1].trim();
      return { version, deepLink: storeUrl };
    }
    const matchNewLayout = text.match(/\[\[\["([\d.]+?)"\]\]/);
    if (matchNewLayout) {
      const version = matchNewLayout[1].trim();
      return { version: version, deepLink: storeUrl };
    }
  } catch (e) {
    console.warn("ERREOR fetching version from play store", e)
  }
}

export const getAppStoreLatestVersion = async () => {
  try {
    const { storeUrl, deepLink } = getAppStoreUrl()
    const resultSet = await fetch(storeUrl)
    const json = await resultSet.json()
    if (json.resultCount) {
      const version = json.results[0].version;
      const appId = json.results[0].trackId;
      return {
        version,
        deepLink: `${deepLink}${appId}`
      }
    }
    console.warn("No info about this app.")
  } catch (e) {
    console.warn("ERREOR fetching version from app store", e)
  }
}

type VersionType = {
  currentVersion: string
  storeVersion: string
}

export const isMajorVersion = ({
  currentVersion,
  storeVersion
}: VersionType) => {
  const majorCurrentVersion = currentVersion.split('.')[0]
  const majorStoreVersion = storeVersion.split('.')[0]
  return parseInt(majorStoreVersion) > parseInt(majorCurrentVersion)
}

export const getStoreLatestVersion = Platform.select({
  android: getPlayStoreLatestVersion,
  ios: getAppStoreLatestVersion
})

export const getStoreUrl = Platform.select({
  android: getPlayStoreUrl,
  ios: getAppStoreUrl
})

const formatAppVersion = (versionNumber?: string, buildNumber?: string) => () => ({ versionNumber, buildNumber })

export const getAppVersion = Platform.select({
  android: formatAppVersion(REACT_ANDROID_VERSION_NAME, deviceInfoModule.getBuildNumber()),
  ios: formatAppVersion(REACT_IOS_APP_VERSION, deviceInfoModule.getBuildNumber()),
  default: formatAppVersion(),
});