import { connect } from 'react-redux';

import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import {
  profilEditPasswordReset,
  profilEditPasswordRequest,
} from '@modules/profile/actions/profileActions';
import { AppState } from '@modules/reducers';
import {
  getEditPasswordError,
  getIsProfilEditPasswordSuccess,
  getProfileError
} from '@modules/profile/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';

import { EditPasswordPage as EditPasswordPageComponent } from './EditPasswordPage.component';

const mapStateToProps = (state: AppState) => {

  return {
    appSettings: getApplicationSettings(state),
    isApiLoading: state.isLoading[ProfileActionsType.EDIT_PASSWORD_REQUEST],
    isDisplaySuccessModal: getIsProfilEditPasswordSuccess(state),
    error: getProfileError(state),
    editPasswordError: getEditPasswordError(state),
  };
};

const mapDispatchToProps = {
  onSetProfileUserPasswordChangeRequest: profilEditPasswordRequest,
  onCloseSuccessModal: profilEditPasswordReset,
};

export const EditPasswordPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPasswordPageComponent);
