import React from 'react';

import {
  SelfcareSuccessPageTemplate,
} from '@components/index';
import { SelfCareEmailType } from '@modules/invitation/types';
import { ApplicationSettingsState } from '@modules/settings/types';

interface Props {
  selfcareEmail: SelfCareEmailType;
  applicationSettings: ApplicationSettingsState;
}

export const SelfcareVisiogoSuccessPageComponent: React.FC<Props> = ({
  selfcareEmail,
  applicationSettings,
}) => {

  return (
    <SelfcareSuccessPageTemplate
      applicationSettings={applicationSettings}
      selfcareEmail={selfcareEmail}
    />
  );
};

