import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { StyleProp, TextStyle, ViewStyle, View, Platform } from 'react-native';

import {
  useTheme,
  ThemeType,
  useScreenSizes,
  OfflineAnnounceLayout360,
  FontIcon,
  SimpleButton
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { createUseStyles } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { SelfCareEmailType } from '@modules/invitation/types';
import { loginOpenId, clearStaleState } from '@modules/auth/services';
import { ApplicationSettingsState, AuthConfigType } from '@modules/settings/types';
import { useLogoNavigation } from '@utils/index';

interface Props {
  selfcareEmail: SelfCareEmailType;
  applicationSettings: ApplicationSettingsState;
  authConfig: AuthConfigType;
  logoutCallbackSuccess: () => void;
}

type AccountCreationSucessPageStyles = {
  bottomLinksContainer?: StyleProp<ViewStyle>;
  iconCloseButton?: StyleProp<ViewStyle>;
  helpLinkStyle?: StyleProp<TextStyle>;
};

type StylesContext = {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
};

export const SelfcareLetterSuccessPageComponent: React.FC<Props> = ({
  logoutCallbackSuccess,
  selfcareEmail,
  applicationSettings,
  authConfig
}) => {
  const navigation = useNavigation();
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, {});
  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();

  const handlePressClose = () => {
    navigation.navigate(RouteNames.OidcLogoutCallbackRedirect);
  };
  const renderCloseButton = () => (
    <FontIcon
      name="fermer"
      onPress={handlePressClose}
      size={theme.metrics.iconSizes.xxm}
      style={styles.iconCloseButton}
    />
  );

  const handleLoginOpenId = () => {
    loginOpenId(authConfig);
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      clearStaleState(authConfig);
    }
    logoutCallbackSuccess && logoutCallbackSuccess(); // never called, @todo: add action creator to dispatch it
  }, []);

  const renderActions = () => {
    return (
      <View style={styles.bottomLinksContainer}>
        <SimpleButton
          design="solid"
          isHoverableButton
          onPress={handleLoginOpenId}
          title={formatMessage({ id: 'EerSelfcareStep8DejaActifButton' })}
        />
      </View>
    );
  };

  return (
    <OfflineAnnounceLayout360
      actions={renderActions}
      description={formatMessage({ id: 'EerSelfcareStep7DejaActifLabel' })}
      onLogoPress={logoNavigateTo}
      renderInOfflinePageLayoutEnd={renderCloseButton}
      sourceName={'profileCongrats'}
      title={formatMessage({ id: 'EerSelfcareStep6DejaActifTitle' })}
    />
  );
};

const getStyles = (
  context?: StylesContext,
  style?: AccountCreationSucessPageStyles
): AccountCreationSucessPageStyles => ({
  bottomLinksContainer: [
    {
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.m
    }
  ],
  helpLinkStyle: [
    {
      alignItems: 'center'
    }
  ],
  iconCloseButton: [
    {
      position: 'absolute'
    },
    context?.isMobile
      ? {
        top: context?.theme.metrics.spacing.xm,
        right: context?.theme.metrics.spacing.m
      }
      : {
        top: context?.theme.metrics.spacing.xl,
        right: context?.theme.metrics.spacing.l
      }
  ]
});

const useStyles = createUseStyles(getStyles);
