import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { Platform, View } from 'react-native';

import {
  OfflinePageLayout,
  LegalMenuFooterConnected,
  useScreenSizes,
  useTheme,
  LanguageSelector360,
  Modal,
  ModalFrame,
  Text,
  useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';
import { useModalTimer } from '@hooks/useModalTimer';
import { clearStaleState } from '@modules/auth/services';
import { useLogoNavigation } from '@utils/index';
import { getAppVersion } from "@utils/versionManager";

import { AuthenticationBottomContainer } from './components/AuthenticationBottomContainer';
import { IdLandingPageHeader } from './components/IdLandingPageHeader';
import { IdentificationLandingPageTemplateProps } from './interfaces';
import { getStyles } from './styles';

export const IdentificationLandingPageTemplate: React.FC<
  PropsWithChildren<IdentificationLandingPageTemplateProps>
> = ({
  applicationSettings,
  isSelfcareLetterLanding = false,
  authConfig,
  title,
  subTitle,
  children,
}) => {
    const { versionNumber, buildNumber } = getAppVersion()
    const { isDesktop, isTablet, isMobile, windowWidth } = useScreenSizes();
    const { logoNavigateTo } = useLogoNavigation();
    const { formatMessage } = useTranslation();
    const theme = useTheme();
    const styles = useStyles({ theme, isMobile, isTablet, isDesktop, windowWidth }, {});
    const [modalVisible, setModalVisible] = React.useState<boolean>(false);
    const { handleClick } = useModalTimer({
      showModal: setModalVisible,
      duration: 5000
    });
    useEffect(() => {
      if (Platform.OS === 'web') {
        clearStaleState(authConfig);
      }
    }, []);
    const handleModalClose = React.useCallback(() => {
      setModalVisible(false);
    }, []);
    const renderModalText = useMemo(() => {
      return (version: string | number | undefined, label: string) =>
        !!version && (
          <Text
            style={styles.modalTextStyle}
            variant="t2">
            {formatMessage({ id: label })}: {version}
          </Text>
        );
    }, []);
    const renderMenuLegalFooter = () => <LegalMenuFooterConnected isWhite />;

    return (
      <OfflinePageLayout
        displayLogo={true}
        displayMobileBackgroundImage={true}
        innerContainerStyle={styles.offLineLayoutInnerContainer}
        onLogoPress={['ios', 'android'].includes(Platform.OS) ? handleClick : logoNavigateTo}
        renderDesktopCardBelowContent={renderMenuLegalFooter}
        renderMobileFooter={renderMenuLegalFooter}>
        <View style={styles.container}>
          <IdLandingPageHeader
            subTitle={subTitle}
            title={title}
          />
          {children}
          <AuthenticationBottomContainer
            applicationSettings={applicationSettings}
            handleLoginOpenId={() => { }}
            // TODO ??
            isSelfcareLetterLanding={isSelfcareLetterLanding}
          />
        </View>
        <LanguageSelector360 offlineLayoutAbsolutePosition />
        <Modal visible={modalVisible}>
          <ModalFrame
            desktopPosition="center"
            mobilePosition="bottom"
            onClose={handleModalClose}
            title={formatMessage({ id: 'ApplicationDebugTitle' })}>
            <View style={styles.modalContainer}>
              <View style={styles.modalTextContainerStyle}>
                {renderModalText(buildNumber, 'ApplicationDebugBuild')}
                {renderModalText(versionNumber, 'ApplicationDebugVersion')}
                {renderModalText(Platform.Version, 'ApplicationDebugOS')}
              </View>
            </View>
          </ModalFrame>
        </Modal>
      </OfflinePageLayout>
    );
  };

const useStyles = createUseStyles(getStyles);
