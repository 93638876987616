import React, { useMemo, useState } from 'react';
import { TouchableOpacity, useWindowDimensions, View } from 'react-native';
import {
  AvailabilitiesDetailsByDate
} from '@components/ERE360Components/AvailabilityContainer/components/AvailibilitiesDetailsByDate/AvailabilitiesDetailsByDate';
import {
  AvailabilitiesDetailsByFunds
} from '@components/ERE360Components/AvailabilityContainer/components/AvailabilitiesDetailsByFunds/AvailabilitiesDetailsByFunds';
import { useStyles } from './useStyles';
import { AvailabilitiesDetailCardProps } from './interfaces';
import { Text } from '@ere-uilib/atoms';
import { InnerPageContainer } from '@ere-uilib/molecules';
import { BulletList } from 'react-content-loader/native';
import { SavingsPlaceholder } from '@ere-uilib/organisms';
import { useTranslation } from '@components/ERE-UILib';

export const AvailabilityContainer: React.FC<AvailabilitiesDetailCardProps> =
  ({
     amount,
     selectedPlan,
     availabilitiesDetail,
     availabilitiesFunds,
     isApiLoading,
     navigation,
     planName,
     isSapiendoActive,
     isUnlockHelpActive,
     onSapiendoPress
   }) => {
    const styles = useStyles();
    const [isFilterByFund, setIsFilterByFund] = useState<boolean>(false);
    const { height } = useWindowDimensions()
    const bulletListHeight = useMemo(() => {
      return height / 4;
    }, [])
    const { formatMessage } = useTranslation();

    return (
      <>
        {isApiLoading
          ? (
            <InnerPageContainer>
              <View>
                <BulletList
                  style={{ flex: 1 }}
                  width={'100%'}
                  height={bulletListHeight}
                />
              </View>
            </InnerPageContainer>
          )
          : amount === 0
            ? <SavingsPlaceholder
              titleLabelId={selectedPlan === 0
                ? 'Saving_Tab1_noFundsAll_label'
                : 'Saving_Tab1_noFunds_label'}
            />
            : (<>
                <InnerPageContainer>
                  <View style={styles.pageContainer}>
                  <View style={styles.buttonContainer}>
                    <TouchableOpacity
                      onPress={() => setIsFilterByFund(false)}
                      style={[
                        styles.button,
                        styles.leftButton,
                        isFilterByFund? styles.inactiveButton : styles.activeButton
                      ]}>
                      <Text variant={"t3"}>{formatMessage({ id: 'AvailabilityButtonDate' })}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => setIsFilterByFund(true)}
                      style={[
                        styles.button,
                        isFilterByFund? styles.activeButton : styles.inactiveButton
                      ]}>
                      <Text variant={"t3"}>{formatMessage({ id: 'AvailabilityButtonFund' })}</Text>
                    </TouchableOpacity>
                  </View>

                  {isFilterByFund?
                    <AvailabilitiesDetailsByFunds
                      navigation={navigation}
                      availabilitiesFunds={availabilitiesFunds}
                    />
                    :
                    <AvailabilitiesDetailsByDate
                      availabilitiesDetail={availabilitiesDetail}
                      planName={planName}
                      onSapiendoPress={onSapiendoPress}
                      isUnlockHelpActive={isUnlockHelpActive}
                      isSapiendoActive={isSapiendoActive}
                    />
                  }
                  </View>
                </InnerPageContainer>
              </>
            )
        }
      </>

    );
  };
