import React from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { ExpendableGreen } from '@ere-uilib/molecules';
import { createUseStyles } from '@ere-uilib/styles';
import { useTheme } from '@ere-uilib/styles/theme';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const CardManagedFundsWrapper: React.FC<Props> = ({
  containerStyle,
  children,
  investedAmmont
}) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const styles = useStyles({ theme }, { containerStyle });

  return (
    <View style={styles.containerStyle}>
      <View style={styles.headSupportsContainerStyle}>
        <View style={styles.valueContainerStyle}>
          <Text
            variant="t3"
            weight="light">
            {formatMessage({ id: 'repartition_support_actualposition_label' })}
          </Text>
          <Text variant="t3">{investedAmmont}</Text>
        </View>
        <ExpendableGreen
          title={formatMessage({ id: 'VV_repartition_Pilote_schowlist_label' })}
        >
          <View style={styles.wrapperContentStyle}>
            {children}
          </View>
        </ExpendableGreen>
      </View>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
