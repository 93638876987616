import React, { useCallback } from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { useTranslation } from '@ere-uilib/translations';
import { categoryType, documentType, planType } from '@modules/retirement/types';
import { TypeOfSendEnum } from '@pages/Retirement/RetirementRecoverSavingPage/interfaces';

import { AnnuityCard } from '../AnnuityCard';
import { DocumentsContainer } from '../DocumentsContainer';
import { RenderDocs } from './components';
import { Props, recognizedType, recognizedCategoryType } from './interfaces';
import { useController } from './useController';
import { useStyles } from './useStyles';

export const RetirementRecoverSavingPlanCard: React.FC<Props> = ({
  RetirementPlanDocument,
  onSubmit,
  isLoading,
  isLoadingBulletin,
  shouldDisplayAnnuityCard,
  setIsEsPacteFile,
  setIsLinkPressed
}) => {
  const styles = useStyles();
  const { formatCurrencyNumber, formatMessage } = useTranslation();
  const {
    sortOrderBy,
    title,
    subTitle,
    onRemoveDuplicateDocumentsByCategory,
    filterDocumentTypesByFormat,
  } = useController(RetirementPlanDocument);

  const renderCategoryItem = useCallback((label: string, amount: string) => {
    return (
      <View
        style={styles.formItemContainer}
      >
        <Text
          style={styles.formItemTxt}
          variant='t4'
        >
          {label}
        </Text>

        <Text
          style={styles.formItemTxt}
          variant='t4'
        >
          {amount}
        </Text>
      </View>
    )
  }, [styles]);

  const renderCategory = useCallback((recognizedCategory, index, plan) => {
    return (
      <View
        key={index}
      >
        {recognizedCategory.categories.map((q: recognizedCategoryType) => {
          const category: categoryType = plan.categories.find((category: categoryType) => category.code === q.categoryId);
          const label = formatMessage({ id: category.labelCode });
          const amount = formatCurrencyNumber({ value: category.amount })
          return renderCategoryItem(label, amount);
        })}

        <RenderDocs
          documents={JSON.parse(recognizedCategory.documents)}
          isLoading={isLoading}
          onSubmit={onSubmit}
          retirementPlanDocument={RetirementPlanDocument}
          setIsEsPacteFile={setIsEsPacteFile}
          setIsLinkPressed={setIsLinkPressed}
        />
      </View>
    )
  }, [isLoading, onSubmit, RetirementPlanDocument, setIsEsPacteFile, formatMessage, formatCurrencyNumber, renderCategoryItem]);

  const renderAnnuityCard = useCallback(() => {
    if (!(RetirementPlanDocument.sendType === TypeOfSendEnum.SentByPost
      && shouldDisplayAnnuityCard)) return;
    return (
      <AnnuityCard
        isLoading={isLoadingBulletin}
        onSubmit={onSubmit}
        setIsEsPacteFile={setIsEsPacteFile}
        setIsLinkPressed={setIsLinkPressed}
      />
    )
  }, [
    RetirementPlanDocument,
    onSubmit,
    isLoading,
    shouldDisplayAnnuityCard,
    setIsEsPacteFile
  ]);

  const renderDocuments = useCallback((documentsTypesList: documentType[]) => {
    if (documentsTypesList.length <= 0) return;
    return (
      <DocumentsContainer
        documentsTypesList={documentsTypesList}
        handleDocumentPress={onSubmit}
        setIsLinkPressed={setIsLinkPressed}
      />
    )
  }, [onSubmit]);

  const renderContent = useCallback(() => {
    const orderedRetirementPlanDocuments: planType[] = sortOrderBy(RetirementPlanDocument.documentTypesPlans);
    return (
      <>
        <View
          style={styles.contentContainer}
        >
          {
            orderedRetirementPlanDocuments.map((plan, index) => {
              const orderedCategories: categoryType[] = sortOrderBy(plan.categories);
              const recognizedCategories = onRemoveDuplicateDocumentsByCategory(orderedCategories)
              const documentsTypesList = filterDocumentTypesByFormat(plan.categories);
              return (
                <View
                  key={index}
                  style={styles.formContainer}
                >
                  <Text
                    style={styles.formTitle}
                    variant='t3'
                    weight='bold'
                  >
                    {plan.shortName}
                  </Text>

                  {
                    recognizedCategories.map((recognizedCatergory: recognizedType, index: number) => (
                      renderCategory(recognizedCatergory, index, plan)
                    ))
                  }

                  {renderDocuments(documentsTypesList)}
                </View>
              )
            })
          }
        </View>
      </>
    )
  }, [
    RetirementPlanDocument,
    filterDocumentTypesByFormat,
    onRemoveDuplicateDocumentsByCategory,
    renderCategory,
    renderDocuments,
    sortOrderBy,
    styles,
  ]);

  return (
    <View style={styles.container}>
      <Text
        style={styles.title}
        variant='t1'
        weight='bold'
      >
        {title}
      </Text>
      <Text
        variant='t3'
        weight='regular'
      >
        {subTitle}
      </Text>
      {renderContent()}

      {renderAnnuityCard()}
    </View>
  )
};