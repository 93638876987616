import { useTranslation } from "@ere-uilib/translations";
import { AccountState, AllocationState } from "@modules/dashboard/types";



export const getPlansNameByCompany = (company: AccountState | undefined) => {
  const { formatMessage } = useTranslation();

  const shortNameArray: string[] = [];
  company?.plans.map((plan: AllocationState) => {
    shortNameArray.push(` ${plan.shortName}`);
  });

  return `${formatMessage({ id: 'multientreprise_choice_list_label' })}${shortNameArray.toString()}`;
};