import React from "react";
import { WebView, WebViewSourceTypeEnum } from "@ere-uilib/atoms";
import { MaintenancePageProps } from "./interface";
import { StyleSheet } from "react-native";

export const MaintenancePageComponent: React.FC<MaintenancePageProps> = ({ htmlContent }) => {

	if (!htmlContent) return null;

	return (<WebView
		sourceHtml={{
			html: htmlContent
		}}
		sourceType={WebViewSourceTypeEnum.HTML}
		style={styles.htmlContainer}
	/>
	)
}

const styles = StyleSheet.create({
	htmlContainer: {
		width: '100%',
		height: '100%',
	}
})