import React from 'react'
import { View } from 'react-native'

import { RadioButton } from '@ere-uilib/molecules'
import { createUseStyles, useTheme } from '@ere-uilib/styles'

import { Props } from './interfaces'
import { getStyles } from './styles'

export const RadiosButtonList: React.FC<Props> = ({
  activeRadio,
  onChange,
  containerStyle,
  flexDirection = 'column',
  options,
  labelStyle
}: Props) => {
  const theme = useTheme()
  const styles = useStyles(
    {
      theme,
      flexDirection
    },
    {
      containerStyle
    }
  )
  if (!options) {
    return null
  }
  return (
    <View style={styles.containerStyle}>
      {options.map((option, index) => {
        return (
          <RadioButton
            containerStyle={[styles.radioButton, index !== 0 && styles.radioButtonMarginIfRow]}
            disabled={option.disabled}
            key={index}
            label={option.label}
            onPress={() => {
              !!onChange && onChange(option.value)
            }}
            value={option.value === activeRadio}
            testId={`option_${index}`}
            labelStyle={labelStyle}
          />
        )
      })}
    </View>
  )
}

const useStyles = createUseStyles(getStyles)
