
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native'

import { useTranslation, Text, useTheme } from '@components/index';
import { ProfitSharingIncentiveTypeEnum, OperationHistoryTypesEnum, OperationDetailsTypeEnum, ThresholdTriggerTypesEnum, OperationFamilyEnum } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { OperationDetails } from '@modules/savings/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';

interface CancelOperationModalInfosType {
  cancelModalTitle: string,
  renderCancelModalDescription?: () => React.ReactNode,
  cancelModalSuccessTitle: string,
  renderCancelModalSuccessDescription?: () => React.ReactNode,
  modalRestartOperationLabel: string | undefined,
  onModalRestartOperation: (() => void) | undefined,
}

export const useCancelOperationModalInfos = ({
  operationDetails,
}: {
  operationDetails: OperationDetails | undefined;
}) => {
  const { formatMessage } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>()
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    modalDescriptionPargraphStyle: {
      marginBottom: theme.metrics.spacing.l,
    },
  }), [theme])

  const operationFamily = operationDetails?.family;
  const isArbitration = operationFamily === OperationFamilyEnum.ARBITRATION;
  const isRefund = operationFamily === OperationFamilyEnum.REFUND;
  const thresholdType = operationDetails?.details?.conditionalOrder?.thresholdType

  const cancelModalSuccessTitleVCP = (isArbitration || isRefund) && thresholdType === ThresholdTriggerTypesEnum.VCP ?
    formatMessage({ id: 'DetailOperationSeuilAnnulationConfirmation' }) :
    ''

  const cancelOperationModalInfos = useMemo(() => {
    let output: CancelOperationModalInfosType = {
      cancelModalTitle: formatMessage({ id: 'PIAnnulationTitre' }),
      renderCancelModalDescription: undefined,
      cancelModalSuccessTitle: cancelModalSuccessTitleVCP || formatMessage({ id: 'PIAnnulationComfirmationTitre' }),
      renderCancelModalSuccessDescription: undefined,
      modalRestartOperationLabel: undefined,
      onModalRestartOperation: undefined
    }
    const bulletinOptionType = operationDetails?.source?.bulletinOption?.type
    if (!bulletinOptionType) return output
    switch (bulletinOptionType) {
      case OperationDetailsTypeEnum.PROFITSHARING:
      case OperationDetailsTypeEnum.INCENTIVE:
        const isProfitSharing = bulletinOptionType === OperationDetailsTypeEnum.PROFITSHARING
        output = {
          ...output,
          renderCancelModalDescription: () => (
            <>
              <Text
                style={styles.modalDescriptionPargraphStyle}
                variant="t3"
                weight="light"
              >
                {formatMessage({ id: 'PIAnnulationConfirmationNouvelleSaisieDescription' })}
              </Text>
              <Text
                style={styles.modalDescriptionPargraphStyle}
                variant="t3"
                weight="light"
              >
                {formatMessage({ id: 'PIAnnulationConfirmationAffectationDefaultDescription' })}
              </Text>
            </>
          ),
          cancelModalSuccessTitle: cancelModalSuccessTitleVCP || formatMessage({ id: 'PIAnnulationConfirmationAnnulationTitre' }),
          renderCancelModalSuccessDescription: () => (
            <>
              <Text
                style={styles.modalDescriptionPargraphStyle}
                variant="t3"
                weight="light"
              >
                {formatMessage({ id: 'PIAnnulationConfirmationAnnulationDescription' })}
              </Text>
            </>
          ),
          modalRestartOperationLabel: isProfitSharing ?
            formatMessage({ id: 'PIAnnulationAffecteParticipationBouton' })
            : formatMessage({ id: 'PIAnnulationAffecterInteressementBouton' }),
          onModalRestartOperation: () => {
            navigation.replace(RouteNames.ProfitSharingIncentive, {
              screen: RouteNames.ProfitSharingIncentiveDescription,
              params: {
                type: isProfitSharing ?
                  ProfitSharingIncentiveTypeEnum.profitsharing
                  : ProfitSharingIncentiveTypeEnum.incentive
              }
            })
          },
        }
        break;
      default:
        break;
    }
    return output
  }, [
    operationDetails?.type,
    navigation,
    styles,
    formatMessage,
  ])

  return cancelOperationModalInfos
}