import { connect } from 'react-redux';

import {
  AppState,
} from '@modules/index';
import { otpFlowAskCodeAgainRequest, otpFlowSendCodeRequest } from '@modules/profile/actions/profileActions';
import { ProfileActionsType} from '@modules/profile/actions/profileActionsTypes';
import { getIsAskAgainSuccess, getOtpError, getOtpParameters } from '@modules/profile/selectors';

import { OtpValidationFormPage as OtpValidationFormPageComponent } from './OtpValidationFormPage.component';

const mapStateToProps = (state: AppState) => ({
  isAskAgainSuccess: getIsAskAgainSuccess(state),
  otpParameters: getOtpParameters(state),
  errorMessage: getOtpError(state).message,
  isAskCodeAgainCodeLoading: state.isLoading[ProfileActionsType.OTP_FLOW_ASK_CODE_AGAIN_REQUEST],
  isSendCodeLoading: state.isLoading[ProfileActionsType.OTP_FLOW_SEND_CODE_REQUEST]
});

const mapDispatchToProps = {
  resendCode: otpFlowAskCodeAgainRequest,
  onSubmit: otpFlowSendCodeRequest
};

export const OtpValidationFormPage = connect(mapStateToProps, mapDispatchToProps)(OtpValidationFormPageComponent);
