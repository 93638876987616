import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { FundEligibility, RiskLevel, Text } from '@components/index';
import { ReadMoreText } from '@ere-uilib/atoms';
import { ExpendableLine } from '@ere-uilib/molecules/cards/ExpandableLineSet/ExpandableLine/ExpandableLine';
import {
  useTheme,
  ThemeType,
  createUseStyles,
  useScreenSizes,
} from '@ere-uilib/styles';
import {
  FundRepartitionSupportsState,
  FundSourceEnum,
  OverviewState,
} from '@modules/funds/types';
import { useTranslation } from '@translations/index';

import PlaceholderLoader from '../components/PlaceholderLoader';
import { FormatDateOptions } from 'react-intl';

interface DataProps {
  fundsRepartitionSupport?: FundRepartitionSupportsState;
  fundOverview?: OverviewState;
  isApiLoadingOverview: boolean;
}

interface OverviewData {
  cardTitle?: string;
  content?: string | ReactElement;
  render?: (index: number) => JSX.Element | null;
}

type FundOverviewTabStyles = {
  globalContainer?: StyleProp<ViewStyle>;
  cardContainer?: StyleProp<ViewStyle>;
  content?: StyleProp<ViewStyle>;
  title?: StyleProp<TextStyle>;
  riskContainer?: StyleProp<TextStyle>;
  riskDetails?: StyleProp<TextStyle>;
  riskContent?: StyleProp<TextStyle>;
  overviewLeft?: StyleProp<TextStyle>;
  overviewRight?: StyleProp<TextStyle>;
  expandableHeaderStyle?: StyleProp<TextStyle>;
  expandableContentStyle?: StyleProp<TextStyle>;
  expandableTitleStyle?: StyleProp<TextStyle>;
  iconColor?: StyleProp<TextStyle>;
  titleStyle?: StyleProp<TextStyle>;
  subTitleStyle?: StyleProp<TextStyle>;
  riskLegendContainer?: StyleProp<TextStyle>;
  riskLegendTitle?: StyleProp<TextStyle>;
  riskLegendDynamicStyle?: StyleProp<TextStyle>;
};

type FundOverviewTabStylesContext = {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
};

type Props = DataProps & FundOverviewTabStyles;

export const FundOverviewTab: React.FC<Props> = ({
  fundsRepartitionSupport,
  fundOverview,
  isApiLoadingOverview,
  globalContainer,
  cardContainer,
  content,
  title,
}: Props) => {
  const riskLevel = parseInt(fundOverview?.risqueSRI || fundOverview?.risqueSRRI || '');
  const riskLevelLabel = fundOverview?.risqueSRI ?
    'Fundsheet_Tab1_risk-level_title' :
    'Fundsheet_Tab1_risk-level_SRRI_Old_title';
  const amfCategoryLabel = fundOverview?.amfCategory ?
    `FundsheetTab1NarrativeSFDR${fundOverview?.amfCategory}` :
    'FundsheetSFDRDonneeAbsenteContent';
  const sfrArticleLabel = fundOverview?.sfdrArticle ?
    `FundsheetTab1NarrativeSFDRArt${fundOverview?.sfdrArticle}` :
    'FundsheetSFDRDonneeAbsenteContent';
  const placementDuration = fundOverview?.dureePlacement || '';
  const isNumberPlacementDuration = !isNaN(+placementDuration);
  const riskLevelLegend1Number =
    +placementDuration > 1
      ? 'FundsheetTab1RiskLevelLegend1DynamicPlurial'
      : 'FundsheetTab1RiskLevelLegend1DynamicSingular';
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const {
    formatMessage,
    formatCurrencyNumber,
    formatDate,
    formatShareCountNumber,
    getLocal
  } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const styles = useStyles(
    { theme, isMobile, isTablet },
    {
      globalContainer,
      cardContainer,
      content,
      title,
    }
  );

  const renderFundEligibility = useCallback(() => {
    if (
      !fundsRepartitionSupport ||
      fundsRepartitionSupport.plans.length === 0
    ) {
      return;
    }

    return <FundEligibility plans={fundsRepartitionSupport.plans} />;
  }, [fundsRepartitionSupport]);

  const displayFundData = useCallback(
    (data?: string | null) => {
      if (
        data === null ||
        data === '' ||
        data === undefined ||
        data?.length === 0
      ) {
        return (
          <Text
            variant="t3"
            weight="light">
            {formatMessage({ id: 'Funsheet_DataUnavailable' })}
          </Text>
        );
      }
      return (
        <ReadMoreText
          numberOfLines={5}
          weight="light">
          {data}
        </ReadMoreText>
      );
    },
    [formatMessage]
  );

  const renderFundTypeSection = useCallback(() => {
    if (fundOverview?.isSolidaire && fundOverview?.isISR) {
      return (
        <>
          <View>
            {displayFundData(
              formatMessage({ id: 'MyFunds_Screen1_Solidaire_label' })
            )}
          </View>
          <View>
            {displayFundData(
              formatMessage({ id: 'MyFunds_Screen1_ISR_label' })
            )}
          </View>
        </>
      );
    }

    if (fundOverview?.isSolidaire) {
      return displayFundData(
        formatMessage({ id: 'MyFunds_Screen1_Solidaire_label' })
      );
    }

    if (fundOverview?.isISR) {
      return displayFundData(
        formatMessage({ id: 'MyFunds_Screen1_ISR_label' })
      );
    }

    return '';
  }, [formatMessage, displayFundData, fundOverview]);

  const hasFundType = fundOverview?.isISR || fundOverview?.isSolidaire;
  const repartitionSupportDate = useMemo(() => {
    if (!fundsRepartitionSupport?.netAssetValue) {
      return;
    }

    const formattedDate = formatDate({
      value: fundsRepartitionSupport.netAssetValue.dateValue,
      options: {
        dateStyle: 'short',
      },
    });

    return formattedDate;
  }, [formatDate, fundsRepartitionSupport]);

  const isFundsRepartitionSupportNotAvailable =
    !fundsRepartitionSupport ||
    !fundsRepartitionSupport.amount ||
    fundsRepartitionSupport.amount.amount === 0;
  const renderAmountOfFund = useCallback(() => {
    if (isFundsRepartitionSupportNotAvailable) {
      return null;
    }
    return (
      <>
        <View style={styles.title}>
          <Text
            variant="t3"
            weight="bold">
            {`${formatMessage({
              id: 'Fundsheet_Tab1_Fund_Position_at_date_title'
            })} ${repartitionSupportDate}`}
          </Text>
          <Text
            variant="t3"
            weight="bold">
            {formatCurrencyNumber({
              value: fundsRepartitionSupport.amount.amount
            })}
            {` ${formatMessage({
              id: 'Fundsheet_Tab1_Fund_Position_row_legend'
            })}`}
            {` ${formatMessage({
              id: 'Fundsheet_Tab1_Fund_Position_nbre_of_shares_legend',
              values: {
                variable: formatShareCountNumber({
                  value: fundsRepartitionSupport.amount.numberOfUnits
                })
              }
            })}`}
          </Text>
        </View>
        <View style={styles.content}>
          {fundsRepartitionSupport.plans.map((plan, index) => {
            if (plan.totalInvestedAmount === 0) {
              return null;
            }
            return (
              <Text
                key={index + '_' + plan.planName}
                weight="light">
                {plan.planName}: {formatCurrencyNumber({ value: plan.totalInvestedAmount })}
                {` ${formatMessage({
                  id: 'Fundsheet_Tab1_Fund_Position_nbre_of_shares_legend',
                  values: {
                    variable: formatShareCountNumber({
                      value: plan.totalNumberOfUnits
                    })
                  }
                })}`}
              </Text>
            );
          })}
        </View>
      </>
    );
  }, [
    fundsRepartitionSupport,
    styles,
    formatMessage,
    formatCurrencyNumber,
    repartitionSupportDate,
  ]);
  const renderLastVL = useCallback(() => {
    if (!fundOverview?.lastVL) {
      return null;
    }
    const lastVL = fundOverview?.lastVL
    const dateOptions: FormatDateOptions = getLocal() !== 'ar' ? { dateStyle: 'short' }
      : {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        second: '2-digit',
        minute: '2-digit',
        hour: '2-digit'
      }
    const formattedSharePriceHistoDate = formatDate({
      value: fundOverview?.dateLastVL,
      options: dateOptions,
    })
    const title = formatMessage({
      id: 'FundsheetTab1LastVLTitle',
      values: { variable: repartitionSupportDate || '' },
    })

    return (
      <>
        <View style={styles.title}>
          <Text
            variant="t3"
            weight="bold">
            {title}
          </Text>
        </View>
        <View style={styles.content}>
          <Text
            weight="light">
            {formatCurrencyNumber({ value: lastVL })}
            {' '}({formattedSharePriceHistoDate})
          </Text>
        </View>
      </>
    );
  }, [formatDate, formatMessage, repartitionSupportDate, styles.title, styles.content, formatCurrencyNumber]);

  const overviewDataLeft: OverviewData[] = [
    {
      cardTitle: 'Fundsheet_Tab1_ISIN_Code_title',
      content: displayFundData(fundOverview?.isin)
    },
    {
      render: renderLastVL
    },
    {
      render: renderAmountOfFund
    },
    {
      cardTitle: riskLevelLabel,
      content: <RiskLevel value={riskLevel} />
    },

    {
      content: (
        <>
          {!!placementDuration && fundOverview?.source === FundSourceEnum.BNPP && (
            <Text
              style={styles.titleStyle}
              variant="t3">
              {formatMessage({
                id: isNumberPlacementDuration
                  ? riskLevelLegend1Number
                  : 'FundsheetTab1RiskLevelLegend1Dynamic',
                values: { [isNumberPlacementDuration ? 'number' : 'text']: placementDuration }
              })}
            </Text>
          )}
          <Text
            style={styles.subTitleStyle}
            variant="t3"
            weight="light">
            {formatMessage({ id: 'FundsheetTab1RiskLevelLegend3Fixed' })}{' '}
            {formatMessage({
              id: 'FundsheetTab1RiskLevelLegend4DynamicRiskLevelRelated' + riskLevel
            })}
          </Text>
          <Text variant="t3">
            {formatMessage({
              id: 'FundsheetTab1RiskLevelLegend5FixedCase1'
            })}
          </Text>
        </>
      )
    },
    (fundOverview?.narrativeRisks && fundOverview?.narrativeRisks?.length > 0) ?
      {
        content: <ExpendableLine
          arrowPosition={'right'}
          arrowStyle={styles.iconColor}
          contentStyle={styles.expandableContentStyle}
          headerStyle={styles.expandableHeaderStyle}
          iconDesign={{ color: 'black', size: 22 }}
          iconStyle={styles.iconColor}
          isOpened={expanded}
          onChange={() => setExpanded(!expanded)}
          title={formatMessage({
            id: 'FundsheetTab1NarrativeRisk_Title'
          })}
          titleStyle={styles.expandableTitleStyle}>
          <>
            {fundOverview?.narrativeRisks?.map(risk => (
              <View style={styles.riskContainer}>
                <Text
                  variant="t3"
                  weight="light">
                  <Text variant="t3">
                    {formatMessage({ id: `FundsheetTab1Narrative${risk}` })}
                    {' : '}
                  </Text>
                  {formatMessage({ id: `FundsheetTab1Narrative${risk}_DETAILS` })}
                </Text>
              </View>
            ))}
          </>
        </ExpendableLine>
      }
      :
      {},
    {
      cardTitle: 'Fundsheet_Tab1_advised_placement_period_title',
      content: displayFundData(placementDuration)
    },
    {
      cardTitle: 'Fundsheet_Tab1_volatility_title',
      content: (
        <Text
          variant={'t3'}
          weight="light">
          {`${Number(fundOverview?.volatility).toFixed(2)} %`}
        </Text>
      )
    },
    {
      cardTitle: 'fund-card_classification',
      content: displayFundData(fundOverview?.classification)
    },
    {
      cardTitle: 'Fundsheet_Tab1_geographical_area_title',
      content: displayFundData(fundOverview?.zoneGeograph)
    }
  ];

  if (hasFundType) {
    overviewDataLeft.push({
      cardTitle: 'Fundsheet_Tab1_Fund_Type_title',
      content: renderFundTypeSection(),
    });
  }

  const overviewDataRight: OverviewData[] = [
    {
      cardTitle: 'Fundsheet_Tab1_asset_management_target_title',
      content: displayFundData(fundOverview?.objectifGestion),
    },
    {
      cardTitle: 'Fundsheet_Tab1_Eligibility_title',
      content: renderFundEligibility(),
    },
    {
      cardTitle: 'FundsheetArticleSFDRTitle',
      content: <Text
        variant="t3"
        weight="light">
        {formatMessage({
          id: sfrArticleLabel
        })}
      </Text>,
    },
    {
      cardTitle: 'FundsheetCategorieAMFTitle',
      content: <Text
        variant="t3"
        weight="light">
        {formatMessage({ id: amfCategoryLabel })}
      </Text>,
    },
    {
      cardTitle: 'Fundsheet_Tab1_Valuation_Period_title',
      content: displayFundData(
        fundOverview?.periodiciteValorisation &&
        formatMessage({
          id: `FundSheetPricingFrequency${fundOverview?.periodiciteValorisation}`
        })
      )
    },
    {
      cardTitle: 'Fundsheet_Tab1_teritorial_right_title',
      content: displayFundData(fundOverview?.deDroit),
    },
    {
      cardTitle: 'Fundsheet_Tab1_legal_form_title',
      content: displayFundData(fundOverview?.formeJuridique),
    },
    {
      cardTitle: 'Fundsheet_Tab1_Asset_Management_Company_title',
      content: displayFundData(fundOverview?.managementFirm),
    },
  ];
  const isEven = (index: number) => (index + 1) % 2 === 0;
  return isApiLoadingOverview ? (
    <PlaceholderLoader />
  ) : (
    <View style={styles.globalContainer}>
      <View style={styles.overviewLeft}>
        {overviewDataLeft.map((item, index) => {
          return !!item &&
            <View
              key={item.cardTitle + '_' + index}
              style={[
                styles.cardContainer,
                isEven(index) && { marginRight: 0 },
              ]}
            >
              {item.render ? (
                item.render(index)
              ) : (
                <>
                  {item.cardTitle && <View style={styles.title}>
                    <Text
                      variant="t3"
                      weight="bold">
                      {formatMessage({ id: item.cardTitle })}
                    </Text>
                  </View>}
                  <View style={styles.content}>{item.content}</View>
                </>
              )}
            </View>
        })}
      </View>
      <View style={styles.overviewRight}>
        {overviewDataRight.map((item, index) => {
          if (item.render) {
            return item.render(index);
          }

          return (
            <View
              key={item.cardTitle + '_' + index}
              style={[
                styles.cardContainer,
                isEven(index) && { marginRight: 0 },
              ]}
            >
              <View style={styles.title}>
                <Text
                  variant="t3"
                  weight="bold">
                  {formatMessage({ id: item.cardTitle })}
                </Text>
              </View>
              <View style={styles.content}>{item.content}</View>
            </View>
          );
        })}
        <View
          style={[styles.cardContainer, { marginRight: 0 }]}
        >
          <Text
            variant="t3"
            weight="light"
            testId='Fundsheet_Tab3Perf_Sourcing'>
            {
              fundOverview?.isMorningStarFund ?
                formatMessage({ id: 'Fundsheet_Tab3Perf_Sourcing_Morningstar_legend' })
                :
                formatMessage({ id: 'Fundsheet_Tab3Perf_Sourcing_BNPPariabs_AM_legend' })
            }
          </Text>
        </View>
      </View>
    </View>
  );
};

const getStyles = (
  context?: FundOverviewTabStylesContext,
  style?: FundOverviewTabStyles
): FundOverviewTabStyles => ({
  globalContainer: [
    {
      alignSelf: 'center',
      width: '100%',
      maxWidth: context?.theme.metrics.contentSizes.centeredContentMaxWidth,
      backgroundColor: context?.theme.colors.basics.white,
    },
    style?.globalContainer,
  ],
  cardContainer: [
    {
      borderBottomColor: context?.theme.colors.basics.grey.c100,
      borderBottomWidth: 1,
      paddingVertical: context?.theme.metrics.spacing.xxm,
    },
    (context?.isMobile || context?.isTablet) && {
      paddingHorizontal: context?.theme.metrics.spacing.s,
    },
    !context?.isMobile && {
      width: '100%',
    },
    style?.cardContainer,
  ],
  content: [{}, style?.content],
  title: [{ marginBottom: context?.theme.metrics.spacing.xs }, style?.title],
  riskContainer: [
    {
      marginBottom: context?.theme.metrics.spacing.m,
    },
    style?.riskContainer
  ],
  riskDetails: [{
    fontFamily: context?.theme.fonts.fontFamily.regular,
    marginRight: context?.theme.metrics.spacing.xs,
    fontWeight: '300'
  },
  style?.riskDetails],
  riskContent: [{
    marginLeft: context?.theme.metrics.spacing.xs,
    fontFamily: context?.theme.fonts.fontFamily.light,
    fontWeight: '300'
  }, style?.riskContent],
  overviewRight: {
    flex: context?.isMobile ? undefined : 1,
  },
  expandableHeaderStyle: {
    paddingHorizontal: 0
  },
  expandableContentStyle: {
    borderBottomWidth: 0
  },
  expandableTitleStyle: {
    fontFamily: context?.theme.fonts.fontFamily.regular,
    color: context?.theme.colors.basics.grey.c900
  },
  iconColor: {
    color: context?.theme.colors.basics.grey.c900
  },
  titleStyle: {
    color: context?.theme.colors.basics.black,
    marginBottom: context?.theme.metrics.spacing.s
  },
  subTitleStyle: {
    marginBottom: context?.theme.metrics.spacing.m
  },
  riskLegendDynamicStyle: {
    marginLeft: context?.theme.metrics.spacing.xs
  },
  riskLegendTitle: {
    color: context?.theme.colors.basics.grey.c900
  },
  riskLegendContainer: {
    flexDirection: 'row',
    fontWeight: 'bold',
    fontSize: context?.theme.fonts.fontSize.paragraph.t3,
    color: context?.theme.colors.basics.black
  }
});

const useStyles = createUseStyles(getStyles);
