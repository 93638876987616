import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { RouteNames } from '@constants/navigation';

import { OfflineAnnounceLayout360 } from '@components/index';
import { useTranslation } from '@ere-uilib/translations';
import { useLogoNavigation } from '@utils/index';

type BackOfficeAccessParam = {
  boAccessParams: {
    token: string
    clientId: string
  }
}

interface Props {
  //onSubmit: (accessToken: string) => void
  onSubmit: (token: string, clientId: string) => void
}

export const BackOfficeAccessPage: React.FC<Props> =  ({ onSubmit }: Props) => {
  const navigation = useNavigation()
  const route = useRoute<RouteProp<BackOfficeAccessParam, 'boAccessParams'>>()
  /*useEffect(() => {
    const urlParams =  new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');
    (accessToken && accessToken !== '') && onSubmit(accessToken);
  }, []);*/
  useEffect(() => {
    if(Platform.OS !== 'web'){
       navigation.navigate(RouteNames.BottomTabNavigator,{
        screen: RouteNames.Home
       })
    }else{
      const token = route.params?.token
      const clientId = route.params?.clientId;
      ((token && token !== '') && (clientId && clientId !== '')) && onSubmit(token, clientId);
    }
  }, []);

  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();

  return (
    <OfflineAnnounceLayout360
      description={formatMessage({ id: 'login_transition_label' })}
      onLogoPress={logoNavigateTo}
      sourceName="welcomeGif"
      title={formatMessage({ id: 'login_transition_title' })}
    />
  );};

