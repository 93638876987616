import React, { ReactNode } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { CardWithLeftThread } from '@ere-uilib/atoms';
import { HeaderResponse, SimpleButton } from '@ere-uilib/molecules';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

interface PropsStyles {
  content?: StyleProp<ViewStyle>
  button?: StyleProp<ViewStyle>
  container?: StyleProp<ViewStyle>
  bodyTextContainer?: StyleProp<ViewStyle>

}

interface Props {
  children?: ReactNode;
  amountHidden?: boolean;
  amount?: number;
  borderLeftColor?: string;
  headerBackgroundColor?: string
  title?: string
  containerStyle?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
  bodyTextContainerStyle?: StyleProp<ViewStyle>
  childrenView?: StyleProp<ViewStyle>
  onValidate: () => void
}

interface StylesContext {
  theme:ThemeType
}
export const PlanChoiceCard: React.FC<Props & PropsStyles> = ({
  amountHidden,
  children,
  amount,
  borderLeftColor,
  headerBackgroundColor,
  title,
  containerStyle,
  contentStyle,
  buttonStyle,
  bodyTextContainerStyle,
  onValidate,
}) => {
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    {
      container: containerStyle,
      content: contentStyle,
      button: buttonStyle,
      bodyTextContainer: bodyTextContainerStyle,
    });
  const { formatMessage } = useTranslation();

  return (
    <CardWithLeftThread
      addShadow={true}
      borderLeftColor={borderLeftColor}
      containerStyle={styles.container}
    >
      <HeaderResponse
        amount={ !amountHidden ? amount : undefined}
        customAmountStyle={{ color: theme.colors.basics.black }}
        customContainerStyle={{
          backgroundColor: headerBackgroundColor,
          borderTopLeftRadius: 0,
          marginBottom: 0,
        }}
        customTitleStyle={{ color: theme.colors.basics.black, marginLeft: 0 }}
        title={title}
      />

      <View style={styles.content}>
        {
          children
        }
        <SimpleButton
          containerStyle={styles.button}
          onPress={onValidate}
          title={formatMessage({ id: 'Remboursement_choixFinalDispositif_Valider' })}
        />
      </View>
    </CardWithLeftThread>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: PropsStyles,
): PropsStyles => ({
  container: style?.container,
  content: [
    {
      alignItems: 'flex-start',
      marginHorizontal: context?.theme.metrics.spacing.s,
      marginVertical: context?.theme.metrics.spacing.xm,
    },
    style?.content,
  ],
  button: [
    {
      paddingVertical: context?.theme.metrics.spacing.xs,
      paddingHorizontal: context?.theme.metrics.spacing.m,
      minHeight: 0,
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.button,
  ],
  bodyTextContainer: [
    {
      marginTop: context?.theme.metrics.spacing.s,
    },
    style?.bodyTextContainer,
  ],
});

const useStyles = createUseStyles(getStyles);
