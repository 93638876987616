import React from 'react';
import {
  StyleProp, TextStyle, View, ViewStyle
} from 'react-native';

import { DashedLine, Text } from '@ere-uilib/atoms';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import { DashboardRepartitionPlanType } from '@ere-uilib/types';

interface RepartitionGraphProps extends SharedStyles  {
  allPlans: DashboardRepartitionPlanType[];
}

interface SharedStyles {
  containerStyle?: StyleProp<ViewStyle>;
}
interface Styles extends SharedStyles {
  graphContainer?: StyleProp<ViewStyle>;
  innerContainer?: StyleProp<ViewStyle>;
  repartitionDetailsContainer?: StyleProp<ViewStyle>;
  repartitionDetails?: StyleProp<ViewStyle>;
  title?: StyleProp<TextStyle>;
  dashlineContainer?: StyleProp<ViewStyle>;
}

type StylesContext = {theme: ThemeType};

export const RepartitionGraph = ({
  allPlans,
  containerStyle,
}: RepartitionGraphProps) => {
  let dashlineHeight = {
    height: -4,
  };
  const theme = useTheme();
  const styles = useStyles({ theme }, { containerStyle });
  let totalAmount = 0;
  allPlans.forEach(plan=>{
    totalAmount += plan.value;
  });
  return (
    <View style={styles.containerStyle}>
      <View style={styles.graphContainer}>
        {allPlans?.map((plan, index) => {
          const isFirstPlan = index === 0;
          const isLastPlan = index === allPlans.length - 1;
          return (
            <View
              key={index}
              style={[
                styles.innerContainer,
                {
                  backgroundColor: plan.color,
                  flex: Math.round((plan.value * 100) / totalAmount) / 10,
                  borderTopLeftRadius: isFirstPlan ? 15 : 0,
                  borderBottomLeftRadius: isFirstPlan ? 15 : 0,
                  borderTopRightRadius: isLastPlan ? 15 : 0,
                  borderBottomRightRadius: isLastPlan ? 15 : 0,
                },
              ]}
            />
          );
        })}
      </View>
      <View style={styles.repartitionDetailsContainer}>
        {allPlans?.map((plan, index) => {
          dashlineHeight = {
            ...dashlineHeight,
            height: dashlineHeight.height + 14,
          };
          const percentage = Math.round((plan.value * 100) / totalAmount);
          return (
            <View
              key={index}
              style={[
                styles.repartitionDetails,
                {
                  flex: percentage / 10,
                },
              ]}
            >
              <View style={styles.dashlineContainer}>
                <DashedLine
                  axis="vertical"
                  dashColor={theme.colors.basics.grey.c300}
                  dashGap={2}
                  dashLength={4}
                  dashStyle={{ borderRadius: 5 }}
                  style={{ minHeight: dashlineHeight.height, alignSelf: 'center' }}
                />
                <Text
                  numberOfLines={1}
                  style={styles.title}
                  weight="bold"
                >
                  {`${plan.label}: ${percentage}%`}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  containerStyle: [
    {
      flexDirection: 'column',
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.containerStyle,
  ],
  graphContainer: {
    flexDirection: 'row',
    height: 15,
    borderRadius: 15,
  },
  innerContainer: {
    zIndex: 5,
  },
  repartitionDetailsContainer: {
    flexDirection: 'row',
  },
  repartitionDetails: {
    flexDirection: 'column',
  },
  title: {
    fontSize: context?.theme.fonts.fontSize.paragraph.t4,
    alignSelf: 'flex-end',
    textAlign: 'right',
    backgroundColor: 'transparent',
    marginRight: '50%',
    minWidth: 100,
  },
  dashlineContainer: {
    backgroundColor: 'transparent',
  },
});

const useStyles = createUseStyles(getStyles);
