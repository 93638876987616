import React from 'react'
import { View } from 'react-native'

import { locator } from '@constants/locator'
import { SVGLocalLoader } from '@ere-uilib/atoms'
import { CardImageTitle } from '@ere-uilib/molecules'
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'

import { getStyles } from '../../styles'

type Props = {
  isSelfcareLetterLanding?: boolean
  OptionEERPremiereConnexion?: boolean
  handleActivateAccountPress?: () => void
  handleLoginOpenId?: () => void
}
export const AuthenticationChoice: React.FC<Props> = ({
  isSelfcareLetterLanding = false,
  OptionEERPremiereConnexion,
  handleActivateAccountPress,
  handleLoginOpenId
}) => {
  const { isDesktop, isTablet, isMobile, windowWidth } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile, isTablet, isDesktop, windowWidth }, {});
  const { formatMessage } = useTranslation();

  const ActiverCompteCard = {
    CardTitle: formatMessage({ id: 'EerSelfcareLandingPageActiverCompteTitle' }),
    cardDescription: formatMessage({ id: 'EerSelfcareLandingPageActiverComptelabel' }),
  }
  return (
    <View style={styles.containerCard}>
      {!!OptionEERPremiereConnexion && (
        <View style={isSelfcareLetterLanding ? styles.imageCardSelfcareLetterLanding : styles.imageCard}>
          {isDesktop &&
            <>
              <View
                pointerEvents="none"
                style={styles.leftImageStickerHand}
              >
                <SVGLocalLoader
                  name={'hand'}
                />
              </View>
              <View style={styles.leftImageStickerBody}>
                <SVGLocalLoader
                  name={'man'}
                />
              </View>
            </>
          }
          <CardImageTitle
            description={ActiverCompteCard.cardDescription}
            hasShadow={!isDesktop}
            imageName="chronoSteps"
            isSmall={isMobile}
            isWhite={!isDesktop}
            onPress={handleActivateAccountPress}
            testId={locator._login._personal_space}
            title={ActiverCompteCard.CardTitle}
          />
        </View>
      )}
      {!isSelfcareLetterLanding &&
        <View style={styles.imageCard}>
          <CardImageTitle
            description={formatMessage({ id: 'EerSelfcareLandingPageConnecterLabel' })}
            hasShadow={!isDesktop}
            imageName={isDesktop ? 'loginForm' : 'laptopGirl'}
            isSmall={isMobile}
            isWhite={!isDesktop}
            onPress={handleLoginOpenId}
            testId={locator._login._connect}
            title={formatMessage({ id: 'EerSelfcareLandingPageConnecterTitle' })}
          />
          {isDesktop &&
            <View
              pointerEvents="none"
              style={styles.rightImageSticker}
            >
              <SVGLocalLoader
                name={'woman'}
              />
            </View>
          }
        </View>
      }
    </View>
  )
}

const useStyles = createUseStyles(getStyles);
