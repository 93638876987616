import React, { useCallback, useMemo, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { useTranslation } from '@ere-uilib/index';
import { DateField, DateFieldDictionaryType } from '@ere-uilib/molecules/inputs/DateField';

import { AnnuityBottomActionsWrapper } from '../AnnuityBottomActionsWrapper';
import { useStyles } from './useStyles';

const MAX_DATE = new Date();

interface Props {
  value: Date | undefined;
  onSubmit?: (birthDate?: Date | undefined) => void;
  onChange?: (birthDate?: Date | undefined) => void;
  validator?: (birthDate: Date | undefined) => boolean;
}

interface Style {
  contentStyle?: StyleProp<ViewStyle>;
}

export const AnnuityBirthDate: React.FC<Props & Style> = ({
  value,
  onSubmit,
  onChange,
  validator,
  contentStyle
}) => {
  const styles = useStyles();
  const [birthDate, setBirthDate] = useState(value ? new Date(value) : undefined);
  const { formatMessage } = useTranslation();

  const isValidBirthDate = !!validator?.(birthDate);

  const dictionary: DateFieldDictionaryType = useMemo(
    () => ({
      placeholderDay: formatMessage({
        id: 'EerSelfcareStep2PlaceholderJourLabel'
      }),
      placeholderMonth: formatMessage({
        id: 'EerSelfcareStep2PlaceholderMoisLabel'
      }),
      placeholderYear: formatMessage({
        id: 'EerSelfcareStep2PlaceholderAnneeLabel'
      }),
      dateErrorMessage: formatMessage({
        id: 'EerSaisieDateNaissanceInvalide'
      })
    }),
    [formatMessage]
  );

  const handleChange = useCallback(
    (birthDate: Date | undefined) => {
      setBirthDate(birthDate);
      onChange?.(birthDate);
    },
    [onChange]
  );

  const handleSubmit = useCallback(
    (birthDate: Date | undefined) => onSubmit?.(birthDate),
    [onSubmit]
  );

  const renderContent = useCallback(
    (birthDate: Date | undefined, isValid?: boolean) => (
      <View style={[styles.dateFieldContainer, contentStyle]}>
        <DateField
          dictionary={dictionary}
          hasError={!!birthDate && !isValid}
          maxDate={MAX_DATE}
          onChange={handleChange}
          value={birthDate}
        />
      </View>
    ),
    [contentStyle, dictionary, handleChange, styles]
  );

  if (!onSubmit) return renderContent(birthDate, isValidBirthDate);

  return (
    <AnnuityBottomActionsWrapper
      confirmButtonDisabled={!isValidBirthDate}
      confirmButtonLabel={formatMessage({
        id: 'PensionSimulatorStartSimulationContinueButton'
      })}
      onValidate={() => handleSubmit(birthDate)}>
      {renderContent(birthDate, isValidBirthDate)}
    </AnnuityBottomActionsWrapper>
  );
};
