import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { FontIcon, Modal, Text, Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import {
  WorkInProgressContent,
  LogoBnp,
  LogoColorModeEnum,
  LogoOrientationEnum,
} from '@ere-uilib/molecules';
import { ModalFrame } from '@ere-uilib/molecules';
import { DesktopMenuIconTabs } from '@ere-uilib/organisms';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import { BottomTabNavigatorRouteNames, ProfileStackNavigatorRouteNames, SavingsNavigatorRouteNames } from '@navigation/Interfaces';
import { useTranslation } from '@translations/index';

import { Props } from './interfaces';
import { useStyles } from './styles';
import { DialOncePressable } from '@components/templates';
import { useSelector } from 'react-redux';
import { getApplicationSettings } from '@modules/settings/selectors';

export const DesktopMenu: React.FC<Props> = ({
  DesktopMenuUserInformationsConnected,
  route,
  onLogoPress,
  isWishMenuModalVisible,
  setIsWishMenuModalVisible,
}) => {
  //TODO ASK IF WE USE ROUTENAMES AS TABNAME OR TRANSLATIONS
  const [selectedTab, setSelectedTab] = useState(
    BottomTabNavigatorRouteNames.Home
  );
  const [visible, setVisible] = useState(false);
  const { formatMessage } = useTranslation();
  const applicationSettings = useSelector(getApplicationSettings);

  const theme = useTheme();
  const navigation = useNavigation();
  const styles = useStyles();

  useEffect(() => {
    let currentTab = route.name;
    const savingsRoutesNames = Object.values(SavingsNavigatorRouteNames);
    const profilesRoutesNames = Object.values(ProfileStackNavigatorRouteNames);

    if (savingsRoutesNames.includes(route.name)) {
      currentTab = RouteNames.Savings;
    }

    if (profilesRoutesNames.includes(route.name)) {
      currentTab = RouteNames.Profile;
    }
    setSelectedTab(currentTab);
  }, [route.name]);

  //TODO ASK IF WE USE ROUTENAMES AS TABNAME OR TRANSLATIONS
  const tabs = [
    {
      tabName: RouteNames.Home,
      tabTitle: formatMessage({ id: 'MainMenu_Home_label' }),
      testId: locator._tab_bar._home,
      tabIcon: IconEnum.HOME,
      onPress: () =>
        navigation.navigate(RouteNames.BottomTabNavigator, {
          screen: RouteNames.Home,
        }),
    },
    {
      tabName: RouteNames.Savings,
      tabTitle: formatMessage({ id: 'MainMenu_Savings_label' }),
      testId: locator._tab_bar._savings,
      tabIcon: IconEnum.SAVING,
      onPress: () =>
        navigation.navigate(RouteNames.BottomTabNavigator, {
          screen: RouteNames.SavingsStack,
          params: {
            screen: RouteNames.Savings,
          },
        }),
    },
    {
      tabName: RouteNames.History,
      tabTitle: formatMessage({ id: 'MainMenu_History_label' }),
      testId: locator._tab_bar._my_activity_life,
      tabIcon: IconEnum.HISTORIC,
      onPress: () =>
        navigation.navigate(RouteNames.BottomTabNavigator, {
          screen: RouteNames.HistoryStack,
          params: {
            screen: RouteNames.History,
          },
        }),
    },
    {
      tabName: RouteNames.Profile,
      tabTitle: formatMessage({ id: 'MainMenu_Profile_label' }),
      testId: locator._tab_bar._profile,
      tabIcon: IconEnum.PROFILE,
      onPress: () =>
        navigation.navigate(RouteNames.BottomTabNavigator, {
          screen: RouteNames.ProfileStack,
          params: {
            screen: RouteNames.Profile,
          },
        }),
    },
  ];

  return (
    <>
      <View style={styles.sidebarContainer}>
        <LogoBnp
          colorMode={LogoColorModeEnum.dark}
          containerStyle={styles.logoBnpStyle}
          onLogoPress={onLogoPress}
          orientation={LogoOrientationEnum.row}
        />

        {DesktopMenuUserInformationsConnected}

        <DesktopMenuIconTabs
          containerStyle={styles.desktopMenuIconTabsContainerStyle}
          selectedTab={selectedTab}
          setIsWishMenuModalVisible={setIsWishMenuModalVisible}
          tabs={tabs}
        />

        <TouchableOpacity
          onPress={() => setIsWishMenuModalVisible(!isWishMenuModalVisible)}
          style={styles.wishMenuButton}
        >
            <FontIcon
              color={theme.colors.basics.white}
              name={"monnaie"}
              size={theme.metrics.iconSizes.mplus}
              style={styles.iconStyle}
            />

          <Title
            style={styles.wishMenuTitle}
            testId='wish_menu_button'
            variant={'t6'}>
            {formatMessage({ id: 'DashboardAgirSurMonEpargneBouton' })}
          </Title>
        </TouchableOpacity>

        <DialOncePressable
          integrationKey={applicationSettings?.HelpDialOnceIntegrationKey || ''}
          targetedFlowId={applicationSettings?.HelpActionMenuDialOnceId || ''}>
          <Text
            style={styles.helpActionMenuButton}
            variant={"t2"}
          >
            {formatMessage({ id: 'AbritrageOADResultatQRBesoinAideDescription' })}
          </Text>

        </DialOncePressable>

        <Modal visible={visible}>
          <ModalFrame
            desktopPosition="center"
            mobilePosition="full"
            onClose={() => setVisible(false)}
          >
            <WorkInProgressContent />
          </ModalFrame>
        </Modal>
      </View>
    </>
  );
};