import React from 'react';
import { TouchableOpacity, Linking, StyleProp, ViewStyle } from 'react-native';

import { SVGLocalLoader } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

interface Props {
  style?: StyleProp<ViewStyle>;
}

type StylesContext = {theme: ThemeType};

export const GreenNumber: React.FC<Props> = ({
  style
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, style);
  // TODO : get a svg without number abd get the number from settings
  const callNumber = ()=>{
    const url = 'tel:+33969320346';
    Linking.canOpenURL(url).then(() => {
      return Linking.openURL(url);
    });
  };
  return (
    <TouchableOpacity
      onPress={callNumber}
      style={styles}
    >
      <SVGLocalLoader name="support" />
    </TouchableOpacity>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: StyleProp<ViewStyle>,
): StyleProp<ViewStyle> => [
  {
    height: 40,
    width: 300,
    alignItems: 'flex-start',
    backgroundColor: 'transparent'
  },
  style
];

const useStyles = createUseStyles(getStyles);
