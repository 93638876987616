import {RefundPageContext, RefundPageStyle} from './interfaces';

export const getStyles = (
  context?: RefundPageContext,
  style?: RefundPageStyle
): RefundPageStyle => ({
  containerStyle: [{}, style?.containerStyle],
  bodyContainer: [
    !context?.isMobile && {
      paddingLeft: context?.theme?.metrics.spacing.xl,
    },
    style?.bodyContainer,
  ],
  title: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.title,
  ],
  dialOnceHelpButton: [
    {
      marginTop: context?.theme?.metrics.spacing.s,
      width: context?.theme?.metrics.contentSizes.dialOnceButton,
    },
    context?.isMobile && {
      alignSelf: 'center',
    },
    style?.dialOnceHelpButton,
  ],
  stepperContainer: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.stepperContainer,
  ],
  blockedPageContainer: [
    {
      width: '100%',
      maxWidth: context?.theme?.metrics.contentSizes.centeredContentMaxWidth,
      alignSelf: 'center',
    },
    style?.blockedPageContainer,
  ],
  buttonContainer: [
    context?.isMobile
      ? {
        paddingHorizontal: context?.theme?.metrics.spacing.s,
      }
      : {
        flexDirection: 'row',
      },
    {
      marginTop: context?.theme?.metrics.spacing.xxl,
    },

    style?.buttonContainer,
  ],
  loader: [
    { flex: 1, marginLeft: context?.theme?.metrics.spacing.xm },
    style?.loader,
  ],
  loaderContainer: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: context?.theme?.metrics.spacing.xs,
    },
    style?.loaderContainer,
  ],
  dashboardWrapper: [
    { backgroundColor: context?.theme?.colors.basics.white },
    style?.dashboardWrapper,
  ],
  notification: [
    {
      alignSelf: 'flex-start',
    },
    context?.isMobile && {
      flex: 1,
    },
    style?.notification,
  ],
  notificationLabel: [
    {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    style?.notificationLabel,
  ],

  dialonceStyle: [
    {
      alignSelf: 'flex-start',
      maxWidth: 335,
      width: '100%',
      marginTop: context?.theme?.metrics.spacing.l,
    },
    style?.dialonceStyle,
  ],
  titleStyle: [
    {
      marginBottom: context?.theme?.metrics.spacing.xs,
    },
    style?.titleStyle,
  ],
  descriptionStyle: [
    {
      marginBottom: context?.theme?.metrics.spacing.xxl,
    },
    style?.descriptionStyle,
  ],
});
