import React, { useCallback, useEffect, useMemo } from 'react'
import { View } from 'react-native'

import {
  DashboardContentWrapperCardPlaceHolder,
  RetryActionPlaceHolder,
  HtmlStyledRenderConnectedContainer,
  useTheme,
  useTranslation
} from '@components/index'
import { ContributionFamilyEnum } from '@constants/index'
import { Paragraph, Text, Title } from '@ere-uilib/atoms'
import {
  AccountsErrorState,
  CibledContributionContentState,
  ContributionByCompanyType,
  ContributionByOperationType
} from '@modules/dashboard/types'
import { useFormatHrefHtml } from '@utils/index'

import { ContributionOperationCard, ContributionTable } from '../components'
import { useStyles } from './useStyles'

const OPERATIONS = [
  ContributionFamilyEnum.PAYMENT,
  ContributionFamilyEnum.INCENTIVE,
  ContributionFamilyEnum.PROFITSHARING
]

interface ContributionDetailsTabProps {
  contributionByCompany: ContributionByCompanyType | null | undefined
  contributionByOperations: ContributionByOperationType | null | undefined
  isContributionByCompanyLoading?: boolean
  isContributionByOperationsLoading?: boolean
  contributionByCompanyError: AccountsErrorState
  onGetContributionByCompany: () => void
  onGetContributionByOperation: () => void
  onGetCibledContribution: (code: string) => void
  cibledContributionContent: CibledContributionContentState | null | undefined
}

export const ContributionDetailsTab: React.FC<Partial<ContributionDetailsTabProps>> = ({
  contributionByCompany,
  contributionByOperations,
  isContributionByOperationsLoading,
  contributionByCompanyError,
  onGetContributionByOperation,
  cibledContributionContent,
  onGetCibledContribution
}) => {
  const { formatMessage, formatCurrencyNumber } = useTranslation()
  const styles = useStyles()
  const theme = useTheme()

  const label = useMemo(
    () => ({
      topSectionTitle: formatMessage({ id: 'EmployerContributionTopupZoneTitle' }),
      topSectionSubtitle: formatMessage({ id: 'EmployerContributionTopupZoneIntro' }),
      topSectionMaxTopupYearly:
        formatMessage({ id: 'EmployerContributionTopupZoneMaxTopupYear' }) +
        formatCurrencyNumber({ value: contributionByCompany?.totalLimit || 0 }),
      bottomSectionTitle: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerTitle' }),
      bottomSectionSubtitle: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerIntro' })
    }),
    [formatMessage, formatCurrencyNumber, contributionByCompany]
  )

  const isContributionTableVisible = useMemo(
    () => !!contributionByCompany?.planContributions?.length,
    [contributionByCompany]
  )

  const isOptimizerDisplayed = useMemo(
    () => !!contributionByOperations?.hasOptimization,
    [contributionByOperations]
  )

  const renderContributionHtmlContent = useCallback(() => {
    if (!cibledContributionContent) return null
    return (
      <View style={styles.notificationCardStyle}>
        <HtmlStyledRenderConnectedContainer
          baseFontStyle={styles.contributionCardStyle}
          html={useFormatHrefHtml(cibledContributionContent.content)}
        />
      </View>
    )
  }, [cibledContributionContent, styles, theme])

  const renderTopSection = useCallback(() => {
    return (
      <>
        <Title variant="t4">{label.topSectionTitle}</Title>
        <Paragraph
          autoMargin
          variant="t3"
          weight="light">
          {label.topSectionSubtitle}
        </Paragraph>
        <Text variant="t3">{label.topSectionMaxTopupYearly}</Text>
        {isContributionTableVisible && (
          <ContributionTable
            containerStyle={styles.contributionTable}
            data={contributionByCompany}
          />
        )}
      </>
    )
  }, [contributionByCompany, isContributionTableVisible, label, styles])

  useEffect(() => {
    onGetContributionByOperation?.()
    onGetCibledContribution?.('ABONDEMENT_INFO_ENTREPRISE')
  }, [])

  const renderBottomSection = useCallback(() => {
    if (isContributionByOperationsLoading) {
      return <DashboardContentWrapperCardPlaceHolder />
    }
    if (!isOptimizerDisplayed) return null
    const hasError = !!contributionByCompanyError?.message
    if (hasError)
      return (
        <RetryActionPlaceHolder
          retryAction={() => {
            onGetContributionByOperation?.()
          }}
        />
      )

    return (
      <View style={styles.bottomSectionContainer}>
        <Title variant="t4">{label.bottomSectionTitle}</Title>
        <Paragraph
          autoMargin
          variant="t3"
          weight="light">
          {label.bottomSectionSubtitle}
        </Paragraph>
        {OPERATIONS.reduce<React.ReactNode[]>((OperationCards, operation) => {
          const data = contributionByOperations?.[operation]

          if (!data?.hasOptimization) return OperationCards

          const hasPrevious = !!OperationCards.length

          const OperationCard = (
            <>
              {hasPrevious && (
                <Text
                  style={styles.textSeparator}
                  variant="t3"
                  weight="light">
                  {formatMessage({ id: 'EmployerContributionTopupZone2OptimizerAndOrColumnTable' })}
                </Text>
              )}
              <ContributionOperationCard
                data={data}
                operation={operation}
              />
            </>
          )

          return [...OperationCards, OperationCard]
        }, [])}
      </View>
    )
  }, [
    contributionByCompanyError,
    contributionByOperations,
    formatMessage,
    isContributionByOperationsLoading,
    isOptimizerDisplayed,
    label,
    onGetContributionByOperation,
    styles
  ])

  return (
    <>
      {renderTopSection()}
      {renderContributionHtmlContent()}
      {renderBottomSection()}
    </>
  )
}
