import React, { useCallback, useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { VictoryPie } from 'victory-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

import { useStyles } from './useStyles';

type OadRepartitionStyles = {
  containerStyle?: StyleProp<ViewStyle>;
};

interface Props extends OadRepartitionStyles {
    chartData?: {
      value: number;
      key: string;
      color: string;
      isPlaceholder?: boolean;
    }[];
    labelFontSize: number;
    height?: number;
    width?: number;
    labelRadius: number;
    minSectionLargerPercentageValue: number;
    hasPlaceholder?: boolean;
};

interface OadRepartitionItemProps {
  x: string;
  y: number;
  dy?: number;
  label?: string;
  textAnchor?: string;
  repartitionData?: {
    value: number;
    key: string;
    color: string;
  }[];
};

type VictoryPieData = OadRepartitionItemProps[] | undefined; 

export const PieChart: React.FC<Props> = ({
  chartData,
  labelFontSize,
  height,
  width,
  labelRadius,
  minSectionLargerPercentageValue,
  hasPlaceholder = true,
}) => {
  const theme = useTheme();
  const fontColor = theme.colors.basics.white;
  const { isMobile } = useScreenSizes();
  const styles = useStyles();
  const victoryPiePadding = { top: 0, bottom: 0, right: 0, left: 0 };

  const placeholderData = useMemo(() => {
    let remainingPercentage = hasPlaceholder ? 1 : 0;

    const newPlaceholder = {
      value: remainingPercentage,
      key: "",
      title: "",
      color: theme.colors.basics.grey.c100,
      isPlaceholder: true,
    };

    if (!chartData || chartData.length === 0) {
      return newPlaceholder;
    }

    remainingPercentage = chartData.reduce((newRemainingPercentage, section) => {
      newRemainingPercentage -= section.value;

      return newRemainingPercentage;
    }, remainingPercentage);

    newPlaceholder.value = remainingPercentage;

    return newPlaceholder;
  }, [chartData, theme]);

  const chartDataWithPlaceholder = useMemo(() => {
    if (!chartData) {
      return [placeholderData];
    }

    const newChartDataWithPlaceholder = chartData.concat(placeholderData);

    return newChartDataWithPlaceholder;
  }, [placeholderData, chartData]);

  const colorScale = chartDataWithPlaceholder && chartDataWithPlaceholder.map(item => item.color);

  const victoryPieStyle = {
    data: {
      fillOpacity: 0.9,
      stroke: theme.colors.basics.white,
      strokeWidth: 3,
    },
    labels: {
      padding: 10,
      margin: 10,
      fontSize: labelFontSize,
      fontFamily: theme.fonts.fontFamily.bold,
      fill: fontColor,
    },
  };

  const formatVictoryPieData = useCallback(() => {
    let currentTotal = 0;
    let lastY = 0;
    let lastDy = 0;
    const spacingLabel = isMobile ? 10 : 0;

    const newData: VictoryPieData = chartDataWithPlaceholder && chartDataWithPlaceholder.map(item => {
      const y = item.value * 100 ;
      const dy = y <= 10 && lastY <= 10 ? lastDy - (labelFontSize - spacingLabel) : 0;
      const label = item.isPlaceholder || y === 0 ? "" : `${Math.round(y)}%`;
      const textAnchor = 'start';

      const formattedY = (!item.isPlaceholder && item.value < minSectionLargerPercentageValue && item.value > 0) ?
        minSectionLargerPercentageValue * 100
        : item.value * 100;

      currentTotal = currentTotal + y;
      lastY = y;
      lastDy = dy;

      return {
        x: item.key,
        y: formattedY,
        dy,
        label,
        textAnchor,
      };
    });

    return newData;
  }, [isMobile, chartDataWithPlaceholder, labelFontSize, minSectionLargerPercentageValue]);

  const formatteddData = useMemo(() => formatVictoryPieData(), [formatVictoryPieData]);

  if (!formatteddData || formatteddData?.length === 0) {
    return null;
  }

  return (
    <View style={styles.container}>
      <VictoryPie
        colorScale={colorScale}
        data={formatteddData}
        height={height}
        labelPlacement='vertical'
        labelPosition="centroid"
        labelRadius={labelRadius}
        labels={({ datum }) => datum.label}
        padding={victoryPiePadding}
        style={victoryPieStyle}
        width={width}
      />
    </View>
  );
};
