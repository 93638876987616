import React, { useCallback, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';

import {
  CardWithLeftThread,
  ColumnsContainer,
  CompareFundsModal,
  DashboardPageWrapperConnected,
  FundSheetModal,
  FundSheetModalDataType,
  getPlanTypeColors,
  IconEnum,
  OperationTotalSubHeader,
  ManagementWrapper,
  ProfitSharingIncentiveAmountFieldModalConnected,
  ProfitSharingIncentiveAmountFieldModalConnectedRefType,
  SavingRepartitionCardWithFundSheet,
  SimpleButton,
  Text,
  useScreenSizes,
  useTheme,
  useTranslation,
  ProfitSharingIncentiveRetirementAmountValidationConnected,
  LoaderPlaceholderView
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { ProfitSharingIncentiveTypeEnum } from '@constants/ProfitSharingIncentive';
import {
  DirectionEnum
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { PlanCompartmentsType } from '@modules/common/types';
import { RefundRepartitionSupportsState } from '@modules/refund/available-refund/types';
import { constructStepperItems } from '@pages/Common/utils';

import {
  CompartmentCardHeader,
  ProfitSharingIncentiveHeader,
  CompartmentCardRepartition,
  ExpandableProvider
} from '../components';
import { ModalConsentValidation } from '../components/ModalConsentValidation';
import {
  useLabels
} from './hooks/index';
import { ProfitSharingIncentiveRepartitionProps } from './interfaces';
import { useStyles } from './styles';
import { getTechnicalFund } from './utils';
import { RootNavigation } from '@navigation/RootNavigation';
import { PlanFamillyList } from '@ere-uilib/constants';
import { locator } from '@constants/locator';

export const ProfitSharingIncentiveRepartition: React.FC<
  ProfitSharingIncentiveRepartitionProps
> = ({
  navigation,
  initData,
  repartition,
  assigmentAmounts,
  investedProjectPlans,
  investedRetirementPlans,
  allSupportsToCompare,
  setProfitSharingIncentiveRepartitionAmount,
  isContributionError,
  isContributionLoading
}) => {
    const amountModalRef = useRef<ProfitSharingIncentiveAmountFieldModalConnectedRefType>(null)
    const { formatMessage, getMessageRaw } = useTranslation();
    const theme = useTheme();
    const { isDesktop } = useScreenSizes();
    const styles = useStyles();
    const labels = useLabels();
    const [isPercentage, setIsPercentage] = useState(true);
    const [displayCompareFundsModal, setDisplayCompareFundsModal] =
      useState(false);
    const [fundsToCompare, setFundsToCompare] = useState<
      RefundRepartitionSupportsState[] | undefined
    >();
    const [fundSheetModalData, setFundSheetModalData] =
      useState<FundSheetModalDataType | null>();
    const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);

    const [displayModalConsentValidation, setDisplayModalConsentValidation] =
      useState(false);

    const stepperItems = constructStepperItems({
      size: 3,
      direction: DirectionEnum.ROW,
      activeStep: 2,
    });

    const totalInvestedAmount = useMemo(() => assigmentAmounts?.reinvestAmount || 0, [assigmentAmounts])

    const investedPercent = repartition?.formValues?.amountPercent || 0;
    const investedAmount = repartition?.formValues?.amount || 0;
    const hasStartedInvest = investedPercent > 0
    let remainingInvestedPercent = 100.00 - investedPercent;
    if (remainingInvestedPercent < 0) { remainingInvestedPercent = 0 }
    let remainingInvestedAmount = totalInvestedAmount - investedAmount;
    if (remainingInvestedAmount < 0) { remainingInvestedAmount = 0 }
    const isformFilled = isPercentage ?
      remainingInvestedPercent === 0
      : remainingInvestedAmount === 0

    const handleLinkButtonPress = useCallback(() => {

      RootNavigation.replace(RouteNames.RedirectOperationPage, {
        provider: PlanFamillyList.ES,
        operationType: initData?.type || '',
      });
    }, [initData])

    const handleModalValidation = useCallback(() => {
      setDisplayModalConsentValidation(false);
      navigation.navigate(RouteNames.ProfitSharingIncentive, {
        screen: RouteNames.ProfitSharingIncentiveSynthesis,
      });
    }, [navigation]);

    const renderLoaderLayerOverPage = useCallback(() => {
      if (!isContributionLoading) { return null }
      return (
        <LoaderPlaceholderView
          backgroundColor={theme.colors.basics.white}
          foregroundColor={theme.colors.basics.grey.c200}
          style={styles.contributionLoadingLayer}
        />
      )
    }, [styles, isContributionLoading, theme])

    const renderBottomActions = useCallback(() => (
      <View style={styles.bottomActionsStyle}>
        <View style={styles.bottomActionsButtonsContainerStyle}>
          <SimpleButton
            design="outlined"
            leftIcon={IconEnum.CHEVRON_LEFT}
            onPress={() => navigation.goBack()}
          />
          <SimpleButton
            containerStyle={styles.simpleButtonStyle}
            design="solid"
            disabled={!isformFilled}
            onPress={() => {
              setDisplayModalConsentValidation(true);
            }}
            size="small"
            title={labels.nextBtn}
            testId={locator._pi._synthese_continu}
          />
        </View>
        {renderLoaderLayerOverPage()}
      </View>
    ), [
      styles,
      navigation,
      isformFilled,
      labels,
      renderLoaderLayerOverPage
    ]);

    const renderSubHeader = useCallback(() => {

      return (
        <OperationTotalSubHeader
          isAmountPercent={isPercentage}
          isSwitchEnabled={false}
          mainAmount={isPercentage ?
            remainingInvestedPercent
            : remainingInvestedAmount}
          mainTitle={hasStartedInvest ?
            formatMessage({ id: 'PIChoixInvestInvestProjetDejaInvestiLabel' })
            : formatMessage({ id: 'PIChoixInvestMontantRepartirLabel' })}
          secondaryAmount={isPercentage ?
            remainingInvestedAmount
            : remainingInvestedPercent}
          secondaryTitle={formatMessage({ id: 'PIChoixInvestInvestProjetDejaInvestiSoitLabel' })}
        />
      )
    }, [
      isPercentage,
      remainingInvestedPercent,
      remainingInvestedAmount,
      formatMessage,
      hasStartedInvest
    ]);

    const RenderHeader = useCallback(() => {
      const title = initData?.type === ProfitSharingIncentiveTypeEnum.incentive ?
        formatMessage({ id: 'PIChoixInvestInteressementTitre' })
        : formatMessage({ id: 'PIChoixInvestParticipationTitre' });

      return (
        <>
          <ProfitSharingIncentiveHeader
            displayCloseButton
            helpPoint
            helpPointContentHtml={getMessageRaw({
              id: 'PIChoixInvestContentHelp',
            })}
            helpPointModalTitle={formatMessage({
              id: 'PIChoixInvestTitleHelp',
            })}
            helpPointPosition="left"
            stepperItems={stepperItems}
            title={title}
          />
          {!isDesktop && renderSubHeader()}
        </>
      );
    }, [
      isDesktop,
      stepperItems,
      initData?.type,
      getMessageRaw,
      formatMessage,
      renderSubHeader
    ]);

    const renderPlans = useCallback((plans: PlanCompartmentsType[] | undefined) => {

      const linkUnderfieldLabel = !hasStartedInvest ?
        formatMessage({ id: 'PISaisieToutInvestirLien' })
        : formatMessage({ id: 'PISaisieInvestirResteLien' });

      return plans?.map((plan, planIndex) => {
        const planColors = theme.colors.basics.primary
        const planId = plan?.planId;
        const contributionPotentiel = (plan.advancedProperties.ContributionLimit || 0) - (plan.advancedProperties.ContributionConsumed || 0)
        const isContributionPotentiel = contributionPotentiel > 0
        const isMergedExternal = !!plan?.advancedProperties?.IsMergedExternal;

        const isContributionDisplayed = isMergedExternal || plan?.advancedProperties.HasContribution

        const technicalFund = getTechnicalFund(plan);

        const technicalFundIdSet = {
          planId,
          compartmentCode: technicalFund?.compartmentCode || '',
          managementId: technicalFund?.managementId || '',
          fundId: technicalFund?.supportIsin || ''
        }

        const remainingTechnicalFundInvestedPercent = remainingInvestedPercent + (technicalFund?.formValues?.amountPercent || 0);
        const remainingTechnicalFundInvestedAmount = remainingTechnicalFundInvestedPercent * ((totalInvestedAmount || 0) / 100);

        const renderCompartmentCard = (areCompartmentsExpanded = true) => (
          <CardWithLeftThread
            addShadow
            backgroundColor={planColors?.c500}
            borderLeftColor={planColors?.c500}
            containerStyle={styles.planCardContainer}
            key={planIndex + planId}
          >
            <CompartmentCardHeader
              editable={isMergedExternal}
              fieldAmount={technicalFund?.formValues?.amount}
              fieldPercent={technicalFund?.formValues?.amountPercent}
              isPercentagePlusAmount
              isFieldAmountPercentage={isMergedExternal}
              isLinkUnderField={!isformFilled}
              linkUnderfieldLabel={linkUnderfieldLabel}
              onEditAmountPress={() => {
                amountModalRef?.current?.openWithData({
                  displayData: {
                    title: plan?.label || "",
                    initialAmount: technicalFund?.formValues?.amountPercent || 0,
                    amountParameters: {
                      reinvestablePercentage: remainingTechnicalFundInvestedPercent,
                      maxPercent: remainingTechnicalFundInvestedPercent
                    },
                    allAmountValue: remainingTechnicalFundInvestedPercent,
                    allAmountLabel: linkUnderfieldLabel,
                  },
                  isPercentage,
                  ...technicalFundIdSet
                })
              }}
              onLinkUnderFieldPress={() => {
                setProfitSharingIncentiveRepartitionAmount({
                  amount: remainingTechnicalFundInvestedAmount,
                  amountPercent: remainingTechnicalFundInvestedPercent,
                  contribution: null,
                  ...technicalFundIdSet
                })
              }}
              onResetAmountPress={() => {
                setProfitSharingIncentiveRepartitionAmount({
                  amount: 0,
                  amountPercent: 0,
                  contribution: null,
                  ...technicalFundIdSet
                })
              }}
              backGroundColorCustom={planColors?.c500}
              contributionEstimation={plan.formValues?.contributionAmount}
              contributionPotentiel={contributionPotentiel}
              dispositifTitle={`${formatMessage({ id: 'PISaisieMonMontantTitre' })} ${plan?.planName}`}
              isContributionDisplayed={isContributionDisplayed}
              underFieldLinkStyle={styles.planUnderFieldLink}
            />
            {isMergedExternal && <CompartmentCardRepartition />}
            {areCompartmentsExpanded && plan?.compartments.map(compartment => {
              const compartmentId = compartment.code;
              const isMultipleManagements = compartment.managements.length > 1
              return compartment.managements.map((management, managementIndex) => {
                if (management?.advancedProperties?.IsTechnicalManagement) return;

                const managementId = management.id;
                const masterSupport = management.supports.find(support => support.isMaster)
                const masterSupportAmount: number = masterSupport?.formValues?.amountPercent || 0;
                const fieldAmountpercent = !!masterSupport ?
                  masterSupportAmount
                  : management?.investedFormValues?.amountPercent;
                const managementContribution = !!masterSupport ?
                  masterSupport.formValues?.contributionAmount
                  : management?.formValues?.contributionAmount;

                const isVisibleManagementHeader = isMultipleManagements;
                const isFreeManagement = management.isFree;
                const isExpandable = isVisibleManagementHeader && !isMergedExternal;
                const isFundsDisabled = isMergedExternal;
                const isSupportDisabled = isMergedExternal || !isFreeManagement;
                const isSupportEditable = !isMergedExternal && management.isFree;
                const isManagementHasContribution = isContributionPotentiel && management.advancedProperties.HasContribution
                const isDisplayingExtraManagement = !isMergedExternal && !isFreeManagement;
                const managementAllAmountValue = remainingInvestedPercent + (management.formValues?.amountPercent || 0)
                const remainingManagementInvestedPercent = remainingInvestedPercent + (fieldAmountpercent || 0)
                const remainingManagementInvestedAmount = remainingManagementInvestedPercent * ((totalInvestedAmount || 0) / 100)

                const fieldAmountPercent = isMergedExternal ?
                  management?.advancedProperties?.PourcentageRepartition :
                  management?.formValues?.amountPercent;

                return (
                  <ManagementWrapper
                    isFundsDisabled={isFundsDisabled}
                    hasIncorrectDrivingData={management.advancedProperties.HasIncorrectDrivingData}
                    operationType={initData?.type}
                    backGroundColorCustom={planColors?.c100}
                    contribution={managementContribution}
                    fieldAmount={management.formValues?.amount}
                    fieldAmountPercent={fieldAmountPercent}
                    isContributionDisplayed={isManagementHasContribution}
                    isContributionError={isContributionError}
                    isContributionLast
                    isDisplayingExtraManagement={isDisplayingExtraManagement}
                    isExpandable={isExpandable}
                    isExtraManagementEditable={!isFreeManagement}
                    isFieldAmountPercentage={isPercentage}
                    isFieldPercentagePlusAmount
                    isLinkUnderField={!isformFilled}
                    key={managementIndex + management.id}
                    linkUnderfieldLabel={linkUnderfieldLabel}
                    onEditMasterAmountPress={() => {
                      amountModalRef?.current?.openWithData({
                        displayData: {
                          title: plan?.label || "",
                          subTitle: management.label,
                          initialAmount: management.formValues?.amountPercent || 0,
                          amountParameters: {
                            reinvestablePercentage: managementAllAmountValue,
                            maxPercent: managementAllAmountValue
                          },
                          allAmountValue: managementAllAmountValue,
                          allAmountLabel: linkUnderfieldLabel,
                          isContributionActive: isManagementHasContribution
                        },
                        isPercentage: isPercentage,
                        planId: plan.planId,
                        compartmentCode: compartment.code,
                        managementId: managementId,
                        isManagementDirectContributionAmounts: !masterSupport,
                        isManagementDirectAmounts: !masterSupport
                      })
                    }}
                    onLinkUnderFieldPress={() => {
                      setProfitSharingIncentiveRepartitionAmount({
                        planId,
                        compartmentCode: compartmentId || "",
                        managementId: managementId,
                        fundId: masterSupport?.supportIsin,
                        amount: remainingManagementInvestedAmount,
                        amountPercent: remainingManagementInvestedPercent,
                        contribution: null
                      })
                    }}
                    onResetAmountPress={() => {
                      setProfitSharingIncentiveRepartitionAmount({
                        planId,
                        compartmentCode: compartmentId || "",
                        managementId: managementId,
                        fundId: masterSupport?.supportIsin,
                        amount: 0,
                        amountPercent: 0,
                        contribution: null
                      })
                    }}
                    onLinkButtonPress={handleLinkButtonPress}
                    title={management.label}
                    totalAmount={management.totalAmount}
                    visibleHeader={isVisibleManagementHeader}
                    visibleInvestedAmount
                  >
                    {management.supports.map((support, supportIndex) => {
                      const remainingSupportInvestedPercent = remainingInvestedPercent + (support.formValues?.amountPercent || 0)
                      const remainingSupportInvestedAmount = remainingSupportInvestedPercent * ((totalInvestedAmount || 0) / 100)
                      const fieldPercent = isMergedExternal ?
                        support?.advancedProperties?.PourcentageRepartition :
                        support?.formValues?.amountPercent;

                      if (support?.advancedProperties?.IsTechnicalFund) return;

                      return (
                        <SavingRepartitionCardWithFundSheet
                          source={support?.source}
                          hasNoFundSheet={support?.hasNoFundSheet}
                          contribution={support.formValues?.contributionAmount}
                          disabled={isSupportDisabled}
                          editable={isSupportEditable}
                          fieldAmount={support.formValues?.amount}
                          fieldPercent={fieldPercent}
                          investedAmount={support.amount?.amount}
                          isContributionDisplayed={isContributionPotentiel && support.hasContribution}
                          isContributionError={isContributionError}
                          isContributionLast
                          isFieldAmountPercentage={isPercentage}
                          isFieldPercentagePlusAmount
                          isLinkUnderField={!isformFilled}
                          key={supportIndex + support.supportIsin}
                          legalStatus={support.legalStatus}
                          linkUnderfieldLabel={linkUnderfieldLabel}
                          navigation={navigation}
                          onEditAmountPress={() => {
                            amountModalRef?.current?.openWithData({
                              displayData: {
                                title: plan.label,
                                subTitle: support.supportName,
                                initialAmount: support.formValues?.amountPercent || 0,
                                amountParameters: {
                                  reinvestablePercentage: remainingSupportInvestedPercent,
                                  maxPercent: remainingSupportInvestedPercent
                                },
                                allAmountValue: remainingSupportInvestedPercent,
                                allAmountLabel: linkUnderfieldLabel,
                                isContributionActive: isContributionPotentiel && support.hasContribution
                              },
                              isPercentage: isPercentage,
                              planId: plan.planId,
                              compartmentCode: compartment.code,
                              managementId: managementId,
                              fundId: support.supportIsin,
                            })
                          }}
                          onLinkUnderFieldPress={() => {
                            setProfitSharingIncentiveRepartitionAmount({
                              planId,
                              compartmentCode: compartmentId || "",
                              managementId: managementId,
                              fundId: support.supportIsin,
                              amount: remainingSupportInvestedAmount,
                              amountPercent: remainingSupportInvestedPercent,
                              contribution: null
                            })
                          }
                          }
                          onResetAmountPress={() => {
                            setProfitSharingIncentiveRepartitionAmount({
                              planId,
                              compartmentCode: compartmentId || "",
                              managementId: managementId,
                              fundId: support.supportIsin,
                              amount: 0,
                              amountPercent: 0,
                              contribution: null
                            })
                          }}
                          riskLevel={support.riskLevelSRI || support.riskLevelSRRI}
                          isRiskLevelSRI={!!support.riskLevelSRI}
                          supportIsin={support.supportIsin}
                          supportName={support.supportName}
                          testId={support.supportName}
                        />
                      );
                    })}
                  </ManagementWrapper>
                );
              });
            })}
          </CardWithLeftThread>
        )

        return (
          <ExpandableProvider isDefaultExpanded={!isMergedExternal}>
            {(areCompartmentsExpanded) => renderCompartmentCard(areCompartmentsExpanded)}
          </ExpandableProvider>
        );
      });
    }, [
      styles,
      isPercentage,
      theme,
      isContributionError,
      formatMessage,
      navigation,
      remainingInvestedPercent,
      setProfitSharingIncentiveRepartitionAmount,
      totalInvestedAmount,
      isformFilled,
      hasStartedInvest
    ]);

    return (
      <DashboardPageWrapperConnected cardContentStyle={styles.dashboardWrapper}>
        <ColumnsContainer
          renderHeader={RenderHeader}
          renderRightDesktopColumn={renderSubHeader}
          renderStickyMobileBottom={() => renderBottomActions()}
        >
          {(allSupportsToCompare?.supports?.length || 0) > 1 && (
            <View style={styles.compareButtonContainerStyle}>
              <SimpleButton
                containerStyle={styles.compareButtonStyle}
                design="outlined"
                onPress={() => {
                  setFundsToCompare(allSupportsToCompare?.supports);
                  setDisplayCompareFundsModal(true);
                }}

                size="small"
                title={formatMessage({
                  id: 'PIChoixInvestComparateurFondsBouton',
                })}
              />
            </View>
          )}
          {!!investedProjectPlans && investedProjectPlans.length > 0 && (
            <>
              <Text
                variant="t3"
                weight="bold">
                {labels.investProjectTitle}
              </Text>
              <Text
                style={{ marginVertical: 15 }}
                variant="t3"
                weight="light">
                {labels.investProjectDescription}
              </Text>
              <View style={styles.cardContainer}>
                {renderPlans(investedProjectPlans)}
              </View>
            </>
          )}
          {!!investedRetirementPlans && investedRetirementPlans.length > 0 && (
            <>
              <Text
                style={{ marginTop: 15 }}
                variant="t3"
                weight="bold">
                {labels.investRetraiteTitle}
              </Text>
              <Text
                style={{ marginVertical: 15 }}
                variant="t3"
                weight="light">
                {labels.investRetraiteDescription}
              </Text>
              <View style={styles.cardContainer}>
                {renderPlans(investedRetirementPlans)}
              </View>
            </>
          )}
          {fundSheetModalData && (
            <FundSheetModal
              id={fundSheetModalData?.id}
              modalIsVisible={displayFundSheetModal}
              navigation={navigation}
              onCloseModal={() => {
                setDisplayFundSheetModal(false);
                setFundSheetModalData(null);
              }}
              title={fundSheetModalData?.title}
            />
          )}
          {isDesktop && renderBottomActions()}
          {renderLoaderLayerOverPage()}
        </ColumnsContainer>
        <CompareFundsModal
          fundsToCompare={fundsToCompare?.map(fund => {
            return {
              isinCode: fund.supportIsin,
              supportName: fund.supportName,
              addedValue: 0,
              hasContribution: fund.hasContribution,
              amount: fund.amount.amount,
              hasNoFundSheet: fund.hasNoFundSheet,
              source: fund.source
            };
          })}
          modalIsVisible={displayCompareFundsModal}
          onCloseModal={() => {
            setDisplayCompareFundsModal(false);
          }}
        />
        <ProfitSharingIncentiveAmountFieldModalConnected
          ref={amountModalRef}
        />
        <ModalConsentValidation
          modalIsVisible={displayModalConsentValidation}
          onModalClose={() => {
            setDisplayModalConsentValidation(false);
          }}
          onModalValidation={handleModalValidation}
        />

        <ProfitSharingIncentiveRetirementAmountValidationConnected />
      </DashboardPageWrapperConnected>
    );
  };