import React from 'react';
import { Image, ImageStyle, ImageURISource, StyleProp } from 'react-native';
import { ImageSource } from 'react-native-vector-icons/Icon';

import { createUseStyles } from '@ere-uilib/styles';

interface Props {
  platform: string;
  hasProperty: boolean;
  source: string;
  testID: string;
  SVGComponent: StyleProp<ImageURISource | ImageSource>;
}
interface Styles {
  SVGStyle?: StyleProp<ImageStyle>;
}

export const ImageManager: React.FC<Props & Styles> = ({
  platform,
  hasProperty,
  source,
  testID,
  SVGComponent,
  SVGStyle
}) => {
  const styles = useStyles({}, { SVGStyle });
  const renderImage = React.useCallback(
    src => {
      if (platform !== 'web' && !hasProperty) {
        return (
          <SVGComponent
            height={'100%'}
            testID={testID}
            width={'100%'}
          />
        );
      }
      return (
        <Image
          source={src}
          style={styles.SVGStyle}
          testID={testID} // test use it to detect if it's the right svg name expected
        />
      );
    },
    [SVGComponent, testID, hasProperty]
  );
  return renderImage(source);
};
const getStyles = (style?: Styles): Styles => ({
  SVGStyle: [
    {
      flex: 1,
      resizeMode: 'contain',
      width: '100%',
      height: '100%'
    },
    style?.SVGStyle
  ]
});

const useStyles = createUseStyles(getStyles);
