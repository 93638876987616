import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { Title } from '@ere-uilib/atoms';
import { SimpleSwitch } from '@ere-uilib/molecules/buttons';
import { useTranslation } from '@ere-uilib/translations';

import { useStyles } from './useStyles'

interface OperationTotalSubHeaderProps {
  mainTitle?: string; 
  secondaryTitle?: string;
  contributionTitle?: string; 
  mainAmount?: number; 
  secondaryAmount?: number;
  contributionAmount?: number;
  showContribution?: boolean;
  addShadow?: boolean;
  hasContributionError?: boolean;
  isAmountPercent?: boolean;
  isSwitchEnabled?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const OperationTotalSubHeader: React.FC<OperationTotalSubHeaderProps> = ({
  mainTitle,
  secondaryTitle,
  contributionTitle,
  mainAmount,
  secondaryAmount,
  contributionAmount,
  showContribution,
  style,
  addShadow = true,
  hasContributionError,
  isAmountPercent = false,
  isSwitchEnabled
}) => {
  const { formatCurrencyNumber, formatMessage, formatPercentNumber } = useTranslation();
  const styles = useStyles({ style, addShadow });
  const finalMainAmount = typeof mainAmount === 'number' ? mainAmount : 0
  const finalSecondaryAmount = typeof secondaryAmount === 'number' ? secondaryAmount : 0

  return (
    <View style={styles.containerStyle}>
      <View style={styles.mainRowStyle}>
        <View>
          <Title
            style={styles.textStyle}
            variant="t6"
            weight="light"
          >
            {mainTitle}
            {" "}
            <Title
              style={styles.textStyle}
              variant="t6"
              weight="bold"
            >
              {isAmountPercent ? 
                formatPercentNumber({ value: finalMainAmount }) 
                : formatCurrencyNumber({ value: finalMainAmount })
              }
            </Title>
          </Title>
          {!!secondaryTitle && (
            <Title
              style={styles.textStyle}
              variant="t7"
              weight="light"
            >
              {secondaryTitle}
              {" "}
              {!isAmountPercent ? 
                formatPercentNumber({ value: finalSecondaryAmount }) 
                : formatCurrencyNumber({ value: finalSecondaryAmount })
              }
            </Title>
          )}
        </View>
        
        { isSwitchEnabled &&
          <SimpleSwitch 
            onChange={()=> {}}
          
          />
        }
      </View>
      {(showContribution || hasContributionError) && (
        <>
          <View style={styles.contributionRowStyle}>
            <Title
              variant="t7"
              weight="light">
              {contributionTitle}
            </Title>
            <Title
              style={styles.contributionRowAmountStyle}
              variant="t7"
              weight="bold"
            >
              {
                hasContributionError && '- €'
              }
              {!hasContributionError && typeof contributionAmount === 'number' &&
                formatCurrencyNumber({ value: contributionAmount })
              }
              {!hasContributionError && typeof contributionAmount !== 'number' &&
                formatCurrencyNumber({ value: 0 })
              }
            </Title>
          </View>
          {hasContributionError && (
            <View style={styles.contributionRowStyle}>
              <Title
                style={styles.contributionErrorTitle}
                variant="t7"
                weight="regular"
              >
                {formatMessage({ id: 'VV_repartition_contribution_error_label' })}
              </Title>
            </View>
          )}
        </>
      )}
    </View>
  );
};
