import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  Paragraph,
  Title,
  WebView,
  useTranslation,
} from '@components/index';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { getQuickSignUrl } from '@modules/bank-details/selectors';
import { getProfileUserInfos } from '@modules/profile/selectors';
import { PopinContentTypeEnum } from '@pages/BankDetails/enums/PopinContentTypeEnum';
import { constructStepperItems } from '@pages/Common/utils';

import { BankDataHeader, BankDetailsSuccessOrErrorPopin } from '../components';
import { Props } from './interfaces';
import { useStyles } from './useStyles';


export const BankSignaturePage: React.FC<Props> = ({
  onGetUserInfo,
}) => {
  const { formatMessage } = useTranslation();
  const styles = useStyles();
  const [selectedQuickSignMember, setSelectedQuickSignMember] = useState<string | undefined>();
  const [isPopinVisible, setIsPopinVisible] = useState(false);
  const quickSignUrl = useSelector(getQuickSignUrl);
  const userInfo = useSelector(getProfileUserInfos);

  useEffect(() => { onGetUserInfo?.() }, [onGetUserInfo])
  // TODO: manage souldSign

  const handlePaymentRedirect = useCallback((success: boolean, errorCode: string) => {
    setIsPopinVisible(true);
    (success === false) ?
      setSelectedQuickSignMember(errorCode)
      : setSelectedQuickSignMember(PopinContentTypeEnum.SUCCESS);
  }, [selectedQuickSignMember, setSelectedQuickSignMember, isPopinVisible, setIsPopinVisible]);

  const stepperItems = constructStepperItems({
    size: 4,
    direction: DirectionEnum.ROW,
    activeStep: 3
  });

  const renderMainContent = () => {
    return (<>
      <Title
        variant="t4"
        weight="bold">
        {formatMessage({ id: 'CoordonneesBancairesMandatQuickSignTitre' })}
      </Title>
      <Paragraph
        autoMargin
        variant="t3"
        weight='light'>
        {formatMessage({
          id: 'CoordonneesBancairesMandatQuickSignTexte', values: {
            'numero_de_mobile': userInfo.phone ?? ''
          }
        })}
      </Paragraph>
      <WebView
        isQuickSign
        onQuisignMessage={(success: boolean, errorCode: string) => handlePaymentRedirect(success, errorCode)}
        sourceUri={{ uri: quickSignUrl ?? '' }}
        style={styles.htmlFrameStyle}
      />
    </>
    );
  };

  const renderHeader = () => {
    return (<BankDataHeader
      displayBackButton
      displayCloseButton
      helpPoint={false}
      stepperItems={stepperItems}
      title={formatMessage({ id: "CoordonneesBancairesMandatQuickSignTitre" })}
    />);
  };
  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        columnSingleRowStyle={styles.contentContainer}
        hasColumnCenter
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={styles.content}
        renderHeader={renderHeader}
        scrollViewContentContainerStyle={styles.scrollviewContent}>
        {renderMainContent()}
        <BankDetailsSuccessOrErrorPopin
          isModalVisible={isPopinVisible}
          quickSignEnumMember={selectedQuickSignMember}
        />
      </ColumnsContainer>
    </DashboardPageWrapperConnected>

  );
};
