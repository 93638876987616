import React from 'react';
import { StyleProp, Text, TextStyle } from 'react-native';

import { createUseStyles, useTheme, getParagraphFontStyle } from '@ere-uilib/styles';

import { ParagraphProps, ParagraphStylesContext } from './interfaces';

export const Paragraph: React.FC<ParagraphProps> = ({
  style,
  variant,
  autoMargin = false,
  weight = 'regular',
  testId,
  ...otherProps
}) => {
  const theme = useTheme();
  const textStyle = useStyles({
    theme,
    variant,
    autoMargin,
    weight,
    numberOfLines: otherProps.numberOfLines,
  }, style);

  return (
    <Text
      testID={testId}
      accessibilityLabel={testId}
      style={textStyle}
      {...otherProps}
    />
  );
};

const getStyles = (
  context: ParagraphStylesContext,
  style?: StyleProp<TextStyle>,
): StyleProp<TextStyle> => [
  {
    ...getParagraphFontStyle({
      weight: context?.weight,
      variant: context.variant,
      autoMargin: context.autoMargin,
      theme: context?.theme,
      numberOfLines: context?.numberOfLines,
    }),
  },
  style,
];

const useStyles = createUseStyles(getStyles);
