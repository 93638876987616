import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';

import { DesktopMenuUserInformationsComponent } from '@components/index';
import { RouteNames } from '@constants/navigation';
import { DecodedTokenState, UserInfoState } from '@modules/auth/types';

interface DataProps {
  decodedToken?: DecodedTokenState;
  userInformationContainer?: StyleProp<ViewStyle>;
  userInformationTitle?: StyleProp<TextStyle>;
  userInformationSubtitle?: StyleProp<TextStyle>;
  userInfo: UserInfoState;
}

export interface Styles {
  userInformationContainer?: StyleProp<ViewStyle>;
  userInformationTitle?: StyleProp<TextStyle>;
  userInformationSubtitle?: StyleProp<TextStyle>;
}

type Props = DataProps & Styles;

export const DesktopMenuUserInformationsConnectedComponent: React.FC<Props> = ({
  userInformationTitle,
  userInformationContainer,
  userInformationSubtitle,
  decodedToken,
  userInfo
}: Props) => {
  const navigation = useNavigation();
  const handlePressLogoutButton = useCallback(() => {
    navigation.navigate(RouteNames.Logout);
  }, [navigation]);

  return (
    <DesktopMenuUserInformationsComponent
      decodedToken={decodedToken}
      onPressLogoutButton={() => handlePressLogoutButton()}
      userInformationContainer={userInformationContainer}
      userInformationSubtitle={userInformationSubtitle}
      userInformationTitle={userInformationTitle}
      userInfo={userInfo}
    />
  );
};
