import React from 'react';
import { View, StyleProp, StyleSheet, ViewStyle, Image, ImageStyle } from 'react-native';

import {
  ThemeType,
  createUseStyles,
  useScreenSizes,
  useTheme,
} from '@ere-uilib/styles';

type PageBackgroundGreyProps = PageBackgroundGreyStyle

interface PageBackgroundGreyStyle {
  containerStyle?: StyleProp<ViewStyle>;
  imagesContainer?: StyleProp<ViewStyle>;
  imageTop?: StyleProp<ImageStyle>;
  imageBottom?: StyleProp<ImageStyle>;
}
type StylesContext = {
  theme: ThemeType;
  isMobileTablet?: boolean;
  windowWidth: number;
};

export const PageBackgroundGrey: React.FC<PageBackgroundGreyProps> = ({
  children,
  containerStyle,
  imagesContainer,
  imageTop,
  imageBottom,
}) => {
  const { windowWidth, isMobile, isTablet } = useScreenSizes();
  const theme = useTheme();
  const isMobileTablet = isMobile || isTablet;

  const styles = useStyles({
    theme,
    isMobileTablet,
    windowWidth: windowWidth || 1,
  }, {
    containerStyle,
    imagesContainer,
    imageTop,
    imageBottom,
  });

  const images = {
    backgroundTop: require('@assets/icons/top_background.svg').default,
    backgroundBottom: require('@assets/icons/bottom_background.svg').default,
  };
  return (
    <View style={styles.containerStyle}>
      {!isMobileTablet && (
        <View style={styles.imagesContainer}>
          <Image
            source={images.backgroundTop}
            style={styles.imageTop}
          />
          <Image
            source={images.backgroundBottom}
            style={styles.imageBottom}
          />
        </View>
      )}
      {children}
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: PageBackgroundGreyStyle,
):PageBackgroundGreyStyle => ({
  containerStyle: [
    {
      flex: 1,
      ...(context?.isMobileTablet ? {} : { justifyContent: 'center' }),
    },
    style?.containerStyle,
  ],
  imagesContainer: [
    {
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: context?.theme.colors.background.default.c500 + 'BB',
      ...StyleSheet.absoluteFillObject,
    },
    style?.imagesContainer,
  ],
  imageTop: [
    {
      height: 237 / 1920 * (context?.windowWidth || 1),
      width: '100%',
    },
    style?.imageTop,
  ],
  imageBottom: [
    {
      height: 608 / 1920 * (context?.windowWidth || 1),
      width: '100%',
    },
    style?.imageBottom,
  ],
});

const useStyles = createUseStyles(getStyles);
