import { 
  ProfitSharingIncentiveFrameEnum, 
  ProfitSharingIncentiveTypeEnum, 
} from '@constants/ProfitSharingIncentive';

import {
  PiProfitSharingStepsComplementary, 
  PiProfitSharingStepsExceptional, 
  PiProfitSharingStepsNormal, 
  PiIncentiveStepsComplementary, 
  PiIncentiveStepsExceptional, 
  PiIncentiveStepsNormal, 
  PISTEPS 
} from '../constants'

export const useProfitSharingIncentiveTypeAndFrameValues = ({
  profitFrame,
  profitType
}:{
  profitFrame: ProfitSharingIncentiveFrameEnum | undefined
  profitType: ProfitSharingIncentiveTypeEnum | undefined
}) => {

  let pageTitle = "";
  let steps = PISTEPS;
  if(profitType === ProfitSharingIncentiveTypeEnum.incentive) {
    
    switch(profitFrame) {
    case ProfitSharingIncentiveFrameEnum.complementary :
      steps = PiIncentiveStepsComplementary;
      pageTitle = "PIMonInteressementCOMPTitre";
      break;
    case ProfitSharingIncentiveFrameEnum.normal :
      steps = PiIncentiveStepsNormal;
      pageTitle = "PIMonInteressementTitre";
      break;
    case ProfitSharingIncentiveFrameEnum.exceptional :
      steps = PiIncentiveStepsExceptional;
      pageTitle = "PIMonInteressementEXCEPTitre";
      break;
    default:
      pageTitle = "PIMonInteressementTitre";
      break;
    }
  } else {
    switch(profitFrame) {
    case ProfitSharingIncentiveFrameEnum.complementary :
      steps = PiProfitSharingStepsComplementary;
      pageTitle = "PIMaParticipationCOMPTitre";
      break;
    case ProfitSharingIncentiveFrameEnum.normal :
      steps = PiProfitSharingStepsNormal;
      pageTitle = "PIMaParticipationTitre";
      break;
    case ProfitSharingIncentiveFrameEnum.exceptional :
      steps = PiProfitSharingStepsExceptional;
      pageTitle = "PIMaParticipationEXCEPTitre";
      break;
    default:
      pageTitle = "PIMaParticipationTitre";
      break;
    }
  }
  return {
    steps,
    pageTitle
  }
}