import { useEffect, useMemo, useState } from 'react'

import { useTranslation } from '@ere-uilib/translations'

import { UseControllerParams } from './interface'
import { AVAILABILITY_TYPE } from '@pages/Arbitration/components/Support/components/DueDateCard/interface'

export function useController({
  visible,
  initialAmount,
  amountParameters,
  isContributionActive,
  dueDateOption,
  onResetCalculatedContribution,
  onCalculContribution,
  onValid,
  onCancel
}: UseControllerParams) {
  const { formatMessage, formatDate, formatCurrencyNumber, formatPercentNumber, formatShareCountNumber } = useTranslation()
  const [isAmountValid, setIsAmountValid] = useState<boolean>(false)
  const [amountError, setAmountError] = useState<undefined | string>(undefined)

  // effects
  useEffect(() => {
    if (!visible) {
      return
    }

    testAmount(initialAmount)
  }, [visible])

  // actions
  const handleAmountChange = (newAmount: number | undefined | null) => {
    testAmount(newAmount)
  }
  const handleResetCalculatedContribution = () => {
    onResetCalculatedContribution?.()
  }
  const handleContributionCalculPressed = (amount: number | undefined | null) => {
    onCalculContribution?.(amount)
  }
  const handleValidPressed = (amount: number | undefined | null) => {
    onValid(amount)
  }
  const handleCancelPressed = () => {
    onCancel()
  }

  // amount test
  const testAmount = (newAmount: number | undefined | null) => {
    let isAmountValidTest = true
    // test value exist and modal amount paramters ( min , max, ...)
    const isAmountValue = newAmount && newAmount >= 0

    const isAmountMinValid = typeof amountParameters?.min === 'number' ?
      newAmount && newAmount >= amountParameters.min
      : true;
    const isAmountMaxValid = typeof amountParameters?.max === 'number' ?
      newAmount && newAmount <= amountParameters.max
      : true;
    const isAmountUcMinValid = amountParameters?.ucMin ?
      newAmount && newAmount >= 15 // TODO : obtain this value from settings ?
      : true;
    const isAvailableAmountValid = amountParameters?.availableAmount ?
      newAmount && newAmount <= amountParameters.availableAmount
      : true;
    const isArbitrableAmountValid = amountParameters?.arbitrableAmount ?
      newAmount && newAmount <= amountParameters.arbitrableAmount
      : true;
    const isReivestmentPercentageValid = (amountParameters?.reinvestablePercentage) ?
      (newAmount && newAmount >= 0 && newAmount <= 100)
      : true;
    const isReivestmentPercentageMaxValid = (amountParameters?.maxPercent ||
      amountParameters?.maxPercent === 0) ?
      (newAmount && newAmount <= (amountParameters?.maxPercent)) : true;

    if (
      isAmountValue &&
      (!isAmountMinValid ||
        !isAmountMaxValid ||
        !isAmountUcMinValid ||
        !isAvailableAmountValid ||
        !isArbitrableAmountValid ||
        !isReivestmentPercentageMaxValid ||
        !isReivestmentPercentageValid)
    ) {
      isAmountValidTest = false
      let errorMessage
      if (!isAmountMinValid) {
        errorMessage =
          typeof amountParameters?.min === 'number'
            ? // TODO : get specific label
            `NOT LABEL : Le montant doit être suppèrieur ou égal à ${formatCurrencyNumber({
              value: amountParameters.min
            })}`
            : undefined
      }
      if (!isAmountMaxValid) {
        errorMessage =
          typeof amountParameters?.max === 'number'
            ? // TODO : get specific label
            `NOT LABEL : Le montant doit être infèrieur ou égal à ${formatCurrencyNumber({
              value: amountParameters.max
            })}`
            : undefined
      }
      if (!isAmountUcMinValid) {
        errorMessage = formatMessage({ id: 'VV_repartition_edit_UC_threshold_error_label' })
      }
      if (!isAvailableAmountValid) {
        errorMessage = `${formatMessage({
          id: 'Remboursement_modale_msgErreur'
        })}${formatCurrencyNumber({ value: amountParameters?.availableAmount || 0 })}`
      }
      if (!isArbitrableAmountValid) {
        errorMessage = `${formatMessage({
          id: 'Arbitrage_modale_msgerreur'
        })}${formatCurrencyNumber({ value: amountParameters?.arbitrableAmount || 0 })}`
      }
      if (!isReivestmentPercentageValid) {
        errorMessage = formatMessage({
          id: 'Arbitrage_modale_msgerreurPourcentage',
          values: {
            pourcentageInvestir: formatPercentNumber({ value: 1 ?? 0 })
          }
        })
      }

      if (!isReivestmentPercentageMaxValid) {
        errorMessage = formatMessage({
          id: 'Arbitrage_modale_msgerreurPourcentage',
          values: {
            pourcentageInvestir: formatPercentNumber({
              value: (amountParameters?.maxPercent && amountParameters?.maxPercent) || 0
            })
          }
        })
      }
      setAmountError(errorMessage)
    } else {
      setAmountError(undefined)
    }

    // update state if changed
    if (isAmountValid !== isAmountValidTest) {
      setIsAmountValid(isAmountValidTest)
    }
  }

  const renderAvailabilityData = useMemo(() => {
    switch (dueDateOption?.code) {
      case AVAILABILITY_TYPE.AVAILABLE: {
        return `${formatMessage({ id: 'MaturityArbitrageFeatureAvailableNow' })} : `
      }
      case AVAILABILITY_TYPE.UNAVAILABLE: {
        return `${formatMessage({
          id: 'MaturityArbitrageFeatureAvailableAtMaturity'
        })} ${formatDate({
          value: dueDateOption?.date,
          options: {
            dateStyle: 'short'
          }
        })} : `
      }
      case AVAILABILITY_TYPE.RETIREMENT: {
        return `${formatMessage({ id: 'MaturityArbitrageFeatureAvailableForRetirement' })} : `
      }
      default:
        return ''
    }
  }, [dueDateOption])

  // local data constructions
  const getDetailsParameterList = () => {
    const parametersLabels = []
    // Build details parameter list from modal parameter ( min , max, ...)
    if (typeof amountParameters?.min === 'number') {
      parametersLabels.push(
        formatMessage({
          id: 'VV_repartition_threshold_label',
          values: { montant: amountParameters.min }
        })
      )
    }
    if (typeof amountParameters?.max === 'number') {
      parametersLabels.push(
        formatMessage({
          id: 'VV_repartition_ceiling_label',
          values: { montant: amountParameters.max }
        })
      )
    }
    if (amountParameters?.ucMin) {
      parametersLabels.push(formatMessage({ id: 'VV_repartition_edit_UC_threshold_label' }))
    }
    if (amountParameters?.availableAmount) {
      parametersLabels.push(
        `${formatMessage({
          id: 'Remboursement_modale_MontantSupport'
        })} ${formatCurrencyNumber({
          value: amountParameters.availableAmount
        })}`
      )
    }
    if (amountParameters?.sharesCount) {
      parametersLabels.push(
        `${formatMessage({
          id: 'Remboursement_modale_nbPartsSupport'
        })} ${formatShareCountNumber({ value: amountParameters.sharesCount })}`
      )
    }

    if (amountParameters?.arbitrableAmount) {
      parametersLabels.push(
        `${formatMessage({
          id: 'Arbitrage_Modale_montantarbitrable'
        })} ${renderAvailabilityData.toLowerCase()}${formatCurrencyNumber({
          value: amountParameters.arbitrableAmount
        })}`
      )
    }

    if (
      amountParameters?.reinvestablePercentage ||
      amountParameters?.reinvestablePercentage === 0
    ) {
      parametersLabels.push(
        `${formatMessage({
          id: 'Arbitrage_reinvest_resteReinvest'
        })} ${formatPercentNumber({
          value: amountParameters.reinvestablePercentage ?? 0
        })}`
      )
    }

    return parametersLabels
  }
  const detailsParameters = getDetailsParameterList()
  return {
    detailsParameters,
    handleAmountChange,
    handleResetCalculatedContribution,
    handleContributionCalculPressed,
    handleValidPressed,
    handleCancelPressed,
    isAmountValid,
    amountError
  }
}
