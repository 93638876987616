import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { NotificationIcon, NotificationIconTypeEnum, SquareIconSizeEnum, Text, Title, FontIcon } from '@ere-uilib/atoms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { getStyles } from './styles';
import { Props } from './types';

export const NotificationWrapper: React.FC<Props> = ({
  type = NotificationIconTypeEnum.INFO,
  text,
  title,
  isExpandable,
  children,
  isLarge,
  textContainerStyle,
  headerStyle,
  chevronStyle,
  mainStyle,
  
}: Props) => {
  const theme = useTheme();
  const isMobile = useScreenSizes().isMobile;
  const screenWidth = useScreenSizes().windowWidth;
  const [expanded, setExpanded] = useState(false);

  const styles = useStyles({
    isLarge,
    isMobile,
    screenWidth,
    theme,
  },
  {
    textContainerStyle,
    headerStyle,
    chevronStyle,
    mainStyle,
  });

  return (
    <View style= {styles.mainStyle}>
      <View style={{ width: '100%' }}>
        <TouchableOpacity
          onPress={()=> isExpandable && setExpanded(!expanded)}
        >

          <View style={styles.headerStyle} >
            <NotificationIcon
              size={SquareIconSizeEnum.SMALL}
              type={type}
            />
            {title && <Title
              style={styles.titleStyle}
              variant={'t7'}>{title}</Title>}
            {isExpandable &&
            <FontIcon
              color={theme.colors.basics.black}
              name={expanded ? 'chevron-haut' : 'chevron-bas'}
              size={theme.metrics.iconSizes.xs}
              style={styles.chevronStyle}
            />
            }
          </View>
        </TouchableOpacity>
      </View>
      { (!isExpandable || (isExpandable && expanded)) && (
        <View style={styles.textContainerStyle}>

          {text && <Text
            variant={'t3'}
            weight="light">{text}</Text>}
          {children}
        </View>
      )}

    </View>
  );
};

const useStyles = createUseStyles(getStyles);
