import React from 'react';
import {
  View,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import { Title, Paragraph } from '@ere-uilib/atoms';
import { FranceConnectComponent } from '@ere-uilib/organisms';
import { createUseStyles, useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@translations/index';
interface Props {
  children?: React.ReactNode
  onHelpPressed: () => void
  onFranceConnectPressed: () => void
  EnableFeatureFranceConnect?: boolean
  title: string
  subtitle: string
}

type IdentityValidationStylesProps = {
  containerStyle?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<TextStyle>
  subtitleStyle?: StyleProp<TextStyle>
  supportStyle?: StyleProp<TextStyle>
  separatorContainer?: StyleProp<ViewStyle>
  separator?: StyleProp<ViewStyle>
  separatorTitle?: StyleProp<TextStyle>
};

type StylesContext = { theme: ThemeType; isMobile: boolean, isTablet: boolean };

export const IdentityValidation: React.FC<Props & IdentityValidationStylesProps> = ({
  subtitleStyle,
  titleStyle,
  supportStyle,
  children,
  onHelpPressed,
  onFranceConnectPressed,
  EnableFeatureFranceConnect,
  title,
  subtitle,
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles(
    { theme, isMobile, isTablet },
    { titleStyle, subtitleStyle, supportStyle },
  );
  return (
    <View style={styles.containerStyle}>
      <Title
        style={styles.titleStyle}
        variant="t3"
        weight="bold">
        {title}
      </Title>
      <Paragraph
        style={styles.subtitleStyle}
        variant="t2">
        {subtitle}
      </Paragraph>
      {children}
      <TouchableOpacity onPress={onHelpPressed}>
        <Paragraph
          style={styles.supportStyle}
          variant="t3">
          {formatMessage({ id: 'support_title' })}
        </Paragraph>
      </TouchableOpacity>
      {EnableFeatureFranceConnect &&
        <View>
          <View style={styles.separatorContainer}>
            <View style={styles.separator} />
            <Title
              style={styles.separatorTitle}
              variant="t3"
              weight="bold"
            >
              {formatMessage({ id: 'validate-identity_choice_label' })}
            </Title>
            <View style={styles.separator} />
          </View>
          <FranceConnectComponent onPress={onFranceConnectPressed} />
        </View>
      }
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: IdentityValidationStylesProps,
): IdentityValidationStylesProps => ({
  containerStyle: [
    style?.containerStyle,
  ],
  titleStyle: [
    {},
    style?.titleStyle,
  ],
  subtitleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.subtitleStyle,
  ],
  supportStyle: [
    {
      color: context?.theme.colors.text.link.c500,
      textDecorationColor: context?.theme.colors.text.link.c500,
      textDecorationLine: 'underline',
      marginVertical: context?.theme.metrics.spacing.l,
    },
    style?.supportStyle,
  ],
  separatorContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    flex: 1,
    height: 1,
    backgroundColor: context?.theme.colors.basics.black,
  },
  separatorTitle: {
    marginHorizontal: context?.theme.metrics.spacing.s,
  },
});

const useStyles = createUseStyles(getStyles);
