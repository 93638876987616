import { connect } from 'react-redux';

import {
  getProfileAddressRequest,
  getProfileUserInfosRequest
} from '@modules/profile/actions/profileActions';
import { AppState } from '@modules/reducers';
import { getProfilAddresses, getProfileUserInfos } from '@modules/profile/selectors';

import { EditProfileInformationsPage as EditProfileInformationsPageComponent } from './EditProfileInformationsPage.component';
import { mockProfilAddresses } from '@__mocks__/mockProfilAddresses';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes';

const mapStateToProps = (state: AppState) => {
  return {
    profileUserInformations: getProfileUserInfos(state),
    profilAddresses: getProfilAddresses(state),
    isLoading: state.isLoading[ProfileActionsType.GET_PROFILE_USER_INFOS_REQUEST] || state.isLoading[ProfileActionsType.GET_PROFILE_ADDRESS_REQUEST] || state.isLoading[DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST],
  };
};

const mapDispatchToProps = {
  onGetProfileUserInfosRequest: getProfileUserInfosRequest,
  onGetProfilAddressRequest: getProfileAddressRequest
};

export const EditProfileInformationsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfileInformationsPageComponent);
