import { connect } from 'react-redux';

import { ValidateIdentityActionsType } from '@modules/validate-identity/actions/validateIdentityActionsTypes';
import { validateEEREmailNewIdentifierRequest } from '@modules/validate-identity/actions/validateIdentityActions';
import { AppState } from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';
import { getIdentityValidationModuleError } from '@modules/validate-identity/selectors';

import { ChooseIdentificationMailPage as ChooseIdentificationMailPageComponent } from './ChooseIdentificationMailPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state),
    validateIdentityError: getIdentityValidationModuleError(state),
    isApiLoading: state.isLoading[ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_REQUEST],
    appSettings: getApplicationSettings(state),
  };
};

const mapDispatchToProps = ({
  validateEEREmailNewIdentifier: validateEEREmailNewIdentifierRequest,
});
export const ChooseIdentificationMailPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChooseIdentificationMailPageComponent);
