import React, { FC } from 'react';

import { ThemeType, useTheme } from '@ere-uilib/styles';

import { HtmlRendererProps } from './types';

export const HtmlRenderer: FC<HtmlRendererProps> = ({
  html
}) => {
  const theme = useTheme();

  const styleToInsert = `<style>
    .html-renderer-container strong {
      font-family:${theme.fonts.fontFamily.bold};
      font-weight:400;
    }
  </style>`;
  const htmlStyled: string = styleToInsert + html;
  return (
    <div
      style={styles(theme).containerStyle}
      className={'html-renderer-container'}
      dangerouslySetInnerHTML={{ __html: htmlStyled }}>
    </div>
  );
};

export const styles = (theme: ThemeType) => ({
  containerStyle: {
    height: '100%',
    width: '100%',
    fontFamily: theme.fonts.fontFamily.regular,
    fontSize: theme.fonts.fontSize.paragraph.t3,
    color: theme.colors.text.primary.c500,
  }
});

