import React, { useCallback, useRef, useState } from 'react';
import { View } from 'react-native';

import { DashboardPageWrapperConnected } from '@components/ERE360Components';
import {
  ArbitrationPercentageFieldModalConnectedRefType,
  ArbitrationPercentFieldModalConnected
} from '@components/ERE360Components/ArbitrationPercentFieldModalConnected';
import {
  CompareFundsModal,
  FundSheetModal,
  FundSheetModalDataType
} from '@components/templates';
import { RouteNames } from '@constants/navigation';
import { PlanFamillyList } from '@constants/index';
import { IconEnum } from '@ere-uilib/enums';
import {
  CardWithLeftThread, ColumnsContainer, OperationTotalSubHeader, SimpleButton, Text,
} from '@ere-uilib/index';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles'
import {
  DirectionEnum
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { useTranslation } from '@ere-uilib/translations';
import { ManagmentType, SupportType } from '@modules/common/types';
import { RefundRepartitionSupportsState } from '@modules/refund/available-refund/types';
import { constructStepperItems } from '@pages/Common/utils';

import { CompartmentCardHeader } from '../ArbitrationDisinvestmentsSavingPage/components';
import {
  ArbitrationHeader,
  Management, ModalConsentValidation, Support
} from '../components';
import { SourceSupportWrapper } from '../components/SourceSupportWrapper';
import { useDeinvestmentSource } from './hooks/useDeinvestmentSource';
import { useLabels } from './hooks/useLabels';
import { ArbitrationReinvestmentProps } from './interfaces';
import { getStyles } from './styles';
import { locator } from '@constants/locator';

export const ArbitrationReinvestment: React.FC<
  ArbitrationReinvestmentProps
> = ({
  navigation,
  selectedDispositif,
  onSelectInvestedSupport,
  onSelectInvestedManagement,
  selectedInvestedCompartment,
  investedTargetData,
  totalInvestedPercent,
  planCompartments,
}) => {
    const { formatMessage, getMessageRaw } = useTranslation();
    const labels = useLabels();
    const [displayCompareFundsModal, setDisplayCompareFundsModal] =
      useState(false);
    const percentModalRef =
      useRef<ArbitrationPercentageFieldModalConnectedRefType>(null);
    const [fundsToCompare, setFundsToCompare] = useState<
      RefundRepartitionSupportsState[] | undefined
    >();
    const [fundSheetModalData, setFundSheetModalData] =
      useState<FundSheetModalDataType | null>();
    const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);
    const [displayModalConsentValidation, setDisplayModalConsentValidation] =
      useState(false);
    const theme = useTheme();
    const { isMobile, isTablet } = useScreenSizes();
    const useStyles = createUseStyles(getStyles);
    const style = useStyles(
      {
        theme,
        isMobile: isMobile || isTablet,
        isTablet,
      },
      {}
    );

    const stepperItems = constructStepperItems({
      size: 3,
      direction: DirectionEnum.ROW,
      activeStep: 2,
    });

    const dispositif = selectedDispositif && selectedDispositif[0];
    const partialReallocation = dispositif?.filterProperties.PartialReallocation;
    const { sourceCompartment } = useDeinvestmentSource();
    const planColors = dispositif && theme.colors.basics.primary
    const compartment = selectedInvestedCompartment;
    const isVisibleCompartmentTitle = compartment?.label && compartment.label !== '';
    const investedPlanCompartment =
      investedTargetData && investedTargetData.compartments;
    const isVisibleManagementHeader =
      (investedPlanCompartment &&
        investedPlanCompartment[0].managements &&
        investedPlanCompartment[0].managements.length > 1 &&
        partialReallocation) ||
      !partialReallocation;

    const compartmentId = compartment?.code || '';
    const shouldDisplayAddedValue = dispositif?.planFamily !== PlanFamillyList.RC;

    const handleModalValidation = useCallback(() => {
      setDisplayModalConsentValidation(false);
      navigation.navigate(RouteNames.ArbitrationStack, {
        screen: RouteNames.ArbitrationSynthesis,
      });
    }, [navigation]);
    const renderBottomActions = () => (
      <View style={style.bottomActionsStyle}>
        <View style={style.bottomActionsButtonsContainerStyle}>
          <SimpleButton
            containerStyle={style.arrowButtonStyle}
            design="outlined"
            leftIcon={IconEnum.CHEVRON_LEFT}
            onPress={() => navigation.goBack()}
          />
          <SimpleButton
            containerStyle={style.simpleButtonStyle}
            design="solid"
            disabled={totalInvestedPercent !== 0}
            onPress={() => {
              setDisplayModalConsentValidation(true);
            }}
            size="small"
            title={labels.nextBtn}
            testId={locator._arbitration._confirm_new_allocation}
          />
        </View>
      </View>
    );
    const renderSubHeader = () => (
      <OperationTotalSubHeader
        isAmountPercent={true}
        mainAmount={totalInvestedPercent}
        mainTitle={labels.reinvestTotal}
      />
    );

    const RenderHeader = () => {
      return (
        <>
          <ArbitrationHeader
            displayCloseButton
            helpPoint
            helpPointContentHtml={labels.helpContent}
            helpPointModalTitle={labels.helpTitle}
            helpPointPosition="left"
            stepperItems={stepperItems}
            title={labels.pageTitle}
          />
          {(isMobile || isTablet) && renderSubHeader()}
        </>
      );
    };

    return (
      <DashboardPageWrapperConnected
        cardContentStyle={style.dashboardWrapper}
        renderStickyMobileBottom={() => renderBottomActions()}
      >
        <ColumnsContainer
          renderHeader={RenderHeader}
          renderRightDesktopColumn={renderSubHeader}

        >
          <Text
            variant="t3"
            weight="light">
            {labels.deinvestTitle}
          </Text>
          <View style={style.cardContainer}>
            <CardWithLeftThread
              addShadow={true}
              backgroundColor={planColors?.c500}
              borderLeftColor={planColors?.c500}
            >
              <>
                <CompartmentCardHeader
                  backGroundColorCustom={planColors?.c500}
                  compartmentTitle={
                    isVisibleCompartmentTitle ? compartment?.label : null
                  }
                  dispositifTitle={dispositif?.name}
                  key={`compartment${compartmentId}`}
                />
                {sourceCompartment && sourceCompartment.managements && sourceCompartment.managements.map(management => (
                  <Management
                    fieldAmountPercent={management?.advancedProperties?.PourcentageRepartition ?? 0 * 100}
                    isFieldAmountPercentage={true}
                    backGroundColorCustom={planColors?.c100}
                    canCompareFunds={false}
                    isDisplayingExtraManagement={false}
                    isSupportMasterSelectable={false}
                    isVisibleManagementHeader={!!management?.label}
                    management={management}
                  >
                    {management.supports && (
                      <SourceSupportWrapper
                        isPartialRealocation={partialReallocation}
                        management={management}
                        navigation={navigation}
                        shouldDisplayAddedValue={shouldDisplayAddedValue}
                        supports={management.supports}
                      />
                    )}
                  </Management>
                ))}
              </>
            </CardWithLeftThread>
          </View>
          <Text
            variant="t3"
            weight="light">
            {labels.reinvestTitle}
          </Text>
          <View style={style.cardContainer}>
            <CardWithLeftThread
              addShadow={true}
              backgroundColor={planColors?.c500}
              borderLeftColor={planColors?.c500}
            >
              <>
                <CompartmentCardHeader
                  backGroundColorCustom={planColors?.c500}
                  compartmentTitle={
                    isVisibleCompartmentTitle ? compartment?.label : null
                  }
                  dispositifTitle={dispositif?.name}
                  key={`compartment${compartmentId}`}
                />
                {investedPlanCompartment &&
                  investedPlanCompartment[0]?.managements.map(
                    (management: ManagmentType) => {
                      const managementId = management.id;
                      const masterSupportAmount: number =
                        management.supports.filter(
                          (support: { isMaster: any }) => support.isMaster
                        )[0]?.investedFormValues?.amountPercent || 0;
                      const fieldAmountpercent =
                        management?.investedFormValues?.amountPercent ||
                        masterSupportAmount;

                      const isLinkUnderfieldLabel =
                        totalInvestedPercent === 100
                          ? formatMessage({
                            id: 'Arbitrage_modaleDesinvest_ToutReinvest',
                          })
                          : totalInvestedPercent < 100 && totalInvestedPercent > 0
                            ? formatMessage({ id: 'Arbitrage_reinvest_lienreinvest' })
                            : undefined;
                      const isPartialReallocation =
                        dispositif?.filterProperties.PartialReallocation;
                      const isFreeManagement = management.isFree;
                      const isSupportMasterSelectable =
                        !isFreeManagement && isPartialReallocation;
                      const isDisplayingExtraManagement = !isFreeManagement;
                      return (
                        <Management
                          backGroundColorCustom={planColors?.c100}
                          canCompareFunds={management && management.supports.length > 1}
                          cardTitle={labels.managementTitle}
                          fieldAmountPercent={fieldAmountpercent}
                          isDisplayingExtraManagement={
                            isDisplayingExtraManagement
                          }
                          isExtraManagementEditable
                          isFieldAmountPercentage
                          isManagementSelectable={false}
                          isSupportMasterSelectable={false}
                          isVisibleManagementHeader={isVisibleManagementHeader}
                          linkUnderfieldLabel={isLinkUnderfieldLabel}
                          management={management}
                          onEditMasterAmountPress={() => {
                            const reinvestablePercentage = fieldAmountpercent + totalInvestedPercent;
                            const description = labels.getReinvestDescription(reinvestablePercentage);
                            percentModalRef.current?.openWithData({
                              displayData: {
                                title:
                                  dispositif?.name + ' - ' + management.label ||
                                  '',
                                descriptions: [description],
                                initialAmount: fieldAmountpercent,
                                amountParameters: {
                                  reinvestablePercentage,
                                  maxPercent:
                                    fieldAmountpercent + totalInvestedPercent,
                                },
                                allAmountValue:
                                  fieldAmountpercent + totalInvestedPercent,
                                allAmountLabel: labels.reinvestAll
                              },

                              compartmentId,
                              managementId,
                            });
                          }}
                          onLinkUnderFieldPress={() => {
                            onSelectInvestedManagement({
                              amountPercent:
                                fieldAmountpercent + totalInvestedPercent,
                              compartmentId: compartmentId,
                              managementId: managementId,
                            });
                          }}
                          onSelectChange={() => { }}
                          onSelectSupportMasterChange={() => {
                            // TODO Handle suppoert master change
                          }}
                        >
                          <View>
                            {management.supports.map((support: SupportType, index) => {
                              const isManagementFree = management.isFree;
                              //const isSupportDisabled = !support.filterProperties?.StockReallocation; // @todo: ask backend developers to how disable supports or if support has to be disabled
                              support.investedFormValues?.isSelected;
                              const percentAmount =
                                support.investedFormValues?.amountPercent;
                              const supportAmount = !isManagementFree
                                ? undefined
                                : support.amount.amount;

                              const linkUnderfieldLabel =
                                percentAmount === 0 || !percentAmount
                                  ? formatMessage({
                                    id: 'Arbitrage_modaleDesinvest_ToutReinvest',
                                  })
                                  : formatMessage({
                                    id: 'Arbitrage_reinvest_lienreinvest',
                                  });
                              return (
                                <Support
                                  isDisabledSupport={false}
                                  isFieldAmountPercentage
                                  isInputEditable={isManagementFree}
                                  isPercentageField
                                  isSupportSelectable={false}
                                  linkUnderfieldLabel={isLinkUnderfieldLabel}
                                  navigation={navigation}
                                  onEditAmountPress={() => {
                                    const reinvestablePercentage =
                                      (support?.investedFormValues?.amountPercent || 0) +
                                      totalInvestedPercent
                                    const description = labels.getReinvestDescription(reinvestablePercentage);
                                    percentModalRef.current?.openWithData({
                                      displayData: {
                                        title:
                                          dispositif?.name +
                                          ' - ' +
                                          management?.label || '',
                                        descriptions: [description],
                                        initialAmount:
                                          support?.investedFormValues
                                            ?.amountPercent || undefined,
                                        contentTitle:
                                          support.supportName?.toUpperCase(),
                                        amountParameters: {
                                          reinvestablePercentage,
                                          maxPercent:
                                            (support?.investedFormValues
                                              ?.amountPercent || 0) +
                                            totalInvestedPercent,
                                        },
                                        allAmountValue:
                                          (support?.investedFormValues
                                            ?.amountPercent || 0) +
                                          totalInvestedPercent,
                                        allAmountLabel: labels.reinvestAll
                                      },
                                      supportIsIn: support.supportIsin,
                                      compartmentId,
                                      managementId,
                                    });
                                  }}
                                  onLinkUnderFieldPress={() => {
                                    // TODO handle click on reinvest rest / all link
                                  }}
                                  onSelectChange={() =>
                                    onSelectInvestedSupport({
                                      selectState: true,
                                      managementId,
                                      supportIsin: support.supportIsin,
                                    })
                                  }
                                  shouldDisplayAddedValue={shouldDisplayAddedValue}
                                  support={support}
                                  supportAmount={supportAmount}
                                  supportIndexTestId={`${index}`}
                                />
                              );
                            })}
                          </View>
                        </Management>
                      );
                    }
                  )}
              </>
            </CardWithLeftThread>
          </View>
          {fundSheetModalData && (
            <FundSheetModal
              id={fundSheetModalData?.id}
              modalIsVisible={displayFundSheetModal}
              navigation={navigation}
              onCloseModal={() => {
                setDisplayFundSheetModal(false);
                setFundSheetModalData(null);
              }}
              title={fundSheetModalData?.title}
            />
          )}
          {!isMobile && !isTablet && renderBottomActions()}
          <ArbitrationPercentFieldModalConnected ref={percentModalRef} />
        </ColumnsContainer>
        <CompareFundsModal
          fundsToCompare={fundsToCompare?.map(fund => {
            return {
              isinCode: fund.supportIsin,
              supportName: fund.supportName,
              addedValue: 0,
              hasContribution: fund.hasContribution,
              amount: fund.amount.amount,
              hasNoFundSheet: fund.hasNoFundSheet,
              source: fund.source
            };
          })}
          modalIsVisible={displayCompareFundsModal}
          onCloseModal={() => {
            setDisplayCompareFundsModal(false);
          }}
        />
        <ModalConsentValidation
          modalIsVisible={displayModalConsentValidation}
          onModalClose={() => {
            setDisplayModalConsentValidation(false);
          }}
          onModalValidation={handleModalValidation}
        />
      </DashboardPageWrapperConnected>
    );
  };
