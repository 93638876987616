import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

import { CardStepperArrow } from "./CardStepperArrow";
import { CardStepperMeasure, CardStepperStep } from "./CardStepperStep";
import { CardStepperProps } from "./interfaces";
import { useStyles } from "./styles";

export const CardStepper: React.FC<CardStepperProps> = ({
  style,
  stepSpacing: propStepSpacing,
  steps
}) => {
  const theme = useTheme()
  const stepSpacing = useMemo(() => {
    return propStepSpacing || theme.metrics.spacing.s;
  }, [propStepSpacing, theme])
  const styles = useStyles({ style, stepSpacing });
  const [arrowMeasures, setArrowMeasures] = useState<CardStepperMeasure[]>([])
  
  useEffect(() => {
    if(arrowMeasures.length > 0) {
      setArrowMeasures(prevMeasures => {
        const newMeasures = [...prevMeasures];
        newMeasures.forEach((measure, index) => {
          const isMatchingStep = !!steps?.[index];
          if (!isMatchingStep) newMeasures.splice(index, 1);
        })
        return newMeasures;
      })
    }
  }, [steps])

  const handleCardLayout = useCallback((measure: CardStepperMeasure, index: number) => {
    setArrowMeasures(prevMeasures => {
      const newMeasures = [...prevMeasures];
      newMeasures[index] = measure;
      return newMeasures;
    })
  }, [])

  const renderSteps = useCallback(() => {
    return steps.map((step, index) => {
      const isLast = index === (steps.length - 1)
      return <CardStepperStep
        key={`stepper-item-${index}`}
        onChangeMeasure={measure => {
          handleCardLayout(measure, index)
        }}
        step={step}
        style={!isLast && styles.stepNotLast}
      />
    });
  }, [
    steps,
    styles,
    handleCardLayout
  ])

  const renderArrows = useCallback(() => {
    return arrowMeasures.map((arrowMeasure, index) => {
      const color = steps[index]?.arrowColor;

      const nextArrowMeasure = arrowMeasures?.[index + 1]
      if (!nextArrowMeasure) {
        return null
      }
      const arrowSpacing = theme.metrics.spacing.s
      let cardTopStart = 0
      for (let loopIndex = 0; loopIndex < index; loopIndex++) {
        const loopArrow = arrowMeasures[loopIndex]
        if(!loopArrow) continue;
        cardTopStart += loopArrow.mainContentHeight
          + loopArrow.secondaryContentHeight
          + stepSpacing;
      }
      const top = cardTopStart + (arrowMeasure?.indicatorTop || 0) + (arrowMeasure?.indicatorHeight || 0) + arrowSpacing
      const height =
        (arrowMeasure?.mainContentHeight || 0) / 2
        + stepSpacing
        + (nextArrowMeasure?.mainContentHeight || 0) / 2
        + (arrowMeasure?.secondaryContentHeight || 0)
        - arrowMeasure?.indicatorHeight
        - arrowSpacing * 2
      return (
        <CardStepperArrow
          color={color}
          key={index}
          length={height}
          offset={top}/>
      )
    })
  }, [
    steps, 
    arrowMeasures, 
    stepSpacing,
    theme
  ])

  return (
    <View style={styles.container}>
      {renderSteps()}
      {renderArrows()}
    </View>
  );
}

