import React, { useState } from 'react';
import { FontIcon, Text, Pressable } from '@ere-uilib/atoms';

import { useTheme, ThemeType } from '@ere-uilib/styles';
import { Props } from './interfaces';
import { useStyles } from './styles';

export const DesktopMenuIconButton: React.FC<Props> = ({
  isSelected,
  tabIcon,
  tabTitle,
  onPress,
  testId,
  textStyle,
  containerStyle
}) => {

  const [hovered, setHovered] = useState<boolean>(false);

  const theme = useTheme();
  const styles = useStyles(isSelected, hovered);


  return (
    <Pressable
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onPress={onPress}
      style={[styles.containerStyle]}>
      <FontIcon
        color={theme.colors.basics.black}
        name={tabIcon}
        size={theme.metrics.iconSizes.m}
        style={styles.iconStyle}
      />
      <Text
        style={styles.textStyle}
        weight="light"
        testID={testId}>{tabTitle}
      </Text>
    </Pressable>
  );
};
