import React, { useContext } from 'react';

import { LinkBar } from '@ere-uilib/molecules';
import { LinkBarProps } from '@ere-uilib/molecules/LinkBar/interface';

import { TabsContext } from './TabsContext';

interface TabsProps extends Omit<LinkBarProps, 'items' | 'selectedItem' | 'onSelect'> {
  tabs: string[];
}

export const Tabs: React.FC<TabsProps> = ({ tabs, ...props }) => {
  const { activeTab, onChangeTab: handleChangeTab } = useContext(TabsContext);

  return (
    <LinkBar
      {...props}
      items={tabs}
      onSelect={handleChangeTab}
      selectedItem={activeTab}
    />
  );
};
