import React from 'react';
import { View, ScrollView } from 'react-native';

import { Modal, SVGLocalLoader, Title } from '@components/index';
import { ModalFrame } from '@ere-uilib/molecules';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';

import { ModalWithHtmlContentProps } from './interface';
import { getStyles } from './styles';

export const ModalWithHtmlContent: React.FC<ModalWithHtmlContentProps> = ({
  display,
  onClose,
  title,
  containerStyle,
  logoStyle,
  contentContainerStyle,
  innerScrollStyle,
  titleStyle,
  renderHtmlContent
}) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useStyles(
    { theme, isMobile },
    {
      containerStyle,
      logoStyle,
      contentContainerStyle,
      innerScrollStyle,
      titleStyle,
    }
  );

  return (
    <Modal
      visible={display}
    >
      <ModalFrame
        desktopPosition="fullHeight"
        maxDesktopWidth={1030}
        mobilePosition="full"
        onClose={onClose}>
        <ScrollView>
          <View style={styles.containerStyle}>
            {!isMobile && (
              <View style={styles.logoStyle}>
                <SVGLocalLoader name="logoHorizontalDark" />
              </View>
            )}
            <View style={styles.contentContainerStyle}>
              <Title
                style={styles.titleStyle}
                variant="t4"
                weight="bold"
              >
                {title}
              </Title>
              <ScrollView contentContainerStyle={styles.innerScrollStyle}>
                {renderHtmlContent?.()}
              </ScrollView>
            </View>
          </View>
        </ScrollView>
      </ModalFrame>
    </Modal>
  );
};

const useStyles = createUseStyles(getStyles);
