import React, { useMemo } from 'react';
import { Svg } from 'react-native-svg';
import { VictoryPie } from 'victory-native';
import { VictoryPieProps } from 'victory-pie';

import { colors } from '@ere-uilib/styles/theme/themeFolder';

export interface GaugeProps
  extends Omit<
    VictoryPieProps,
    | 'cornerRadius'
    | 'innerRadius'
    | 'data'
    | 'startAngle'
    | 'endAngle'
    | 'standalone'
    | 'width'
    | 'height'
  > {
  value: number;
  thickness?: number;
  fillColor?: string;
  backgroundColor?: string;
  size?: number;
}

export const Gauge: React.FC<GaugeProps> = ({
  value,
  fillColor = colors.amounts.positive.c500,
  backgroundColor = colors.basics.grey.c200,
  thickness = 15,
  size = 300,
  labels = () => null,
  ...props
}) => {
  const { radius, innerRadius, borderRadius } = useMemo(() => {
    const radius = size * 0.5;
    const innerRadius = radius - thickness;
    const borderRadius = thickness * 0.5;
    return {
      radius,
      innerRadius,
      borderRadius
    };
  }, [thickness, size]);

  return (
    <Svg
      height={radius}
      width={size}>
      <VictoryPie
        colorScale={[backgroundColor]}
        cornerRadius={borderRadius}
        data={[{ x: 0, y: 1 }]}
        endAngle={90}
        height={size}
        innerRadius={innerRadius}
        labels={labels}
        radius={radius}
        standalone={false}
        startAngle={-90}
        width={size}
      />
      <VictoryPie
        colorScale={[fillColor, 'transparent']}
        cornerRadius={borderRadius}
        data={[
          { x: 0, y: value },
          { x: 1, y: 1 - value }
        ]}
        endAngle={90}
        height={size}
        innerRadius={innerRadius}
        labels={labels}
        radius={radius}
        standalone={false}
        startAngle={-90}
        width={size}
        {...props}
      />
    </Svg>
  );
};
