import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
 
import { SavingsNavigatorInterface, SavingsNavigatorRouteNames } from '@navigation/Interfaces/SavingsNavigatorInterface';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import { FundSheetPage, SavingsPage, VVPListPage } from '@pages/index';

const SavingsStack = createStackNavigator<SavingsNavigatorInterface>();

export const SavingsStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  return (
    <SavingsStack.Navigator
      initialRouteName={SavingsNavigatorRouteNames.Savings}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        cardStyle: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <SavingsStack.Screen
        component={SavingsPage}
        name={SavingsNavigatorRouteNames.Savings}
        options={defaultScreensOptions.Savings}
      />
      <SavingsStack.Screen
        component={VVPListPage}
        name={SavingsNavigatorRouteNames.VVPList}
        options={defaultScreensOptions.VVPList}
      />
      <SavingsStack.Screen
        component={FundSheetPage}
        name={SavingsNavigatorRouteNames.funds}
        options={defaultScreensOptions.funds}
      />
    </SavingsStack.Navigator>
  );
};