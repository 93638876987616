import React, { ReactNode } from 'react'
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native'

import { Title, FontIcon } from '@ere-uilib/atoms'
import { useTheme, ThemeType, createUseStyles } from '@ere-uilib/styles'

interface LoaderCardProps {
  title?: string
  children: ReactNode
  loaderContainerStyle?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  isMobile?: boolean
}
interface LoaderCardStyle {
  titleContainer?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<TextStyle>
  containerStyle?: StyleProp<ViewStyle>
  loaderContainerStyle?: StyleProp<ViewStyle>

}

type StylesContext = { theme: ThemeType, isMobile?: boolean }

export const LoaderCard: React.FC<LoaderCardProps> = ({
  title,
  children,
  loaderContainerStyle,
  containerStyle,
  isMobile
}) => {
  const theme = useTheme();

  const styles = useStyles(
    { theme, isMobile },
    {
      loaderContainerStyle,
      containerStyle
    }
  )

  return (
    <View style={styles.containerStyle}>
      {title && <View style={styles.titleContainer}>
        <FontIcon
          color={theme.colors.basics.grey.c200}
          name={'info'}
          size={theme.metrics.iconSizes.mplus}
        />
        <Title
          style={styles.titleStyle}
          variant="t6"
          weight="bold"
        >
          {title}
        </Title>
      </View>}
      <View style={styles.loaderContainerStyle}>
        {children}
      </View>
    </View>
  )
}

const getStyles = (
  context?: StylesContext,
  style?: LoaderCardStyle
): LoaderCardStyle => ({
  titleContainer: [
    {
      flexDirection: 'row',
      marginBottom: context?.theme.metrics.spacing.xl
    },
    style?.titleContainer
  ],
  titleStyle: [
    {
      color: context?.theme.colors.basics.grey.c400,
      marginLeft: context?.theme.metrics.spacing.m
    },
    style?.titleStyle
  ],
  containerStyle: [
    context?.isMobile ?
      {
        marginHorizontal: context?.theme.metrics.spacing.s,
        marginVertical: context?.theme.metrics.spacing.l
      }
      :
      {
        margin: context?.theme.metrics.spacing.l
      },
    style?.containerStyle
  ],
  loaderContainerStyle: [
    {
    },
    style?.loaderContainerStyle
  ]
})

const useStyles = createUseStyles(getStyles)