import axios from "axios";

import { API_URL } from "@config/index";
import {
  ProfitSharingIncentiveTypeEnum
} from "@constants/index"
import {
  ProfitSharingIncentiveSubmitAllocationParamsType,
  ProfitSharingIncentiveSubmitAllocationResponseType
} from "@modules/profit-sharing-incentive/types"

export async function fetchProfitSharingIncentiveInitData({
  companyId,
  type
}:{
  companyId: string,
  type: ProfitSharingIncentiveTypeEnum
}): Promise<{}> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/bulletin-options?type=${type}`);
}

export async function fetchProfitSharingIncentiveRepartition({
  companyId,
  type
}:{
  companyId: string,
  type: ProfitSharingIncentiveTypeEnum
}): Promise<{}> {
  return axios.post(`${API_URL}/api/v1/companies/${companyId}/plans/${type}/profitsharingandincentive`);
}

export async function fetchProfitSharingIncentiveSubmitAllocation({
  companyId,
  params
}:ProfitSharingIncentiveSubmitAllocationParamsType
): Promise<ProfitSharingIncentiveSubmitAllocationResponseType> {
  return axios.post(
    `${API_URL}/api/v1/demands/companies/${companyId}/bulletinallocation`,
    JSON.stringify(params)
  );
}
