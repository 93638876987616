import { DashboardPageWrapperConnected } from '@components/ERE360Components';
import { RouteNames } from '@constants/navigation';
import {
  ColumnsContainer,
  RetryActionPlaceHolder,
  SimpleButton,
  Spinner,
  useTranslation
} from '@ere-uilib/index';
import { DocumentCategory } from '@modules/document-space/types';
import { useNavigation } from '@react-navigation/native';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { View } from 'react-native';
import { DocumentSpaceHeader } from '../components';
import { DocumentFilterContent } from '../components/DocumentFilterContent';
import { getSelectedFilesByCategories } from '../utils';
import { GeneralDocumentsProps } from './interfaces';
import { useStyles } from './useStyles';



export const GeneralDocuments: React.FC<GeneralDocumentsProps> = ({
  categories,
  files,
  isLoadingCategories,
  categoriesError,
  onGetDocumentsCategories,
  onSetDocumentsCategoriesFilter,
  onSetDocumentsCategoriesReset,
  onSetAllDocumentsCategoriesFilter
}) => {
  const [documentsNumber, setDocumentsNumber] = useState(0);
  const [availableCategories, setAvailableCategories] = useState(categories);
  const navigation = useNavigation();
  const { formatMessage, getMessageRaw } = useTranslation();
  const style = useStyles();

  const selectedCategories = useMemo(() => categories.filter(category => !!category.isSelected), [categories]);
  const resultsBottomActionEnabled = selectedCategories.length > 0;

  const resetAll = useCallback(() => {
    onSetDocumentsCategoriesReset()
    onGetDocumentsCategories();
    setAvailableCategories(categories);
  }, [categories, onGetDocumentsCategories, onSetDocumentsCategoriesReset]);

  useEffect(() => {
    onGetDocumentsCategories();
  }, [onGetDocumentsCategories]);

  const setCategoryToFilter = (category: DocumentCategory) => {
    onSetDocumentsCategoriesFilter(category.id, !!category.isSelected);
  };

  useEffect(() => {
    const matchingSelectedDocuments = getSelectedFilesByCategories(categories, files);
    setDocumentsNumber(matchingSelectedDocuments.length);
    setAvailableCategories(categories);
  }, [categories, documentsNumber, files]);

  const redirectToFilesPage = useCallback(() => {
    navigation.navigate(RouteNames.DocumentSpaceStack, {
      screen: RouteNames.DocumentSpaceGeneralDocumentsFiles
    });
  }, [navigation]);

  //   TO DO SET TAG TRACKER
  const renderBottomResultsActions = () => (
    <View style={style.bottomActionsStyle}>
      <View style={style.bottomActionsButtonsContainerStyle}>
        <SimpleButton
          containerStyle={style.simpleButtonLeftStyle}
          design="outlined"
          onPress={resetAll}
          size="large"
          title={formatMessage({ id: 'DocCenterReinitiateButton' })}
        />
        <SimpleButton
          containerStyle={style.simpleButtonRightStyle}
          design="solid"
          onPress={redirectToFilesPage}
          size="large"
          title={`${formatMessage({ id: 'DocCenterResultatButton' })} (${documentsNumber})`}
        />
      </View>
    </View>
  );

  const renderBottomDefaultActions = () => (
    <View style={style.bottomActionsStyle}>
      <View style={style.bottomActionsButtonsContainerStyle}>
        <SimpleButton
          containerStyle={style.simpleButtonStyle}
          design="solid"
          disabled={files.length === 0}
          onPress={() => {
            onSetAllDocumentsCategoriesFilter()
            redirectToFilesPage()
          }}
          size="large"
          title={formatMessage({
            id: 'DocCenterSeeAllButton',
            values: { variable: files.length }
          })}
        />
      </View>
    </View>
  );

  const RenderHeader = () => {
    return (
      <DocumentSpaceHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'DocCenterInfoContenu' })}
        helpPointModalTitle={formatMessage({ id: 'DocCenterInfoTitre' })}
        helpPointPosition="left"
        title={formatMessage({ id: 'TermsetFrontActionMenuMesDocuments' })}
      />
    );
  };

  const submit = useCallback(
    () =>
      resultsBottomActionEnabled && documentsNumber > 0
        ? renderBottomResultsActions()
        : renderBottomDefaultActions(),
    [
      documentsNumber,
      renderBottomDefaultActions,
      renderBottomResultsActions,
      resultsBottomActionEnabled
    ]
  );

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={style.dashboardWrapper}
      renderStickyMobileBottom={
        !isLoadingCategories && !categoriesError?.message ? submit : undefined
      }>
      <ColumnsContainer
        containerStyle={style.containerStyle}
        hasColumnCenter
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}
      >
        {!isLoadingCategories && categoriesError?.message && <RetryActionPlaceHolder
          retryAction={() => { onGetDocumentsCategories() }}
        />}
        {!isLoadingCategories && !categoriesError?.message ? <DocumentFilterContent
          availableCategories={availableCategories}
          renderBottomActions={submit}
          setCategoryToFilter={setCategoryToFilter}
        /> : <View>
          <Spinner />
        </View>}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
