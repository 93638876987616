import { connect } from 'react-redux';

import { setSelectedDispositif } from '@modules/installment/actions/installmentActions';
import { AppState } from '@modules/reducers';
import { getInstallmentElligibleDispositif } from '@modules/installment/selectors';

import { InstallmentPlanChoicePageComponent } from './InstallmentPlanChoicePage.component';

const mapStateToProps = (state: AppState) => ({
  dispositifs: getInstallmentElligibleDispositif(state)
});

const mapDispatchToProps = {
  onSelectDispositif: setSelectedDispositif
};

export const InstallmentPlanChoicePage = connect(mapStateToProps, mapDispatchToProps)(InstallmentPlanChoicePageComponent);
