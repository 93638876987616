import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import { Paragraph, Link } from '@ere-uilib/atoms';
import { FranceConnectButton } from '@ere-uilib/molecules/buttons/FranceConnectButton';
import { createUseStyles } from '@ere-uilib/styles';
import { ThemeType, useTheme } from '@ere-uilib/styles/theme';
import { useTranslation } from '@ere-uilib/translations';

interface Props {
  onPress: () => void
}

type StyleProps = {
  container?: StyleProp<ViewStyle>
  FranceConnectButtonContainer?: StyleProp<ViewStyle>
  franceConnectTextLink?: StyleProp<TextStyle>
  franceConnectExplanationText?: StyleProp<TextStyle>
};

type StylesContext = {theme: ThemeType};

export const FranceConnectComponent: React.FC<Props & StyleProps> = ({
  onPress, container
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(
    { theme }, { container }
  );
  return (
    <View style={styles.container}>
      <Paragraph
        style={styles.franceConnectExplanationText}
        variant="t3"
        weight="light"
      >
        {useTranslation().formatMessage({ id: 'login_fc-explanation_description' })}
      </Paragraph>
      <FranceConnectButton
        buttonContainer={styles.FranceConnectButtonContainer}
        onClick={onPress}
      />
      <Link
        onPress={() => window.open(formatMessage({ id: 'quescequeFranceConnect' }), '_blank')}
        textStyle={styles.franceConnectTextLink}
        textVariant="t3"
      >
        {formatMessage({ id: 'verify-identity_fc-explanation' })}
      </Link>
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: StyleProps,
): StyleProps => ({
  container: [{
    alignItems: 'center',
    marginTop: context?.theme.metrics.spacing.xm,
    marginBottom: context?.theme.metrics.spacing.s
  }, style?.container],
  FranceConnectButtonContainer: [{
    marginBottom: context?.theme.metrics.spacing.s
  }],
  franceConnectTextLink: [
    {
      textAlign: 'center',
      color: context?.theme.colors.franceConnect.link
    }
  ],
  franceConnectExplanationText: [
    {
      textAlign: 'center',
      marginBottom: context?.theme.metrics.spacing.s
    }
  ]
});

const useStyles = createUseStyles(getStyles);
