import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import {
  OfflineAnnounceLayout360,
  SimpleButton,
  useTranslation,
  LanguageSelector360,
} from '@components/index';
import { AuthConfigType } from '@modules/settings/types';
import { loginOpenId } from '@modules/auth/services';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { useLogoNavigation } from '@utils/index';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>
  authConfig: AuthConfigType
  route: {
    params: {
      errorTitleLabel: string,
      errorDescriptionLabel: string,
      errorButtonTitleLabel: string,
      redirectionRouteName: keyof AppNavigatorInterface,
      redirectionRouteParams: object | undefined
    }
  }
}

export const Error: React.FC<Props> = ({
  navigation,
  authConfig,
  route,
}: Props) => {

  const {
    errorTitleLabel = 'error_title',
    errorDescriptionLabel = 'generic_error_label',
    errorButtonTitleLabel = 'return_login_button',
    redirectionRouteName,
    redirectionRouteParams = undefined,
  } = route?.params || {};
  const { formatMessage } = useTranslation();
  const errorTitle = formatMessage({ id: errorTitleLabel });
  const errorDescription = formatMessage({ id: errorDescriptionLabel });
  const errorButtonTitle = formatMessage({ id: errorButtonTitleLabel });

  const customRedirection = () => navigation.replace(redirectionRouteName, redirectionRouteParams);

  const { logoNavigateTo } = useLogoNavigation();

  const renderActions = () => {
    return (
      <SimpleButton
        onPress={() => redirectionRouteName ? customRedirection() : loginOpenId(authConfig)}
        title={errorButtonTitle}
      />
    );
  };

  const renderLanguageSelector = () => <LanguageSelector360 offlineLayoutAbsolutePosition />;
  return (
    <OfflineAnnounceLayout360
      actions={renderActions}
      description={errorDescription}
      onLogoPress={logoNavigateTo}
      renderInOfflinePageLayoutEnd={renderLanguageSelector}
      sourceName="invitationError"
      title={errorTitle}
    />
  );
};
