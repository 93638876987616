import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native'

import {
  CardStepper,
  CardStepperStepStatus,
  CardStepperStepType,
  ColumnsContainer,
  DashboardPageWrapperConnected,
  FontIcon,
  NotificationSoft,
  Paragraph,
  SimpleButton,
  Text,
  Title,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index'
import { RouteNames } from "@constants/navigation";
import {
  ProfitSharingIncentiveAssigmentAmountsType,
  ProfitSharingIncentiveInitDataState,
} from '@modules/profit-sharing-incentive/types';
import { ApplicationSettingsState } from '@modules/settings/types';

import { useStyles } from './useStyles'
import { locator } from '@constants/locator';

interface ProfitSharingIncentiveSuccessPageProps {
  applicationSettings: ApplicationSettingsState;

  clearProfitSharingIncentiveData(): void;

  initData: ProfitSharingIncentiveInitDataState | null;
  assigmentAmounts: ProfitSharingIncentiveAssigmentAmountsType | null;
}

export const ProfitSharingIncentiveSuccessPage: React.FC<ProfitSharingIncentiveSuccessPageProps> = ({
  applicationSettings,
  initData,
  assigmentAmounts,
  clearProfitSharingIncentiveData
}) => {

  const navigation = useNavigation()
  const { isDesktop } = useScreenSizes()
  const theme = useTheme()
  const styles = useStyles()
  const {
    formatMessage,
    formatDate,
  } = useTranslation()

  const handleLeavePage = useCallback(() => {
    navigation.navigate(RouteNames.BottomTabNavigator, {
      screen: RouteNames.Home
    });
    clearProfitSharingIncentiveData?.()
  }, [
    navigation,
    clearProfitSharingIncentiveData
  ])

  const steps = useMemo(() => {
    const output: CardStepperStepType[] = []
    if (!initData) return output

    const isRefund = (assigmentAmounts?.refundAmount || 0) > 0
    const isReinvest = (assigmentAmounts?.reinvestAmount || 0) > 0

    const maxChangeDate = new Date(initData?.deadlineDate)
    const maxChangeDateText = formatDate({ value: maxChangeDate });
    
    const endAssigmentDateText = maxChangeDateText;

    const delayGlobalProcessingDayCount = applicationSettings.PIDelaiTraitementGlobal;
    const delayProcessingDayCount = applicationSettings.PIDelaiTraitement;

    const paymentDate = moment(initData?.deadlineDate)
    paymentDate.add(Number(delayGlobalProcessingDayCount), 'day');
    const paymentDateText = formatDate({ value: paymentDate.toDate() });

    output.push({
      status: CardStepperStepStatus.SUCCESS,
      title: formatMessage({ id: 'PIConfirmationOperationCardChoixTitre' }),
      renderMainContent: () => (
        <View style={styles.stepCustomContainer}>
          <NotificationSoft containerStyle={styles.stepNotification}>
            <Text
              variant="t3"
              weight="light"
            >
              {formatMessage({ id: 'PIConfirmationOperationCardChoixDateDescription' })}
              {' '}
              <Text
                variant="t3"
                weight="regular"
              >
                {maxChangeDateText}
              </Text>
            </Text>
          </NotificationSoft>
          <Paragraph
            variant='t3'
            weight='light'
          >
            {formatMessage({ id: 'AbritrageOADPageFinaleSuivreAvancementDescription' })}
          </Paragraph>
        </View>
      )
    })
    output.push({
      isDisabled: true,
      status: CardStepperStepStatus.SCHEDULED,
      title: formatMessage({ id: 'PIConfirmationOperationCardPeriodeAffectationTitre' }),
      description:
        formatMessage({ id: 'PIConfirmationOperationCardPeriodeAffectationLabel' }) +
        ' ' +
        endAssigmentDateText,
    })
    output.push({
      isDisabled: true,
      status: CardStepperStepStatus.SCHEDULED,
      title: formatMessage({ id: 'PIConfirmationOperationCardPeriodeTitre' }),
      renderMainContent: () => (
        <View style={styles.stepCustomContainer}>
          <Paragraph
            variant='t3'
            weight='light'
          >
            {formatMessage({
              id: 'PIConfirmationOperationCardPeriodeDelaiLabel',
              values: { jours_traitement: delayGlobalProcessingDayCount }
            })}
          </Paragraph>
          <Paragraph
            variant='t3'
            weight='light'
          >
            {formatMessage({ id: 'PIConfirmationOperationCardPeriodeDelai2Label', })}
          </Paragraph>
        </View>
      )
    })
    if (isReinvest) {
      output.push({
        isDisabled: true,
        status: CardStepperStepStatus.SCHEDULED,
        title: formatMessage({ id: 'PIConfirmationOperationCardTraitementOperationTitre' }),
        renderMainContent: () => (
          <View style={styles.stepCustomContainer}>
            <Paragraph
              variant='t3'
              weight='light'
            >
              {formatMessage({
                id: 'PIConfirmationOperationCardTraitementOperationDelaiLabel',
                values: { jour_invest: delayProcessingDayCount }
              })}
            </Paragraph>
            <Paragraph
              variant='t3'
              weight='light'
            >
              {formatMessage({ id: 'PIConfirmationOperationCardTraitementOperationDelai2Label', })}
            </Paragraph>
          </View>
        )
      })
    }
    if (isRefund) {
      output.push({
        isDisabled: true,
        status: CardStepperStepStatus.SCHEDULED,
        title: formatMessage({ id: 'PIConfirmationOperationCardEmissionPaiementTitre' }),
        description:
          formatMessage({ id: 'PIConfirmationOperationCardEmissionPaiementLabel' }) +
          ' ' +
          paymentDateText,
      })
    }
    output.push({
      isDisabled: true,
      status: CardStepperStepStatus.SCHEDULED,
      title: formatMessage({ id: 'AbritrageOADPageFinaleOperationTermineeDescription' }),
    })

    return output
  }, [
    initData,
    applicationSettings,
    assigmentAmounts,
    formatDate,
    formatMessage,
    styles
  ])

  const renderActions = useCallback(() => {
    return (
      <SimpleButton
        containerStyle={styles.button}
        onPress={handleLeavePage}
        title={formatMessage({ id: 'PIConfirmationOperationRetourAccueilBouton' })}
        testId={locator._pi._back_home}
      />
    )
  }, [
    handleLeavePage,
    formatMessage,
    styles
  ])

  const renderHeader = useCallback(() => {
    return (
      <View style={styles.headerContainer}>
        <View style={styles.headerMiddleContainer}>
          <Title
            variant="t4"
            weight="bold"
          >
            {formatMessage({ id: 'PIConfirmationOperationTitre' })}
          </Title>
        </View>
        <View style={styles.headerRightContainer}>
          <TouchableOpacity
            onPress={handleLeavePage}
            style={styles.headerCloseButton}
          >
            <FontIcon
              color={theme.colors.text.primary.c500}
              name="fermer"
              size={theme.metrics.iconSizes.xxm}
            />
          </TouchableOpacity>
        </View>
      </View>
    )
  }, [formatMessage, handleLeavePage, styles, theme])

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}
        renderStickyMobileBottom={renderActions}
      >
        <View style={styles.contentContainer}>
          <CardStepper
            steps={steps}
            stepSpacing={theme.metrics.spacing.xm}
            style={styles.stepper}
          />
          {isDesktop && renderActions()}
        </View>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
