import { useRoute } from '@react-navigation/core';
import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { ViewStyle, StyleProp, NativeScrollEvent, NativeSyntheticEvent } from 'react-native';

import { DashboardPageWrapper } from '@components/index';
import { useLogoNavigation } from '@utils/index';

import { DesktopMenuUserInformationsConnected } from '../DesktopMenuUserInformationsConnected';

interface Props extends DashboardPageWrapperStyle {
  title?: string | undefined;
  renderStickyHeader?: () => React.ReactNode;
  renderStickyMobileBottom?: () => React.ReactNode;
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  dashboardPageWrapperRef?: MutableRefObject<{ scrollToTop: () => void }>;
  setScrollValue?: Dispatch<SetStateAction<number>>;
}

interface DashboardPageWrapperStyle {
  cardContentStyle?: StyleProp<ViewStyle>;
  scrollContentContainerStyle?: StyleProp<ViewStyle>;
}

export const DashboardPageWrapperConnected: React.FC<Props> = ({
  children,
  cardContentStyle,
  scrollContentContainerStyle,
  renderStickyHeader,
  dashboardPageWrapperRef,
  renderStickyMobileBottom,
  onScroll,
  setScrollValue,
}) => {
  const { logoNavigateTo } = useLogoNavigation();
  const route: any = useRoute();

  return (
    <DashboardPageWrapper
      dashboardPageWrapperRef={dashboardPageWrapperRef}
      DesktopMenuUserInformationsConnected={<DesktopMenuUserInformationsConnected />}
      onLogoPress={logoNavigateTo}
      onScroll={onScroll}
      renderStickyHeader={renderStickyHeader}
      renderStickyMobileBottom={renderStickyMobileBottom}
      route={route}
      scrollContentContainerStyle={scrollContentContainerStyle}
      setScrollValue={setScrollValue}
    >
      {children}
    </DashboardPageWrapper>
  );
};
