import React from 'react';
import { View } from 'react-native';

import { FontIcon, Title, Paragraph } from '@ere-uilib/atoms';
import { useTheme } from '@ere-uilib/styles';

import { OperationsSubHeaderProps } from './interfaces';
import { useStyles } from './useStyles';

export const OperationsSubHeader: React.FC<OperationsSubHeaderProps> = ({
  advantageList,
  style
}) => {
  const theme = useTheme();

  const styles = useStyles({ style });  
  return (
    <View style={styles.containerStyle}>
      {advantageList.map(advantage => {
        return(
          <View
            style={[styles.detailContainer]}>
            <FontIcon
              color={theme.colors.basics.primary.c500}
              name={advantage.icon || ""}
              size={theme.metrics.iconSizes.m}
              style={styles.chevronStyle}
            />
            <View style={styles.textContainer}>
              <Title
                variant="t8"
                weight='bold'
              >
                {advantage.title}
                {advantage.titleDetail && (
                  <Title
                    variant="t8"
                    weight='regular'
                  >
                    {advantage.titleDetail}
                  </Title>
                )}
              </Title>
              {advantage.description && (
                <Paragraph
                  variant='t4'
                  weight='light'>
                  {advantage.description}
                </Paragraph>
              )}
              {advantage?.customContent}
            </View>
          </View>
        )
      })}
    </View>
  );
};