import React, { useCallback } from 'react';
import {
  HtmlStyledRenderer,
  useTheme,
} from '@components/ERE-UILib';
import { InformativePopin } from '@ere-uilib/organisms';
import { InformativePopinProps } from '@ere-uilib/organisms/overlay/InformativePopin/interfaces';
import { useAdaptedContent } from './useAdaptedContent';

interface BankDetailsSuccessOrErrorPopinProps
  extends Omit<InformativePopinProps, 'onCancel' | 'isDescriptionHtml' | 'description'> {
  quickSignEnumMember?: string
}


export const BankDetailsSuccessOrErrorPopin: React.FC<BankDetailsSuccessOrErrorPopinProps> = ({
  title,
  quickSignEnumMember = '',
  ...props
}) => {

  const theme = useTheme()
  const {
    content,
    popinActions,
    iconProps,
    styles,
    shouldDisplay } = useAdaptedContent(quickSignEnumMember, props.isModalVisible);

  const renderContent = useCallback(() => {
    return (
      <HtmlStyledRenderer
        baseFontStyle={{ fontFamily: theme.fonts.fontFamily.light }}
        html={content.description}
        style={styles.htmlStyledRender}
      />
    );
  }, [styles, theme, quickSignEnumMember]);

  if (!content) return null;

  return (
    <InformativePopin
      content={renderContent}
      title={content.title}
      {...popinActions}
      {...iconProps}
      {...props}
      isModalVisible={shouldDisplay}
    />
  );
};
