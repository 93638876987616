import { connect } from 'react-redux';

import { clearInstallmentData } from '@modules/installment/actions/installmentActions';
import { AppState } from '@modules/reducers';

import { InstallmentFailedPage as InstallmentFailedPageComponent } from './InstallmentFailedPage.component';

const mapStateToProps = (state: AppState) => {
  return {
  };
};

const mapDispatchToProps = ({
  clearInstallmentData: clearInstallmentData
});

export const InstallmentFailedPage = connect(mapStateToProps, mapDispatchToProps)(InstallmentFailedPageComponent);
