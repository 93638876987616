import React, { useCallback, useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';

import {
  DashboardContentWrapperCardComponent,
  DashboardContentWrapperCardPlaceHolder,
  FontIcon,
  getPlanTypeColors,
  IconEnum,
  SimpleButton,
  Text,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { createUseStyles } from '@ere-uilib/styles';
import { BiAccountsState } from '@modules/dashboard/types';

import { BudgetInsightProps as Props } from '../interface';
import { getStyles } from '../styles';
import { BudgetInsightAccountsListCardContent, BudgetInsightDatatableType } from './components';

export const BudgetInsightAccountsListCard = ({
  budgetInsightAccounts,
  onGetBudgetInsightAccounts,
  containerStyle,
  navigation,
  isError,
  isLoading,
  setBudgetInsightManagerUrl
}: Props) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();

  const isWeb = !(isMobile || isTablet);
  const useStyles = createUseStyles(getStyles);

  const styles = useStyles({ theme, isMobile, isTablet, isWeb }, { containerStyle });

  const { formatMessage } = useTranslation();

  const isBudgetInsightAlreadyEnabled = useMemo(() => (
    budgetInsightAccounts && budgetInsightAccounts.length > 0
  ), [budgetInsightAccounts]);

  const renderLoader = () => {
    return <DashboardContentWrapperCardPlaceHolder />;
  };

  const renderLink = useCallback(() => {
    if (!budgetInsightAccounts || budgetInsightAccounts.length === 0) {
      return null;
    }

    return (
      <View style={styles.headerRow}>
        <TouchableOpacity
          onPress={setBudgetInsightManagerUrl}
          style={styles.iconButonRow}>
          <FontIcon
            name={IconEnum.SETTING}
            size={theme.metrics.iconSizes.l}
            style={styles.settingsIconButon}
          />
          <Text style={styles.IconButonRoWText}>
            {!isMobile ? formatMessage({ id: 'AgregationCardDispositifsGererBouton' }) : null}
          </Text>
        </TouchableOpacity>
      </View>
    );
  }, [
    budgetInsightAccounts,
    formatMessage,
    isMobile,
    setBudgetInsightManagerUrl,
    styles,
    theme,
  ]);

  const helpPoint = useMemo(() => {
    const labels = {
      contentHtml: formatMessage({ id: 'AgregationCardInfoDescription' }),
      contentTitle: formatMessage({ id: 'AgregationCardInfoTitle' }),
      modalTitle: formatMessage({ id: 'AgregationCardInfoTitre' })
    }

    return labels;
  }, [formatMessage]);

  const pressBiButton = useCallback(() => {
    if (!isBudgetInsightAlreadyEnabled) navigation.navigate(RouteNames.BiConsentment);
  }, [isBudgetInsightAlreadyEnabled, navigation]);

  const renderEmptyBudgetInsightAccounts = useCallback(() => {
    return (
      <View style={styles.contentStyle}>
        <Text
          style={styles.contentTextStyle}
          variant="t3"
          weight="light">
          {formatMessage({ id: 'AgregationCardDescription' })}
        </Text>
        <SimpleButton
          design="outlined"
          leftIcon={IconEnum.AJOUTER}
          onPress={pressBiButton}
          title={formatMessage({ id: 'AgregationCardAjouterCompteExtBouton' })}
        />
      </View>
    );
  }, [formatMessage, styles, pressBiButton]);

  const formattedAccounts = useMemo(() => {
    if (!budgetInsightAccounts) {
      return [];
    }

    const accounts = new Array<BudgetInsightDatatableType>();

    budgetInsightAccounts.forEach(
      (establishment: { accounts: BiAccountsState[]; name: string; cumulativeBalance: number }) => {
        establishment.accounts.forEach((account: { name: string; balance: number }) => {
          accounts.push({
            account: account.name,
            amount: account.balance,
            establishment: establishment.name
          });
        });
      }
    );

    const newFormattedAccounts = accounts.map(biAccount => {
      const color = theme.colors.basics.primary

      const formattedAccount = {
        color: color.c500,
        account: biAccount.account,
        establishment: biAccount.establishment,
        amount: biAccount.amount
      };

      return formattedAccount;
    });

    return newFormattedAccounts;
  }, [budgetInsightAccounts, theme]);

  const cumulativeBalance = useMemo(() => {
    let newCumulativeBalance = 0;

    if (!budgetInsightAccounts) {
      return newCumulativeBalance;
    }

    budgetInsightAccounts.forEach(
      (establishment: { accounts: BiAccountsState[]; name: string; cumulativeBalance: number }) => {
        newCumulativeBalance += establishment.cumulativeBalance;
      }
    );

    return newCumulativeBalance;
  }, [budgetInsightAccounts])

  const renderContent = useCallback(() => {
    if (!budgetInsightAccounts) {
      return null;
    }

    if (budgetInsightAccounts.length === 0) {
      return renderEmptyBudgetInsightAccounts();
    }

    return (
      <BudgetInsightAccountsListCardContent
        contributionData={{
          legend: formatMessage({ id: 'AgregationCardMontantsBruts' }),
          head: {
            balance: cumulativeBalance
          },
          body: formattedAccounts
        }}
        navigation={navigation}
      />
    );
  }, [
    budgetInsightAccounts,
    cumulativeBalance,
    formatMessage,
    formattedAccounts,
    navigation,
    renderEmptyBudgetInsightAccounts,
  ]);

  return (
    <View style={styles.containerStyle}>
      <DashboardContentWrapperCardComponent
        errorRetryAction={onGetBudgetInsightAccounts}
        helpPoint={helpPoint}
        isError={isError}
        isLoading={isLoading}
        renderLink={renderLink}
        renderLoader={renderLoader}
        title={formatMessage({ id: 'AgregationCardTitre' })}
        testId='AgregationCardTitre'
      >
        {renderContent()}
      </DashboardContentWrapperCardComponent>
    </View>
  );
};
