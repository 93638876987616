import { API_URL } from "@config/index";
import { EstimateCalculContributionApiResponse } from "@constants/contribution";
import axios from "axios";

export async function fetchEstimateCalculContributionByPlanData({
  companyId,
  payload,
  operationType
}: any): Promise<{
  data: EstimateCalculContributionApiResponse[];
}> {
  return axios.post(
    `${API_URL}/api/v1/companies/${companyId}/estimatecontribution/${operationType}`,
    // TODO: Change payload
    JSON.stringify(payload)
  );
}