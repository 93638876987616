import React, { useState } from 'react';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { View } from 'react-native';

import { createUseStyles } from '@ere-uilib/styles';
import { useTheme } from '@ere-uilib/styles/theme';

import { LoaderPlaceholderViewProps } from './interfaces';
import { getStyles } from './styles';

export const LoaderPlaceholderView: React.FC<LoaderPlaceholderViewProps> = ({
  style,
  backgroundColor,
  foregroundColor,
  speed,
  children,
  testID,
})=>{
  const theme = useTheme();
  const [size, setSize] = useState<{width:number, height:number}>({ width: 0, height: 0 });
  const defaultValues = {
    backgroundColor: theme.colors.basics.grey.c200,
    foregroundColor: theme.colors.basics.grey.c100,
    speed: 2,
    height: theme.metrics.spacing.m,
  };
  const styles = useStyles({
    theme,
  }, {
    style,
  });

  return (
    <View
      onLayout={e=>{
        const { width, height } = e.nativeEvent.layout;
        if (width !== size.width || height !== size.height) {
          setSize({ width, height });
        }
      }}
      style={styles.style}
      testID={testID}
    >
      <ContentLoader
        backgroundColor={backgroundColor || defaultValues.backgroundColor}
        foregroundColor={foregroundColor || defaultValues.foregroundColor}
        height={size.height || defaultValues.height}
        speed={speed || defaultValues.speed}
        style={{ position: 'absolute', top: 0, left: 0 }}
        width={size.width}
      >
        <Rect
          height={size.height}
          width={size.width}
          x="0"
          y="0" />
      </ContentLoader>
      {children}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
