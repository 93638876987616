import { RouteProp, useRoute } from "@react-navigation/native";
import React from 'react';
import { useEffect } from 'react';

import {
  OfflineAnnounceLayout360,
  useTranslation,
  LanguageSelector360,
} from '@components/index';
import { InvitationState } from '@modules/invitation/types';
import {
  RelationShipEnteringNavigatorInterface,
  RelationShipEnteringNavigatorRouteNames
} from "@navigation/Interfaces";
import { useLogoNavigation } from '@utils/index';

interface Props {
  onFetchInvitation: (token: string) => void
  invitation: InvitationState
}

export const WelcomePage: React.FC<Props> = ({ invitation, onFetchInvitation }) => {
  const route =
    useRoute<
      RouteProp<
        RelationShipEnteringNavigatorInterface,
        RelationShipEnteringNavigatorRouteNames.Welcome
      >
    >();
  const token = route.params?.token || ''

  useEffect(() => {
    onFetchInvitation(token);
  }, [onFetchInvitation, token]);

  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();
  const renderLanguageSelector = () => <LanguageSelector360 offlineLayoutAbsolutePosition />;
  return (
    <OfflineAnnounceLayout360
      description={formatMessage({ id: 'welcome_description' })}
      onLogoPress={logoNavigateTo}
      renderInOfflinePageLayoutEnd={renderLanguageSelector}
      sourceName="welcomeGif"
      title={formatMessage({ id: 'hello_title', values: { variable: invitation.user.firstName } })}
    />
  );
};
