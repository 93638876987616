import { LanguageSelectorStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: LanguageSelectorStyles
): LanguageSelectorStyles => ({
  containerStyles: [
    {
      paddingVertical: context?.theme.metrics.spacing.s,
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.xs,
    },
    context?.offlineLayoutAbsolutePosition && {
      position: 'absolute',
      ...((context?.isDesktop || context?.isTablet) && {
        top: context?.theme.metrics.spacing.l,
        right: context?.theme.metrics.spacing.l,
      }),
      ...(context?.isMobile && {
        top: context?.theme.metrics.spacing.xs,
        right: context?.theme.metrics.spacing.xs,
      }),
    },
    style?.containerStyles,
  ],
  currentValueStyles: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      height: context?.theme.metrics.iconSizes.l,
    },
    style?.currentValueStyles,
  ],
  flagContainer: [
    {
      width: context?.theme.metrics.iconSizes.l,
      height: context?.theme.metrics.iconSizes.l,
      marginRight: context?.theme.metrics.spacing.xs,
    },
    style?.flagContainer,
  ],
  languageLabelText: [
    {
      marginRight: context?.theme.metrics.spacing.xs,
      minWidth: 20,
      textAlign: 'center',
    },
    style?.languageLabelText,
  ],
  chevronIcon: [
    {
      fontSize: context?.theme.metrics.iconSizes.xm,
    },
    style?.chevronIcon,
  ],
  optionsContainer: [
    {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      paddingTop: context?.theme.metrics.spacing.xxxxl,
      paddingBottom: context?.theme.metrics.spacing.s,
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.xs,
      borderRadius: context?.theme.metrics.borderRadius.s,
    },
    context?.modalVisible ? {
      backgroundColor: context?.theme.colors.basics.white,
      shadowColor: context?.theme.colors.basics.grey.c900,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.15,
      shadowRadius: 5,
      elevation: 7,
    } : {

    },
    style?.optionsContainer,
  ],
  optionContainer: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: context?.theme.metrics.spacing.xs,
    },
    style?.optionContainer,
  ],
  modalContainer: {
    alignItems: 'center',
    marginTop: context?.theme.metrics.spacing.l,
  },
  modalLanguages: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
  },
  modalLanguageButtons: {
    alignItems: 'center',
  },
  modalLanguageSelectedButtons: {
    alignItems: 'center',
    backgroundColor: context?.theme.colors.buttons.menuItemHover.c500,
    borderColor: context?.theme.colors.buttons.menuItemActive.c500,
  },
  modalLanguageButtonsContainer: {
    width: '50%',
    padding: context?.theme.metrics.spacing.s,
  },
});
