import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import { Text, Title } from '@ere-uilib/atoms';
import { useTheme } from '@ere-uilib/styles';

type Nullable<T> = T | null | undefined;

export interface FilePickerErrorStyleProps {
  containerStyle: StyleProp<ViewStyle>;
  titleStyle: StyleProp<TextStyle>;
  subTitleStyle: StyleProp<TextStyle>;
}

export interface FilePickerErrorProps extends Partial<FilePickerErrorStyleProps> {
  title?: Nullable<string>;
  description?: Nullable<string>;
}

export const FilePickerError: React.FC<FilePickerErrorProps> = ({
  title,
  description,
  containerStyle,
  titleStyle,
  subTitleStyle
}) => {
  const theme = useTheme();
  const color = theme.colors.notifications.warning.c500;

  if (!title && !description) return <></>;

  return (
    <View style={containerStyle}>
      {!!title && (
        <Title
          style={[{ color }, titleStyle]}
          variant="t7">
          {title}
        </Title>
      )}
      {!!description && (
        <Text
          style={[{ color }, subTitleStyle]}
          variant="t4"
          weight="light">
          {description}
        </Text>
      )}
    </View>
  );
};
