import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { CardWithLeftThread, Text } from '@ere-uilib/atoms';
import { FiletStepper, HeaderResponse } from '@ere-uilib/molecules';

import { StepProps } from './types';
import { useStyles } from './useStyles';

export function FiletStepperCardWrapper({
  title,
  borderLeftColor,
  headerResponseCustomContainerStyle,
  headerResponseAmount,
  headerResponseTitle,
  showHeaderResponseDarkText,
  disableLine,
  disableIcone,
  children,
  containerStyle,
  isExpandable = false,
  isDefaultExpanded = true,
  displayFiletTitle = true
}: StepProps) {
  const styles = useStyles(displayFiletTitle);
  const [isExpanded, setIsExpanded] = useState(!isExpandable || isDefaultExpanded);

  const toggleExpanded = useCallback(
    () => setIsExpanded(isActuallyExpanded => !isActuallyExpanded),
    []
  );
  return (
    <FiletStepper
      containerStyle={styles.filetContainer}
      contentContainer={containerStyle}
      disableIcone={displayFiletTitle ? disableIcone : true }
      disableLine={disableLine}
      filetContainerStyle={styles.filetSubContainer}>
      <View style={styles.fromContent}>
        {!!title && displayFiletTitle &&(
          <Text style={styles.filetText}>
            {title}
          </Text>
        )}
        <CardWithLeftThread
          addShadow={true}
          borderLeftColor={borderLeftColor}
        >
          {!!headerResponseTitle && (
            <HeaderResponse
              amount={headerResponseAmount}
              customContainerStyle={
                [
                  { borderTopLeftRadius: 0 },
                  headerResponseCustomContainerStyle,
                ]
              }
              darkText={showHeaderResponseDarkText}
              disabled={!isExpandable}
              onPress={toggleExpanded}
              title={headerResponseTitle}
            />
          )}
          {isExpanded && children}
        </CardWithLeftThread>
      </View>
    </FiletStepper>
  );
}
