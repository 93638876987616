import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import {
  DashboardPageWrapperConnected,
  HtmlStyledRenderConnectedContainer
} from '@components/ERE360Components';
import { RouteNames } from '@constants/navigation';
import { NavigationHeader, SimpleButton } from '@ere-uilib/molecules';
import { ItemState } from '@ere-uilib/molecules/inputs/Select/types';
import { ColumnsContainer } from '@ere-uilib/templates';
import { postDrivingDataRequest } from '@modules/control-data/actions/controlDataActions';
import { FetchControlDataState, ControlDataManagementRiskProfile, DrivingDataInputKeyValueEnum, EditedControlDataType } from '@modules/control-data/types';
import ConfirmModalComponent from '@pages/Common/ConfirmModalComponent/ConfirmModalComponent';

import { ControlDataCard, ControlDataCardContent, ControlDataCardLoader, useController } from './components';
import { CardContentType, ControlDataPageComponentProps } from './Interface';
import { useStyles } from './styles';
import { adaptedRiskProfilesData } from './utils';
import { InformativePopin } from '@ere-uilib/organisms';
import { SVGLocalLoader, Text } from '@ere-uilib/atoms';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { locator } from '@constants/locator';

export const ControlDataPageComponent: React.FC<
  ControlDataPageComponentProps
> = ({
  data,
  editingOptions,
  onGetControlData,
  onGetEditingOptions,
  onRehydrateControlData,
  onPostDrivingData,
  onClearControlData,
  controlDataStatus
}) => {
    const styles = useStyles();
    const {
      pageDictionnary,
      onInputValueChange,
      getControlDataFormValues,
      renderDrivingDataCard
    } = useController();

    const [isEditing, setIsEditing] = useState<Record<string, boolean>>({});
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
    const [isPopinVisible, setIsPopinVisible] = useState<boolean>(false);
    const [currentItemPlanId, setCurrentItemPlanId] = useState<number>(-1);
    const [currentManagementValid, setCurrentManagementValid] = useState<Record<string, boolean>>({});
    const [drivingDataFormState, setDrivingDataFormState] = useState<EditedControlDataType>({
      drivingDataRequestViewModels: []
    });
    const navigation = useNavigation();
    const isCurrentManagementEmpty = Object.keys(currentManagementValid).length === 0
      || Object.values(currentManagementValid).includes(false);

    const isValidationButtonDisabled = useMemo(() => isCurrentManagementEmpty
      || controlDataStatus.isPostDrivingDataLoading
      || controlDataStatus.isEditApiLoading, [isCurrentManagementEmpty, controlDataStatus]);

    const isRcCardVisible = useMemo(() => (data.length !== 0)
      || controlDataStatus.isLoadingInit
      || controlDataStatus.isDevicesControlDataError, [data, controlDataStatus]);

    useEffect(() => {
      if (controlDataStatus.isPostDrivingDataSuccess) {
        setIsPopinVisible(true)
      }
    }, [controlDataStatus]);

    useFocusEffect(
      useCallback(() => {
        onGetControlData();
      }, [onGetControlData])
    )

    const handleCloseModal = useCallback(() => {
      setIsConfirmModalVisible(!isConfirmModalVisible);
      setIsEditing({})
    }, [isConfirmModalVisible]);

    const handaleGoBack = useCallback(() => {
      onClearControlData()
      handleCloseModal()
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.Home
      })
    }, [navigation, onClearControlData, handleCloseModal]);

    const handlePopinVisibility = useCallback(() => {
      setIsPopinVisible(false)
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.Home
      });
      onClearControlData()
      setIsEditing({})
    }, [onClearControlData, navigation]);

    const renderHeader = useCallback(
      () => (
        <NavigationHeader
          displayBackButton
          displayCloseButton
          onClose={handleCloseModal}
          onGoBack={handleCloseModal}
          title={pageDictionnary.title}
        />
      ),
      [handaleGoBack, pageDictionnary]
    );

    const handleEditPress = useCallback((planId: FetchControlDataState['planId']) => {
      setIsEditing(prev => ({ [planId]: !prev[planId] })); // removed ...prev, to fix
      if (!isCurrentManagementEmpty) {
        setDrivingDataFormState({
          drivingDataRequestViewModels: []
        })
        setCurrentManagementValid({})
      }
    }, [isCurrentManagementEmpty]);

    const onManagementIdChange = useCallback((managementId: string, condition: boolean) => {
      setCurrentManagementValid(prev => ({ ...prev, [managementId]: condition }))
    }, [])

    const onInputChange = useCallback((
      planId: number,
      formState: EditedControlDataType,
      ...args: Parameters<typeof onInputValueChange>
    ) => {
      const newManagement = onInputValueChange(...args);
      const managementId = args[3].managementId;

      onRehydrateControlData(planId, newManagement);
      const formValues = getControlDataFormValues(newManagement, formState);
      if (formValues) {
        setDrivingDataFormState({
          drivingDataRequestViewModels: [...formValues.drivingDataRequestViewModels]
        })
      }

      const controlDataEditionFormatedData = formValues?.drivingDataRequestViewModels;
      const exist = controlDataEditionFormatedData?.find(e => e.managementId === managementId);

      if (exist && exist.horizon && exist.riskProfile) {
        onManagementIdChange(managementId, exist.horizon > 0 && exist.riskProfile !== '')
      }
    }, [onRehydrateControlData, getControlDataFormValues, setDrivingDataFormState, onManagementIdChange]);

    const renderCard = useCallback(({ isEditable, index, planId, management, grouppedData }: CardContentType) => {
      return (
        <ControlDataCardContent
          isEditing={isEditable}
          isHorizonDisabled={false}//TODO: acceptance criteria
          isRiskProfileDisabled={false} //TODO: acceptance criteria
          itemIndex={index}
          key={`control-data-card-${planId}-row-${index}`}
          management={management}
          onHorizonChange={(item: ItemState) => onInputChange(
            planId,
            drivingDataFormState,
            DrivingDataInputKeyValueEnum.HORIZON_KEY,
            DrivingDataInputKeyValueEnum.HORIZON_VALUE,
            item,
            management
          )}
          onRiskProfileChange={(item: ItemState) => {
            return onInputChange(
              planId,
              drivingDataFormState,
              DrivingDataInputKeyValueEnum.RISK_KEY,
              DrivingDataInputKeyValueEnum.RISK_VALUE,
              item,
              management
            )
          }}
          onManagementChange={onManagementIdChange}
          riskProfilesData={grouppedData}
        />
      )
    }, [onInputChange, onManagementIdChange, drivingDataFormState]);

    const renderCardContents = useCallback(
      (
        planId: FetchControlDataState['planId'],
        managements: FetchControlDataState['managements'],
        isEditable: boolean
      ) => {

        return managements.map((management, index) => {
          const riskProfilesData: ControlDataManagementRiskProfile[] | undefined = editingOptions?.managements.find(
            updData => updData.managementId === management.managementId
          )?.riskProfiles

          const grouppedData = adaptedRiskProfilesData(riskProfilesData || []);
          let itemIdx = 0;
          grouppedData.ageRange.map((_, i) => itemIdx = i)

          const cardContentData = { isEditable, index: itemIdx, planId, management, grouppedData };
          return renderCard(cardContentData)
        });
      },
      [editingOptions, drivingDataFormState, onInputValueChange, onRehydrateControlData]
    );

    const onValidationButtonPress = useCallback(
      (...args: Parameters<typeof postDrivingDataRequest>) => {
        onPostDrivingData(...args)
      }, [onPostDrivingData]);

    const handleRetry = useCallback(
      (planId: number | string) => (isEditing[planId] ? onGetEditingOptions(Number(planId)) : onGetControlData()),
      [isEditing, onGetControlData, onGetEditingOptions]
    );

    const handleCardEditPress = useCallback((planId: number) => {
      handleEditPress(planId)
      onGetEditingOptions(planId);
      setCurrentItemPlanId(planId)

    }, [handleEditPress, setCurrentItemPlanId, onGetEditingOptions]);

    const renderIgnitError = useCallback(() => {
      return (
        <ControlDataCardLoader
          isError={controlDataStatus.isDevicesControlDataError}
          isLoading={controlDataStatus.isLoadingInit}
          retryAction={onGetControlData} />
      );
    }, [onGetControlData, controlDataStatus, data]);

    const renderCards = useCallback(() => {
      if (controlDataStatus.isLoadingInit || controlDataStatus.isDevicesControlDataError) return renderIgnitError()
      return data?.map(({ planId, planName, managements, authorization }) => {
        return renderDrivingDataCard({
          controlDataStatus,
          isEditing,
          planId,
          authorization,
          planName,
          contents: () => renderCardContents(planId, managements, isEditing[planId]),
          onEdit: handleCardEditPress,
          onRetry: handleRetry,
        })
      })

    }, [
      controlDataStatus,
      isEditing,
      renderCardContents,
      handleCardEditPress,
      handleRetry,
      renderIgnitError
    ]);

    const renderSVGContent = useCallback(() => {
      return (
        <View style={styles.successPopinSVGImgContent}>
          <View style={styles.svgImgWrapper}>
            <SVGLocalLoader
              name={"budgetinsightSuccess"}
            />
          </View>
          <Text
            variant='t3'
            weight='light'
            style={styles.successTextDescription}
          >
            {pageDictionnary.popinMessage}
          </Text>
        </View>
      )
    }, [pageDictionnary]);

    const renderValidationButton = useCallback(() => {

      if (isEditing[currentItemPlanId]) {
        return (
          < SimpleButton
            loading={controlDataStatus.isPostDrivingDataLoading}
            containerStyle={styles.buttonContainer}
            disabled={isValidationButtonDisabled}
            onPress={() => onValidationButtonPress(drivingDataFormState, currentItemPlanId)}
            size='large'
            title={pageDictionnary.validationButtonTitle}
            testId={locator._profile._piloted_data._save_piloted_data}
          />
        )
      }
    }, [
      isEditing,
      currentItemPlanId,
      isValidationButtonDisabled,
      drivingDataFormState,
      onValidationButtonPress,
      pageDictionnary,
    ]);

    const renderDrivingDataHtmlContent = useCallback(() => {
      if (!data.length) return null;
      return (
        <View style={styles.headerHtmlWrapper}>
          <HtmlStyledRenderConnectedContainer
            html={pageDictionnary.headerDescription}
          />
        </View>
      );
    }, [styles, pageDictionnary, data]);

    const renderHtmlRcContent = useCallback(() => {
      if (isRcCardVisible) return null;

      return (
        <ControlDataCard noHeader>
          <HtmlStyledRenderConnectedContainer
            html={pageDictionnary.drivingDataRCForm}
          />
        </ControlDataCard>
      );
    }, [
      isRcCardVisible,
      pageDictionnary
    ]);

    return (
      <DashboardPageWrapperConnected cardContentStyle={styles.container}>
        <ColumnsContainer
          hasColumnCenter
          hasRenderRightDesktopColumn={false}
          renderHeader={renderHeader}>
          <View style={styles.contentWrapper}>
            {renderDrivingDataHtmlContent()}
            {renderCards()}
            {renderValidationButton()}
            {renderHtmlRcContent()}
          </View>
          <ConfirmModalComponent
            closeModalAction={handaleGoBack}
            goBackAction={handleCloseModal}
            isPage={false}
            modalVisible={isConfirmModalVisible}
          />
        </ColumnsContainer >
        <InformativePopin
          content={renderSVGContent}
          buttonTitle={pageDictionnary.popinButtonTitle}
          isModalVisible={isPopinVisible}
          onValidate={handlePopinVisibility}
          testId={locator._profile._piloted_data._go_back}
        />
      </DashboardPageWrapperConnected >
    );
  };
