import { connect } from 'react-redux';

import { 
  getProfitSharingIncentiveInitDataState,
} from '@modules/profit-sharing-incentive/selectors';
import { AppState } from '@modules/reducers';

import { ProfitSharingIncentiveOnboardingPageComponent } from './ProfitSharingIncentiveOnboardingPage.component';

const mapStateToProps = (state: AppState) => {
  const initData = getProfitSharingIncentiveInitDataState(state)
  return {
    profitType: initData?.type,
    profitFrame: initData?.legalFramework
  }
};

export const ProfitSharingIncentiveOnboardingPage = connect(
  mapStateToProps,
  null
)(ProfitSharingIncentiveOnboardingPageComponent);
