import React, { useCallback } from 'react';
import { SafeAreaView } from 'react-native';

import { DesktopMenuIconButton } from '@ere-uilib/molecules';
import { createUseStyles, useTheme } from '@ere-uilib/styles';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const DesktopMenuIconTabs: React.FC<Props> = props => {
  const { tabs, selectedTab, containerStyle, setIsWishMenuModalVisible } = props;
  const theme = useTheme();
  const styles = useStyles({ theme }, { containerStyle });

  const onPress = useCallback((itemOnPress: any) => {
    itemOnPress();
    setIsWishMenuModalVisible(false);
  }, [setIsWishMenuModalVisible]);

  return (
    <SafeAreaView style={styles.containerStyle}>
      {tabs.map((item: any) => {
        return (
          <DesktopMenuIconButton
            isSelected={selectedTab === item.tabName}
            key={item.tabName}
            onPress={() => onPress(item.onPress)}
            tabIcon={item.tabIcon}
            tabTitle={item.tabTitle}
            testId={item.testId}
          />
        );
      })}
    </SafeAreaView>
  );
};
const useStyles = createUseStyles(getStyles);
